import styled, { css } from 'styled-components'
import { media } from 'styled-bootstrap-grid'

import Button from 'views/shared/Button'

const FormWrap = styled.div`
  background-color: white;
  margin-bottom: 16px;

  ${media.tablet`
    border: 1px solid ${({ theme }) => theme.colors.black300};
  `}
`

const FormContainer = styled.div`
  padding: 16px 16px 8px 16px;

  ${media.tablet`
    padding: 24px 32px 16px;
  `}
`

const FormTitle = styled.h3`
  font-family: ${({ theme }) => theme.fonts.interTight};
  font-size: 16px;
  line-height: ${28 / 16};
  font-weight: 600;
  color: ${({ theme }) => theme.colors.black1000};
`

const FormRow = styled.div`
  margin-right: -8px;
  margin-left: -8px;
  display: flex;
  flex-wrap: wrap;
`

const FormRowAddress = styled(FormRow)`
  ${media.tablet`
    flex-wrap: nowrap;
  `}
`

const FormCol = styled.div`
  padding-right: 8px;
  padding-left: 8px;
  width: 100%;
  ${({ withoutLabel }) =>
    withoutLabel &&
    css`
      display: flex;
      align-items: flex-end;
    `}
`

const FormColDouble = styled(FormCol)`
  ${media.tablet`
    width: 50%;
  `}
`

const FormColQuarter = styled(FormCol)`
  width: 50%;

  ${media.tablet`
    width: 40%;
  `}
`

const FormColHalf = styled(FormCol)`
  width: 50%;
`

const FormColLong = styled(FormCol)`
  ${media.tablet`
    width: 50%;
  `}

  ${media.desktop`
    width: 75%;
  `}
`

const FormColCard = styled(FormCol)`
  ${media.tablet`
    width: 100%;
  `}

  ${media.desktop`
    width: 50%;
  `}
`
const FormColCardHalf = styled(FormCol)`
  ${media.tablet`
    width: 50%;
  `}

  ${media.desktop`
    width: 25%;
  `}
`

const FormColIndex = styled(FormCol)`
  width: 128px;
  flex-shrink: 0;
`

const FormCardData = styled.div`
  ${media.tablet`
    max-width: 332px;
  `}
`

const Divider = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.black300};
  margin-top: 16px;

  ${media.tablet`
    margin-top: 0;
  `}
`

const ButtonWrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  margin: 0;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;

  ${media.tablet`
    flex-direction: row;
    justify-content: space-between;
    padding-right: 16px;
  `}
`
const ButtonContainer = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  margin: auto;
  max-width: 1200px;
  width: 100%;
  padding: 0 16px;

  ${media.tablet`
    flex-direction: row;
    padding: 0 36px;
    width: auto;
  `}
`

const Agreements = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-right: 16px;
  padding-left: 16px;
  padding-bottom: 16px;

  ${media.tablet`
    padding: 0;
    margin-bottom: 24px;
  `}
`

const SubmitButton = styled(Button)`
  width: 100%;
  margin-top: 4px;

  ${media.tablet`
    width: auto;
    margin-bottom: 0;
    margin-left: 16px;
  `}
`

const CancellationReason = styled.p`
  color: ${({ theme }) => theme.colors.black700};
  padding-right: 16px;
  padding-left: 16px;
  font-size: 14px;
  line-height: ${20 / 14};
  margin-bottom: 16px;

  ${media.tablet`
    display: none;
  `}
`
const FormDivider = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.black300};
`
const FormBlockHeader = styled.div`
  padding: 16px;
  ${media.tablet`
    padding-right: 24px;
    padding-left: 24px;
  `}
`

export {
  FormWrap,
  FormContainer,
  FormTitle,
  FormRow,
  FormRowAddress,
  Divider,
  FormCol,
  FormColDouble,
  FormColHalf,
  FormColQuarter,
  FormColLong,
  FormColCard,
  FormColIndex,
  FormCardData,
  ButtonWrapper,
  ButtonContainer,
  SubmitButton,
  Agreements,
  CancellationReason,
  FormColCardHalf,
  FormBlockHeader,
  FormDivider,
}
