import React from 'react'
import { FormattedMessage } from 'react-intl'

import { TERMS_OF_USE_LINK, DATA_PROCESSING_POLICY_LINK } from 'constants'
import * as S from './styled'

const Agreements = () => (
  <S.Wrapper>
    <FormattedMessage id="reviewBookingView.agreementStart" />
    <S.Link href={DATA_PROCESSING_POLICY_LINK} rel="noopener noreferrer" target="_blank">
      <FormattedMessage id="reviewBookingView.privacyPolicy" />
    </S.Link>
    <FormattedMessage id="reviewBookingView.agreementContinuation" />
    <S.Link href={TERMS_OF_USE_LINK} rel="noopener noreferrer" target="_blank">
      <FormattedMessage id="reviewBookingView.terms" />
    </S.Link>
    <FormattedMessage id="reviewBookingView.agreementEnd" />
  </S.Wrapper>
)

export default Agreements
