import React from 'react'
import PropTypes from 'prop-types'

import { Row, Col } from 'styled-bootstrap-grid'
import Theme from 'constants/Theme'
import isPresent from 'utils/isPresent'
import MainContainer from 'views/shared/MainContainer'
import Header from 'views/shared/Header'
import DesktopPageTitle from 'views/shared/DesktopPageTitle'
import MobilePageTitle from 'views/shared/MobilePageTitle'
import Search from 'views/shared/Search'
import SearchEmpty from 'views/shared/Search/SearchEmptyState'
import Stepper from 'views/shared/Stepper'
import InfiniteScroll from 'views/shared/InfiniteScroll'
import MobileStepperCounter from 'views/shared/MobileStepperCounter'
import ServiceProviders from './ServiceProviders'
import * as S from './styled'

const ProvidersListViewComponent = ({
  providers,
  makeSelectHandler,
  handleSearch,
  handleLoadMore,
  hasMore,
  isLoading,
  isWithTimeSlots,
  isWithBackButton,
}) => (
  <MainContainer bgColor={Theme.colors.black200}>
    <Header withBackButton={isWithBackButton} />
    <S.ScrollWrapper>
      <S.Wrapper fluid>
        <S.StyledRowShadow>
          <Col md={12}>
            <DesktopPageTitle text={{ id: 'providersListView.title' }} />
          </Col>
        </S.StyledRowShadow>
        <Row>
          <S.StyledCol md={4}>
            <S.ScrolledBlock>
              <Stepper />
              <MobileStepperCounter />
              <MobilePageTitle text={{ id: 'providersListView.title' }} />
            </S.ScrolledBlock>
          </S.StyledCol>
          <S.StyledCol md={8}>
            <S.SearchWrap>
              <Search
                className="main-search"
                placeholder={{ id: 'providersListView.searchPlaceholder' }}
                filterEntities={handleSearch}
              />
            </S.SearchWrap>
            <S.ListWrapper>
              {isLoading === false && !isPresent(providers) && (
                <SearchEmpty noSearchResults={{ id: 'providersListView.noSearchResults' }} />
              )}
              <ServiceProviders
                providers={providers}
                isWithTimeSlots={isWithTimeSlots}
                makeSelectHandler={makeSelectHandler}
              />
              <InfiniteScroll hasMore={hasMore} onLoadMore={handleLoadMore} isLoading={isLoading} />
            </S.ListWrapper>
          </S.StyledCol>
        </Row>
      </S.Wrapper>
    </S.ScrollWrapper>
  </MainContainer>
)

ProvidersListViewComponent.defaultProps = {
  providers: [],
  isLoading: undefined,
  hasMore: true,
}

ProvidersListViewComponent.propTypes = {
  isLoading: PropTypes.bool,
  providers: PropTypes.arrayOf(PropTypes.shape()),
  makeSelectHandler: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleLoadMore: PropTypes.func.isRequired,
  isWithTimeSlots: PropTypes.bool.isRequired,
  hasMore: PropTypes.bool,
  isWithBackButton: PropTypes.bool.isRequired,
}

export default ProvidersListViewComponent
