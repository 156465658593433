import React from 'react'
import PropTypes from 'prop-types'
import { is } from 'ramda'

import SvgIcon from 'views/shared/Icons'
import * as S from './styled'

const SearchComponent = ({ className, type, value, placeholder, autoComplete, onChange, onClear, intl, dataCy }) => (
  <S.Wrapper className={className}>
    <S.LeftIcon iconName="search" size={16} />
    <S.Input
      type={type}
      autoComplete={autoComplete}
      placeholder={is(Object, placeholder) ? intl.formatMessage(placeholder) : placeholder}
      value={value}
      onChange={onChange}
      data-cy={dataCy}
    />
    {value && (
      <S.RightButton onClick={onClear} data-cy="search-input-cross">
        <SvgIcon iconName="iconCross" color="black500" />
      </S.RightButton>
    )}
  </S.Wrapper>
)

SearchComponent.defaultProps = {
  type: 'text',
  className: '',
  placeholder: null,
  autoComplete: 'off',
  dataCy: 'search-input',
}

SearchComponent.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  autoComplete: PropTypes.string,
  dataCy: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  intl: PropTypes.shape().isRequired,
}

export default SearchComponent
