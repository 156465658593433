import React from 'react'
import PropTypes from 'prop-types'

import WidgetButton from 'views/shared/WidgetButton'
import FrameLayout from 'views/FrameLayout'

import * as S from './styled'

const OpenButton = ({ buttonAsIcon, buttonText, size, onClick }) => (
  <S.Wrapper size={size} data-cy="widget-open-button-wrapper">
    <FrameLayout dataCy="widget-iframe-button">
      <WidgetButton size={size} onClick={onClick} dataCy="widget-open-button">
        {buttonAsIcon ? (
          <S.ButtonIcon dataCy="widget-open-button-icon" size={36} iconName="calendar" />
        ) : (
          <S.ButtonText data-cy="widget-open-button-text">{buttonText}</S.ButtonText>
        )}
      </WidgetButton>
    </FrameLayout>
  </S.Wrapper>
)

OpenButton.defaultProps = {
  size: 80,
}

OpenButton.propTypes = {
  size: PropTypes.number,
  buttonAsIcon: PropTypes.bool.isRequired,
  buttonText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default OpenButton
