import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import { dataApiRequest, dataApiSuccess, dataApiFailure } from 'state/data/actions'
import { FETCH_TIMEZONES } from 'state/concepts/timezones/types'
import { fetchTimezonesEndpoint } from '../endpoints'

const fetchTimezonesOperation = createLogic({
  type: FETCH_TIMEZONES,
  latest: true,

  async process({ httpClient }, dispatch, done) {
    const { endpoint, url } = fetchTimezonesEndpoint

    dispatch(dataApiRequest({ endpoint }))
    try {
      const { data } = await httpClient.get(url)
      const response = normalize(data)

      window.localStorage.setItem('timezones', JSON.stringify(response))
      window.localStorage.setItem('timezonesCachedAt', Date.now())

      dispatch(dataApiSuccess({ response, endpoint }))
    } catch {
      dispatch(dataApiFailure({ endpoint }))
    }

    done()
  },
})

export default fetchTimezonesOperation
