import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import StyledError from './styled'

const Error = ({ isBgTransition }) => (
  <StyledError>
    <div className={classnames('error-block__inner', { 'error-block__inner--bg-trans': isBgTransition })}>
      <div className="error-block__inner-img">
        <img src={`${ASSET_CDN}/assets/images/oops.svg`} alt="Oops" />
      </div>
      <p className="error-block__inner-txt">
        <FormattedMessage id="widgetPage.errorPage.weAreSorry" />
        <br />
        <FormattedMessage id="widgetPage.errorPage.urlMayBeMisspelled" />
      </p>
    </div>
  </StyledError>
)

Error.defaultProps = {
  isBgTransition: false,
}

Error.propTypes = {
  isBgTransition: PropTypes.bool,
}

export default Error
