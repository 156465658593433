import styled, { css } from 'styled-components'
import { Container, Row, Col, media } from 'styled-bootstrap-grid'

import SvgIcon from 'views/shared/Icons'

const ScrollWrapper = styled.div`
  flex-grow: 1;
  overflow-y: scroll;
`

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: ${({ theme }) => theme.sizes.containerMaxWidth};
  padding-top: ${({ theme }) => theme.sizes.servicesListTopOffset};
  padding-bottom: 8px;

  ${media.tablet`
    padding-top: ${({ theme }) => theme.sizes.servicesListTopOffsetTablet};
    padding-right: 36px;
    padding-left: 36px;
  `}

  ${media.desktop`
    padding-top: ${({ theme }) => theme.sizes.servicesListTopOffsetDesktop};
  `}
`

const StyledRow = styled(Row)`
  flex-grow: 1;
`

const StyledCol = styled(Col)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  ${({ isLoading }) =>
    isLoading &&
    css`
      justify-content: center;
      align-items: center;
    `}
`

const ScrolledBlock = styled.div`
  ${media.tablet`
    position: sticky;
    top: 4px;
  `}
`

const CatAndSearch = styled.div`
  display: flex;
  flex-direction: column-reverse;
  margin-right: -16px;
  margin-bottom: 8px;
  margin-left: -16px;
  border-radius: 2px;
  padding: 12px 16px;
  background-color: white;

  ${media.tablet`
    border-radius: 2px;
    background-color: transparent;
    outline: 1px solid ${({ theme }) => theme.colors.black400};
    flex-direction: row;
    margin-right: 0;
    margin-bottom: 16px;
    margin-left: 0;
    padding: 0;

    &:focus,
    &:focus-within {
      outline-width: 2px;
      outline-color: ${({ theme }) => theme.settings.color};
    }
  `}

  .main-search {
    margin-bottom: 12px;
    border-radius: 2px;
    outline: 1px solid ${({ theme }) => theme.colors.black400};

    &:focus,
    &:focus-within {
      outline-width: 2px;
      outline-color: ${({ theme }) => theme.settings.color};
    }

    ${media.tablet`
      outline: none;
      margin-bottom: 0;
      border-width: 0;
    `}
  }
`

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-right: -16px;
  margin-left: -16px;

  ${media.tablet`
    margin-right: 0;
    margin-left: 0;
  `}
`

const WrapperShadow = styled.div`
  position: relative;
  box-shadow: 0 0 8px 2px rgba(${({ theme }) => theme.colors.boxShadowColor}, 0.1);
`

const StyledRowShadow = styled(StyledRow)`
  ${media.tablet`
    margin-right: 24px;
    margin-left: 24px;
  `}
`

const ShadowContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  ${({ isScrolled }) =>
    isScrolled &&
    css`
      position: relative;
      z-index: 1;
    `}

  ${media.tablet`
    margin-right: -36px;
    margin-left: -36px;
  `}
`

const LoaderWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const CatDropdownWrap = styled.div`
  position: relative;
  flex-shrink: 0;
  width: 100%;
  background-color: white;
  margin-top: 8px;
  margin-left: -8px;

  ${media.tablet`
    width: 195px;
    border-right: 1px solid ${({ theme }) => theme.colors.black400};
    margin-top: 0;
    margin-left: 0;
  `}

  ${media.desktop`
    width: 228px;
  `}
`

const CatDropdownBtn = styled.span`
  position: relative;
  display: inline-block;
  padding-right: 24px;

  ${media.tablet`
    display: inline;
    position: unset;
    padding-right: 0;
  `}
`

const CatDropdownIcon = styled(SvgIcon)`
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  cursor: pointer;
  fill: ${({ theme, active }) => (active ? theme.settings.color : theme.colors.black500)};
`

const CatDropdownCurrent = styled.div`
  display: flex;
  align-items: center;
  overflow-x: hidden;
  height: 100%;
  padding-right: 28px;
  padding-left: 12px;
  cursor: pointer;
`

const CatDropdownText = styled.span`
  text-overflow: ellipsis;
  max-width: 100%;
  margin-right: 8px;
  color: ${({ theme }) => theme.colors.black1000};
`

const CatDropdownAll = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  border-radius: 2px;
  font-size: 12px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.black700};
  background-color: ${({ theme }) => theme.colors.black300};
`

const CatDropdownList = styled.div`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  position: absolute;
  width: 100%;
  max-height: 325px;
  top: calc(100% + 4px);
  left: 0;
  overflow-y: auto;
  background-color: white;
  padding-top: 8px;
  padding-bottom: 2px;
  border-radius: 4px;
  box-shadow: 0 10px 20px rgba(${({ theme }) => theme.colors.boxShadowColor}, 0.1);
  z-index: 1;

  ${media.tablet`
    width: 268px;
  `}
`

const CatDropdownListItem = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  padding: 14px 16px;
  ${({ isSelected }) => isSelected && 'padding-right: 34px;'}
  overflow-x: hidden;
  background-color: transparent;
  transition: background-color 0.15s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.black100};
  }
`

export {
  ScrollWrapper,
  StyledContainer,
  StyledRow,
  StyledCol,
  WrapperShadow,
  StyledRowShadow,
  CatAndSearch,
  LoaderWrap,
  ListWrapper,
  ShadowContainer,
  CatDropdownWrap,
  CatDropdownIcon,
  CatDropdownText,
  CatDropdownCurrent,
  CatDropdownAll,
  CatDropdownList,
  CatDropdownListItem,
  ScrolledBlock,
  CatDropdownBtn,
}
