import React from 'react'
import PropTypes from 'prop-types'
import Frame from 'react-frame-component'
import styled from 'styled-components'

import initialContent from 'utils/initialContent'
import InnerFrameLayout from './InnerFrameLayout'

const FrameLayout = ({ children, id, script, mountTarget, dataCy }) => (
  <FrameLayout.Frame
    id={id}
    data-cy={dataCy}
    initialContent={initialContent(mountTarget, script)}
    head={
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no, maximum-scale=1.0, user-scalable=no"
      />
    }
    mountTarget={`#${mountTarget}`}
  >
    <InnerFrameLayout>{children}</InnerFrameLayout>
  </FrameLayout.Frame>
)

FrameLayout.defaultProps = {
  id: null,
  mountTarget: 'mountTarget',
  script: undefined,
  dataCy: '',
}

FrameLayout.propTypes = {
  id: PropTypes.string,
  mountTarget: PropTypes.string,
  script: PropTypes.string,
  children: PropTypes.node.isRequired,
  dataCy: PropTypes.string,
}

FrameLayout.Frame = styled(Frame)`
  width: 100%;
  height: 100%;
  border-width: 0;
`

export default FrameLayout
