import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: ${({ theme }) => (theme.settings.position === 'right' ? 'row' : 'row-reverse')};
  height: 100%;
  min-height: 100vh;
  /* stylelint-disable */
  min-height: -webkit-fill-available;
`

// eslint-disable-next-line import/prefer-default-export
export { Wrapper }
