import Axios from 'axios'
import qs from 'qs'

import { API_HOST } from 'constants'
import tokenInterceptor from './interceptors/tokenInterceptor'
import refreshInterceptor from './interceptors/refreshInterceptor'

const httpClientParams = {
  // workaround for axios bug https://github.com/axios/axios/issues/1664#issuecomment-415492981
  headers: {
    common: {},
  },
  paramsSerializer: {
    serialize: params => qs.stringify(params, { arrayFormat: 'brackets' }),
  },
}

const httpClient = Axios.create({
  baseURL: `${API_HOST}/api/v1`,
  ...httpClientParams,
})

httpClient.interceptors.request.use(tokenInterceptor)
httpClient.interceptors.response.use(null, refreshInterceptor(httpClient))

export const rootHttpClient = Axios.create({
  baseURL: `${API_HOST}/api/v1/workspaces`,
  ...httpClientParams,
})

rootHttpClient.interceptors.request.use(tokenInterceptor)
rootHttpClient.interceptors.response.use(null, refreshInterceptor(rootHttpClient))

export default httpClient
