import { FormattedMessage } from 'react-intl'
import { Field, useFormikContext } from 'formik'
import React from 'react'

import InputField from 'views/shared/InputField/container'
import isPresent from 'utils/isPresent'
import PhoneInputField from 'views/shared/PhoneInputField'
import * as S from './styled'

const ClientInformationForm = () => {
  const { initialValues } = useFormikContext()

  return (
    <S.FormWrap>
      <S.FormBlockHeader>
        <S.FormTitle>
          <FormattedMessage id="reviewBookingView.completeInfo" />
        </S.FormTitle>
      </S.FormBlockHeader>
      <S.FormDivider />
      <S.FormContainer>
        <S.FormRow>
          <S.FormColDouble>
            <Field
              id="firstName"
              name="firstName"
              type="text"
              component={InputField}
              disabled={isPresent(initialValues.firstName)}
              label={{ id: 'reviewBookingView.client.firstName' }}
            />
          </S.FormColDouble>
          <S.FormColDouble>
            <Field
              id="lastName"
              name="lastName"
              type="text"
              component={InputField}
              disabled={isPresent(initialValues.lastName)}
              label={{ id: 'reviewBookingView.client.lastName' }}
            />
          </S.FormColDouble>
        </S.FormRow>

        <S.FormRow>
          <S.FormCol>
            <Field
              id="email"
              name="email"
              type="text"
              component={InputField}
              label={{ id: 'reviewBookingView.client.email' }}
              placeholder="name@gmail.com"
              disabled={isPresent(initialValues.email)}
            />
          </S.FormCol>
        </S.FormRow>

        <S.FormRow>
          <S.FormColDouble>
            <Field
              id="phoneNumber"
              name="phoneNumber"
              component={PhoneInputField}
              label={{ id: 'reviewBookingView.client.phone' }}
              disabled={isPresent(initialValues.phoneNumber)}
            />
          </S.FormColDouble>
        </S.FormRow>
      </S.FormContainer>
    </S.FormWrap>
  )
}

export default ClientInformationForm
