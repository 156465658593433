import styled from 'styled-components'

const MainContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  /* stylelint-disable */
  min-height: -webkit-fill-available;
  background-color: ${({ bgColor }) => bgColor || 'white'};
`

export default MainContainer
