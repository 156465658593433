import React from 'react'
import PropTypes from 'prop-types'
import { prop, is } from 'ramda'
import { useIntl } from 'react-intl'

import { STRIPE_BASE_STYLES } from 'constants'
import FormattedOrRawMessage from 'views/shared/FormattedOrRawMessage'
import Tooltip from 'views/shared/Tooltip'
import * as S from './styled'

const PaymentFieldComponent = ({ id, label, component, placeholder, tooltipText, onChange, onBlur, form }) => {
  const { formatMessage } = useIntl()
  const error = prop(id, form.errors)
  const touched = prop(id, form.touched)

  return (
    <S.Wrapper label={label}>
      {label && (
        <S.Label htmlFor={id}>
          {formatMessage(label)}
          {tooltipText && (
            <>
              {' '}
              <Tooltip placement="bottom" content={formatMessage(tooltipText)} iconSize={14} />
            </>
          )}
        </S.Label>
      )}
      <S.FieldWrapper isErrors={error && touched}>
        {React.createElement(component, {
          onChange,
          onBlur,
          options: {
            ...STRIPE_BASE_STYLES,
            placeholder: is(Object, placeholder) ? formatMessage(placeholder) : placeholder,
          },
        })}
      </S.FieldWrapper>
      {error && touched && (
        <S.Message isErrors={error && touched}>
          <S.MessageIcon isErrors={error && touched} size={14} iconName="close" />
          <FormattedOrRawMessage message={error} />
        </S.Message>
      )}
    </S.Wrapper>
  )
}

PaymentFieldComponent.defaultProps = {
  label: null,
  placeholder: '',
  errors: undefined,
  component: undefined,
  tooltipText: null,
}

PaymentFieldComponent.propTypes = {
  errors: PropTypes.shape(),
  label: PropTypes.shape({
    id: PropTypes.string.isRequired,
    values: PropTypes.shape(),
  }),
  form: PropTypes.shape({
    errors: PropTypes.shape().isRequired,
    touched: PropTypes.shape().isRequired,
  }).isRequired,
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
  tooltipText: PropTypes.shape(),
  component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
}

export default PaymentFieldComponent
