import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

import { STEP_TYPE_PROPERTIES } from 'constants'
import ServiceStep from './ServiceStep'
import ProviderStep from './ProviderStep'
import TimeStep from './TimeStep'
import ReviewStep from './ReviewStep'
import LocationStep from './LocationStep'

const components = {
  [STEP_TYPE_PROPERTIES.location]: LocationStep,
  [STEP_TYPE_PROPERTIES.service]: ServiceStep,
  [STEP_TYPE_PROPERTIES.provider]: ProviderStep,
  [STEP_TYPE_PROPERTIES.time]: TimeStep,
  [STEP_TYPE_PROPERTIES.review]: ReviewStep,
}

const StepperComponent = props => {
  const { steps } = props

  return (
    <StepperComponent.Wrap data-cy="stepper">
      {steps.map(step => React.createElement(components[step], { ...props, key: step }))}
    </StepperComponent.Wrap>
  )
}

StepperComponent.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
}

StepperComponent.Wrap = styled.div`
  flex-shrink: 0;
  margin-top: 4px;

  ${media.tablet`
    margin-top: 0;
  `}
`

export default StepperComponent
