const namespace = 'session'

export const LOGIN = `${namespace}/LOGIN`
export const LOGOUT = `${namespace}/LOGOUT`
export const LOGOUT_SUCCESS = `${namespace}/LOGOUT_SUCCESS`
export const SEND_CODE = `${namespace}/SEND_CODE`
export const LOGIN_SUCCESS = `${namespace}/LOGIN_SUCCESS`
export const SET_LOGIN_EMAIL = `${namespace}/SET_LOGIN_EMAIL`
export const GET_CLIENT_OWN_PROFILE = `${namespace}/GET_CLIENT_OWN_PROFILE`
export const SET_PASSED_CURRENT_CLIENT = `${namespace}/SET_PASSED_CURRENT_CLIENT`
