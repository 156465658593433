import { combineReducers } from 'redux'

import { SET_BOOKING, SET_STRIPE_ERROR } from './types'

const bookingId = (state = null, action) => {
  switch (action.type) {
    case SET_BOOKING:
      return action.bookingId
    default:
      return state
  }
}

const stripeError = (state = null, action) => {
  switch (action.type) {
    case SET_STRIPE_ERROR:
      return action.stripeError
    default:
      return state
  }
}

export default combineReducers({
  bookingId,
  stripeError,
})
