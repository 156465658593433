import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import build from 'redux-object'

import assignFormErrors from 'utils/form/assignFormErrors'
import { isErrorStatusUnauthorized } from 'utils/getErrorStatus'
import { LOGIN } from 'state/concepts/session/types'
import { dataApiSuccess } from 'state/data/actions'
import { loginSuccess, setLoginEmail } from 'state/concepts/session/actions'
import { sessionRoute } from 'tools/apiRoutes'
import { widgetSelector } from 'state/concepts/widget/selectors'
import setClientCookies from 'utils/setClientCookies'
import getCookiesDomain from 'utils/getCookiesDomain'

const loginOperation = createLogic({
  type: LOGIN,
  latest: true,

  async process({ action: { values, form, history }, getState, httpClient }, dispatch, done) {
    try {
      const { workspace } = widgetSelector(getState())
      const params = {
        email: values.email,
        login_code: values.loginCode,
        captcha: values.captcha,
        include: 'workspace',
      }
      const { data } = await httpClient.post(sessionRoute(workspace.uniqCode), params)

      const response = normalize(data)
      const currentClient = build(response, 'clientProfile')[0]
      const tokens = data.meta.jwt
      setClientCookies(currentClient, tokens)
      setClientCookies(currentClient, tokens, {
        path: `/client/${workspace.uniqCode}`,
        domain: getCookiesDomain(),
      })

      dispatch(loginSuccess(currentClient.id))
      dispatch(dataApiSuccess({ response }))
      dispatch(setLoginEmail(null))
      history.goBack()
    } catch (error) {
      if (isErrorStatusUnauthorized(error)) {
        assignFormErrors(form, error)
      }
    }

    form.setSubmitting(false)
    done()
  },
})

export default loginOperation
