import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'ramda'
import { DateTime } from 'luxon'
import { loadScript } from '@paypal/paypal-js'

import WidgetContext from 'views/Widget/WidgetContext'
import { diffToLocalTime } from 'utils/dateTime'
import { formatDistance } from 'utils/duration'
import { resetSelection as resetSelectionAction } from 'state/store/actions'
import * as modalActions from 'state/modal/actions'
import { recreateBooking as recreateBookingAction } from 'state/concepts/booking/actions'
import { isWithPaymentSelector, bookingSettingsSelector, paypalSelector } from 'state/concepts/booking/selectors'
import { publishableApiKeySelector, stripeAccountSelector } from 'state/concepts/widget/selectors'
import { lastStepNumberSelector } from 'state/steps/selectors'
import ReviewBookingViewComponent from './component'

class ReviewBookingView extends React.Component {
  static contextType = WidgetContext

  state = {
    reservationTime: null,
    startTime: DateTime.local().plus({ minutes: 10 }),
    stripe: null,
    paypal: null,
  }

  componentDidMount = () => {
    this.handleStartTimer()
    this.setStripe()
    this.setPaypal()
  }

  componentDidUpdate = () => {
    this.setStripe()
  }

  setStripe = () => {
    const { contentWindow } = this.context

    const { isWithPayment, publishableApiKey, stripeAccount } = this.props

    if (isWithPayment && !this.state.stripe && stripeAccount) {
      this.setState({ stripe: contentWindow.Stripe(publishableApiKey, { stripeAccount }) })
    }
  }

  setPaypal = async () => {
    const { paypalConfig } = this.props

    if (!paypalConfig?.merchantId || !paypalConfig?.clientId) {
      return
    }

    const paypal = await loadScript({
      clientId: paypalConfig.clientId,
      merchantId: paypalConfig.merchantId,
      dataPartnerAttributionId: paypalConfig.bnCode,
      disableFunding: 'credit,card',
    })

    this.setState({ paypal })
  }

  componentWillUnmount = () => {
    if (this.timerHandle) {
      clearInterval(this.timerHandle)
    }
  }

  handleStartTimer = () => {
    this.updateTimer()
    this.timerHandle = setInterval(this.updateTimer, 1000)
  }

  handleExtend = () => {
    const { recreateBooking, hideModal } = this.props

    recreateBooking(this.handleReplace)
    hideModal()
    this.setState({ startTime: DateTime.local().plus({ minutes: 10 }) })
    this.handleStartTimer()
  }

  handleCancel = () => {
    const { hideModal, resetSelection } = this.props

    this.handleReplace()
    resetSelection()
    hideModal()
  }

  updateTimer = () => {
    const { showModal } = this.props

    this.setState(({ startTime }) => {
      const { milliseconds } = diffToLocalTime(startTime)
      if (milliseconds < 0) {
        clearInterval(this.timerHandle)

        showModal({
          modalType: 'RESERVATION_EXPIRED',
          modalProps: { onCancel: this.handleCancel, onExtend: this.handleExtend },
        })

        return null
      }
      return { reservationTime: formatDistance(startTime, DateTime.local()) }
    })
  }

  handleReplace = () => {
    const { history } = this.props

    history.replace('/')
  }

  render = () => <ReviewBookingViewComponent {...this.props} {...this.state} />
}

ReviewBookingView.defaultProps = {
  publishableApiKey: undefined,
  stripeAccount: undefined,
  isWithPayment: false,
  paypalConfig: {},
}

ReviewBookingView.propTypes = {
  bookingSettings: PropTypes.shape().isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  recreateBooking: PropTypes.func.isRequired,
  history: PropTypes.shape().isRequired,
  resetSelection: PropTypes.func.isRequired,
  publishableApiKey: PropTypes.string,
  stripeAccount: PropTypes.string,
  isWithPayment: PropTypes.bool,
  paypalConfig: PropTypes.shape(),
}

const mapStateToProps = state => ({
  bookingSettings: bookingSettingsSelector(state),
  publishableApiKey: publishableApiKeySelector(state),
  stripeAccount: stripeAccountSelector(state),
  isWithPayment: isWithPaymentSelector(state),
  lastStepNumber: lastStepNumberSelector(state),
  paypalConfig: paypalSelector(state),
})

const mapDispatchToProps = {
  recreateBooking: recreateBookingAction,
  resetSelection: resetSelectionAction,
  ...modalActions,
}

export { ReviewBookingView as ReviewBookingViewContainer }
export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(ReviewBookingView)
