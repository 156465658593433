import { Field, Formik } from 'formik'
import { withRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'
import React from 'react'
import PropTypes from 'prop-types'
import { prop } from 'ramda'

import Theme from 'constants/Theme'
import MainContainer from 'views/shared/MainContainer'
import Header from 'views/shared/Header'
import DesktopPageTitle from 'views/shared/DesktopPageTitle'
import MobilePageTitle from 'views/shared/MobilePageTitle'
import Stepper from 'views/shared/Stepper'
import PriceBar from 'views/shared/PriceBar'
import MobileStepperCounter from 'views/shared/MobileStepperCounter'
import ClientInformationForm from 'views/shared/ClientInformationForm'
import { currentClientSelector } from 'state/concepts/session/selectors'
import { validationSchema } from 'views/ReviewBookingView/BookingFormInjected/BookingForm/bookingSchemas'
import { createWaitlistRequest } from 'state/concepts/waitlistRequest/actions'
import { lastStepNumberSelector } from 'state/steps/selectors'
import useFormSubmit from 'tools/hooks/useFormSubmit'
import CheckboxField from 'views/shared/CheckboxField'
import Agreements from 'views/ReviewBookingView/BookingFormInjected/BookingForm/Agreements'
import { widgetSelector } from 'state/concepts/widget/selectors'
import useDispatchWithArgsAction from 'tools/hooks/useDispatchWithArgsAction'
import { abortStep as abortStepAction } from 'state/steps/actions'
import isSubmitDisabled from 'utils/form/isSubmitDisabled'
import * as S from './styled'

const ReviewWaitlistRequestView = ({ history }) => {
  const currentClient = useSelector(currentClientSelector)
  const abortStep = useDispatchWithArgsAction(abortStepAction, history.goBack)
  const handleSubmit = useFormSubmit(createWaitlistRequest, { history, abortStep })
  const lastStepNumber = useSelector(lastStepNumberSelector)
  const widget = useSelector(widgetSelector)

  return (
    <MainContainer bgColor={Theme.colors.black200}>
      <Header withBackButton />
      <S.Wrapper fluid>
        <S.StyledContainer>
          <S.InnerWrapper>
            <DesktopPageTitle text={{ id: 'reviewBookingView.title' }} />
            <S.StyledRow>
              <S.StyledCol md={4}>
                <S.ScrolledBlock>
                  <Stepper />
                  <PriceBar />
                  <MobileStepperCounter number={lastStepNumber} />
                  <MobilePageTitle text={{ id: 'reviewBookingView.MobileTitle' }} />
                </S.ScrolledBlock>
              </S.StyledCol>
              <S.StyledCol md={8}>
                <S.StuckContainer>
                  <Formik
                    initialValues={{
                      firstName: currentClient?.firstName || '',
                      lastName: currentClient?.lastName || '',
                      email: currentClient?.email || '',
                      phoneNumber: currentClient?.phoneNumber || '',
                      phoneRequired: prop('smsEnabled', widget),
                      agreements: false,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ isSubmitting, dirty, isValid }) => (
                      <>
                        <ClientInformationForm />
                        <S.Agreements>
                          <Field
                            id="agreements"
                            name="agreements"
                            component={CheckboxField}
                            label={<Agreements />}
                            dataCy="agreements-checkbox-field"
                          />
                        </S.Agreements>
                        <S.ButtonWrapper>
                          <S.ButtonContainer>
                            <S.Button
                              minWidth={160}
                              text={{ id: 'availabilityPreferences.addToWaitlist' }}
                              isDisabled={isSubmitDisabled({ isSubmitting, dirty, isValid })}
                            />
                          </S.ButtonContainer>
                        </S.ButtonWrapper>
                      </>
                    )}
                  </Formik>
                </S.StuckContainer>
              </S.StyledCol>
            </S.StyledRow>
          </S.InnerWrapper>
        </S.StyledContainer>
      </S.Wrapper>
    </MainContainer>
  )
}

ReviewWaitlistRequestView.propTypes = {
  history: PropTypes.shape().isRequired,
}

export default withRouter(ReviewWaitlistRequestView)
