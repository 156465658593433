import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

const useDispatchAction = actionCreator => {
  const dispatch = useDispatch()

  return useCallback((...dynamicArgs) => dispatch(actionCreator(...dynamicArgs)), [actionCreator, dispatch])
}

export default useDispatchAction
