import { combineReducers } from 'redux'
import { DateTime } from 'luxon'
import { mergeRight } from 'ramda'
import { omitBy, min, max } from 'lodash'

import { SET_DATE, ADD_TIME_SLOTS, RESET_TIME_SLOTS } from './types'

const date = (state = DateTime.local(), action) => {
  switch (action.type) {
    case SET_DATE:
      return action.date
    default:
      return state
  }
}

const timeSlots = (state = null, action) => {
  switch (action.type) {
    case ADD_TIME_SLOTS: {
      const days = Object.keys(action.timeSlots)

      return mergeRight(
        omitBy(state || {}, (_, day) => day >= min(days) && day <= max(days)),
        action.timeSlots,
      )
    }
    case RESET_TIME_SLOTS:
      return null
    default:
      return state
  }
}

export default combineReducers({
  date,
  timeSlots,
})
