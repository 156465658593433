import styled from 'styled-components'
import { rgba } from 'polished'

import SvgIcon from 'views/shared/Icons'

const DownArrowIcon = styled(SvgIcon)`
  fill: ${({ theme }) => theme.colors.black500};
`

const UpArrowIcon = styled(SvgIcon)`
  transform: rotate(180deg);
  fill: ${({ theme }) => theme.settings.color};
`

const TimePickerWrapper = styled.div`
  .ant-picker {
    padding: 10px 12px;
    border-radius: 2px;
    height: 48px;
    width: 100%;
    border-color: ${({ theme }) => theme.colors.black400};
    cursor: pointer;
  }

  .ant-picker-focused .ant-picker-suffix {
    transform: rotate(180deg);
  }

  .ant-btn-primary {
    border-color: transparent;
  }

  .ant-picker-dropdown {
    inset: 52px auto auto 0 !important;
  }

  .ant-picker input {
    cursor: pointer;
  }

  .ant-btn-primary:enabled,
  .ant-btn-primary:enabled:hover,
  .ant-btn-primary:enabled:focus {
    border-radius: 2px;
    background-color: ${({ theme }) => theme.settings.color};
  }

  .ant-picker-dropdown
    .ant-picker-time-panel-column
    > li.ant-picker-time-panel-cell-selected
    .ant-picker-time-panel-cell-inner {
    background-color: ${({ theme }) => rgba(theme.settings.color, 0.1)};
  }

  .ant-picker-outlined:focus-within {
    box-shadow: none;
    border-color: ${({ theme }) => theme.settings.color};
  }

  .ant-picker-dropdown .ant-picker-panel-container {
    border-radius: 4px;
  }

  .ant-picker-dropdown .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
    width: 92px;
    text-align: center;
    padding-inline-start: 0;
  }

  .ant-picker-dropdown .ant-picker-time-panel-column {
    width: 100px;
  }

  .ant-picker-dropdown
    .ant-picker-time-panel-column
    > li.ant-picker-time-panel-cell
    .ant-picker-time-panel-cell-inner:hover {
    background-color: ${({ theme }) => rgba(theme.settings.color, 0.1)};
  }

  .ant-picker-large .ant-picker-input > input {
    font-size: 14px;
  }
`

export { DownArrowIcon, UpArrowIcon, TimePickerWrapper }
