import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

const Wrapper = styled.div`
  margin-bottom: 12px;

  ${media.tablet`
    margin-bottom: 0;
  `}
`

const Title = styled.h3`
  font-size: 14px;
  line-height: ${20 / 14};
  font-weight: 600;
  text-align: center;
  color: ${({ theme }) => theme.colors.black700};
  margin-bottom: 16px;
`

const NoAvailableHours = styled.p`
  text-align: center;
  color: ${({ theme }) => theme.colors.black400};
  margin-bottom: 0;
  padding-top: 12px;
`

const Slot = styled.div`
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }
`

export { Wrapper, Title, Slot, NoAvailableHours }
