import styled from 'styled-components'

const StyledError = styled.div`
  .error-block__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 52px);
    margin-bottom: 4px;
    background-color: white;
    border: 1px solid #e6eaf1;
  }

  .error-block__inner--bg-trans {
    background-color: transparent;
    border: none;
  }

  .error-block__inner-img {
    width: 200px;
    height: 200px;
    margin-bottom: 16px;
  }

  .error-block__inner-txt {
    width: 80%;
    color: #6b7698;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
  }

  @media (min-width: 1200px) {
    .error-block__inner-img {
      width: 256px;
      height: 256px;
    }

    .error-block__inner-txt {
      width: 460px;
    }
  }
`

export default StyledError
