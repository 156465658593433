import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { media } from 'styled-bootstrap-grid'
import { FormattedMessage } from 'react-intl'

import { EXPERT_BOX_LINK } from 'constants'

const Footer = ({ className }) => (
  <Footer.Container className={className}>
    <FormattedMessage
      id="footer.agreement"
      values={{
        expertBoxLink: (
          <Footer.Link href={EXPERT_BOX_LINK} target="_blank">
            <FormattedMessage id="footer.agreementLink" />
          </Footer.Link>
        ),
        year: new Date().getFullYear(),
      }}
    />
  </Footer.Container>
)

Footer.Container = styled.footer`
  text-align: center;
  padding: 16px;
  border-top: 1px solid ${({ theme }) => theme.colors.black300};
  font-size: 12px;
  line-height: 1.33;
  flex-shrink: 0;
  background-color: white;
  color: ${({ theme }) => theme.colors.black700};

  ${media.tablet`
    white-space: nowrap;
  `}
`

Footer.Link = styled.a`
  color: ${({ theme }) => theme.settings.color};
`

Footer.defaultProps = {
  className: '',
}

Footer.propTypes = {
  className: PropTypes.string,
}

export default Footer
