import React, { memo } from 'react'
import PropTypes from 'prop-types'

import IntlProvider from 'views/IntlProvider'
import AnimatedLayout from 'views/AnimatedLayout'
import Content from './Content'

const PreviewComponent = memo(({ handleCloseWidget, settings, isExpanded }) => (
  <IntlProvider workspaceCode={settings.workspaceId}>
    <AnimatedLayout settings={settings.widgetSettings} isExpanded={isExpanded} handleCloseWidget={handleCloseWidget}>
      <Content settings={settings} handleCloseWidget={handleCloseWidget} />
    </AnimatedLayout>
  </IntlProvider>
))

PreviewComponent.displayName = 'PreviewComponent'

PreviewComponent.defaultProps = {
  settings: null,
}

PreviewComponent.propTypes = {
  settings: PropTypes.shape(),
  isExpanded: PropTypes.bool.isRequired,
  handleCloseWidget: PropTypes.func.isRequired,
}

export default PreviewComponent
