import React from 'react'
import PropTypes from 'prop-types'
import { connect as formikConnect } from 'formik'
import { pick, isEmpty, compose } from 'ramda'
import { connect } from 'react-redux'

import { updatePendingClient as updatePendingClientAction } from 'state/concepts/booking/actions'
import * as S from './styled'

class PaypalButton extends React.Component {
  static propTypes = {
    paypal: PropTypes.shape().isRequired,
    booking: PropTypes.shape().isRequired,
    onSubmit: PropTypes.func.isRequired,
    onValidateEmail: PropTypes.func.isRequired,
    formik: PropTypes.shape().isRequired,
    updatePendingClient: PropTypes.func.isRequired,
  }

  containerRef = React.createRef()

  componentDidMount() {
    this.renderButton()
  }

  get isSubmitDisabled() {
    const {
      formik: { errors, dirty },
    } = this.props

    const formErrors = pick(['firstName', 'lastName', 'email', 'agreements'], errors)

    return !dirty || !isEmpty(formErrors)
  }

  handleClick = (data, actions) => {
    const { formik, onValidateEmail } = this.props
    const {
      values: { email },
    } = formik

    return onValidateEmail(email).then(isValid => (isValid ? actions.resolve() : actions.reject()))
  }

  handleApprove = (data, actions) => {
    const { onSubmit } = this.props

    return actions.order.capture().then(() => {
      onSubmit()
    })
  }

  handleCreateOrder = (data, actions) => {
    const {
      booking,
      updatePendingClient,
      formik: { values },
    } = this.props

    updatePendingClient(pick(['firstName', 'lastName', 'email'], values))

    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: booking.price,
          },
          custom_id: booking.id,
        },
      ],
    })
  }

  renderButton = () => {
    const { paypal } = this.props

    paypal
      .Buttons({
        style: {
          color: 'blue',
          label: 'pay',
          height: 40,
        },
        createOrder: this.handleCreateOrder,
        onApprove: this.handleApprove,
        onClick: this.handleClick,
      })
      .render(this.containerRef.current)
  }

  render() {
    return <S.ButtonContainer ref={this.containerRef} disabled={this.isSubmitDisabled} />
  }
}

const mapDispatchToProps = {
  updatePendingClient: updatePendingClientAction,
}

export { PaypalButton as PaypalButtonContainer }
export default compose(formikConnect, connect(null, mapDispatchToProps))(PaypalButton)
