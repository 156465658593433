import React from 'react'
import PropTypes from 'prop-types'

import * as S from './styled'

const AlertComponent = ({ iconClass, message, canClose, view, onClose, isFullWidth }) => (
  <S.Wrapper view={view} isFullWidth={isFullWidth}>
    <S.AlertIcon view={view} iconName={iconClass} />
    <S.Message>{message}</S.Message>
    {canClose && (
      <button type="button" onClick={onClose}>
        <S.AlertIcon view={view} iconName="iconCross" />
      </button>
    )}
  </S.Wrapper>
)

AlertComponent.defaultProps = {
  canClose: false,
  isFullWidth: false,
  view: '',
  onClose: undefined,
}

AlertComponent.propTypes = {
  canClose: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  view: PropTypes.string,
  iconClass: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func,
}

export default AlertComponent
