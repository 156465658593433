import React from 'react'
import PropTypes from 'prop-types'

import CheckboxFieldComponent from './component'

class CheckboxField extends React.Component {
  static propTypes = {
    field: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
      value: PropTypes.bool,
    }).isRequired,
    onChange: PropTypes.func,
  }

  static defaultProps = {
    onChange: () => {},
  }

  handleChange = event => {
    const { field, onChange } = this.props

    onChange(event.target.checked)
    field.onChange(event)
  }

  render() {
    return <CheckboxFieldComponent {...this.props} onChange={this.handleChange} />
  }
}

export default CheckboxField
