import React from 'react'
import PropTypes from 'prop-types'
import { Col } from 'styled-bootstrap-grid'

import isPresent from 'utils/isPresent'
import Theme from 'constants/Theme'
import MainContainer from 'views/shared/MainContainer'
import Header from 'views/shared/Header'
import DesktopPageTitle from 'views/shared/DesktopPageTitle'
import MobilePageTitle from 'views/shared/MobilePageTitle'
import Search from 'views/shared/Search'
import SearchEmpty from 'views/shared/Search/SearchEmptyState'
import InfiniteScroll from 'views/shared/InfiniteScroll'
import Stepper from 'views/shared/Stepper'
import MobileStepperCounter from 'views/shared/MobileStepperCounter'
import CategoryDropdown from 'views/ServicesListView/CategoryDropdown'
import ServiceCards from './ServiceCards'
import * as S from './styled'

const ServicesListViewComponent = ({
  selectedProvider,
  services,
  serviceCategories,
  category,
  haveCategories,
  shouldSearchReset,
  makeFilterServices,
  resetFilter,
  handleSearch,
  isLoading,
  makeSelectHandler,
  handleLoadMore,
  hasMore,
  totalServicesCount,
  isWithBackButton,
}) => (
  <MainContainer bgColor={Theme.colors.black200}>
    <Header withBackButton={isWithBackButton} />
    <S.ScrollWrapper>
      <S.StyledContainer fluid>
        <S.ShadowContainer>
          <S.StyledRowShadow>
            <Col md={12}>
              <DesktopPageTitle text={{ id: 'servicesListView.title' }} />
            </Col>
          </S.StyledRowShadow>
        </S.ShadowContainer>
        <S.StyledRow>
          <S.StyledCol md={4}>
            <S.ScrolledBlock>
              <Stepper />
              <MobileStepperCounter />
              <MobilePageTitle text={{ id: 'servicesListView.title' }} />
            </S.ScrolledBlock>
          </S.StyledCol>

          <S.StyledCol md={8}>
            <S.CatAndSearch>
              {haveCategories && (
                <CategoryDropdown
                  options={serviceCategories}
                  totalServicesCount={totalServicesCount}
                  makeFilterServices={makeFilterServices}
                  resetCategory={resetFilter}
                  value={category}
                />
              )}
              <Search
                className="main-search"
                shouldReset={shouldSearchReset}
                placeholder={{ id: 'shared.search' }}
                filterEntities={handleSearch}
              />
            </S.CatAndSearch>
            <S.ListWrapper>
              {isLoading === false && !isPresent(services) && (
                <SearchEmpty noSearchResults={{ id: 'servicesListView.noSearchResults' }} />
              )}
              <ServiceCards
                services={services}
                selectedProvider={selectedProvider}
                makeSelectHandler={makeSelectHandler}
              />
              <InfiniteScroll hasMore={hasMore} onLoadMore={handleLoadMore} isLoading={isLoading} />
            </S.ListWrapper>
          </S.StyledCol>
        </S.StyledRow>
      </S.StyledContainer>
    </S.ScrollWrapper>
  </MainContainer>
)

ServicesListViewComponent.defaultProps = {
  services: [],
  serviceCategories: [],
  shouldSearchReset: false,
  selectedProvider: null,
  isLoading: undefined,
  totalServicesCount: null,
  hasMore: true,
  category: null,
}

ServicesListViewComponent.propTypes = {
  shouldSearchReset: PropTypes.bool,
  totalServicesCount: PropTypes.number,
  services: PropTypes.arrayOf(PropTypes.shape()),
  serviceCategories: PropTypes.arrayOf(PropTypes.shape()),
  makeFilterServices: PropTypes.func.isRequired,
  resetFilter: PropTypes.func.isRequired,
  haveCategories: PropTypes.bool.isRequired,
  selectedProvider: PropTypes.shape(),
  isLoading: PropTypes.bool,
  handleSearch: PropTypes.func.isRequired,
  hasMore: PropTypes.bool,
  makeSelectHandler: PropTypes.func.isRequired,
  handleLoadMore: PropTypes.func.isRequired,
  category: PropTypes.shape(),
  isWithBackButton: PropTypes.bool.isRequired,
}

export default ServicesListViewComponent
