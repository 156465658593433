import { createSelector } from 'reselect'
import build from 'redux-object'
import { path, pipe, prop } from 'ramda'

import { localTimezoneSelector } from 'state/concepts/timezones/selectors'
import { currentClientTimezoneOffsetSelector } from 'state/concepts/session/selectors'

const dataSelector = prop('data')

export const timeSelector = path(['time', 'time'])
export const timezoneIdSelector = path(['time', 'timezoneId'])

export const timezoneSelector = createSelector(
  localTimezoneSelector,
  timezoneIdSelector,
  dataSelector,
  (localTimezone, id, data) => (id ? build(data, 'timezone', id) : localTimezone),
)

export const localTimezoneOffsetSelector = pipe(timezoneSelector, prop('offset'))

export const timezoneOffsetSelector = createSelector(
  currentClientTimezoneOffsetSelector,
  localTimezoneOffsetSelector,
  (clientTimezoneOffset, localTimezoneOffset) => clientTimezoneOffset || localTimezoneOffset,
)
