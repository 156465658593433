import { DateTime, Interval } from 'luxon'
import { groupWith, compose, head, pipe, keys, curry, __ } from 'ramda'

import { CALENDAR_BUFFER_DAYS, DAYS_PER_WEEK } from 'constants/timeUnits'
import { formatDuration } from './duration'

export const isToday = date => {
  const nowLocal = DateTime.local()

  return nowLocal.hasSame(date, 'year') && nowLocal.hasSame(date, 'month') && nowLocal.hasSame(date, 'day')
}

export const isDaysEquals = (date, otherDate) =>
  date.year === otherDate.year && date.month === otherDate.month && date.day === otherDate.day

export const formatSlotDateTime = slot => {
  const date = DateTime.fromISO(slot, { setZone: true })

  return isToday(date) ? date.toFormat('t') : date.toFormat('ccc d, t')
}

export const formatSlotDateTitle = day => {
  const date = DateTime.fromISO(day, { setZone: true })

  if (isToday(date)) {
    return { id: 'providersListView.nearestSlotsToday' }
  }

  if (isDaysEquals(date, DateTime.local().plus({ days: 1 }))) {
    return { id: 'providersListView.nearestSlotsTomorrow' }
  }

  return { id: 'providersListView.nearestSlotsDate', values: { date: date.toFormat('cccc, d') } }
}

export const groupNearestTimeSlots = compose(
  head,
  groupWith((a, b) => {
    const aDate = DateTime.fromISO(a, { setZone: true })
    const bDate = DateTime.fromISO(b, { setZone: true })

    return isDaysEquals(aDate, bDate)
  }),
)

export const firstDateOfCalendarISO = date => date.startOf('month').minus({ days: CALENDAR_BUFFER_DAYS }).toISODate()

export const lastDateOfCalendarISO = date => date.endOf('month').plus({ days: CALENDAR_BUFFER_DAYS }).toISODate()

export const dateHour = date => DateTime.fromISO(date, { setZone: true }).hour

export const formattedTime = date => DateTime.fromISO(date, { setZone: true }).toFormat('t')

export const formattedMonthTime = date => DateTime.fromISO(date, { setZone: true }).toFormat('DDD')

export const formattedDate = date => DateTime.fromISO(date, { setZone: true }).toFormat('ccc, t (ZZZZ)')

export const formattedDay = date => DateTime.fromISO(date, { setZone: true }).toFormat('d')

export const formattedMonth = date => DateTime.fromISO(date, { setZone: true }).toFormat('MMMM')

export const formatFullTimeDurationString = (date, duration) =>
  `${DateTime.fromISO(date, { setZone: true }).toFormat('ff')}, ${formatDuration(duration)}`

export const diffToLocalTime = time => time.diff(DateTime.local())

export const nearestSlotsDate = pipe(keys, head, curry(DateTime.fromISO)(__, { setZone: true }))

export const formatReviewDate = day => {
  const date = DateTime.fromISO(day)
  const localDateTime = DateTime.local()
  const { days } = Interval.fromDateTimes(date, localDateTime).toDuration(['days']).toObject()
  const daysCount = Math.trunc(days)

  if (isDaysEquals(date, localDateTime)) {
    return { id: 'shared.today' }
  }
  if (isDaysEquals(date, localDateTime.minus({ days: 1 }))) {
    return { id: 'shared.yesterday' }
  }
  if (daysCount < DAYS_PER_WEEK) {
    return { id: 'shared.formatTimeDays', values: { count: daysCount } }
  }
  if (daysCount === DAYS_PER_WEEK) {
    return { id: 'shared.weekAgo' }
  }
  if (date.hasSame(localDateTime, 'year')) {
    return { id: 'shared.otherDay', values: { date: date.toFormat('MMM d') } }
  }
  return { id: 'shared.otherDay', values: { date: date.toFormat('MMM d, yyyy') } }
}

export const formatFilterDate = date => DateTime.fromJSDate(date).toFormat('d MMM y')
