import styled, { css } from 'styled-components'
import { media } from 'styled-bootstrap-grid'

const FormWrap = styled.div`
  background-color: white;
  margin-bottom: 16px;

  ${media.tablet`
    border: 1px solid ${({ theme }) => theme.colors.black300};
  `}
`

const FormContainer = styled.div`
  padding: 16px 16px 8px 16px;

  ${media.tablet`
    padding: 24px 32px 16px;
  `}
`

const FormTitle = styled.h3`
  font-family: ${({ theme }) => theme.fonts.interTight};
  font-size: 16px;
  line-height: ${28 / 16};
  font-weight: 600;
  color: ${({ theme }) => theme.colors.black1000};
`

const FormRow = styled.div`
  margin-right: -8px;
  margin-left: -8px;
  display: flex;
  flex-wrap: wrap;
`

const FormCol = styled.div`
  padding-right: 8px;
  padding-left: 8px;
  width: 100%;
  ${({ withoutLabel }) =>
    withoutLabel &&
    css`
      display: flex;
      align-items: flex-end;
    `}
`

const FormColDouble = styled(FormCol)`
  ${media.tablet`
    width: 50%;
  `}
`

const FormColIndex = styled(FormCol)`
  width: 128px;
  flex-shrink: 0;
`

const FormDivider = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.black300};
`

const FormBlockHeader = styled.div`
  padding: 16px;
  ${media.tablet`
    padding-right: 24px;
    padding-left: 24px;
  `}
`

export {
  FormWrap,
  FormContainer,
  FormTitle,
  FormRow,
  FormCol,
  FormColDouble,
  FormColIndex,
  FormBlockHeader,
  FormDivider,
}
