import { createSelector } from 'reselect'
import { path, prop, pipe } from 'ramda'

import buildObject from 'utils/buildObject'
import buildCollection from 'utils/buildCollection'
import { pageCountSelector } from 'state/data/selectors'
import { timezoneSelector, timeSelector } from 'state/concepts/time/selectors'
import { fetchProvidersEndpoint } from 'state/concepts/providers/endpoints'
import { workspaceIdSelector, widgetIdSelector } from 'state/concepts/widget/selectors'
import { locationIdSelector } from 'state/concepts/locations/selectors'

const dataSelector = prop('data')
export const serviceIdSelector = path(['services', 'serviceId'])

export const providerIdSelector = path(['providers', 'providerId'])
export const providerIdsSelector = path(['providers', 'providerIds'])
export const paginationSelector = path(['providers', 'pagination'])
export const filtersSelector = path(['providers', 'filters'])

export const fetchProvidersEndpointSelector = createSelector(
  workspaceIdSelector,
  widgetIdSelector,
  pipe(fetchProvidersEndpoint, prop('endpoint')),
)

export const providerSelector = createSelector(providerIdSelector, dataSelector, buildObject('provider'))

export const providersSelector = createSelector(providerIdsSelector, dataSelector, (providerIds, data) => {
  const providers = buildCollection('provider')(providerIds, data)

  return providers.map(provider => ({ ...provider, price: path(['userProfileService', 'price'], provider) }))
})

export const currentPageSelector = createSelector(paginationSelector, prop('number'))

export const searchQuerySelector = createSelector(filtersSelector, prop('name'))

export const appliedFilters = createSelector(
  searchQuerySelector,
  timezoneSelector,
  serviceIdSelector,
  timeSelector,
  locationIdSelector,
  (name, timezone, serviceId, timeslot, locationId) => ({
    name,
    timezone: prop('id', timezone),
    service_id: serviceId,
    timeslot,
    ...(locationId ? { location_id: locationId } : { online_location: true }),
  }),
)

export const hasMoreProvidersSelector = createSelector(
  pageCountSelector,
  currentPageSelector,
  (count, currentPage) => count > currentPage,
)
