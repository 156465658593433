import { combineReducers } from 'redux'
import { uniq } from 'ramda'

import { SET_LOCATIONS, SET_LOCATION } from './types'

const locationIds = (state = [], action) => {
  switch (action.type) {
    case SET_LOCATIONS:
      return uniq([...state, ...action.locationIds])
    default:
      return state
  }
}

const locationId = (state = null, action) => {
  switch (action.type) {
    case SET_LOCATION:
      return action.locationId
    default:
      return state
  }
}

export default combineReducers({
  locationIds,
  locationId,
})
