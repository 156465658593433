import { createSelector } from 'reselect'
import { prop, propEq, toPairs, find, findLast, pipe, path, length } from 'ramda'

import { STEP_TYPE_PROPERTIES } from 'constants'
import { defineVisibility } from 'utils/stepper'
import isPresent from 'utils/isPresent'

export const serviceIdSelector = path(['services', 'serviceId'])
export const stepsSelector = path(['steps', 'stepsHistory'])
export const stepsOrderSelector = path(['steps', 'stepsOrder'])

export const currentStepSelector = createSelector(
  stepsSelector,
  pipe(
    toPairs,
    findLast(([, step]) => step !== null),
  ),
)

export const findStepByNumberSelector = createSelector(
  stepsSelector,
  (_, stepNumber) => stepNumber,
  (steps, stepNumber) => steps[stepNumber] && [stepNumber, steps[stepNumber]],
)

export const findStepByTypeSelector = createSelector(
  stepsSelector,
  (_, type) => type,
  (steps, type) =>
    find(
      ([, step]) => (type === null && (step === null || step.type === null)) || prop('type', step) === type,
      toPairs(steps),
    ),
)

export const isWithTimeSlotsSelector = createSelector(
  serviceIdSelector,
  currentStepSelector,
  (serviceId, currentStep) =>
    isPresent(serviceId) && isPresent(currentStep) && propEq(STEP_TYPE_PROPERTIES.provider, 'type', currentStep[1]),
)

export const isStepperVisibleSelector = createSelector(stepsSelector, defineVisibility)

export const currentHumanStepNumberSelector = createSelector(currentStepSelector, currentStep =>
  parseInt(path([0], currentStep), 10),
)

export const lastStepNumberSelector = createSelector(stepsOrderSelector, length)
