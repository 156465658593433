import styled from 'styled-components'
import { rgba } from 'polished'
import { media } from 'styled-bootstrap-grid'

import SvgIcon from 'views/shared/Icons'

const CardWrap = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.colors.black300};
  margin-bottom: 16px;
  margin-left: -16px;
  margin-right: -16px;
  background-color: white;
  transition: 0.7s;
  color: ${({ theme }) => theme.colors.black400};

  &:hover {
    border-color: ${({ theme }) => theme.settings.color};
    background-color: ${({ theme }) => rgba(theme.settings.color, 0.1)};
    color: ${({ theme }) => theme.settings.color};
  }
  ${media.tablet`
    margin-left: 0;
    margin-right: 0;
  `}
  ${media.desktop`
    padding: 24px 32px;
  `}
`
const LinkInfo = styled.div`
  margin-left: 0;
  margin-right: 16px;
  ${media.tablet`
    margin-left: 24px;
  `}
`
const LinkTitle = styled.p`
  font-size: 16px;
  font-weight: 600;
  line-height: ${28 / 16};
  color: ${props => props.theme.colors.black1000};
  margin-bottom: 8px;
  ${media.tablet`
    font-size: 20px;
    line-height: ${32 / 20};
    margin-bottom: 0;
  `}
`
const LinkDescription = styled.p`
  font-size: 14px;
  line-height: ${20 / 14};
  color: ${props => props.theme.colors.black700};
  ${media.tablet`
    font-size: 16px;
    line-height: ${28 / 16};
  `}
`
const VideoIcon = styled(SvgIcon)`
  fill: ${({ theme }) => theme.settings.color};
  width: 20px;
  display: none;
  ${media.tablet`
    width: 24px;
  `}
  ${media.tablet`
    display: block;
  `}
`
const VideoIconMobile = styled(SvgIcon)`
  fill: ${({ theme }) => theme.settings.color};
  width: 20px;
  margin-right: 14px;
  ${media.tablet`
    display: none;
  `}
`
const ArrowIcon = styled(SvgIcon)`
  fill: currentColor;
  margin-left: auto;
`
export { VideoIcon, ArrowIcon, LinkDescription, LinkTitle, LinkInfo, CardWrap, VideoIconMobile }
