import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

import SvgIcon from 'views/shared/Icons'

const PriceBar = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  ${media.tablet`
    margin-bottom: 0;
    padding: 12px 16px;
    background-color: white;
    border: 1px solid ${({ theme }) => theme.colors.black300};
    justify-content: space-between;
  `}
`

const PriceBarTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: ${24 / 16};
  color: ${({ theme }) => theme.colors.black700};
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: white;
  justify-content: center;
  margin-right: 16px;

  ${media.tablet`
    width: auto;
    height: auto;
    border-radius: none;
    background-color: transparent;
    margin-right: 0;
  `}
`

const PriceBarPrice = styled.p`
  font-family: ${({ theme }) => theme.fonts.interTight};
  font-size: 16px;
  line-height: ${24 / 16};
  color: ${({ theme }) => theme.colors.black1000};

  ${media.tablet`
    font-weight: 600;
  `}
`
const PriceMessage = styled.div`
  display: none;

  ${media.tablet`
    display: inline-block;
  `}
`
const PriceBarIcon = styled.p`
  color: ${({ theme }) => theme.colors.black700};

  ${media.tablet`
    margin-right: 16px;
    color: ${({ theme }) => theme.colors.black500};
  `}
`

const Icon = styled(SvgIcon)`
  width: 12px;
  height: 12px;
  fill: ${({ theme }) => theme.colors.black700};

  ${media.tablet`
    width: 22px;
    height: 22px;
    fill: ${({ theme }) => theme.colors.black500};
  `}
`

export { PriceBar, PriceBarTitle, PriceBarPrice, PriceMessage, PriceBarIcon, Icon }
