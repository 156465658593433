import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import { hoursFromSeconds, minutesFromSeconds } from 'utils/duration'
import * as S from './styled'

const DurationComponent = memo(({ seconds, visibleMobile, isStepper, className }) => {
  const hours = hoursFromSeconds(seconds)
  const hasHours = hours > 0
  const minutes = minutesFromSeconds(seconds)
  const hasMinutes = minutes > 0

  return (
    <S.Duration className={`service-duration ${className}`} visibleMobile={visibleMobile} isStepper={isStepper}>
      <span>
        {hasHours && <FormattedMessage id={`duration.hours${visibleMobile ? 'Short' : 'Long'}`} values={{ hours }} />}
        {hasHours && hasMinutes && ' '}
        {hasMinutes && (
          <FormattedMessage id={`duration.minutes${visibleMobile ? 'Short' : 'Long'}`} values={{ minutes }} />
        )}
      </span>
    </S.Duration>
  )
})

DurationComponent.displayName = 'DurationComponent'

DurationComponent.defaultProps = {
  visibleMobile: false,
  isStepper: false,
  className: '',
}

DurationComponent.propTypes = {
  seconds: PropTypes.number.isRequired,
  visibleMobile: PropTypes.bool,
  isStepper: PropTypes.bool,
  className: PropTypes.string,
}

export default DurationComponent
