import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

import SvgIcon from 'views/shared/Icons'

export const ReviewCardWrap = styled.div`
  margin-bottom: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey400};

  ${media.tablet`
    margin-bottom: 24px;
  `}
`

export const ReviewPersonInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`

export const ReviewPersonName = styled.p`
  font-family: ${({ theme }) => theme.fonts.interTight};
  font-size: 14px;
  line-height: ${20 / 14};
  font-weight: 600;
  color: ${({ theme }) => theme.colors.black1000};

  ${media.tablet`
    font-size: 16px;
    line-height: ${24 / 16};
  `}
`

export const ReviewRating = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`

export const ReviewTimestamp = styled.span`
  margin-left: 4px;
  color: ${({ theme }) => theme.colors.black700};
  font-size: 16px;
`

export const ReviewInterviewIcon = styled(SvgIcon)`
  width: 16px;
  fill: ${({ theme }) => theme.colors.black500};

  ${media.tablet`
    width: 20px;
  `}
`

export const ReviewInterview = styled.p`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  font-size: 14px;
  line-height: ${20 / 14};
  color: ${({ theme }) => theme.colors.black700};

  ${media.tablet`
    font-size: 16px;
    line-height: ${24 / 16};
  `}
`
