import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import responseIds from 'utils/responseIds'
import { dataApiRequest, dataApiSuccess, dataApiFailure } from 'state/data/actions'
import { workspaceIdSelector, widgetIdSelector } from 'state/concepts/widget/selectors'
import { FETCH_PROVIDERS } from '../types'
import { setProviders } from '../actions'
import { paginationSelector, appliedFilters } from '../selectors'
import { fetchProvidersEndpoint } from '../endpoints'

const fetchProvidersOperation = createLogic({
  type: FETCH_PROVIDERS,
  latest: true,

  async process({ httpClient, getState }, dispatch, done) {
    const state = getState()
    const workspaceId = workspaceIdSelector(state)
    const widgetId = widgetIdSelector(state)
    const { endpoint, url } = fetchProvidersEndpoint(workspaceId, widgetId)

    const params = {
      page: paginationSelector(state),
      filter: appliedFilters(state),
      include: 'service,user-profile-service',
    }

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.get(url, { params })
      const response = normalize(data, { endpoint })

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(setProviders(responseIds(data)))
    } catch {
      dispatch(dataApiFailure({ endpoint }))
    }
    done()
  },
})

export default fetchProvidersOperation
