import { createStore, applyMiddleware } from 'redux'
import { createLogicMiddleware } from 'redux-logic'
import { composeWithDevTools } from 'redux-devtools-extension'
import { Settings } from 'luxon'

import httpClient from 'tools/httpClient'
import conceptsOperations from '../concepts/operationsRoot'
import stepsOperations from '../steps/operations'
import rootReducer from './reducer'

const operations = [...conceptsOperations, ...stepsOperations]

const configureStore = preloadedState => {
  const operationsDependencies = {
    httpClient,
  }

  const logicMiddleware = createLogicMiddleware(operations, operationsDependencies)
  const middlewares = applyMiddleware(logicMiddleware)
  const enhancer = composeWithDevTools(middlewares)

  const store = createStore(rootReducer, preloadedState, enhancer)

  store.logicMiddleware = logicMiddleware
  store.httpClient = operationsDependencies.httpClient

  Settings.defaultLocale = store.getState().intl.locale

  return store
}

export default configureStore
