export default (mountTarget, script = '') => `
  <!DOCTYPE html>
  <html>
    <head></head>
    <body>
      ${script}
      <div id="${mountTarget}"></div>
    </body>
  </html>
`
