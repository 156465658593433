import { trim, isNil } from 'ramda'

const profileName = (profile, fallbackString = '—') => {
  const { firstName, lastName } = profile || {}

  if (isNil(firstName) || isNil(lastName)) {
    return fallbackString
  }

  return trim(`${firstName} ${lastName}`)
}

export default profileName
