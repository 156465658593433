import styled from 'styled-components'

const colors = {
  error: 'red1000',
  info: 'orange1000',
}

// eslint-disable-next-line import/prefer-default-export
export const Wrapper = styled.div`
  position: relative;

  .main-input__field {
    width: 100%;
    background-color: white;
    border-width: 1px;
    border-style: solid;
    border-radius: 2px;
    padding: 13px 12px 13px 76px;
    font-size: 14px;
    line-height: ${20 / 14};
    color: ${({ theme }) => theme.colors.black700};
    transition: border-color 0.3s ease 0s;
    border-color: ${({ theme, kind }) => (kind ? theme.colors[colors[kind]] : theme.colors.black400)};

    &:hover {
      border-color: ${({ theme }) => theme.settings.color};
    }

    &:focus {
      box-shadow: 0 0 0 1px ${({ theme }) => theme.settings.color};
      border-color: ${({ theme }) => theme.settings.color};
    }

    &::placeholder {
      color: ${({ theme }) => theme.colors.black500};
    }
  }
`
