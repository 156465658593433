import styled from 'styled-components'
import { Col, media, Row, Container } from 'styled-bootstrap-grid'

import SubmitFormButton from 'views/shared/SubmitFormButton'

const Wrapper = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  height: 100vh;
`

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: ${({ theme }) => theme.sizes.containerMaxWidth};
  ${media.tablet`
    padding: 0;
  `}
`

const InnerWrapper = styled.div`
  padding-top: 16px;
  padding-bottom: 78px;
  ${media.tablet`
    padding-top: 32px;
    padding-right: 36px;
    padding-left: 36px;
  `}
`

const StuckContainer = styled.div`
  margin-left: -16px;
  margin-right: -16px;

  ${media.tablet`
    margin-left: 0;
    margin-right: 0;
  `}
`

const Agreements = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-right: 16px;
  padding-left: 16px;
  padding-bottom: 16px;

  ${media.tablet`
    padding: 0;
    margin-bottom: 24px;
  `}
`

const Button = styled(SubmitFormButton)`
  background-color: ${({ theme }) => theme.settings.color};
  margin-left: auto;
`

const ButtonWrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  margin: 0;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;

  ${media.tablet`
    flex-direction: row;
    justify-content: space-between;
    padding-right: 16px;
  `}
`

const ButtonContainer = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  margin: auto;
  max-width: 1200px;
  width: 100%;
  padding: 0 16px;

  ${media.tablet`
    flex-direction: row;
    padding: 0 36px;
    width: auto;
  `}
`

const StyledRow = styled(Row)`
  flex-grow: 1;
`

const StyledCol = styled(Col)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

const ScrolledBlock = styled.div`
  ${media.tablet`
    position: sticky;
    top: 4px;
  `}
`

export {
  Agreements,
  Button,
  ButtonWrapper,
  ButtonContainer,
  Wrapper,
  StuckContainer,
  StyledContainer,
  InnerWrapper,
  StyledRow,
  StyledCol,
  ScrolledBlock,
}
