import { createSelector } from 'reselect'
import { prop, path, pipe } from 'ramda'

import buildObject from 'utils/buildObject'
import buildCollection from 'utils/buildCollection'
import { pageCountSelector, endpointMetaSelector } from 'state/data/selectors'
import { providerIdSelector } from 'state/concepts/providers/selectors'
import { workspaceIdSelector, widgetIdSelector } from 'state/concepts/widget/selectors'
import { fetchServicesEndpoint, fetchServiceCategoriesEndpoint } from 'state/concepts/services/endpoints'
import { locationIdSelector } from 'state/concepts/locations/selectors'

const dataSelector = prop('data')

export const categoryIdsSelector = path(['services', 'categoryIds'])
export const serviceIdSelector = path(['services', 'serviceId'])
export const serviceIdsSelector = path(['services', 'serviceIds'])
export const paginationSelector = path(['services', 'pagination'])
export const filtersSelector = path(['services', 'filters'])

export const fetchServicesEndpointSelector = createSelector(
  workspaceIdSelector,
  widgetIdSelector,
  pipe(fetchServicesEndpoint, prop('endpoint')),
)

export const fetchServicesCategoriesEndpointSelector = createSelector(
  workspaceIdSelector,
  widgetIdSelector,
  pipe(fetchServiceCategoriesEndpoint, prop('endpoint')),
)

export const categoriesSelector = createSelector(categoryIdsSelector, dataSelector, buildCollection('serviceCategory'))

export const serviceSelector = createSelector(serviceIdSelector, dataSelector, buildObject('service'))

export const servicesSelector = createSelector(serviceIdsSelector, dataSelector, (serviceIds, data) => {
  const services = buildCollection('service')(serviceIds, data)

  return services.map(service => ({ ...service, price: path(['userProfileService', 'price'], service) }))
})

export const currentPageSelector = createSelector(paginationSelector, prop('number'))

export const appliedFilters = createSelector(
  filtersSelector,
  providerIdSelector,
  locationIdSelector,
  ({ name, category }, providerId, locationId) => ({
    name,
    service_category_id: category,
    user_profile_id: providerId,
    ...(locationId ? { location_id: locationId } : { online_location: true }),
  }),
)

export const searchQuerySelector = createSelector(filtersSelector, prop('name'))

export const activeCategoryIdSelector = createSelector(filtersSelector, prop('category'))

export const hasMoreServicesSelector = createSelector(
  pageCountSelector,
  currentPageSelector,
  (count, currentPage) => count > currentPage,
)

export const categorySelector = createSelector(activeCategoryIdSelector, dataSelector, buildObject('serviceCategory'))

export const totalServicesCountSelector = createSelector(endpointMetaSelector, prop('totalServicesCount'))
