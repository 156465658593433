import React from 'react'
import { FormattedMessage } from 'react-intl'

import EmptyImage from './emptyImage'
import * as S from './styled'

const WidgetEmpty = () => (
  <S.Wrapper>
    <EmptyImage />
    <S.Text>
      <FormattedMessage id="mainView.empty" />
    </S.Text>
  </S.Wrapper>
)

export default WidgetEmpty
