export const TIME_UNITS_MINUTE = 'minutes'
export const TIME_UNITS_HOUR = 'hours'
export const TIME_UNITS_DAY = 'days'
export const TIME_UNITS_MONTH = 'months'

export const DATE_PICKER_VIEW = {
  year: 'year',
}

export const CALENDAR_BUFFER_DAYS = 6
export const DAYS_PER_WEEK = 7
