import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { PROVIDER_MODAL_TABS } from 'constants'
import ModalContext from 'views/Modal/ModalContext'
import ProviderModal from 'views/ProvidersListView/ProviderModal'
import ReviewStarRating from 'views/shared/ReviewStarRating'
import * as S from './styled'

const ReviewsInformation = ({ provider, provider: { publishedRating, publishedReviewsCount }, handleSelect }) => {
  const rating = Math.round(publishedRating)
  const { showModal } = useContext(ModalContext)

  if (publishedReviewsCount === 0) return null

  return (
    <S.ReviewsInformation
      onClick={showModal(ProviderModal, {
        provider,
        activeTab: PROVIDER_MODAL_TABS.reviews,
        handleSelect,
      })}
    >
      <ReviewStarRating rating={rating} />
      <S.ReviewsCount data-cy="review-count">({publishedReviewsCount})</S.ReviewsCount>
    </S.ReviewsInformation>
  )
}

ReviewsInformation.propTypes = {
  provider: PropTypes.shape().isRequired,
  handleSelect: PropTypes.func.isRequired,
}

export default ReviewsInformation
