import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withFormik } from 'formik'
import { compose, prop } from 'ramda'

import { PAYMENT_METHODS } from 'constants/payments'
import isPresent from 'utils/isPresent'
import isCompleted from 'utils/isCompleted'
import formattedPrice from 'utils/formattedPrice'
import { resetSelection as resetSelectionAction } from 'state/store/actions'
import { updateBooking as updateBookingAction, setStripeError } from 'state/concepts/booking/actions'
import {
  bookingSelector,
  stripeErrorSelector,
  isWithPaymentSelector,
  paypalSelector,
} from 'state/concepts/booking/selectors'
import { isPreviewModeSelector, stripeAccountSelector } from 'state/concepts/widget/selectors'
import WidgetContext from 'views/Widget/WidgetContext'
import { currentClientSelector } from 'state/concepts/session/selectors'
import validateEmail from 'utils/validateEmail'
import BookingFormComponent from './component'
import { validationSchema, validationPaymentSchema } from './bookingSchemas'

class BookingForm extends React.Component {
  static contextType = WidgetContext

  state = {
    isAlertVisible: false,
    stripeForm: {
      touched: { cardNumber: false, cardExpiry: false, cardCvc: false },
      completed: { cardNumber: false, cardExpiry: false, cardCvc: false },
      errors: {
        cardNumber: { id: 'validation.mixed.required' },
        cardExpiry: { id: 'validation.mixed.required' },
        cardCvc: { id: 'validation.mixed.required' },
      },
    },
    paymentMethod: this.defaultPaymentMethod,
  }

  get defaultPaymentMethod() {
    return this.isStripeConnected ? PAYMENT_METHODS.stripe : PAYMENT_METHODS.paypal
  }

  get isSubmitDisabled() {
    const { isWithPayment, dirty, isValid, isSubmitting, isPreviewMode } = this.props
    const {
      stripeForm: { completed },
    } = this.state

    if (isPreviewMode) {
      return false
    }

    return !dirty || !isValid || (isWithPayment && !isCompleted(completed)) || isSubmitting
  }

  get buttonText() {
    const { isWithPayment, booking, isPreviewMode } = this.props

    if (isPreviewMode) {
      return { id: 'reviewBookingView.previewDone' }
    }

    if (isWithPayment && booking) {
      return { id: 'reviewBookingView.bookPaidAppointment', values: { price: formattedPrice(booking.price) } }
    }

    return { id: 'reviewBookingView.bookAppointment' }
  }

  get isPayPalConnected() {
    const { paypalConfig } = this.props

    return isPresent(paypalConfig?.merchantId)
  }

  get isStripeConnected() {
    const { stripeAccount } = this.props

    return isPresent(stripeAccount)
  }

  get isStripeAndPayPalConnected() {
    return this.isPayPalConnected && this.isStripeConnected
  }

  handlePaymentMethodChange = event => {
    this.setState({ paymentMethod: event.target.value })
  }

  completeBooking = async () => {
    const { values, updateBooking, setStatus, setErrors, setSubmitting, stripe, elements } = this.props
    const { paymentMethod } = this.state

    setSubmitting(true)

    const isValidEmail = await this.handleValidateEmail(values.email)

    if (isValidEmail) {
      updateBooking(
        values,
        { setStatus, setErrors, setSubmitting },
        this.handlePush,
        prop('confirmCardPayment', stripe),
        prop('getElement', elements),
        paymentMethod,
      )
    }
  }

  resetAndCloseWidget = () => {
    const { resetSelection, history } = this.props
    const { handleCloseWidget } = this.context

    handleCloseWidget?.()
    resetSelection()
    history.replace('/')
  }

  handleSubmit = event => {
    event?.preventDefault()
    const { isPreviewMode } = this.props

    if (isPreviewMode) {
      this.resetAndCloseWidget()
    } else {
      this.completeBooking()
    }
  }

  handleAlertClose = () => {
    this.props.setStripeError(null)
  }

  handleStripeBlur = ({ elementType }) => {
    const { stripeForm } = this.state

    this.setState({
      stripeForm: {
        ...stripeForm,
        touched: {
          ...stripeForm.touched,
          [elementType]: true,
        },
      },
    })
  }

  handleStripeChange = ({ error, elementType, empty, complete }) => {
    const { stripeForm } = this.state

    this.setState({
      stripeForm: {
        ...stripeForm,
        errors: {
          ...stripeForm.errors,
          [elementType]: empty ? { id: 'validation.mixed.required' } : prop('message', error),
        },
        completed: {
          ...stripeForm.completed,
          [elementType]: complete,
        },
      },
    })
  }

  handlePush = email => {
    const { history } = this.props

    history.push('/confirmation', { email })
  }

  handleValidateEmail = async email => validateEmail({ ...this.props, email })

  render = () => (
    <BookingFormComponent
      {...this.props}
      {...this.state}
      onSubmit={this.handleSubmit}
      onStripeChange={this.handleStripeChange}
      onStripeBlur={this.handleStripeBlur}
      onAlertClose={this.handleAlertClose}
      isSubmitDisabled={this.isSubmitDisabled}
      buttonText={this.buttonText}
      onPaymentMethodChange={this.handlePaymentMethodChange}
      isStripeAndPayPalConnected={this.isStripeAndPayPalConnected}
      isPayPalConnected={this.isPayPalConnected}
      isStripeConnected={this.isStripeConnected}
      onValidateEmail={this.handleValidateEmail}
    />
  )
}

BookingForm.propTypes = {
  values: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    index: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
  }).isRequired,
  setStatus: PropTypes.func.isRequired,
  setTouched: PropTypes.func.isRequired,
  setErrors: PropTypes.func.isRequired,
  setSubmitting: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
  }).isRequired,
  bookingSettings: PropTypes.shape().isRequired,
  dirty: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  updateBooking: PropTypes.func.isRequired,
  resetSelection: PropTypes.func.isRequired,
  setStripeError: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isPreviewMode: PropTypes.bool.isRequired,
  stripe: PropTypes.shape(),
  paypal: PropTypes.shape(),
  elements: PropTypes.shape(),
  isWithPayment: PropTypes.bool,
  booking: PropTypes.shape({
    price: PropTypes.string,
  }).isRequired,
  stripeAccount: PropTypes.string,
  paypalConfig: PropTypes.shape(),
}

BookingForm.defaultProps = {
  stripe: null,
  paypal: null,
  elements: null,
  isWithPayment: false,
  stripeAccount: null,
  paypalConfig: {},
}

const mapStateToProps = state => ({
  booking: bookingSelector(state),
  stripeError: stripeErrorSelector(state),
  isWithPayment: isWithPaymentSelector(state),
  isPreviewMode: isPreviewModeSelector(state),
  stripeAccount: stripeAccountSelector(state),
  paypalConfig: paypalSelector(state),
  currentClient: currentClientSelector(state),
})

const mapDispatchToProps = {
  updateBooking: updateBookingAction,
  resetSelection: resetSelectionAction,
  setStripeError,
}

export { BookingForm as BookingFormContainer }
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: ({ currentClient, booking }) => ({
      firstName: currentClient?.firstName || '',
      lastName: currentClient?.lastName || '',
      email: currentClient?.email || '',
      phoneNumber: currentClient?.phoneNumber || '',
      address: '',
      index: '',
      country: '',
      state: '',
      city: '',
      agreements: false,
      phoneRequired: booking?.phoneRequired,
    }),
    validationSchema: ({ isWithPayment }) => (isWithPayment ? validationPaymentSchema : validationSchema),
  }),
  withRouter,
)(BookingForm)
