import { DateTime } from 'luxon'
import { isDaysEquals } from 'utils/dateTime'

export const formatDate = (date, format) => DateTime.fromJSDate(date).toFormat(format)

export const formatDateTime = (time, format, timezoneOffset) => {
  const zone = timezoneOffset ? `UTC${timezoneOffset}` : 'UTC'

  return DateTime.fromISO(time, { zone }).toFormat(format)
}

export const formattedDateOrInterval = ({ start, end }) => {
  const startDate = DateTime.fromJSDate(start)
  const endDate = DateTime.fromJSDate(end)

  // Dec 12, 2024
  if (isDaysEquals(startDate, endDate) || !end) return `${formatDate(start, 'MMM d, y')}`

  const startYear = formatDate(start, 'y')
  const endYear = formatDate(end, 'y')

  // Dec 12, 2024 - Jan 15, 2025
  if (startYear !== endYear) {
    return `${formatDate(start, 'MMM d, y')} - ${formatDate(end, 'MMM d, y')}`
  }

  const startMonth = formatDate(start, 'MMM')
  const endMonth = formatDate(end, 'MMM')

  // Nov 12 - Dec 12, 2024
  if (startMonth !== endMonth) {
    return `${formatDate(start, 'MMM d')} - ${formatDate(end, 'MMM d')}, ${startYear}`
  }

  // Nov 12 - 15, 2024
  return `${startMonth} ${formatDate(start, 'd')} - ${formatDate(end, 'd')}, ${startYear}`
}

export const formattedTimeOrInterval = ({ start, end, timezoneOffset }) => {
  const formatTime = time => formatDateTime(time, 'h:mm', timezoneOffset).replace(':00', '')
  const formatMeridian = time => formatDateTime(time, 'a', timezoneOffset).toLowerCase()

  const startTime = formatTime(start)
  const startMeridian = formatMeridian(start)

  if (!end) return `${startTime} ${startMeridian}`

  const endTime = formatTime(end)
  const endMeridian = formatMeridian(end)

  if (startMeridian === endMeridian) {
    return `${startTime} - ${endTime} ${endMeridian}`
  }

  return `${startTime} ${startMeridian} - ${endTime} ${endMeridian}`
}

export const getDateText = ({ dateRange }) => {
  const [startDate, endDate] = dateRange

  const id = startDate ? 'availabilityPreferences.datePreference' : 'availabilityPreferences.anyDate'
  const date = startDate ? formattedDateOrInterval({ start: startDate, end: endDate }) : undefined

  return { id, values: { date } }
}

export const getTimeText = ({ startTime, endTime, timeType }, timezoneOffset) => {
  const id = `availabilityPreferences.time.${timeType}`
  const time = startTime ? formattedTimeOrInterval({ start: startTime, end: endTime, timezoneOffset }) : undefined

  return { id, values: { time } }
}
