import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import Header from 'views/shared/ModalHeader'
import Body from 'views/shared/DetailsModal/Body'
import Button from 'views/shared/Button'
import Overlay from 'views/shared/Overlay'
import * as S from 'views/ReviewBookingView/BookingErrorModal/styled'

const ReservationExpiredModal = ({ onCancel, onExtend, dataCy }) => (
  <>
    <S.Container maxWidth={480} data-cy={dataCy}>
      <Header
        header={
          <S.Title>
            <FormattedMessage id="modal.reservationExpired.title" />
          </S.Title>
        }
      />
      <Body>
        <S.TextContainer>
          <S.Text offsetBottom={16}>
            <FormattedMessage id="modal.reservationExpired.body.text1" />
          </S.Text>
          <S.Text offsetBottom={16}>
            <FormattedMessage id="modal.reservationExpired.body.text2" />
          </S.Text>
          <S.Text offsetBottom={32}>
            <FormattedMessage id="modal.reservationExpired.body.text3" />
          </S.Text>
        </S.TextContainer>
        <S.ActionButtons>
          <S.CancelButton onClick={onCancel}>
            <FormattedMessage id="modal.reservationExpired.selectAnotherTime" />
          </S.CancelButton>
          <Button
            minWidth={136}
            text={{ id: 'modal.reservationExpired.keepIfAvailable' }}
            onClick={onExtend}
            dataCy="keep-if-available-button"
          />
        </S.ActionButtons>
      </Body>
    </S.Container>
    <Overlay />
  </>
)

ReservationExpiredModal.defaultProps = {
  dataCy: 'extend-reservation-modal-container',
}

ReservationExpiredModal.propTypes = {
  dataCy: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onExtend: PropTypes.func.isRequired,
}

export default ReservationExpiredModal
