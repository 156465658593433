import { LOGIN_CODE_LENGTH } from 'constants/login'
import yup from 'tools/yupLocalized'

export const emailSchema = yup.object().shape({
  email: yup.string().email().required(),
})

export const loginCodeSchema = yup.object().shape({
  loginCode: yup.string().length(LOGIN_CODE_LENGTH, { id: 'login.loginCode.validation.incorrectCode' }).required(),
})
