import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose, length, equals, path } from 'ramda'
import { withRouter } from 'react-router-dom'

import isPresent from 'utils/isPresent'
import withModalContext from 'views/Modal/withModalContext'
import {
  fetchProviders as fetchProvidersAction,
  filterProviders as filterProvidersAction,
  setProvider as setProviderAction,
  resetProviders as resetProvidersAction,
  setCurrentPage as setCurrentPageAction,
  setFilterParams as setFilterParamsAction,
} from 'state/concepts/providers/actions'
import { createBooking as createBookingAction } from 'state/concepts/booking/actions'
import { setTime as setTimeAction } from 'state/concepts/time/actions'
import { loadingSelector } from 'state/data/selectors'
import { serviceSelector } from 'state/concepts/services/selectors'
import { timeSelector } from 'state/concepts/time/selectors'
import {
  providersSelector,
  currentPageSelector,
  fetchProvidersEndpointSelector,
  hasMoreProvidersSelector,
  providerIdSelector,
} from 'state/concepts/providers/selectors'
import { hasManyLocationsSelector, servicesCountSelector } from 'state/concepts/widget/selectors'
import { isWithTimeSlotsSelector } from 'state/steps/selectors'
import { setService as setServiceAction } from 'state/concepts/services/actions'
import ProvidersListViewComponent from './component'

class ProvidersListView extends React.Component {
  state = { targetElement: null }

  get isWithBackButton() {
    const { servicesCount, hasManyLocations } = this.props

    return servicesCount > 1 || hasManyLocations
  }

  componentDidMount = () => {
    const {
      history: { replace },
      location: { state, pathname },
    } = this.props
    if (state?.skipLoad) {
      replace(pathname)
    } else {
      this.props.resetProviders()
      this.props.setCurrentPage(1)
      this.props.setFilterParams({ name: '' })
      this.props.fetchProviders()
    }
  }

  redirectToReview = () => {
    const { history } = this.props

    history.push('/review')
  }

  makeSelectHandler = (provider, time) => event => {
    event.stopPropagation()
    const {
      selectedTime,
      selectedProviderId,
      hideModal,
      setProvider,
      history,
      selectedService,
      createBooking,
      setService,
      setTime,
    } = this.props

    const providerId = provider.id
    const providerServiceId = path(['service', 'id'], provider)

    if (equals(selectedTime, time) && equals(providerId, selectedProviderId)) {
      return
    }

    setProvider(providerId)

    if (time) {
      setTime(time)
      createBooking(this.redirectToReview)
    } else {
      if (!isPresent(selectedService) && providerServiceId) {
        setService(providerServiceId)
      }

      history.push(selectedService || providerServiceId ? '/time' : '/services')
    }

    hideModal()
  }

  handleSearchProviders = filter => {
    const { filterProviders, resetProviders } = this.props

    resetProviders()
    filterProviders(filter)
  }

  handleLoadMore = () => {
    const { providers, currentPage, fetchProviders, setCurrentPage } = this.props

    if (length(providers) === 0) {
      return
    }

    setCurrentPage(currentPage + 1)
    fetchProviders()
  }

  setScrollElement = node => {
    if (node) {
      this.setState({ targetElement: node })
    }
  }

  render = () => (
    <ProvidersListViewComponent
      {...this.state}
      {...this.props}
      makeSelectHandler={this.makeSelectHandler}
      handleSearch={this.handleSearchProviders}
      handleLoadMore={this.handleLoadMore}
      setScrollElement={this.setScrollElement}
      isWithBackButton={this.isWithBackButton}
    />
  )
}

ProvidersListView.defaultProps = {
  providers: [],
  selectedTime: null,
  selectedProviderId: null,
  selectedService: null,
  servicesCount: null,
  hasManyLocations: false,
}

ProvidersListView.propTypes = {
  selectedTime: PropTypes.string,
  selectedProviderId: PropTypes.string,
  providers: PropTypes.arrayOf(PropTypes.shape()),
  selectedService: PropTypes.shape(),
  currentPage: PropTypes.number.isRequired,
  hideModal: PropTypes.func.isRequired,
  fetchProviders: PropTypes.func.isRequired,
  setProvider: PropTypes.func.isRequired,
  setTime: PropTypes.func.isRequired,
  filterProviders: PropTypes.func.isRequired,
  resetProviders: PropTypes.func.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  setFilterParams: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape().isRequired,
  createBooking: PropTypes.func.isRequired,
  setService: PropTypes.func.isRequired,
  servicesCount: PropTypes.number,
  hasManyLocations: PropTypes.bool,
}

const makeMapStateToProps = initialState => {
  const endpoint = fetchProvidersEndpointSelector(initialState)

  return state => ({
    providers: providersSelector(state),
    selectedService: serviceSelector(state),
    servicesCount: servicesCountSelector(state),
    selectedTime: timeSelector(state),
    selectedProviderId: providerIdSelector(state),
    currentPage: currentPageSelector(state),
    isWithTimeSlots: isWithTimeSlotsSelector(state),
    isLoading: loadingSelector(state, endpoint),
    hasMore: hasMoreProvidersSelector(state, endpoint),
    hasManyLocations: hasManyLocationsSelector(state),
  })
}

const mapDispatchToProps = {
  fetchProviders: fetchProvidersAction,
  filterProviders: filterProvidersAction,
  setProvider: setProviderAction,
  resetProviders: resetProvidersAction,
  setCurrentPage: setCurrentPageAction,
  setFilterParams: setFilterParamsAction,
  setTime: setTimeAction,
  createBooking: createBookingAction,
  setService: setServiceAction,
}

export { ProvidersListView as ProvidersListViewContainer }
export default compose(
  connect(makeMapStateToProps, mapDispatchToProps),
  withModalContext,
  withRouter,
)(ProvidersListView)
