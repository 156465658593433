import React from 'react'
import { Formik, Field } from 'formik'
import { prop } from 'ramda'
import styled from 'styled-components'

import { emailSchema } from 'schemas/login'
import useFormSubmit from 'tools/hooks/useFormSubmit'
import { sendCode } from 'state/concepts/session/actions'
import Alert from 'views/shared/Alert'
import InputField from 'views/shared/InputField'
import SubmitFormButton from 'views/shared/SubmitFormButton'

const EmailForm = () => {
  const handleSubmit = useFormSubmit(sendCode)

  return (
    <Formik initialValues={{ email: '' }} validationSchema={emailSchema} onSubmit={handleSubmit}>
      {({ status, isSubmitting }) => (
        <>
          {prop('base', status) && (
            <EmailForm.Alert>
              <Alert view="error" iconClass="close" message={prop('base', status)} isFullWidth />
            </EmailForm.Alert>
          )}
          <Field
            id="email"
            name="email"
            type="text"
            component={InputField}
            label={{ id: 'login.emailAddress' }}
            className="input-wrap"
            data-cy="email-input"
          />
          <EmailForm.Button
            dataCy="submit-email"
            isFullWith
            text={{ id: 'login.emailForm.submit' }}
            isDisabled={isSubmitting}
          />
        </>
      )}
    </Formik>
  )
}

EmailForm.Alert = styled.div`
  margin-bottom: 24px;
  margin-top: 24px;
`
EmailForm.Button = styled(SubmitFormButton)`
  background-color: ${({ theme }) => theme.settings.color};
`

export default EmailForm
