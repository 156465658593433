import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import isPresent from 'utils/isPresent'
import { formattedTime } from 'utils/dateTime'
import SelectableButton from 'views/shared/SelectableButton'
import * as S from './styled'

const DayPartCard = ({ slots, title, handleSelect, isLoading, isTitleShown }) => (
  <S.Wrapper data-cy="day-part-card">
    {isTitleShown && (
      <S.Title>
        <FormattedMessage {...title} />
      </S.Title>
    )}
    {isPresent(slots) ? (
      slots.map(slot => (
        <S.Slot key={slot}>
          <SelectableButton
            id={slot}
            text={formattedTime(slot)}
            onClick={handleSelect}
            dataCy="time-slot-button"
            disabled={isLoading}
            isTimeslot
          />
        </S.Slot>
      ))
    ) : (
      <S.NoAvailableHours>
        <FormattedMessage id="timeSlotsView.noAvailableHours" />
      </S.NoAvailableHours>
    )}
  </S.Wrapper>
)

DayPartCard.defaultProps = {
  isLoading: undefined,
  isTitleShown: false,
}

DayPartCard.propTypes = {
  isLoading: PropTypes.bool,
  slots: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  title: PropTypes.shape({
    id: PropTypes.string.isRequired,
    values: PropTypes.shape(),
  }).isRequired,
  handleSelect: PropTypes.func.isRequired,
  isTitleShown: PropTypes.bool,
}

export default DayPartCard
