import React, { useEffect, useRef, useState } from 'react'
import { DateTime } from 'luxon'
import { useIntl } from 'react-intl'
import PropTypes from 'prop-types'
import { Field } from 'formik'
import { useBoolean } from 'react-use'
import noop from 'lodash/noop'
import { useFrame } from 'react-frame-component'
import classnames from 'classnames'

import DatePicker from 'views/shared/DatePicker'
import Button from 'views/shared/Button'
import InputField from 'views/shared/InputField'
import { getDateText } from 'utils/availabilityPreferences/timeFormatter'
import Theme from 'constants/Theme'
import * as S from './styled'

const RangePickerField = ({ field: { value, onChange: onChangeField, name }, onChange }) => {
  const { formatMessage } = useIntl()
  const [isOpen, toggleCalendar] = useBoolean(false)
  const [date, setDate] = useState(null)
  const dropdownRef = useRef()
  const today = DateTime.now().startOf('day')
  const minDate = DateTime.local()
  /* istanbul ignore next */
  const disablePastDates = params => params.view === 'month' && DateTime.fromJSDate(params.date) < today

  const handleChange = () => {
    onChangeField({ target: { name, value: Array.isArray(date) ? date : [date, date] } })
    onChange()
    toggleCalendar()
  }

  const onSelectAnyDate = () => {
    onChangeField({ target: { name, value: [] } })
    toggleCalendar()
    setDate([])
  }

  const formatDate = dates => {
    const dateLocale = getDateText({ dateRange: dates || [] })

    return formatMessage({ id: dateLocale.id }, dateLocale.values)
  }

  const { document } = useFrame()

  useEffect(() => {
    const handleClick = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) && isOpen) {
        /* istanbul ignore next */
        toggleCalendar()
      }
    }

    document.addEventListener('click', handleClick, true)

    return () => {
      document.removeEventListener('click', handleClick, true)
    }
  }, [dropdownRef, isOpen, toggleCalendar, document])

  return (
    <S.Wrap className={classnames({ 'is-open': isOpen })}>
      <S.Input onClick={toggleCalendar}>
        <Field
          id="date"
          name="date"
          type="text"
          component={InputField}
          value={value ? formatDate(value) : ''}
          className="mb-8"
          placeholder={{ id: 'availabilityPreferences.selectDate' }}
          readOnly
          label={{ id: 'availabilityPreferences.date' }}
        />
        <S.DownArrowIcon size={10} className="calendar-arrow" iconName="iconUpArrow" />
      </S.Input>
      <S.Dropdown ref={dropdownRef} isOpen={isOpen}>
        <DatePicker date={date} minDate={minDate} onChange={setDate} setTileDisable={disablePastDates} selectRange />
        <S.DropdownFooter>
          <Button
            onClick={onSelectAnyDate}
            bgColor={Theme.colors.black200}
            textColorSetting
            text={{ id: 'availabilityPreferences.anyDate' }}
          />
          <Button text={{ id: 'shared.apply' }} onClick={handleChange} />
        </S.DropdownFooter>
      </S.Dropdown>
    </S.Wrap>
  )
}

RangePickerField.defaultProps = {
  onChange: noop,
}

RangePickerField.propTypes = {
  field: PropTypes.shape().isRequired,
  onChange: PropTypes.func,
}

export default RangePickerField
