import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { compose } from 'ramda'
import { connect } from 'react-redux'

import { resetSelection as resetSelectionAction } from 'state/store/actions'
import BookingErrorModalComponent from './component'

class BookingErrorModal extends React.Component {
  handleRedirect = () => {
    const { onClose, resetSelection, history, onSubmit } = this.props

    if (onSubmit) {
      onSubmit()
    } else {
      history.replace('/')
      resetSelection()
    }
    onClose()
  }

  render = () => <BookingErrorModalComponent {...this.props} onRedirect={this.handleRedirect} />
}

BookingErrorModal.defaultProps = {
  onSubmit: null,
}

BookingErrorModal.propTypes = {
  history: PropTypes.shape().isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  resetSelection: PropTypes.func.isRequired,
}

const mapDispatchToProps = {
  resetSelection: resetSelectionAction,
}

export { BookingErrorModal as BookingErrorModalContainer }
export default compose(connect(null, mapDispatchToProps), withRouter)(BookingErrorModal)
