import * as types from './types'

export const setTime = time => ({
  type: types.SET_TIME,
  time,
})

export const setTimezone = timezoneId => ({
  type: types.SET_TIMEZONE,
  timezoneId,
})
