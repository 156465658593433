import React, { useRef } from 'react'
import { FieldArray, Formik } from 'formik'
import { useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import Theme from 'constants/Theme'
import Header from 'views/shared/Header/container'
import MainContainer from 'views/shared/MainContainer'
import PriceBar from 'views/shared/PriceBar/container'
import MobileStepperCounter from 'views/shared/MobileStepperCounter/container'
import MobilePageTitle from 'views/shared/MobilePageTitle'
import { lastStepNumberSelector } from 'state/steps/selectors'
import useScrollTracking from 'tools/hooks/useScrollTracking'
import DesktopPageTitle from 'views/shared/DesktopPageTitle'
import { TIME_PREFERENCES_TYPES } from 'constants/availabilityPreferences'
import validationSchema from 'schemas/availabilityPreferences'
import AvailabilityPreferenceFields from 'views/AvailabilityPreferences/AvailabilityPreferenceFields'
import { setAvailabilityPreferences as setAvailabilityPreferencesAction } from 'state/concepts/waitlistRequest/actions'
import useDispatchAction from 'tools/hooks/useDispatchAction'
import { availabilityPreferencesSelector } from 'state/concepts/waitlistRequest/selectors'
import Stepper from 'views/shared/Stepper'
import isSubmitDisabled from 'utils/form/isSubmitDisabled'
import * as S from './styled'

const AvailabilityPreferences = ({ history }) => {
  const stepNumber = useSelector(lastStepNumberSelector) - 1
  const scrollRef = useRef(null)
  const isScrolled = useScrollTracking(scrollRef.current)
  const setAvailabilityPreferences = useDispatchAction(setAvailabilityPreferencesAction)
  const availabilityPreferences = useSelector(availabilityPreferencesSelector)

  const backToTimeSlots = () => {
    setAvailabilityPreferences(null)

    history.replace('/time')
  }

  const handleSubmit = values => {
    setAvailabilityPreferences(values.availabilityPreferences)

    history.push('/review_waitlist_request')
  }

  return (
    <MainContainer bgColor={Theme.colors.black200}>
      <Header withBackButton />
      <S.ScrollWrapper>
        <S.Wrapper fluid>
          <DesktopPageTitle text={{ id: 'timeSlotsView.title' }} />
          <S.StyledRow>
            <S.StyledLeftCol md={4}>
              <S.ScrolledBlock>
                <Stepper />
                <PriceBar />
                <MobileStepperCounter number={stepNumber} />
                <MobilePageTitle text={{ id: 'timeSlotsView.title' }} />
              </S.ScrolledBlock>
            </S.StyledLeftCol>
            <S.StyledCol ref={scrollRef} isScrolled={isScrolled} md={8}>
              {isScrolled && <S.WrapperShadow />}
              <Formik
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
                initialValues={{
                  availabilityPreferences: availabilityPreferences || [
                    {
                      dateRange: null,
                      timeType: TIME_PREFERENCES_TYPES.anyTime,
                      startTime: null,
                      endTime: null,
                    },
                  ],
                }}
              >
                {({ isSubmitting, dirty, isValid }) => (
                  <S.FormWrap>
                    <S.FormHeader>
                      <S.BackButton onClick={backToTimeSlots}>
                        <S.BackIcon
                          dataCy="form-back-button"
                          iconName="iconDownArrow"
                          size={10}
                          iconColor="black1000"
                        />
                      </S.BackButton>
                      <S.FormTitle className="mb-0">
                        <FormattedMessage id="availabilityPreferences.title" />
                      </S.FormTitle>
                    </S.FormHeader>
                    <S.FormContainer>
                      <FieldArray name="availabilityPreferences" component={AvailabilityPreferenceFields} />
                    </S.FormContainer>
                    <S.FormFooter>
                      <S.Button
                        text={{ id: 'availabilityPreferences.confirm' }}
                        isDisabled={isSubmitDisabled({
                          isSubmitting,
                          dirty: dirty || availabilityPreferences,
                          isValid,
                        })}
                      />
                    </S.FormFooter>
                  </S.FormWrap>
                )}
              </Formik>
            </S.StyledCol>
          </S.StyledRow>
        </S.Wrapper>
      </S.ScrollWrapper>
    </MainContainer>
  )
}

AvailabilityPreferences.propTypes = {
  history: PropTypes.shape().isRequired,
}

export default withRouter(AvailabilityPreferences)
