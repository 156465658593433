import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

import SvgIcon from 'views/shared/Icons'

const Wrapper = styled.section`
  margin-bottom: 8px;
  background-color: white;
  border: 1px solid ${({ theme }) => theme.colors.black300};

  ${media.tablet`
    margin-bottom: 16px;
  `}
`

const Header = styled.div`
  padding: 12px 16px;

  ${media.tablet`
    padding: 24px;
  `}

  ${media.lg`
    padding: 24px 32px;
  `}
`

const DocumentsCardTitle = styled.p`
  font-family: ${({ theme }) => theme.fonts.interTight};
  font-size: 16px;
  line-height: ${24 / 16};
  font-weight: 600;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.colors.black1000};
`

const DocumentsCardDescr = styled.p`
  line-height: ${20 / 14};
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.black700};
`

const FillOutLink = styled.a`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  width: 100%;
  border-radius: 2px;
  font-size: 14px;
  line-height: ${20 / 14};
  font-weight: 600;
  border: none;
  color: white;
  background-color: ${({ theme }) => theme.settings.color};

  ${media.tablet`
    width: auto;
  `}
`

const FillOutButtonText = styled.span`
  margin-right: 8px;
`

const ArrowIcon = styled(SvgIcon)`
  fill: currentColor;
`

export { Wrapper, Header, DocumentsCardTitle, DocumentsCardDescr, FillOutLink, FillOutButtonText, ArrowIcon }
