const makeFormSubmitAction =
  (type, options = {}) =>
  (values, setErrors, setSubmitting, setStatus, resetForm, setValues, payload = {}) => ({
    type,
    values,
    form: {
      setErrors,
      setSubmitting,
      setStatus,
      resetForm,
      setValues,
    },
    ...payload,
    ...options,
  })

export default makeFormSubmitAction
