import React from 'react'
import PropTypes from 'prop-types'

import * as S from './styled'

const Document = ({ iconName, color, text }) => (
  <S.DocumentWrap data-cy={`${iconName}-form-card`}>
    <S.DocumentIcon iconName={iconName} color={color} size={20} />
    <S.DocumentText data-cy={`${iconName}-form-name`}>{text}</S.DocumentText>
  </S.DocumentWrap>
)

Document.propTypes = {
  iconName: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

export default Document
