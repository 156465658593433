export const TIME_PREFERENCES_TYPES = {
  anyTime: 'any_time',
  before: 'before',
  after: 'after',
  between: 'between',
}

export const TIME_TYPE_OPTIONS = [
  {
    key: TIME_PREFERENCES_TYPES.anyTime,
    value: TIME_PREFERENCES_TYPES.anyTime,
    label: { id: 'addAvailabilityPreferences.timeOption.anyTime' },
  },
  {
    key: TIME_PREFERENCES_TYPES.before,
    value: TIME_PREFERENCES_TYPES.before,
    label: { id: 'addAvailabilityPreferences.timeOption.before' },
  },
  {
    key: TIME_PREFERENCES_TYPES.after,
    value: TIME_PREFERENCES_TYPES.after,
    label: { id: 'addAvailabilityPreferences.timeOption.after' },
  },
  {
    key: TIME_PREFERENCES_TYPES.between,
    value: TIME_PREFERENCES_TYPES.between,
    label: { id: 'addAvailabilityPreferences.timeOption.between' },
  },
]

export const MAX_AVAILABILITY_PREFERENCES = 10
