import { createLogic } from 'redux-logic'

import { dataApiRequest, dataApiSuccess, dataApiFailure } from 'state/data/actions'
import { workspaceIdSelector } from 'state/concepts/widget/selectors'
import { bookingUniqCodeSelector } from 'state/concepts/booking/selectors'
import { deleteBookingEndpoint } from 'state/concepts/booking/endpoints'
import { setBooking } from 'state/concepts/booking/actions'
import { DELETE_BOOKING } from 'state/concepts/booking/types'

const deleteBookingOperation = createLogic({
  type: DELETE_BOOKING,
  latest: true,

  async process({ action: { onSuccess }, getState, httpClient }, dispatch, done) {
    const state = getState()
    const workspaceId = workspaceIdSelector(state)
    const uniqCode = bookingUniqCodeSelector(state)
    const { url, endpoint } = deleteBookingEndpoint(workspaceId, uniqCode)

    try {
      dispatch(dataApiRequest({ endpoint }))

      await httpClient.delete(url)

      dispatch(dataApiSuccess({ endpoint }))
      dispatch(setBooking(null))
      if (onSuccess) {
        onSuccess()
      }
    } catch {
      dispatch(dataApiFailure({ endpoint }))
    }

    done()
  },
})

export default deleteBookingOperation
