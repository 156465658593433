import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import * as S from './styled'

const Timer = ({ time, offsetBottom, offsetLeft }) => (
  <S.TimePhrase offsetBottom={offsetBottom} offsetLeft={offsetLeft}>
    <FormattedMessage id="reviewBookingView.timeSlotReservedFor" />
    <S.Timer>{time}</S.Timer>
  </S.TimePhrase>
)

Timer.defaultProps = {
  offsetBottom: null,
  offsetLeft: null,
}

Timer.propTypes = {
  time: PropTypes.string.isRequired,
  offsetBottom: PropTypes.number,
  offsetLeft: PropTypes.number,
}

export default Timer
