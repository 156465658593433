export default {
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },
  row: {
    padding: 12,
  },
  col: {
    padding: 12,
  },
  container: {
    padding: 16,
  },
}
