export const VIDEO_CALLS_PROVIDERS = {
  expertbox: 'expertbox',
  googleMeet: 'google_meet',
  zoom: 'zoom',
  microsoftTeams: 'microsoft_teams',
}

export const ONLINE_LOCATION_ICONS = {
  [VIDEO_CALLS_PROVIDERS.expertbox]: `${ASSET_CDN}/assets/images/videoCalls/expertbox.svg`,
  [VIDEO_CALLS_PROVIDERS.googleMeet]: `${ASSET_CDN}/assets/images/videoCalls/google-meet.svg`,
  [VIDEO_CALLS_PROVIDERS.microsoftTeams]: `${ASSET_CDN}/assets/images/videoCalls/microsoft-teams.svg`,
  [VIDEO_CALLS_PROVIDERS.zoom]: `${ASSET_CDN}/assets/images/videoCalls/zoom.svg`,
}
