import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import responseIds from 'utils/responseIds'
import { FETCH_REVIEWS } from 'state/concepts/reviews/types'
import { dataApiRequest, dataApiSuccess, dataApiFailure } from 'state/data/actions'
import { workspaceIdSelector } from 'state/concepts/widget/selectors'
import { addReviews } from '../actions'
import { paginationSelector } from '../selectors'
import { fetchReviewsEndpoint } from '../endpoints'

const fetchReviewsOperation = createLogic({
  type: FETCH_REVIEWS,
  latest: true,

  async process({ action: { providerId }, httpClient, getState }, dispatch, done) {
    const state = getState()
    const workspaceId = workspaceIdSelector(state)
    const { endpoint, url } = fetchReviewsEndpoint(workspaceId, providerId)

    const params = {
      page: paginationSelector(state),
      include: 'client-profile,service',
    }

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.get(url, { params })
      const response = normalize(data, { endpoint })

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(addReviews(responseIds(data)))
    } catch {
      dispatch(dataApiFailure({ endpoint }))
    }
    done()
  },
})

export default fetchReviewsOperation
