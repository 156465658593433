import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'ramda'

import {
  fetchReviews as fetchReviewsAction,
  resetReviews as resetReviewsAction,
  setCurrentPage as setCurrentPageAction,
} from 'state/concepts/reviews/actions'
import { loadingSelector } from 'state/data/selectors'
import {
  fetchReviewsEndpointSelector,
  reviewsSelector,
  currentPageSelector,
  hasMoreSelector,
} from 'state/concepts/reviews/selectors'
import ReviewsComponent from './component'

class Reviews extends React.Component {
  componentDidMount = () => {
    const { provider, fetchReviews } = this.props

    fetchReviews(provider.id)
  }

  componentWillUnmount = () => {
    const { resetReviews, setCurrentPage } = this.props

    resetReviews()
    setCurrentPage(1)
  }

  handleLoadMore = () => {
    const { provider, currentPage, fetchReviews, setCurrentPage, hasMore } = this.props

    if (!hasMore) {
      return
    }

    setCurrentPage(currentPage + 1)
    fetchReviews(provider.id)
  }

  render = () => <ReviewsComponent {...this.props} handleLoadMore={this.handleLoadMore} />
}

Reviews.propTypes = {
  provider: PropTypes.shape().isRequired,
  fetchReviews: PropTypes.func.isRequired,
  resetReviews: PropTypes.func.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  currentPage: PropTypes.number.isRequired,
}

const makeMapStateToProps = (initialState, { provider }) => {
  const endpoint = fetchReviewsEndpointSelector(initialState, provider.id)

  return state => ({
    reviews: reviewsSelector(state),
    currentPage: currentPageSelector(state),
    isLoading: loadingSelector(state, endpoint),
    hasMore: hasMoreSelector(state, endpoint),
  })
}

const mapDispatchToProps = {
  fetchReviews: fetchReviewsAction,
  resetReviews: resetReviewsAction,
  setCurrentPage: setCurrentPageAction,
}

export { Reviews as ReviewsContainer }
export default compose(connect(makeMapStateToProps, mapDispatchToProps))(Reviews)
