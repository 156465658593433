import React from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { Container, media } from 'styled-bootstrap-grid'
import { useSelector } from 'react-redux'

import Theme from 'constants/Theme'
import MainContainer from 'views/shared/MainContainer'
import Header from 'views/shared/Header'
import { workspaceNameSelector } from 'state/concepts/widget/selectors'
import { loginEmailSelector } from 'state/concepts/session/selectors'
import { TERMS_OF_USE_LINK, DATA_PROCESSING_POLICY_LINK } from 'constants'
import CodeForm from './CodeForm'
import EmailForm from './EmailForm'

const Login = () => {
  const workspaceName = useSelector(workspaceNameSelector)
  const email = useSelector(loginEmailSelector)

  return (
    <MainContainer bgColor={Theme.colors.black200}>
      <Header withBackButton isUserHidden />
      <Login.ScrollWrapper>
        <Login.Container fluid>
          <Login.LoginWrap>
            <Login.PageTitle>
              <FormattedMessage id="login.title" values={{ workspace: workspaceName }} />
            </Login.PageTitle>
            <Login.Description>
              <FormattedMessage id="login.description" />
            </Login.Description>
            {email ? <CodeForm email={email} /> : <EmailForm />}
          </Login.LoginWrap>
        </Login.Container>
        <Login.PrivacyPolicy>
          <FormattedMessage
            id="login.privacyPolicy"
            values={{
              termsLink: (
                <Login.Link href={TERMS_OF_USE_LINK}>
                  <FormattedMessage id="login.terms.link" />
                </Login.Link>
              ),
              privacyLink: (
                <Login.Link href={DATA_PROCESSING_POLICY_LINK}>
                  <FormattedMessage id="login.privacy.link" />
                </Login.Link>
              ),
            }}
          />
        </Login.PrivacyPolicy>
      </Login.ScrollWrapper>
    </MainContainer>
  )
}

Login.LoginWrap = styled.div`
  padding: 32px;
  background: #fff;
  border: 1px solid ${({ theme }) => theme.colors.black300};
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 4px;

  .input-wrap {
    margin-top: 24px;
    margin-bottom: 32px;
  }
`

Login.PageTitle = styled.p`
  font-family: ${({ theme }) => theme.fonts.interTight};
  font-size: 32px;
  line-height: 48px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.black1000};
`

Login.Description = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.black700};
  margin-top: 12px;
  margin-bottom: 24px;
`

Login.PrivacyPolicy = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.black800};
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 12px;
`

Login.Link = styled.a`
  color: ${({ theme }) => theme.settings.color};
`

Login.ScrollWrapper = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`

Login.Container = styled(Container)`
  flex-grow: 1;
  max-width: ${({ theme }) => theme.sizes.containerMaxWidth};
  padding-top: 16px;
  padding-bottom: 22px;

  ${media.tablet`
    padding-top: 32px;
    padding-right: 36px;
    padding-left: 36px;
  `}
`

export default Login
