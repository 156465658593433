import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { IntlProvider as IntlProviderRedux } from 'react-intl-redux'

import useDispatchAction from 'tools/hooks/useDispatchAction'
import { processLocales as processLocalesAction } from 'state/concepts/customNames/actions'

const IntlProvider = ({ workspaceCode, children }) => {
  const processLocales = useDispatchAction(processLocalesAction)
  useEffect(() => processLocales(workspaceCode), [workspaceCode, processLocales])

  return <IntlProviderRedux>{children}</IntlProviderRedux>
}

IntlProvider.defaultProps = {
  workspaceCode: undefined,
}

IntlProvider.propTypes = {
  children: PropTypes.node.isRequired,
  workspaceCode: PropTypes.string,
}

export default IntlProvider
