import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

const Container = styled.header`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ theme }) => theme.sizes.headerHeight};
  border-bottom: 1px solid ${({ theme }) => theme.colors.black300};
  background: white;
  flex-shrink: 0;

  ${media.desktop`
    height: ${({ theme }) => theme.sizes.headerHeightDesktop};
  `}
`

const ButtonContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  width: 100%;
  height: 100%;
  margin: auto;
`

const Logo = styled.img`
  max-width: 196px;
  width: auto;
  max-height: 28px;
  ${media.desktop`
    max-width: 224px;
    max-height: 32px;
  `}
`

export { Container, ButtonContainer, Logo }
