import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

const FormBlock = styled.div`
  background-color: white;
  margin-bottom: 16px;
  ${media.tablet`
    border: 1px solid ${({ theme }) => theme.colors.black300};
  `}
`

const FormBlockHeader = styled.div`
  padding: 16px;
  ${media.tablet`
    padding-right: 24px;
    padding-left: 24px;
  `}
`

const CollapseHeader = styled.div`
  padding: 24px 16px;

  ${media.tablet`
    padding-right: 32px;
    padding-left: 32px;
  `}
`

const FormDivider = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.black300};
  margin-top: 16px;
  margin-bottom: 24px;
`

const CollapseBody = styled.div`
  padding: 0 16px 8px;
  display: ${props => (props.expanded ? 'block' : 'none')};

  ${media.tablet`
    padding-right: 32px;
    padding-left: ${props => (props.single ? '32px' : '64px')};
    padding-bottom: 16px;
  `}
`

const CollapseBodyPaypal = styled.div`
  padding: 0 16px 8px;
  display: ${props => (props.expanded ? 'block' : 'none')};

  ${media.tablet`
    padding-right: 32px;
    padding-left: 32px;
    padding-bottom: 16px;
  `}
`

const FormTitle = styled.h3`
  font-family: ${({ theme }) => theme.fonts.interTight};
  font-size: 16px;
  line-height: ${24 / 16};
  font-weight: 600;
  color: ${({ theme }) => theme.colors.black1000};
`

const HeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ImagesWrap = styled.div`
  display: flex;
`

const LogoImg = styled.img`
  height: 10px;
  margin-left: 8px;

  ${media.tablet`
    height: 12px;
  `}
`

const LogoPaypal = styled.img`
  height: 22px;
`

const PayPalHint = styled.p`
  color: ${({ theme }) => theme.colors.black700};
  margin-bottom: 16px;
  line-height: 20px;
`

const CardHint = styled.p`
  display: ${props => (props.expanded ? 'block' : 'none')};
  padding-left: ${props => (props.single ? '0px' : '32px')};
  color: ${({ theme }) => theme.colors.black700};
  line-height: 20px;
`

const FormRow = styled.div`
  margin-right: -8px;
  margin-left: -8px;
  display: flex;
  flex-wrap: wrap;
`

const FormRowAddress = styled(FormRow)`
  ${media.tablet`
    flex-wrap: nowrap;
  `}
`

const FormCol = styled.div`
  padding-right: 8px;
  padding-left: 8px;
  width: 100%;
`

const FormColDouble = styled(FormCol)`
  ${media.desktop`
    width: 50%;
  `}
`

const FormColQuarter = styled(FormCol)`
  width: 50%;
  ${media.desktop`
    width: 25%;
  `}
`

const FormColIndex = styled(FormCol)`
  width: 50%;
  flex-shrink: 0;

  ${media.tablet`
    width: 128px;
  `}
`

const PaymentTitle = styled.span`
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.black1000};
`

export {
  ImagesWrap,
  FormBlock,
  FormBlockHeader,
  FormDivider,
  CollapseHeader,
  CollapseBody,
  CollapseBodyPaypal,
  FormTitle,
  HeaderWrap,
  LogoImg,
  LogoPaypal,
  PayPalHint,
  CardHint,
  FormRow,
  FormRowAddress,
  FormCol,
  FormColDouble,
  FormColQuarter,
  FormColIndex,
  PaymentTitle,
}
