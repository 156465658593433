import styled from 'styled-components'
import Select from 'react-styled-select'
import { rgba } from 'polished'

const StyledSelect = styled(Select)`
  --styled-select__border-width: 1px;
  --styled-select__border-radius: 2px;
  --styled-select-control__min-height: 48px;
  --styled-select-control__border-color--focused: ${({ theme }) => theme.settings.color};
  --styled-select-control__border-width--focused: 2px;
  --styled-select-value-wrapper__padding: 10px 22px 10px 12px;
  --styled-select-value__line-height: 22px;
  --styled-select-value__padding: 0;
  --styled-select-input__height: 22px;
  --styled-select-input__line-height: 22px;
  --styled-select__color: ${({ theme }) => theme.colors.black700};
  --styled-select-clear-zone__width: 20px;
  --styled-select-clear__color: ${({ theme }) => theme.colors.black700};
  --styled-select-clear__font-size: 18px;
  --styled-select-arrow__color: ${({ theme }) => theme.colors.black700};
  --styled-select-arrow__size: 14;
  --styled-select-menu-outer__margin: 2px 0 0 0;
  --styled-select-menu-outer__max-height: 200px;
  --styled-select-menu-outer__box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  --styled-select-menu-outer__border-radius: 4px;
  --styled-select-menu__border-radius: 4px;
  --styled-select-option__color: ${({ theme }) => theme.colors.black700};
  --styled-select-option__padding: 10px;
  --styled-select-option__font-family: 'Inter', sans-serif;
  --styled-select-no-results__font-family: 'Inter', sans-serif;
  --styled-select-no-results__color: ${({ theme }) => theme.colors.black700};
  --styled-select-placeholder__padding: 12px;
  --styled-select-placeholder__color: ${({ theme }) => theme.colors.black500};
  --styled-select-placeholder__font-family: 'Inter', sans-serif;
  --styled-select-placeholder__line-height: 22px;
  --styled-select-placeholder__font-size: 14px;
  --styled-select-option__background-color--selected: ${({ theme }) => rgba(theme.settings.color, 0.1)};
  --styled-select-option__background-color--focused: ${({ theme }) => rgba(theme.settings.color, 0.1)};
  --styled-select-control__border-color: ${({ theme, condition }) => {
    switch (condition) {
      case 'error':
        return theme.colors.red1000
      case 'info':
        return theme.colors.orange1000
      default:
        return theme.colors.black400
    }
  }};

  div[aria-haspopup='true'] {
    [data-select-arrow] {
      border-color: ${({ theme }) => theme.settings.color};
    }
  }

  div[data-select-control='true'] {
    cursor: pointer;
  }

  div[data-select-multi-value-wrapper='false'] {
    max-width: 100%;
    outline: currentColor;
  }
`

export default StyledSelect
