import React from 'react'
import PropTypes from 'prop-types'
import { prop } from 'ramda'

import OptionComponent from './component'

class Option extends React.Component {
  static defaultProps = {
    value: undefined,
  }

  static propTypes = {
    option: PropTypes.shape().isRequired,
    value: PropTypes.shape(),
  }

  get isSelected() {
    const { value, option } = this.props

    return prop('id', value) === prop('id', option)
  }

  render = () => <OptionComponent {...this.props} isSelected={this.isSelected} />
}

export default Option
