import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { compose } from 'ramda'

import { TYPES_BY_ROUTES } from 'constants'
import { proceedStep as proceedStepAction } from 'state/steps/actions'
import { serviceSelector } from 'state/concepts/services/selectors'
import { providerSelector } from 'state/concepts/providers/selectors'
import { timeSelector } from 'state/concepts/time/selectors'
import { stepsOrderSelector } from 'state/steps/selectors'
import { locationSelector } from 'state/concepts/locations/selectors'
import { availabilityPreferencesSelector } from 'state/concepts/waitlistRequest/selectors'
import StepperComponent from './component'

class Stepper extends React.Component {
  static propTypes = {
    steps: PropTypes.arrayOf(PropTypes.string).isRequired,
    location: PropTypes.shape().isRequired,
    proceedStep: PropTypes.func.isRequired,
  }

  componentDidMount = () => {
    const {
      location: { pathname },
      proceedStep,
    } = this.props
    const stepType = TYPES_BY_ROUTES[pathname]

    proceedStep(stepType)
  }

  render = () => <StepperComponent {...this.props} />
}

const mapStateToProps = state => ({
  service: serviceSelector(state),
  provider: providerSelector(state),
  offlineLocation: locationSelector(state),
  time: timeSelector(state),
  steps: stepsOrderSelector(state),
  availabilityPreferences: availabilityPreferencesSelector(state),
})

const mapDispatchToProps = {
  proceedStep: proceedStepAction,
}

export { Stepper as StepperContainer }
export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(Stepper)
