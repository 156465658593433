import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { prop, isEmpty } from 'ramda'
import { FormattedMessage } from 'react-intl'

import SvgIcon from 'views/shared/Icons'
import Option from './Option'
import * as S from './styled'

const TimezoneDropdownComponent = forwardRef(
  (
    { value, options, visible, onVisibleChange, makeHandleSelect, filterValue, filterTimezones, clearFilter, intl },
    ref,
  ) => (
    <S.Wrapper ref={ref}>
      <S.Content onClick={onVisibleChange} data-cy="timezone-select">
        <SvgIcon size={20} iconName="timezone" color="black500" />
        <S.Value>{value}</S.Value>
        {visible ? (
          <S.UpArrowIcon size={10} iconName="iconDownArrow" />
        ) : (
          <S.DownArrowIcon size={10} iconName="iconDownArrow" />
        )}
      </S.Content>
      {visible && (
        <S.Dropdown>
          <S.Search>
            <S.SearchIcon iconName="search" size={16} />
            <S.SearchInput
              value={filterValue}
              placeholder={intl.formatMessage({ id: 'timeSlotsView.timezoneSearchPlaceholder' })}
              onChange={filterTimezones}
            />
            {filterValue && (
              <S.ClearButton onClick={clearFilter}>
                <SvgIcon iconName="iconCross" color="black500" size={18} />
              </S.ClearButton>
            )}
          </S.Search>
          <S.DropdownList>
            {options.map(option => (
              <Option key={prop('key', option)} option={option} value={value} onClick={makeHandleSelect(option)} />
            ))}
            {isEmpty(options) && (
              <S.EmptyState>
                <FormattedMessage id="timeSlotsView.timezoneSearchEmpty" />
              </S.EmptyState>
            )}
          </S.DropdownList>
        </S.Dropdown>
      )}
    </S.Wrapper>
  ),
)

TimezoneDropdownComponent.displayName = 'TimezoneDropdownComponent'

TimezoneDropdownComponent.defaultProps = {
  value: '',
  visible: false,
  filterValue: '',
}

TimezoneDropdownComponent.propTypes = {
  value: PropTypes.string,
  visible: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onVisibleChange: PropTypes.func.isRequired,
  makeHandleSelect: PropTypes.func.isRequired,
  filterTimezones: PropTypes.func.isRequired,
  clearFilter: PropTypes.func.isRequired,
  filterValue: PropTypes.string,
  intl: PropTypes.shape({ formatMessage: PropTypes.func }).isRequired,
}

export default TimezoneDropdownComponent
