import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

const useDispatchWithArgsAction = (actionCreator, ...staticArgs) => {
  const dispatch = useDispatch()

  return useCallback(
    (...dynamicArgs) => {
      dispatch(actionCreator(...staticArgs, ...dynamicArgs))
    },
    [actionCreator, dispatch, staticArgs],
  )
}

export default useDispatchWithArgsAction
