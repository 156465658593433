import styled from 'styled-components'

import Button from '../component'

const SecondaryButton = styled(Button)`
  &&& {
    color: ${({ theme }) => theme.settings.color};
    background-color: ${({ filled, theme }) => (filled ? theme.colors.black200 : 'transparent')};
    ${({ filled }) => !filled && 'border: 1px solid currentColor;'};

    &:hover {
      border: ${({ filled }) => !filled && '1px solid currentColor'};
      background-color: ${({ filled, theme }) => (filled ? theme.colors.black400 : 'transparent')};
      ${({ filled }) => !filled && 'border: 1px solid currentColor;'};
    }

    &:active {
      ${({ filled, theme }) => filled && `background-color: ${theme.colors.black400};`}
    }
  }
`

export default SecondaryButton
