import styled from 'styled-components'

const Label = styled.label`
  display: inline-flex;
  cursor: pointer;
  margin-bottom: 16px;

  &:disabled {
    cursor: default;
  }

  &:checked {
    cursor: default;
  }
`

const Input = styled.input`
  display: none;
  margin: 0;
`

const Text = styled.span`
  font-size: 14px;
  line-height: ${20 / 14};
  margin-left: 8px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.black700};
`

export { Label, Input, Text }
