import { equals } from 'ramda'

import { SERVICE_CANCELLATION_OPTIONS } from 'constants'
import { secondsToValueWithUnits } from 'utils/duration'

export default ({ cancellableOption, cancellableUntil }, isWithPayment) => {
  const isServiceFree = !isWithPayment

  if (isServiceFree) {
    return {
      id: 'reviewBookingView.cancelAnytime',
    }
  }

  if (cancellableOption === SERVICE_CANCELLATION_OPTIONS.upTo) {
    return {
      id: 'reviewBookingView.cancelUpTo',
      values: { ...secondsToValueWithUnits(cancellableUntil) },
    }
  }

  return equals(cancellableOption, SERVICE_CANCELLATION_OPTIONS.anytime)
    ? {
        id: 'reviewBookingView.cancelAnytimeWithFullRefund',
      }
    : {
        id: 'reviewBookingView.cancelWithoutRefund',
      }
}
