import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import Theme from 'constants/Theme'
import Header from 'views/shared/ModalHeader'
import Body from 'views/shared/DetailsModal/Body'
import Button from 'views/shared/Button'
import Overlay from 'views/shared/Overlay'
import * as S from 'views/ReviewBookingView/BookingErrorModal/styled'

const DiscardDataModal = ({ onClose, onDiscard, dataCy, isWithPayment, isLoading }) => (
  <>
    <S.Container data-cy={dataCy}>
      <Header
        header={
          <S.Title>
            <FormattedMessage id="modal.discard.title" />
          </S.Title>
        }
        handleClose={onClose}
      />
      <Body>
        <S.TextContainer>
          <S.Text>
            <FormattedMessage id={`modal.discard.${isWithPayment ? 'paidBooking' : 'freeBooking'}.body`} />
          </S.Text>
        </S.TextContainer>
        <S.ActionButtons>
          <S.CancelButton onClick={onClose}>
            <FormattedMessage id="shared.cancel" />
          </S.CancelButton>
          <Button
            minWidth={136}
            text={{ id: 'modal.discard.button' }}
            bgColor={Theme.colors.red1000}
            onClick={onDiscard}
            dataCy="discard-button"
            isSubmitting={isLoading}
          />
        </S.ActionButtons>
      </Body>
    </S.Container>
    <Overlay onClick={onClose} />
  </>
)

DiscardDataModal.defaultProps = {
  dataCy: 'discard-data-modal-container',
  isLoading: undefined,
}

DiscardDataModal.propTypes = {
  dataCy: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onDiscard: PropTypes.func.isRequired,
  isWithPayment: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
}

export default DiscardDataModal
