import styled from 'styled-components'

import SvgIcon from 'views/shared/Icons'

const Wrapper = styled.div`
  margin-bottom: 8px;
  margin-top: ${({ label }) => (label ? '8' : '0')}px;
`

const Label = styled.label`
  display: inline-flex;
  margin-bottom: 4px;
  font-size: 14px;
  line-height: ${20 / 14};
  color: ${({ theme }) => theme.colors.black700};
`

const FieldWrapper = styled.div`
  position: relative;

  .StripeElement {
    width: 100%;
    background-color: white;
    border-width: 1px;
    border-style: solid;
    border-radius: 2px;
    padding: 13px 12px 13px;
    font-size: 14px;
    line-height: ${20 / 14};
    transition: border-color 0.3s ease 0s;
    border-color: ${({ theme, isErrors }) => (isErrors ? theme.colors.red1000 : theme.colors.black400)};

    &:hover {
      border-color: ${({ theme }) => theme.settings.color};
    }
  }

  .StripeElement--focus {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.settings.color};
    border-color: ${({ theme }) => theme.settings.color};
  }
`

const Message = styled.p`
  display: ${({ isErrors }) => (isErrors ? 'flex' : 'none')};
  align-items: center;
  margin-top: 6px;
  font-size: 14px;
  line-height: ${20 / 14};
  color: ${({ theme, isErrors }) => (isErrors ? theme.colors.red1000 : theme.colors.black400)};
`

const MessageIcon = styled(SvgIcon)`
  margin-right: 8px;
  fill: ${({ theme, isErrors }) => (isErrors ? theme.colors.red1000 : theme.colors.black400)};
`

export { Wrapper, Label, FieldWrapper, Message, MessageIcon }
