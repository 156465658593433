import styled from 'styled-components'
import SvgIcon from 'views/shared/Icons'
import { media } from 'styled-bootstrap-grid'

const LocationIcon = styled(SvgIcon)`
  fill: ${({ theme }) => theme.settings.color};
  width: 20px;
  ${media.tablet`
    width: 24px;
  `}
`
const SearchWrap = styled.div`
  display: flex;
  align-items: flex-center;
  justify-content: space-between;
  flex-direction: column;
  padding: 16px;
  background-color: white;
  ${media.tablet`
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid ${props => props.theme.colors.black300};
  `}
  ${media.desktop`
    padding: 16px 32px;
  `}
`
const Search = styled.div`
  max-width: 100%;
  ${media.tablet`
    max-width: 300px
  `}
  .main-search {
    border-radius: 2px;
    outline: 1px solid ${({ theme }) => theme.colors.black400};
    margin-bottom: 0;

    &:focus,
    &:focus-within {
      outline-width: 2px;
      outline-color: ${({ theme }) => theme.settings.color};
    }
  }
`
const Info = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  ${media.tablet`
    margin-bottom : 0;
  `}
`
const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: ${28 / 16};
  color: ${props => props.theme.colors.black1000};
  margin-left: 16px;
  ${media.tablet`
    font-size: 20px;
    line-height: ${32 / 20};
    margin-left: 24px;
  `}
`
const Count = styled.span`
  font-size: 16px;
  font-weight: 600;
  line-height: ${28 / 16};
  color: ${props => props.theme.colors.black400};
  margin-left: 8px;
  ${media.tablet`
    font-size: 20px;
    line-height: ${32 / 20};
  `}
`

export { SearchWrap, LocationIcon, Info, Title, Count, Search }
