import styled, { css } from 'styled-components'
import { media } from 'styled-bootstrap-grid'

import SvgIcon from 'views/shared/Icons'

const Wrapper = styled.section`
  margin-bottom: 16px;
  padding: 0 0 16px 0;
  max-width: 456px;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  border: 1px solid ${({ theme }) => theme.colors.black300};

  ${media.tablet`
    padding-bottom: 24px;
  `}

  ${media.desktop`
    padding: 32px;
    max-width: initial;
    margin-left: initial;
    margin-right: initial;
  `}
`

const SummaryInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  padding-bottom: 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.black300};

  ${media.tablet`
    margin-bottom: 24px;
  `}

  ${media.desktop`
    flex-direction: row;
  `}
`

const SummaryInfoDate = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  ${media.desktop`
    flex-direction: column;
    justify-content: center;
  `}
`

const Col = styled.div`
  /* stylelint-disable */
  ${({ leftColumn }) =>
    leftColumn
      ? css`
          display: flex;
          align-items: center;
          justify-content: center;
          flex-basis: 83px;
          flex-shrink: 0;
          margin-bottom: 24px;
          border-bottom: 1px solid ${({ theme }) => theme.colors.black300};
          ${media.tablet`
            flex-basis: 101px;
          `}
        `
      : css`
          flex-grow: 1;
          padding-left: 16px;
          padding-right: 16px;
          ${media.tablet`
            padding-left: 24px;
            padding-right: 24px;
          `}
        `}

  ${media.desktop`
    ${({ leftColumn }) =>
      leftColumn
        ? css`
            flex-basis: 185px;
            margin-bottom: 0;
            border-bottom: none;
            border-right: 1px solid ${({ theme }) => theme.colors.black300};
          `
        : css`
            padding-left: 32px;
          `}
  `}
`

const ActionInfoWrapper = styled.div`
  padding-left: 16px;
  padding-right: 16px;

  ${media.tablet`
    padding-left: 24px;
    padding-right: 24px;
  `}

  ${media.desktop`
    padding-left: initial;
    padding-right: initial;
  `}
`

const SummaryCalendarButtons = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;

  ${media.desktop`
    flex-direction: row;
    margin-bottom: 24px;
  `}
`

const SummaryCalendarButton = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 108px;
  min-height: 40px;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.colors.black200};
  cursor: pointer;
  transition: background-color 0.15s;
  width: 100%;
  margin-bottom: 16px;

  ${media.desktop`
    width: auto;
    margin-bottom: 0;
    margin-right: 20px;
  `}
`

const SummaryCalendarLinkText = styled.span`
  line-height: ${20 / 14};
  padding-right: 16px;
  padding-left: 16px;
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.settings.color};
`

const Info = styled.section`
  margin-bottom: 24px;

  &:last-of-type {
    margin-bottom: 16px;

    ${media.tablet`
      margin-bottom: 8px;
    `}
  }
`

const InfoTitle = styled.h3`
  line-height: ${20 / 14};
  color: ${({ theme }) => theme.colors.black700};
  text-align: center;

  ${media.desktop`
    text-align: left;
  `}
`

const ConsultantAva = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`

const ConsultantName = styled.p`
  font-size: 16px;
  line-height: ${24 / 16};
  color: ${({ theme }) => theme.colors.black1000};
`

const ConsultantIcon = styled(SvgIcon)`
  fill: ${({ theme }) => theme.settings.color};
  margin-right: 16px;
  flex-shrink: 0;
`

const Consultant = styled.section`
  margin-bottom: 24px;
`

const Price = styled.section`
  display: flex;
`

const PriceValue = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.black1000};
`

const PriceIcon = styled(SvgIcon)`
  fill: ${({ theme }) => theme.settings.color};
  margin-right: 16px;
  flex-shrink: 0;
`

const PriceDescription = styled.div`
  padding-top: 4px;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.black700};
  line-height: ${24 / 16};
`

const SummaryInfoDayWrap = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  width: 50%;
  border-right: 1px solid ${({ theme }) => theme.colors.black300};
  height: inherit;

  ${media.desktop`
    justify-content: center;
    margin-bottom: 16px;
    width: auto;
    height: 54px;
    border-right: none;
  `}
`

const SummaryInfoDay = styled.p`
  font-size: 32px;
  line-height: normal;
  color: ${({ theme }) => theme.colors.black1000};
  margin-right: 8px;
  font-weight: 600;

  ${media.desktop`
    font-size: 36px;
  `}
`

const SummaryInfoMonth = styled.p`
  font-size: 20px;
  line-height: normal;
  color: ${({ theme }) => theme.colors.black1000};
`

const SummaryInfoTime = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.black700};
  text-align: center;
  width: 50%;
  max-width: 105px;
  margin-left: auto;
  margin-right: auto;
  ${media.desktop`
    width: auto;
  `}
`

export {
  Wrapper,
  Col,
  Info,
  InfoTitle,
  Consultant,
  Price,
  ConsultantAva,
  ConsultantIcon,
  ConsultantName,
  ActionInfoWrapper,
  SummaryCalendarButtons,
  SummaryInfo,
  SummaryInfoDate,
  SummaryInfoDayWrap,
  SummaryInfoDay,
  SummaryInfoMonth,
  SummaryInfoTime,
  SummaryCalendarButton,
  SummaryCalendarLinkText,
  PriceIcon,
  PriceValue,
  PriceDescription,
}
