import React from 'react'
import PropTypes from 'prop-types'
import { prop } from 'ramda'
import { FormattedMessage } from 'react-intl'

import DetailsModal from 'views/shared/DetailsModal'
import FormattedPrice from 'views/shared/FormattedPrice'
import FormattedRange from 'views/shared/FormattedRange'
import Duration from 'views/shared/Card/Duration'
import * as S from './styled'

const ServiceModal = ({ service, hideModal, handleSelect, isNotAvailableSlots }) => (
  <DetailsModal
    price={<FormattedPrice {...service} />}
    handleClose={hideModal}
    handleSelect={handleSelect?.(service)}
    disableButton={isNotAvailableSlots}
    actionButtonText={{ id: 'shared.selectService' }}
  >
    <S.ServiceContent>
      <S.ServiceContainer>
        <S.ServiceTitle offsetBottom={8} textSize={20} data-cy="service-profile-service-title">
          {prop('name', service)}
        </S.ServiceTitle>
        <Duration seconds={prop('duration', service)} />
        <S.ServiceLabelWrapper>
          <S.ServiceIcon iconName="userO" offsetRight={8} size={20} />
          <S.ServiceLabelText data-cy="service-profile-expert-count">
            {prop('activeProvidersCount', service)}
          </S.ServiceLabelText>
          <S.ServiceLabelDesc data-cy="service-profile-expert-count-text">
            <FormattedMessage
              id="servicesListView.expertsAvailable"
              values={{ count: prop('activeProvidersCount', service) }}
            />
          </S.ServiceLabelDesc>
        </S.ServiceLabelWrapper>
        {!handleSelect && (
          <S.ServiceLabelWrapper>
            <S.ServiceIcon iconName="card" offsetRight={8} size={20} />
            <S.ServiceLabelText data-cy="service-profile-price">
              <FormattedRange {...service} />
            </S.ServiceLabelText>
          </S.ServiceLabelWrapper>
        )}
        <S.ServiceDescr data-cy="service-profile-description">{prop('description', service)}</S.ServiceDescr>
      </S.ServiceContainer>
    </S.ServiceContent>
  </DetailsModal>
)

ServiceModal.defaultProps = {
  isNotAvailableSlots: false,
  handleSelect: undefined,
}

ServiceModal.propTypes = {
  service: PropTypes.shape({
    id: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  hideModal: PropTypes.func.isRequired,
  handleSelect: PropTypes.func,
  isNotAvailableSlots: PropTypes.bool,
}

export default ServiceModal
