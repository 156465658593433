import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

import SvgIcon from 'views/shared/Icons'

export const ServiceContent = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding-top: 16px;
  padding-bottom: 80px;
  ${media.tablet`
    padding-top: 24px;
  `}
`

export const ServiceContainer = styled.div`
  padding-right: 16px;
  padding-left: 16px;

  ${media.tablet`
    padding-right: 36px;
    padding-left: 36px;
  `}

  ${media.desktop`
    max-width: 648px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 0;
    padding-left: 0;
  `}

  .service-duration {
    display: block;
    ${media.tablet`
      font-size: 16px;
    `}
  }
`

export const ServiceTitle = styled.div`
  font-family: ${({ theme }) => theme.fonts.interTight};
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.black1000};
  margin-bottom: 12px;

  ${media.tablet`
    margin-bottom: 8px;
    font-size: 20px;
  `}
`

export const ServiceLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.black700};
  margin-bottom: 12px;
`

export const ServiceLabelText = styled.span`
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  margin-right: 4px;

  ${media.desktop`
    font-size: 16px;
    line-height: 24px;
  `}
`

export const ServiceLabelDesc = styled.span`
  font-size: 14px;
  line-height: 20px;

  ${media.desktop`
    font-size: 16px;
    line-height: 24px;
  `}
`

export const ServiceIcon = styled(SvgIcon)`
  fill: ${({ theme }) => theme.settings.color};
`

export const ServiceDescr = styled.p`
  font-size: 14px;
  line-height: ${20 / 14};
  color: ${({ theme }) => theme.colors.black700};
  white-space: pre-line;

  ${media.tablet`
    font-size: 16px;
    line-height: ${24 / 16};
  `}
`
