import { createLogic } from 'redux-logic'
import { CardNumberElement } from '@stripe/react-stripe-js'
import normalize from 'json-api-normalizer'

import { PAYMENT_METHODS } from 'constants/payments'
import isBaseErrorReason from 'utils/form/isBaseErrorReason'
import assignFormErrors from 'utils/form/assignFormErrors'
import { isErrorStatusUnprocessableEntity } from 'utils/getErrorStatus'
import getBaseError from 'utils/getBaseError'
import { UPDATE_BOOKING } from 'state/concepts/booking/types'
import { showModal } from 'state/modal/actions'
import { workspaceIdSelector } from 'state/concepts/widget/selectors'
import { isWithPaymentSelector, bookingSelector } from 'state/concepts/booking/selectors'
import { dataApiRequest, dataApiSuccess, dataApiFailure } from 'state/data/actions'
import { setStripeError } from '../actions'
import { updateBookingEndpoint } from '../endpoints'

const updateBookingOperation = createLogic({
  type: UPDATE_BOOKING,
  warnTimeout: 0,

  async process(
    { action: { values, form, pushFunction, confirmCardPayment, getElement, paymentMethod }, httpClient, getState },
    dispatch,
    done,
  ) {
    const state = getState()
    const workspaceId = workspaceIdSelector(state)
    const isWithPayment = isWithPaymentSelector(state)
    const { id, paymentIntentClientSecret } = bookingSelector(state)

    const { endpoint, url } = updateBookingEndpoint(workspaceId, id)

    dispatch(dataApiRequest({ endpoint }))

    try {
      if (isWithPayment && paymentMethod === PAYMENT_METHODS.stripe) {
        const { error } = await confirmCardPayment(paymentIntentClientSecret, {
          payment_method: {
            card: getElement(CardNumberElement),
            billing_details: {
              name: `${values.firstName} ${values.lastName}`,
              email: values.email,
              address: {
                city: values.city,
                state: values.state,
                line1: values.address,
                country: values.country,
                postal_code: values.index,
              },
            },
          },
        })

        if (error) {
          dispatch(setStripeError(error))
          throw error
        }
      }

      const params = {
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
        phone_number: values.phoneNumber,
        include: 'forms',
      }

      const { data } = await httpClient.put(url, params)

      dispatch(dataApiSuccess({ endpoint, response: normalize(data, { endpoint }) }))
      pushFunction(values.email)
    } catch (error) {
      dispatch(dataApiFailure({ endpoint }))
      assignFormErrors(form, error)

      if (isErrorStatusUnprocessableEntity(error) && isBaseErrorReason(error)) {
        dispatch(
          showModal({
            modalType: 'BOOKING_ERROR',
            modalProps: { baseError: getBaseError(error) },
          }),
        )
      }
    }

    form.setSubmitting(false)

    done()
  },
})

export default updateBookingOperation
