import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

import SvgIcon from 'views/shared/Icons'

const FormItem = styled.div`
  position: relative;
  width: calc(100% - 50px);

  ${media.tablet`
    width: 100%;
  `}
`

const FormColThirdParth = styled.div`
  width: 100%;
  padding-left: 8px;
  padding-right: 8px;

  ${media.tablet`
    width: calc(100% / 3);
  `}

  [data-select-arrow-zone] {
    padding-right: 16px;
  }

  [data-select-arrow] {
    border-width: 2px;
    border-color: ${({ theme }) => theme.colors.black500};
  }
`

const ClearBtn = styled(SvgIcon)`
  fill: ${({ theme }) => theme.colors.black500};
`

const ClearBtnWrap = styled.div`
  position: absolute;
  top: 50%;
  left: calc(100% + 20px);
  transform: translateY(-50%);
`

const FormRow = styled.div`
  margin-right: -8px;
  margin-left: -8px;
  display: flex;
  flex-wrap: wrap;
`

export { FormItem, FormColThirdParth, ClearBtn, FormRow, ClearBtnWrap }
