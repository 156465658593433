import styled from 'styled-components'
import SvgIcon from 'views/shared/Icons'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: ${({ isFullWidth }) => (isFullWidth ? '100%' : '380px')};
  padding: 12px;
  background-color: ${({ theme, view }) => {
    switch (view) {
      case 'error':
        return theme.colors.red100
      case 'success':
        return theme.colors.green300
      case 'info':
        return theme.colors.orange100
      default:
        return theme.colors.black200
    }
  }};

  color: ${({ theme, view }) => {
    switch (view) {
      case 'error':
        return theme.colors.red1000
      case 'success':
        return theme.colors.green1000
      case 'info':
        return theme.colors.orange1000
      default:
        return theme.colors.black1000
    }
  }};

  fill: ${({ theme, view }) => {
    switch (view) {
      case 'error':
        return theme.colors.red1000
      case 'success':
        return theme.colors.green1000
      case 'info':
        return theme.colors.orange1000
      default:
        return theme.colors.black1000
    }
  }};
`

const AlertIcon = styled(SvgIcon)`
  margin-left: 12px;
  margin-right: 12px;
  fill: currentColor;
  font-size: 24px;
`

const Message = styled.p`
  flex-grow: 1;
  line-height: ${20 / 14};
`

export { Wrapper, AlertIcon, Message }
