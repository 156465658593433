import React from 'react'
import PropTypes from 'prop-types'

import ModalContext from './ModalContext'

class ModalProvider extends React.Component {
  state = {
    component: null,
    props: {},
  }

  get contextValue() {
    return {
      ...this.state,
      showModal: this.showModal,
      hideModal: this.hideModal,
    }
  }

  showModal =
    (component, props = {}) =>
    () => {
      this.setState({ component, props })
    }

  hideModal = () => {
    this.setState({ component: null, props: {} })
  }

  render = () => {
    const { children } = this.props

    return <ModalContext.Provider value={this.contextValue}>{children}</ModalContext.Provider>
  }
}

ModalProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ModalProvider
