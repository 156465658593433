import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import WidgetContext from 'views/Widget/WidgetContext'
import BackButton from 'views/shared/BackButton'
import MobileCloseWidgetBtn from 'views/shared/MobileCloseWidgetBtn'
import * as S from './styled'

const Header = ({ handleClose, widgetLogo }) => {
  const { handleCloseWidget } = useContext(WidgetContext)

  return (
    <S.Container>
      <S.ButtonContainer>
        {handleCloseWidget && <MobileCloseWidgetBtn onCloseWidget={handleCloseWidget} />}
        <BackButton onGoBack={handleClose} />
        <S.Logo src={widgetLogo} />
      </S.ButtonContainer>
    </S.Container>
  )
}

Header.displayName = 'Header'

Header.defaultProps = {
  handleClose: undefined,
}

Header.propTypes = {
  handleClose: PropTypes.func,
  widgetLogo: PropTypes.string.isRequired,
}

export default Header
