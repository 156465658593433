import { isValidPhoneNumber } from 'react-phone-number-input'

// eslint-disable-next-line import/prefer-default-export
export function phoneNumber() {
  return this.test(
    'phoneNumber',
    {
      id: 'yup.string.phone',
    },
    value => {
      const isValid = (value && isValidPhoneNumber(value)) || !value

      return isValid
    },
  )
}
