import { FormattedMessage, injectIntl } from 'react-intl'
import React from 'react'
import { compose } from 'ramda'
import { useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Row, Col } from 'styled-bootstrap-grid'
import PropTypes from 'prop-types'

import InfoItem from 'views/ConfirmationView/BookingSummary/InfoItem'
import Avatar from 'views/shared/Avatar'
import FormattedPrice from 'views/shared/FormattedPrice'
import Footer from 'views/shared/Footer'
import { waitlistRequestSelector, availabilityPreferencesSelector } from 'state/concepts/waitlistRequest/selectors'
import { formatDuration } from 'utils/duration'
import profileName from 'utils/profileName'
import { priceSelector } from 'state/concepts/widget/selectors'
import { buildMapSearchUrl } from 'utils/locations'
import { getDateText, getTimeText } from 'utils/availabilityPreferences/timeFormatter'
import useDispatchAction from 'tools/hooks/useDispatchAction'
import { resetSelection as resetSelectionAction } from 'state/store/actions'
import { isWithPaymentSelector } from 'state/concepts/booking/selectors'
import GlobalStyles from 'views/ConfirmationView/GlobalStyles'
import Theme from 'constants/Theme'
import Header from 'views/shared/Header'
import MainContainer from 'views/shared/MainContainer'
import * as AppointmentConfirmStyled from 'views/ConfirmationView/styled'
import * as BookingSummaryStyled from 'views/ConfirmationView/BookingSummary/styled'
import { timezoneOffsetSelector } from 'state/concepts/time/selectors'
import * as S from './styled'

const WaitlistRequestConfirmationView = ({ history, intl: { formatMessage } }) => {
  const waitlistRequest = useSelector(waitlistRequestSelector)
  const price = useSelector(priceSelector)
  const availabilityPreferences = useSelector(availabilityPreferencesSelector)
  const { service, userProfile, clientProfile, location } = waitlistRequest
  const resetSelection = useDispatchAction(resetSelectionAction)
  const isWithPayment = useSelector(isWithPaymentSelector)
  const timezoneOffset = useSelector(timezoneOffsetSelector)

  const handleResetSelection = () => {
    resetSelection()

    history.replace('/')
  }

  const availabilityPreferenceItemDescription = () =>
    availabilityPreferences
      .map(availability => {
        const timeLocale = getTimeText(availability, timezoneOffset)
        const dateLocale = getDateText(availability)

        return formatMessage(
          { id: 'waitlistRequestConfirmation.availabilityPreferenceItem' },
          {
            time: formatMessage({ id: timeLocale.id }, timeLocale.values),
            date: formatMessage({ id: dateLocale.id }, dateLocale.values),
          },
        )
      })
      .join('; ')

  return (
    <>
      <GlobalStyles />
      <MainContainer bgColor={Theme.colors.black200}>
        <Header />
        <AppointmentConfirmStyled.Main>
          <AppointmentConfirmStyled.GrowContainer fluid>
            <AppointmentConfirmStyled.Title
              justifyContent="center"
              text={{ id: 'waitlistRequestConfirmation.title' }}
            />
            <AppointmentConfirmStyled.EmailWrap>
              <AppointmentConfirmStyled.EmailDesc>
                <FormattedMessage id="waitlistRequestConfirmation.weSentEmailTo" />
              </AppointmentConfirmStyled.EmailDesc>
              <AppointmentConfirmStyled.Email>{clientProfile.email}</AppointmentConfirmStyled.Email>
            </AppointmentConfirmStyled.EmailWrap>
            <Row>
              <Col md={10} lg={8} mdOffset={1} lgOffset={2}>
                <S.SummaryWrapper>
                  <BookingSummaryStyled.SummaryInfo>
                    <S.Col>
                      <InfoItem
                        dataCy="info-item-service"
                        offsetBottom={24}
                        value={service.name}
                        description={formatDuration(service.duration)}
                        icon="bag"
                      />
                      <BookingSummaryStyled.ConsultantAva>
                        <BookingSummaryStyled.ConsultantIcon iconName="expertRounded" size={20} />
                        <Avatar avatarUrls={userProfile.avatarUrls} size={24} offsetRight={12} />
                        <BookingSummaryStyled.ConsultantName data-cy="summary-info-provider-name">
                          {profileName(userProfile)}
                        </BookingSummaryStyled.ConsultantName>
                      </BookingSummaryStyled.ConsultantAva>
                      {isWithPayment && (
                        <BookingSummaryStyled.Consultant>
                          <BookingSummaryStyled.Price>
                            <BookingSummaryStyled.PriceIcon iconName="price" size={20} />
                            <div>
                              <BookingSummaryStyled.PriceValue>
                                <FormattedPrice price={price} />
                              </BookingSummaryStyled.PriceValue>
                              <BookingSummaryStyled.PriceDescription>
                                <FormattedMessage id="waitlistRequestConfirmation.price" />
                              </BookingSummaryStyled.PriceDescription>
                            </div>
                          </BookingSummaryStyled.Price>
                        </BookingSummaryStyled.Consultant>
                      )}
                      {location && (
                        <InfoItem
                          offsetBottom={24}
                          value={location.name}
                          description={location.addressLine}
                          icon="location"
                          link={buildMapSearchUrl(location.coordinate)}
                          linkText={{ id: 'locationsListView.viewOnGoogleMaps' }}
                        />
                      )}
                      <InfoItem
                        offsetBottom={0}
                        value={{ id: 'waitlistRequestConfirmation.availabilityPreference' }}
                        description={availabilityPreferenceItemDescription()}
                        icon="clock"
                      />
                    </S.Col>
                  </BookingSummaryStyled.SummaryInfo>
                  <BookingSummaryStyled.ActionInfoWrapper>
                    <BookingSummaryStyled.InfoTitle as="p">
                      <FormattedMessage id="waitlistRequestConfirmation.cancelAppointment" />
                    </BookingSummaryStyled.InfoTitle>
                  </BookingSummaryStyled.ActionInfoWrapper>
                </S.SummaryWrapper>
                <AppointmentConfirmStyled.BookWrapper>
                  <AppointmentConfirmStyled.BookText>
                    <FormattedMessage id="waitlistRequestConfirmation.wantToBookAnewAppointment" />
                  </AppointmentConfirmStyled.BookText>
                  <AppointmentConfirmStyled.BookButtonWrap>
                    <AppointmentConfirmStyled.BookButton
                      dataCy="book-appointment"
                      text={{ id: 'waitlistRequestConfirmation.bookNewAppointment' }}
                      filled
                      onClick={handleResetSelection}
                    />
                  </AppointmentConfirmStyled.BookButtonWrap>
                </AppointmentConfirmStyled.BookWrapper>
              </Col>
            </Row>
          </AppointmentConfirmStyled.GrowContainer>
          <Footer />
        </AppointmentConfirmStyled.Main>
      </MainContainer>
    </>
  )
}

WaitlistRequestConfirmationView.propTypes = {
  history: PropTypes.shape().isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func }).isRequired,
}

export default compose(injectIntl, withRouter)(WaitlistRequestConfirmationView)
