import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { prop } from 'ramda'
import { FormattedMessage } from 'react-intl'

import { isReviewsFeatureEnabledSelector } from 'state/concepts/widget/selectors'
import FormattedPriceOrRange from 'views/shared/FormattedPriceOrRange'
import SelectableButton from 'views/shared/SelectableButton'
import ConsultantInfo from 'views/shared/ConsultantInfo'
import ReviewsInformation from 'views/shared/ReviewsInformation'
import * as S from './styled'

const ProviderCard = memo(({ provider, handleSelect, dataCy }) => {
  const isReviewsFeatureEnabled = useSelector(isReviewsFeatureEnabledSelector)
  const servicesCount = prop('activeServicesCount', provider)
  const about = prop('about', provider)

  return (
    <S.Card data-cy={dataCy}>
      <S.CardTop>
        <S.CardTopLeft>
          <ConsultantInfo avatarSize={52} {...provider} />
        </S.CardTopLeft>

        <S.Price isVisibleDesktop>
          <FormattedPriceOrRange {...provider} />
        </S.Price>
      </S.CardTop>

      {isReviewsFeatureEnabled && <ReviewsInformation provider={provider} handleSelect={handleSelect} />}
      <S.CardDescription description={about} isWithoutBottomOffset />

      <S.CardBottom>
        <S.ServiceAvailable>
          <S.ServiceIcon iconName="bag" offsetRight={12} size={20} />
          <S.ServiceCount>{servicesCount}</S.ServiceCount>
          <S.ServiceCountDesc>
            <FormattedMessage id="providersListView.servicesAvailable" values={{ count: servicesCount }} />
          </S.ServiceCountDesc>
        </S.ServiceAvailable>

        <S.Price>
          <FormattedPriceOrRange {...provider} />
        </S.Price>

        <SelectableButton text={{ id: 'shared.selectExpert' }} onClick={handleSelect(provider)} widthAuto />
      </S.CardBottom>
    </S.Card>
  )
})

ProviderCard.displayName = 'ProviderCard'

ProviderCard.defaultProps = {
  dataCy: undefined,
  nearestTimeSlots: [],
  handleSelect: () => {},
}

ProviderCard.propTypes = {
  dataCy: PropTypes.string,
  handleSelect: PropTypes.func,
  nearestTimeSlots: PropTypes.arrayOf(PropTypes.string),
  provider: PropTypes.shape().isRequired,
}

export default ProviderCard
