import { trim } from 'ramda'

import httpClient from 'tools/httpClient'
import { emailValidationRoute } from 'tools/apiRoutes'
import assignFormErrors from 'utils/form/assignFormErrors'

export default async ({ email, setStatus, setErrors, setSubmitting }) => {
  try {
    await httpClient.post(emailValidationRoute, { email: trim(email) })

    return true
  } catch (error) {
    assignFormErrors({ setStatus, setErrors }, error)
    setSubmitting(false)

    return false
  }
}
