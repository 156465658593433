import React from 'react'
import PropTypes from 'prop-types'

import * as S from './styled'

const Tooltip = ({ title, content, placement, iconSize }) => {
  let Component

  switch (placement) {
    case 'right':
      Component = S.ContainerRight
      break
    case 'bottom':
      Component = S.ContainerBottom
      break
    case 'left':
      Component = S.ContainerLeft
      break
    default:
      Component = S.ContainerTop
      break
  }

  return (
    <S.Wrapper>
      {title || <S.TooltipIcon size={iconSize} iconName="info" />}
      <Component>
        <S.Content>{content}</S.Content>
      </Component>
    </S.Wrapper>
  )
}

Tooltip.defaultProps = {
  title: null,
  placement: 'top',
  iconSize: 24,
}

Tooltip.propTypes = {
  title: PropTypes.node,
  iconSize: PropTypes.number,
  placement: PropTypes.string,
  content: PropTypes.node.isRequired,
}

export default Tooltip
