import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import { RECREATE_BOOKING } from 'state/concepts/booking/types'
import { resetSelection } from 'state/store/actions'
import { workspaceIdSelector } from 'state/concepts/widget/selectors'
import { serviceIdSelector } from 'state/concepts/services/selectors'
import { providerIdSelector } from 'state/concepts/providers/selectors'
import { timeSelector, timezoneSelector } from 'state/concepts/time/selectors'
import { dataApiRequest, dataApiSuccess, dataApiFailure } from 'state/data/actions'
import { locationIdSelector } from 'state/concepts/locations/selectors'
import { setBooking } from '../actions'
import { createBookingEndpoint } from '../endpoints'

const recreateBookingOperation = createLogic({
  type: RECREATE_BOOKING,
  latest: true,

  async process({ action: { replaceFunction }, httpClient, getState }, dispatch, done) {
    const state = getState()
    const workspaceId = workspaceIdSelector(state)
    const { endpoint, url } = createBookingEndpoint(workspaceId)

    const params = {
      user_profile_id: providerIdSelector(state),
      service_id: serviceIdSelector(state),
      service_provider_id: providerIdSelector(state),
      start_time: timeSelector(state),
      client_timezone: timezoneSelector(state).id,
      location_id: locationIdSelector(state),
      include: 'booking_setting',
    }

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.post(url, params)
      const response = normalize(data, { endpoint })

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(setBooking(data.data.id))
    } catch {
      replaceFunction()
      dispatch(dataApiFailure({ endpoint }))
      dispatch(resetSelection())
    }
    done()
  },
})

export default recreateBookingOperation
