import { combineReducers } from 'redux'

import * as types from './types'

const currentClientId = (state = null, action) => {
  switch (action.type) {
    case types.LOGIN_SUCCESS:
      return action.currentClientId
    case types.LOGOUT_SUCCESS:
      return null
    default:
      return state
  }
}

const loginEmail = (state = null, action) => {
  switch (action.type) {
    case types.SET_LOGIN_EMAIL:
      return action.email
    default:
      return state
  }
}

const passedCurrentClient = (state = null, action) => {
  switch (action.type) {
    case types.SET_PASSED_CURRENT_CLIENT:
      return action.passedCurrentClient
    case types.LOGOUT_SUCCESS:
      return null
    default:
      return state
  }
}

const sessionReducer = combineReducers({
  currentClientId,
  loginEmail,
  passedCurrentClient,
})

export default sessionReducer
