import React from 'react'
import PropTypes from 'prop-types'

import { STEP_TYPE_PROPERTIES } from 'constants'
import Step from '../Step'
import ProviderInfo from './ProviderInfo'

const ProviderStep = ({ steps, provider }) => (
  <Step iconName="userStar" type={STEP_TYPE_PROPERTIES.provider} steps={steps}>
    <ProviderInfo property={provider} />
  </Step>
)

ProviderStep.defaultProps = {
  provider: null,
}

ProviderStep.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
  provider: PropTypes.shape(),
}

export default ProviderStep
