import React, { useContext } from 'react'

import getDisplayName from 'utils/getDisplayName'
import ModalContext from './ModalContext'

const withModalContext = Component => {
  const WithModalContext = props => {
    const context = useContext(ModalContext)

    return <Component {...{ ...props, ...context }} />
  }

  WithModalContext.displayName = `WithModalContext(${getDisplayName(Component)})`

  return WithModalContext
}

export default withModalContext
