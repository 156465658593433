import { connect } from 'react-redux'

import { widgetLogoSelector } from 'state/concepts/widget/selectors'
import HeaderComponent from './component'

const mapStateToProps = state => ({
  widgetLogo: widgetLogoSelector(state),
})

export default connect(mapStateToProps)(HeaderComponent)
