import { pathOr } from 'ramda'

import formatJsonApiErrors from 'utils/form/formatJsonApiErrors'

const getBaseError = error => {
  const errors = pathOr(null, ['response', 'data', 'errors'], error)
  return formatJsonApiErrors(errors).base
}

export default getBaseError
