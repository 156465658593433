import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

import { MAX_NAME_LENGTH } from 'constants'
import TruncatedText from 'views/shared/TruncatedText'

const CardPosition = ({ text }) =>
  text ? (
    <CardPosition.Container data-cy="position">
      <TruncatedText phrase={text} maxChars={MAX_NAME_LENGTH} placement="bottom" noWrap />
    </CardPosition.Container>
  ) : null

CardPosition.Container = styled.div`
  color: ${({ theme }) => theme.colors.black700};
  font-size: 14px;
  line-height: ${20 / 14};

  ${media.desktop`
    font-size: 16px;
    line-height: ${24 / 16};
  `}
`

CardPosition.defaultProps = {
  text: null,
}

CardPosition.propTypes = {
  text: PropTypes.string,
}

export default CardPosition
