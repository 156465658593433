import { path, pipe, prop } from 'ramda'
import { createSelector } from 'reselect'

import { dataSelector } from 'state/data/selectors'
import buildObject from 'utils/buildObject'

export const loginEmailSelector = path(['session', 'loginEmail'])
export const currentClientIdSelector = path(['session', 'currentClientId'])
export const passedCurrentClientSelector = path(['session', 'passedCurrentClient'])

export const currentClientSelector = createSelector(
  currentClientIdSelector,
  dataSelector,
  passedCurrentClientSelector,
  (id, data, passedCurrentClient) => passedCurrentClient || buildObject('clientProfile', id, data),
)

export const currentClientTimezoneOffsetSelector = pipe(currentClientSelector, prop('timezoneOffset'))
