import styled from 'styled-components'

import SvgIcon from 'views/shared/Icons'

const Wrapper = styled.div`
  margin-bottom: 8px;
  margin-top: ${({ label }) => (label ? '8' : '0')}px;
  ${({ flexGrow }) => flexGrow && 'flex-grow: 1'};
`

const Label = styled.label`
  display: inline-block;
  margin-bottom: 4px;
  font-size: 14px;
  line-height: ${20 / 14};
  color: ${({ theme }) => theme.colors.black700};
`

const FieldWrapper = styled.div`
  position: relative;
  cursor: pointer;
`

const ClearIcon = styled(SvgIcon)`
  position: absolute;
  top: 60%;
  transform: translateY(-50%);
  fill: get-color(complementary-colors, checkbox-default-broder);
  color: get-color(complementary-colors, checkbox-default-broder);
  font-size: 20px;
  vertical-align: middle;
  right: 12px;
  left: auto;
  cursor: pointer;

  &.focused {
    fill: get-color(blue, 600);
    color: get-color(blue, 600);
  }
`

const Input = styled.input`
  width: 100%;
  background-color: white;
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  padding: 13px ${({ rightClass }) => (rightClass ? '40px' : '12px')} 13px;
  font-size: 14px;
  line-height: ${20 / 14};
  color: ${({ theme }) => theme.colors.black700};
  transition: border-color 0.3s ease 0s;
  border-color: ${({ theme, condition }) => {
    switch (condition) {
      case 'error':
        return theme.colors.red1000
      case 'info':
        return theme.colors.orange1000
      default:
        return theme.colors.black400
    }
  }};

  &:hover {
    border-color: ${({ theme }) => theme.settings.color};
  }

  &:focus {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.settings.color};
    border-color: ${({ theme }) => theme.settings.color};
    padding: 13px ${({ rightClass }) => (rightClass ? '39px' : '12px')} 13px;
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.black500};
  }
`

const LeftIcon = styled(SvgIcon)`
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
  fill: ${({ theme }) => theme.colors.black500};
`

const RightButton = styled.button`
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  border: none;
  background-color: transparent;
  font-size: 20px;
  color: ${({ theme }) => theme.colors.black500};
`

const Message = styled.p`
  display: ${({ condition }) => (condition ? 'flex' : 'none')};
  align-items: center;
  margin-top: 6px;
  font-size: 14px;
  line-height: ${20 / 14};
  color: ${({ theme, condition }) => {
    switch (condition) {
      case 'error':
        return theme.colors.red1000
      case 'info':
        return theme.colors.orange1000
      default:
        return theme.colors.black400
    }
  }};
`

const MessageIcon = styled(SvgIcon)`
  margin-right: 8px;
  fill: ${({ theme, condition }) => {
    switch (condition) {
      case 'error':
        return theme.colors.red1000
      case 'info':
        return theme.colors.orange1000
      default:
        return theme.colors.black400
    }
  }};
`

export { Wrapper, Label, FieldWrapper, Input, LeftIcon, RightButton, Message, MessageIcon, ClearIcon }
