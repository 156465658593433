import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import LinkComponent from './component'

class Link extends React.Component {
  static propTypes = {
    to: PropTypes.string.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    disabled: PropTypes.bool,
  }

  static defaultProps = {
    disabled: false,
  }

  onClick = () => {
    if (this.props.disabled || this.props.to === this.props.location.pathname) {
      return
    }
    this.props.history.push(this.props.to)
  }

  render = () => <LinkComponent onClick={this.onClick} {...this.props} />
}

export default withRouter(Link)
