import React from 'react'

import AnimatedLayoutComponent from './component'

class AnimatedLayout extends React.PureComponent {
  state = {
    isEverOpened: false,
  }

  handleAnimationEntered = () => {
    this.setState({ isEverOpened: true })
  }

  render = () => (
    <AnimatedLayoutComponent {...this.props} {...this.state} handleAnimationEntered={this.handleAnimationEntered} />
  )
}

export default AnimatedLayout
