import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

const Wrapper = styled.div`
  display: none;

  ${media.tablet`
    display: block;
  `}
`

export { Wrapper } // eslint-disable-line import/prefer-default-export
