import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Theme from 'constants/Theme'
import icons from './icons'

const SvgIcon = ({ size, color, className, offsetLeft, offsetRight, iconName, dataCy }) => (
  <SvgIcon.Svg
    color={color}
    width={`${size}px`}
    height={`${size}px`}
    className={className}
    viewBox="0 0 1024 1024"
    data-cy={dataCy || `icon-${iconName}`}
    offsetLeft={offsetLeft}
    offsetRight={offsetRight}
  >
    <path d={icons[iconName]} />
  </SvgIcon.Svg>
)

SvgIcon.Svg = styled.svg`
  flex-shrink: 0;
  vertical-align: middle;
  ${({ offsetLeft }) => offsetLeft && `margin-left: ${offsetLeft}px`};
  ${({ offsetRight }) => offsetRight && `margin-right: ${offsetRight}px`};
  fill: ${({ color, theme }) => (theme.colors ? theme.colors[color] : Theme.colors.black500)};
`

SvgIcon.defaultProps = {
  size: 24,
  color: 'black1000',
  className: '',
  offsetLeft: null,
  offsetRight: null,
  dataCy: null,
}

SvgIcon.propTypes = {
  iconName: PropTypes.string.isRequired,
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  offsetLeft: PropTypes.number,
  offsetRight: PropTypes.number,
  dataCy: PropTypes.string,
}

export default SvgIcon
