import React from 'react'
import PropTypes from 'prop-types'
import { useFrame } from 'react-frame-component'
import { StyleSheetManager } from 'styled-components'
import { GridThemeProvider } from 'styled-bootstrap-grid'

import smoothscroll from 'tools/vendor/smoothscroll'
import gridTheme from 'constants/Theme/gridTheme'
import GlobalStyles from 'constants/GlobalStyles'

const InnerFrameLayout = ({ children }) => {
  const { document, window } = useFrame()

  smoothscroll(window, document)

  return (
    <StyleSheetManager target={document.head}>
      <GridThemeProvider gridTheme={gridTheme}>
        <>
          <GlobalStyles />
          {children}
        </>
      </GridThemeProvider>
    </StyleSheetManager>
  )
}

InnerFrameLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default InnerFrameLayout
