import styled from 'styled-components'
import { Container, media, Row, Col } from 'styled-bootstrap-grid'

const Wrapper = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  height: 100vh;
`

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: ${({ theme }) => theme.sizes.containerMaxWidth};
  ${media.tablet`
    padding: 0;
  `}
`

const InnerWrapper = styled.div`
  padding-top: 16px;
  padding-bottom: 78px;
  ${media.tablet`
    padding-top: 32px;
    padding-right: 36px;
    padding-left: 36px;
  `}
`

const StuckContainer = styled.div`
  margin-left: -16px;
  margin-right: -16px;

  ${media.tablet`
    margin-left: 0;
    margin-right: 0;
  `}
`

const StyledRow = styled(Row)`
  flex-grow: 1;
`

const StyledCol = styled(Col)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

const ScrolledBlock = styled.div`
  ${media.tablet`
    position: sticky;
    top: 4px;
  `}
`

const PriceBarInfo = styled.div`
  color: ${({ theme }) => theme.colors.black700};
  margin-top: 16px;
  margin-bottom: 16px;
  font-size: 16px;
  line-height: ${28 / 16};
  display: none;

  ${media.tablet`
    margin-bottom: 0;
    display: block;
  `}
`

const CancellationReason = styled.p`
  display: none;

  ${media.tablet`
    display: block;
    color: ${({ theme }) => theme.colors.black700};
    padding-top: 16px;
    font-size: 16px;
    line-height: ${24 / 16};
  `}
`

export {
  Wrapper,
  InnerWrapper,
  StuckContainer,
  StyledRow,
  StyledCol,
  PriceBarInfo,
  CancellationReason,
  ScrolledBlock,
  StyledContainer,
}
