import { GOOGLE_MAPS_API_KEY } from 'constants'

export const buildStaticMapUrl = (locations, { width, height } = { width: 640, height: 640 }) => {
  const baseUrl = 'https://maps.googleapis.com/maps/api/staticmap'
  const size = `size=${width}x${height}`
  const mapType = 'maptype=roadmap'
  const apiKey = `key=${GOOGLE_MAPS_API_KEY}`
  const markers = locations
    .map(({ coordinate }) => `markers=color:blue%7Clabel:S%7C${coordinate.x},${coordinate.y}`)
    .join('&')

  return `${baseUrl}?${size}&${mapType}&${markers}&${apiKey}`
}

export const buildMapSearchUrl = coordinate =>
  `https://www.google.com/maps/search/?api=1&query=${coordinate.x}%2C${coordinate.y}`
