import React from 'react'
import PropTypes from 'prop-types'

import InfiniteScrollComponent from './component'

class InfiniteScroll extends React.Component {
  handleEnter = () => {
    const { hasMore, onLoadMore } = this.props

    if (hasMore) {
      onLoadMore()
    }
  }

  render = () => <InfiniteScrollComponent {...this.props} onEnter={this.handleEnter} />
}

InfiniteScroll.defaultProps = {
  hasMore: false,
  isLoading: undefined,
}

InfiniteScroll.propTypes = {
  hasMore: PropTypes.bool,
  isLoading: PropTypes.bool,
  onLoadMore: PropTypes.func.isRequired,
}

export default InfiniteScroll
