import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { indexOf } from 'ramda'

import { currentStepSelector, lastStepNumberSelector } from 'state/steps/selectors'
import StepComponent from './component'

class Step extends React.Component {
  get isCompleteStep() {
    const { steps, type, currentStep } = this.props

    if (!currentStep) {
      return false
    }

    const [, { type: currentType }] = currentStep

    return indexOf(type, steps) < indexOf(currentType, steps)
  }

  get isFutureStep() {
    const { steps, type, currentStep } = this.props

    if (!currentStep) {
      return false
    }

    const [, { type: currentType }] = currentStep

    return indexOf(type, steps) > indexOf(currentType, steps)
  }

  get stepNumber() {
    const { steps, type } = this.props

    return indexOf(type, steps) + 1
  }

  render = () => (
    <StepComponent
      {...this.props}
      stepNumber={this.stepNumber}
      isCompleteStep={this.isCompleteStep}
      isFutureStep={this.isFutureStep}
    />
  )
}

Step.defaultProps = {
  currentStep: null,
}

Step.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
  type: PropTypes.string.isRequired,
  currentStep: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.shape()])),
}

const mapStateToProps = state => ({
  currentStep: currentStepSelector(state),
  lastStepNumber: lastStepNumberSelector(state),
})

export { Step as StepContainer }
export default connect(mapStateToProps)(Step)
