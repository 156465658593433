import yup from 'tools/yupLocalized'

const MAX_TEXT_LENGTH = 255
const MAX_FIRST_LAST_NAME_LENGTH = 40
const MAX_ZIPCODE_LENGTH = 20

export const validationSchema = yup.object().shape({
  firstName: yup.string().max(MAX_FIRST_LAST_NAME_LENGTH).required(),
  lastName: yup.string().max(MAX_FIRST_LAST_NAME_LENGTH).required(),
  email: yup.string().email().required(),
  agreements: yup.bool().oneOf([true]),
  phoneNumber: yup
    .string()
    .phoneNumber()
    .when('phoneRequired', {
      is: true,
      then: schema => schema.required(),
    }),
})

export const validationPaymentSchema = yup.object().shape({
  firstName: yup.string().max(MAX_FIRST_LAST_NAME_LENGTH).required(),
  lastName: yup.string().max(MAX_FIRST_LAST_NAME_LENGTH).required(),
  email: yup.string().email().required(),
  address: yup.string().max(MAX_TEXT_LENGTH).required(),
  index: yup.string().max(MAX_ZIPCODE_LENGTH).required(),
  country: yup.string().max(MAX_TEXT_LENGTH).required(),
  state: yup.string().max(MAX_TEXT_LENGTH).required(),
  city: yup.string().max(MAX_TEXT_LENGTH).required(),
  agreements: yup.bool().oneOf([true]),
  phoneNumber: yup
    .string()
    .phoneNumber()
    .when('phoneRequired', {
      is: true,
      then: schema => schema.required(),
    }),
})
