import styled from 'styled-components'
import { Container, media } from 'styled-bootstrap-grid'

import SvgIcon from 'views/shared/Icons'

const StyledContainer = styled(Container)`
  margin: auto;
  max-width: 1200px;
  display: flex;
  padding: 12px 16px;
  ${media.tablet`
    padding: 16px 36px;
  `}
`

const TopWrap = styled.div`
  background: white;
  border-bottom: 1px solid ${({ theme }) => theme.colors.black300};
`
const TopIcon = styled.img`
  max-width: 48px;
`
const TopInfo = styled.div`
  padding-left: 24px;
  font-size: 14px;
  line-height: ${24 / 14};
  ${media.tablet`
    font-size: 16px;
    line-height: ${28 / 16};
  `}
`
const TopAddressWrap = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 4px;
  width: 100%;
  ${media.sm`
    margin-top: 0;
    align-items: center;
    width: auto;
  `}
`
const TopTitle = styled.div`
  color: ${({ theme }) => theme.colors.black700};
`
const TopDescription = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`
const TopName = styled.div`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.black1000};
  margin-right: 8px;
`
const TopAddress = styled.div`
  color: ${({ theme }) => theme.colors.black700};
`
const Icon = styled(SvgIcon)`
  fill: ${({ theme }) => theme.colors.black400};
  margin-right: 4px;
`
export {
  TopWrap,
  TopIcon,
  TopInfo,
  TopAddressWrap,
  TopTitle,
  TopDescription,
  TopName,
  Icon,
  TopAddress,
  StyledContainer,
}
