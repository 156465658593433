export default `
  button {
    height: auto;
    margin: 0;
    padding: 0;
    border: none;
    background: 0 0;
    color: inherit;
    font-family: 'Inter', sans-serif;
    font-size: inherit;
    cursor: pointer;
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  a:focus,
  input:focus,
  button:focus,
  textarea:focus {
    outline: 0;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
`
