import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import { useSelector } from 'react-redux'

import Theme from 'constants/Theme'
import Button from 'views/shared/Button'
import { currentClientSelector } from 'state/concepts/session/selectors'
import UserInfo from './UserInfo'

const User = ({ history }) => {
  const handleRedirect = useCallback(() => {
    history.push('/login')
  }, [history])
  const currentClient = useSelector(currentClientSelector)

  return currentClient ? (
    <UserInfo currentClient={currentClient} />
  ) : (
    <User.HeaderLoginBtnWrapper>
      <User.HeaderLoginBtn onClick={handleRedirect} bgColor={Theme.colors.black200} text={{ id: 'login.authorize' }} />
    </User.HeaderLoginBtnWrapper>
  )
}

User.displayName = 'User'

User.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
}

User.HeaderLoginBtnWrapper = styled.div`
  position: absolute;
  right: 68px;
  top: 50%;
  transform: translateY(-50%);
  ${media.desktop`
    right: 24px;
  `}
`

User.HeaderLoginBtn = styled(Button)`
  color: ${({ theme }) => theme.settings.color};
`

export default User
