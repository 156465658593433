import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { length, compose, path } from 'ramda'
import { withRouter } from 'react-router-dom'

import { SERVICE_FILTERS_INITIAL_STATE } from 'constants'
import withModalContext from 'views/Modal/withModalContext'
import {
  setService as setServiceAction,
  resetServices as resetServicesAction,
  fetchServices as fetchServicesAction,
  fetchServiceCategories as fetchServiceCategoriesAction,
  filterServices as filterServicesAction,
  setCurrentPage as setCurrentPageAction,
  setFilterParams as setFilterParamsAction,
} from 'state/concepts/services/actions'
import { providerSelector } from 'state/concepts/providers/selectors'
import { loadingSelector } from 'state/data/selectors'
import {
  servicesSelector,
  categoriesSelector,
  activeCategoryIdSelector,
  currentPageSelector,
  hasMoreServicesSelector,
  totalServicesCountSelector,
  fetchServicesEndpointSelector,
  fetchServicesCategoriesEndpointSelector,
  categorySelector,
} from 'state/concepts/services/selectors'
import { hasManyLocationsSelector, providersCountSelector } from 'state/concepts/widget/selectors'
import { setProvider as setProviderAction } from 'state/concepts/providers/actions'
import ServicesListViewComponent from './component'

class ServicesListView extends React.Component {
  state = {
    targetElement: null,
    shouldSearchReset: false,
    isDropdownOpen: false,
  }

  get haveCategories() {
    const { serviceCategories } = this.props
    return length(serviceCategories) > 1
  }

  get isWithBackButton() {
    const { providersCount, hasManyLocations } = this.props

    return providersCount > 1 || hasManyLocations
  }

  componentDidMount = () => {
    const {
      history: { replace },
      location: { state, pathname },
    } = this.props
    if (state?.skipLoad) {
      replace(pathname)
    } else {
      this.fetchInitialData()
    }
  }

  fetchInitialData = () => {
    this.props.setFilterParams(SERVICE_FILTERS_INITIAL_STATE)
    this.props.resetServices()
    this.props.setCurrentPage(1)
    this.props.fetchServices()
    this.props.fetchServiceCategories()
  }

  makeFilterServices =
    ({ id }) =>
    () => {
      const { targetElement } = this.state
      const { activeCategoryId, filterServices, resetServices } = this.props

      if (activeCategoryId === id) {
        return
      }

      resetServices()
      filterServices({ category: id, name: '' })
      this.setState({ shouldSearchReset: true })
      targetElement.scrollTo(0, 0)
    }

  resetFilter = () => {
    const { targetElement } = this.state
    const { activeCategoryId, filterServices, resetServices } = this.props

    if (!activeCategoryId) {
      return
    }

    resetServices()
    filterServices({ category: null, name: '' })
    this.setState({ shouldSearchReset: true })
    targetElement.scrollTo(0, 0)
  }

  makeSelectHandler = service => event => {
    event.stopPropagation()
    const { hideModal, setService, history, selectedProvider, setProvider } = this.props
    const serviceProviderId = path(['provider', 'id'], service)

    setService(service.id)

    if (!selectedProvider && serviceProviderId) {
      setProvider(serviceProviderId)
    }

    history.push(selectedProvider || serviceProviderId ? '/time' : '/providers')
    hideModal()
  }

  handleSearchServices = filter => {
    const { filterServices, resetServices } = this.props

    resetServices()
    filterServices(filter)
    this.setState({ shouldSearchReset: false })
  }

  handleLoadMore = () => {
    const { services, currentPage, fetchServices, setCurrentPage } = this.props

    if (length(services) === 0) {
      return
    }

    setCurrentPage(currentPage + 1)
    fetchServices()
  }

  setScrollElement = node => {
    if (node) {
      this.setState({ targetElement: node })
    }
  }

  toggleDropdown = () => {
    this.setState(({ isDropdownOpen }) => ({ isDropdownOpen: !isDropdownOpen }))
  }

  render = () => (
    <ServicesListViewComponent
      {...this.props}
      {...this.state}
      isWithBackButton={this.isWithBackButton}
      makeFilterServices={this.makeFilterServices}
      resetFilter={this.resetFilter}
      makeSelectHandler={this.makeSelectHandler}
      handleSearch={this.handleSearchServices}
      haveCategories={this.haveCategories}
      setScrollElement={this.setScrollElement}
      handleLoadMore={this.handleLoadMore}
      toggleDropdown={this.toggleDropdown}
    />
  )
}

ServicesListView.defaultProps = {
  activeCategoryId: null,
  serviceCategories: [],
  services: [],
  selectedProvider: null,
  providersCount: null,
  hasManyLocations: false,
}

ServicesListView.propTypes = {
  services: PropTypes.arrayOf(PropTypes.shape()),
  selectedProvider: PropTypes.shape(),
  activeCategoryId: PropTypes.string,
  serviceCategories: PropTypes.arrayOf(PropTypes.shape()),
  currentPage: PropTypes.number.isRequired,
  hideModal: PropTypes.func.isRequired,
  setService: PropTypes.func.isRequired,
  resetServices: PropTypes.func.isRequired,
  fetchServices: PropTypes.func.isRequired,
  fetchServiceCategories: PropTypes.func.isRequired,
  filterServices: PropTypes.func.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  setFilterParams: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape().isRequired,
  setProvider: PropTypes.func.isRequired,
  providersCount: PropTypes.number,
  hasManyLocations: PropTypes.bool,
}

const makeMapStateToProps = initialState => {
  const servicesEndpoint = fetchServicesEndpointSelector(initialState)
  const serviceCategoriesEndpoint = fetchServicesCategoriesEndpointSelector(initialState)

  return state => ({
    services: servicesSelector(state),
    selectedProvider: providerSelector(state),
    providersCount: providersCountSelector(state),
    currentPage: currentPageSelector(state),
    isLoading: loadingSelector(state, servicesEndpoint),
    hasMore: hasMoreServicesSelector(state, servicesEndpoint),
    activeCategoryId: activeCategoryIdSelector(state),
    serviceCategories: categoriesSelector(state),
    category: categorySelector(state),
    totalServicesCount: totalServicesCountSelector(state, serviceCategoriesEndpoint),
    hasManyLocations: hasManyLocationsSelector(state),
  })
}

const mapDispatchToProps = {
  setService: setServiceAction,
  resetServices: resetServicesAction,
  fetchServices: fetchServicesAction,
  fetchServiceCategories: fetchServiceCategoriesAction,
  filterServices: filterServicesAction,
  setCurrentPage: setCurrentPageAction,
  setFilterParams: setFilterParamsAction,
  setProvider: setProviderAction,
}

export { ServicesListView as ServicesListViewContainer }
export default compose(connect(makeMapStateToProps, mapDispatchToProps), withModalContext, withRouter)(ServicesListView)
