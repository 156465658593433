import React, { memo } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { media } from 'styled-bootstrap-grid'
import { FormattedMessage } from 'react-intl'
import { is } from 'ramda'

import Tooltip from 'views/shared/Tooltip'
import TooltipContent from './TooltipContent'

const PageTitle = memo(
  ({ className, text, withTooltip, justifyContent, smJustifyContent, offsetBottom, offsetBottomDesktop }) => (
    <PageTitle.Container
      justifyContent={justifyContent}
      smJustifyContent={smJustifyContent}
      offsetBottom={offsetBottom}
      offsetBottomDesktop={offsetBottomDesktop}
      className={className}
    >
      <div data-cy="page-title">
        <PageTitle.Text>{is(Object, text) ? <FormattedMessage {...text} /> : text}</PageTitle.Text>
      </div>
      {withTooltip && <Tooltip placement="right" content={<TooltipContent />} />}
    </PageTitle.Container>
  ),
)

PageTitle.displayName = 'PageTitle'

PageTitle.defaultProps = {
  withTooltip: false,
  justifyContent: 'flex-start',
  smJustifyContent: 'flex-start',
  offsetBottom: 16,
  offsetBottomDesktop: 32,
  className: '',
}

PageTitle.propTypes = {
  withTooltip: PropTypes.bool,
  className: PropTypes.string,
  justifyContent: PropTypes.string,
  smJustifyContent: PropTypes.string,
  offsetBottom: PropTypes.number,
  offsetBottomDesktop: PropTypes.number,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]).isRequired,
}

PageTitle.Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ smJustifyContent }) => smJustifyContent};
  position: relative;
  margin-bottom: ${({ offsetBottom }) => offsetBottom}px;

  ${media.tablet`
    margin-bottom: 24px;
  `}

  ${media.desktop`
    margin-bottom: ${({ offsetBottomDesktop }) => offsetBottomDesktop}px;
    justify-content: ${({ justifyContent }) => justifyContent};
  `}
`

PageTitle.Text = styled.h1`
  margin-right: 4px;
  font-family: ${({ theme }) => theme.fonts.interTight};
  font-size: 24px;
  font-weight: 600;
  line-height: ${36 / 24};
  color: ${({ theme }) => theme.colors.black1000};
  text-overflow: ellipsis;
  overflow: hidden;

  ${media.phone`
    font-size: 24px;
  `}

  ${media.tablet`
    margin-right: 8px;
    font-size: 32px;
  `}
`

export default PageTitle
