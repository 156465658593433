import React from 'react'
import PropTypes from 'prop-types'

import SearchEmptySvg from 'views/shared/SvgIcons/SearchEmpty'
import ReviewsEmptySvg from 'views/shared/SvgIcons/ReviewsEmpty'
import FormattedOrRawMessage from 'views/shared/FormattedOrRawMessage'
import * as S from './styled'

const ICON_COMPONENTS = {
  default: SearchEmptySvg,
  reviews: ReviewsEmptySvg,
}

const SearchEmptyState = ({ icon, title, noSearchResults, offsetTop }) => {
  const IconComponent = ICON_COMPONENTS[icon]

  return (
    <S.Wrapper offsetTop={offsetTop}>
      <S.ImageWrapper>
        <IconComponent />
      </S.ImageWrapper>
      <S.TextWrap>
        <S.TextTitle>
          <FormattedOrRawMessage message={title} />
        </S.TextTitle>
        <S.Text>
          <FormattedOrRawMessage message={noSearchResults} />
        </S.Text>
      </S.TextWrap>
    </S.Wrapper>
  )
}

SearchEmptyState.defaultProps = {
  icon: 'default',
  title: {
    id: 'shared.noSearchResultsTitle',
  },
  noSearchResults: {
    id: 'shared.noSearchResults',
  },
  offsetTop: null,
}

SearchEmptyState.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  noSearchResults: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  offsetTop: PropTypes.string,
}

export default SearchEmptyState
