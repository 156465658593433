import { createLogic } from 'redux-logic'

import assignFormErrors from 'utils/form/assignFormErrors'
import { isErrorStatusUnprocessableEntity } from 'utils/getErrorStatus'
import { SEND_CODE } from 'state/concepts/session/types'
import { setLoginEmail } from 'state/concepts/session/actions'
import { sessionSendCodeRoute } from 'tools/apiRoutes'
import { workspaceIdSelector } from 'state/concepts/widget/selectors'

const sendCodeOperation = createLogic({
  type: SEND_CODE,
  latest: true,

  async process({ action: { values, form }, getState, httpClient }, dispatch, done) {
    try {
      const workspaceId = workspaceIdSelector(getState())
      const params = {
        email: values.email,
      }

      await httpClient.post(sessionSendCodeRoute(workspaceId), params)

      dispatch(setLoginEmail(values.email))
    } catch (error) {
      if (form && isErrorStatusUnprocessableEntity(error)) {
        assignFormErrors(form, error)
      }
    }

    form?.setSubmitting(false)
    done()
  },
})

export default sendCodeOperation
