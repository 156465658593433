import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

const SearchContainerWrap = styled.div`
  width: 100%;
`

const ContainerWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  border: 1px solid ${({ theme }) => theme.colors.black300};
  margin-left: -16px;
  margin-right: -16px;
  background-color: white;
  ${media.tablet`
    margin-left: 0;
    margin-right: 0;
  `}
`
const LocationsWrap = styled.div`
  width: 100%;
  ${media.desktop`
    width: calc(100% - 491px);
    max-height: 392px;
    overflow: auto;
  `}
`
const MapWrap = styled.div`
  width: 100%;
  max-width: 491px;
  display: none;
  ${media.desktop`
    display: block;
  `}
`
const Map = styled.img`
  width: 100%;
  max-height: 392px;
`

export { SearchContainerWrap, ContainerWrap, LocationsWrap, MapWrap, Map }
