import React from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import { MemoryRouter } from 'react-router-dom'

import configureStore from 'state/store'
import Preview from '../Preview'

const store = configureStore()

const AppStartScript = ({ options }) => (
  <Provider store={store}>
    <MemoryRouter>
      <Preview {...options} />
    </MemoryRouter>
  </Provider>
)

AppStartScript.propTypes = {
  options: PropTypes.shape().isRequired,
}

export default AppStartScript
