import React from 'react'
import PropTypes from 'prop-types'
import { prop } from 'ramda'

import * as S from 'views/ServicesListView/styled'

const OptionComponent = ({ option, onClick, isSelected }) => (
  <S.CatDropdownListItem onClick={onClick(option)} data-cy="categories-dropdown-list-item" isSelected={isSelected}>
    <S.CatDropdownText>{prop('name', option)}</S.CatDropdownText>
    <S.CatDropdownAll>{prop('servicesCount', option)}</S.CatDropdownAll>
    {isSelected && <S.CatDropdownIcon active iconName="checked" size={18} />}
  </S.CatDropdownListItem>
)

OptionComponent.displayName = 'OptionComponent'

OptionComponent.propTypes = {
  option: PropTypes.shape().isRequired,
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default OptionComponent
