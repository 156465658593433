import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import Document from './Document'
import * as S from './styled'

const DocumentsCard = ({
  hasDocuments,
  hasAgreements,
  hasIntakeForms,
  pendingIntakeFormsCount,
  pendingAgreementsCount,
  forms,
  fillOutFormsLink,
}) =>
  hasDocuments ? (
    <S.Wrapper>
      <S.Header>
        <S.DocumentsCardTitle data-cy="intake-forms-summary-section">
          {hasIntakeForms && hasAgreements && (
            <FormattedMessage
              id="bookingSummary.agreementsAndFormsToComplete"
              values={{
                agreementsCount: pendingAgreementsCount,
                intakeFormsCount: pendingIntakeFormsCount,
              }}
            />
          )}

          {hasIntakeForms && !hasAgreements && (
            <FormattedMessage id="bookingSummary.intakeFormsToComplete" values={{ count: pendingIntakeFormsCount }} />
          )}

          {hasAgreements && !hasIntakeForms && (
            <FormattedMessage id="bookingSummary.agreementsToComplete" values={{ count: pendingAgreementsCount }} />
          )}
        </S.DocumentsCardTitle>
        <S.DocumentsCardDescr data-cy="intake-forms-summary-section-description">
          <FormattedMessage id="bookingSummary.reviewAndFillOut" />
        </S.DocumentsCardDescr>
        <S.FillOutLink href={fillOutFormsLink} target="_blank" data-cy="intake-forms-fill-out">
          <S.FillOutButtonText>
            <FormattedMessage id="bookingSummary.fillOutDocuments" />
          </S.FillOutButtonText>
          <S.ArrowIcon iconName="rightArrowShort" size={14} />
        </S.FillOutLink>
      </S.Header>

      {forms.map(({ id, name, formType }) => (
        <Document key={id} color="orange1000" iconName={formType === 'intake' ? 'document' : 'agreement'} text={name} />
      ))}
    </S.Wrapper>
  ) : null

DocumentsCard.propTypes = {
  hasDocuments: PropTypes.bool.isRequired,
  hasAgreements: PropTypes.bool.isRequired,
  hasIntakeForms: PropTypes.bool.isRequired,
  pendingAgreementsCount: PropTypes.number.isRequired,
  pendingIntakeFormsCount: PropTypes.number.isRequired,
  forms: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  fillOutFormsLink: PropTypes.string.isRequired,
}

export default DocumentsCard
