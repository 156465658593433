import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import * as S from './styled'

const BackButton = ({ onGoBack }) => (
  <S.BackButton onClick={onGoBack} data-cy="back-button">
    <S.BackIcon iconName="iconDownArrow" size={10} iconColor="black1000" />
    <S.BackText>
      <FormattedMessage id="shared.back" />
    </S.BackText>
  </S.BackButton>
)

BackButton.propTypes = {
  onGoBack: PropTypes.func.isRequired,
}

export default BackButton
