import locale from 'locales/en'

// eslint-disable-next-line import/prefer-default-export
export const ELEMENTS_OPTIONS = {
  locale: locale.locale,
  fonts: [
    {
      family: 'Inter',
      src: `url(${ASSET_CDN}/assets/fonts/inter/inter-v13-latin-regular.woff2)`,
      display: 'swap',
      weight: 'normal',
    },
  ],
}
