import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

export const TimeslotsTabs = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -24px;
  margin-right: -24px;
  width: calc(100% + 48px);
`

export const TimeslotsTab = styled.div`
  width: calc(100% / 3);
  display: flex;
  align-items: center;
  justify-content: center;
  border-style: solid;
  font-size: 14px;
  line-height: ${20 / 14};
  padding: 8px 12px;
  cursor: pointer;

  ${({ theme, isActive }) =>
    isActive
      ? `
      color: ${theme.colors.black1000};
      border-color: ${theme.settings.color};
      border-bottom-width: 2px;
      font-weight: 600;
    `
      : `
      color: ${theme.colors.black700};
      border-color: ${theme.colors.black300};
      border-bottom-width: 1px;
      font-weight: 400;
    `}
`

export const TimeslotsTabContent = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding-top: 16px;
  padding-bottom: 80px;
  ${media.tablet`
    padding-top: 24px;
  `}
`
