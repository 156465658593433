import { connect } from 'react-redux'

import { hideModal } from 'state/modal/actions'
import ModalRootComponent from './component'

const mapStateToProps = ({ modal }) => ({ ...modal })

const mapDispatchToProps = {
  onClose: hideModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalRootComponent)
