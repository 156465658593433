import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

const Wrap = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;

  ${media.tablet`
    display: block;
  `}

  .service-duration {
    margin-bottom: 0;
  }
`

const StepName = styled.p`
  display: none;
  margin-top: 6px;
  margin-bottom: 6px;
  font-family: ${({ theme }) => theme.fonts.interTight};
  font-size: 16px;
  line-height: ${24 / 16};
  font-weight: 600;
  color: ${({ theme }) => theme.colors.black700};

  ${media.tablet`
    display: block;
  `}
`

const Description = styled.div`
  line-height: ${20 / 14};
  margin-bottom: 0;
  margin-right: 8px;
  color: ${({ theme }) => theme.colors.black700};
  cursor: pointer;
  flex-grow: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  ${media.tablet`
    display: block;
    margin-bottom: 2px;
    margin-right: 0;
  `}
`
const Title = styled.div`
  line-height: ${20 / 14};
  margin-bottom: 0;
  margin-right: 8px;
  color: ${({ theme }) => theme.settings.color};
  cursor: pointer;
  flex-grow: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  ${media.tablet`
    display: block;
    margin-bottom: 2px;
    margin-right: 0;
  `}

  .location-name {
    color: ${({ theme }) => theme.settings.color};
  }
`

export { Wrap, StepName, Description, Title }
