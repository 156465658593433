import { TIME_PREFERENCES_TYPES } from 'constants/availabilityPreferences'
import yup from 'tools/yupLocalized'
import isPresent from 'utils/isPresent'

export default yup.object().shape({
  availabilityPreferences: yup.array().of(
    yup.object().shape({
      timeType: yup.string().required(),
      dateRange: yup.array().required(),
      startTime: yup.date().when('timeType', {
        is: timeType => timeType !== TIME_PREFERENCES_TYPES.anyTime,
        then: () => yup.date().required(),
        otherwise: () => yup.date().notRequired(),
      }),
      endTime: yup.date().when(['timeType'], {
        is: timeType => timeType === TIME_PREFERENCES_TYPES.between,
        then: () =>
          yup
            .date()
            .test('End time validation', { id: 'yup.date.endTime.min' }, (value, { parent: { startTime } }) => {
              if (!isPresent(startTime)) {
                return true
              }

              return value > startTime
            })
            .required(),
        otherwise: () => yup.date().notRequired(),
      }),
    }),
  ),
})
