import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import LinesEllipsis from 'react-lines-ellipsis'

import isPresent from 'utils/isPresent'
import * as S from './styled'

const DescriptionComponent = ({ description, maxLine, onToggleClick, isCollapsed, dataCy, className }) =>
  isPresent(description) ? (
    <S.Container className={className} data-сy="card-description-mobile">
      <S.Text>
        {isCollapsed ? (
          <LinesEllipsis
            style={{ whiteSpace: 'pre-wrap' }}
            text={description}
            maxLine={maxLine}
            basedOn="words"
            ellipsis={
              <S.ButtonWrapper>
                {' '}
                ...{' '}
                <S.ShowMore onClick={onToggleClick} data-cy={dataCy}>
                  <FormattedMessage id="shared.showMore" />
                </S.ShowMore>
              </S.ButtonWrapper>
            }
          />
        ) : (
          <>
            <S.FullText>{description}</S.FullText>
            <S.ShowMore onClick={onToggleClick} data-cy={dataCy}>
              <FormattedMessage id="shared.showLess" />
            </S.ShowMore>
          </>
        )}
      </S.Text>
    </S.Container>
  ) : null

DescriptionComponent.defaultProps = {
  description: '',
  dataCy: 'learn-more-button',
  maxLine: 2,
  className: '',
}

DescriptionComponent.propTypes = {
  onToggleClick: PropTypes.func.isRequired,
  isCollapsed: PropTypes.bool.isRequired,
  description: PropTypes.string,
  dataCy: PropTypes.string,
  className: PropTypes.string,
  maxLine: PropTypes.number,
}

export default DescriptionComponent
