import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const WidgetButton = memo(({ children, size, bgColor, dataCy, className, onClick }) => (
  <WidgetButton.Button size={size} bgColor={bgColor} onClick={onClick} data-cy={dataCy} className={className}>
    {children}
  </WidgetButton.Button>
))

WidgetButton.displayName = 'WidgetButton'

WidgetButton.defaultProps = {
  children: undefined,
  size: 48,
  bgColor: undefined,
  dataCy: undefined,
  className: '',
  onClick: () => {},
}

WidgetButton.propTypes = {
  children: PropTypes.node,
  size: PropTypes.number,
  bgColor: PropTypes.string,
  dataCy: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
}

WidgetButton.Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border: none;
  border-radius: 50%;
  background-color: ${({ bgColor, theme }) => bgColor || theme.settings.buttonColor};
  cursor: pointer;
  outline: none;
`

export default WidgetButton
