import React from 'react'
import PropTypes from 'prop-types'
import { Waypoint } from 'react-waypoint'

import * as S from './styled'

const InfiniteScrollComponent = ({ onEnter, isLoading, size }) =>
  isLoading !== false ? (
    <S.LoaderWrap>
      <S.LoaderIcon size={size} />
    </S.LoaderWrap>
  ) : (
    <Waypoint onEnter={onEnter} bottomOffset="-100px" />
  )

InfiniteScrollComponent.defaultProps = {
  isLoading: undefined,
  size: 48,
}

InfiniteScrollComponent.propTypes = {
  onEnter: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  size: PropTypes.number,
}

export default InfiniteScrollComponent
