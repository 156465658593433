import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { isNil } from 'ramda'

import ProviderTimeSlotsCard from './ProviderTimeSlotsCard'
import ProviderCard from './ProviderCard'

const ServiceProviders = memo(({ providers, makeSelectHandler, isWithTimeSlots }) => {
  if (isNil(providers)) {
    return null
  }

  const CardComponent = isWithTimeSlots ? ProviderTimeSlotsCard : ProviderCard

  return providers.map(provider => (
    <CardComponent dataCy="provider-card" key={provider.id} provider={provider} handleSelect={makeSelectHandler} />
  ))
})

ServiceProviders.displayName = 'ServiceProviders'

ServiceProviders.defaultProps = {
  providers: [],
}

ServiceProviders.propTypes = {
  providers: PropTypes.arrayOf(PropTypes.shape()),
  makeSelectHandler: PropTypes.func.isRequired,
  isWithTimeSlots: PropTypes.bool.isRequired,
}

export default ServiceProviders
