import React from 'react'
import PropTypes from 'prop-types'
import Button from 'views/shared/Button'
import * as S from './styled'

const Footer = ({ price, handleClose, handleSelect, disableButton, actionButtonText }) => (
  <S.Container>
    <S.ButtonContainer>
      {handleSelect ? (
        <>
          <S.Price>{price}</S.Price>
          <Button text={actionButtonText} disabled={disableButton} onClick={handleSelect} />
        </>
      ) : (
        <S.DoneButton text={{ id: 'shared.done' }} onClick={handleClose} />
      )}
    </S.ButtonContainer>
  </S.Container>
)

Footer.defaultProps = {
  disableButton: false,
  handleSelect: undefined,
}

Footer.propTypes = {
  disableButton: PropTypes.bool,
  price: PropTypes.node.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSelect: PropTypes.func,
  actionButtonText: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
}

export default Footer
