import { map, replace, keys, reduce, compose } from 'ramda'

import enLocale from 'locales/en'

const articlize = word => {
  const isVowel = ['a', 'e', 'i', 'o', 'u'].includes(word[0])
  return isVowel ? `an ${word}` : `a ${word}`
}

const transformMessages = mappings => message =>
  reduce(
    (acc, key) =>
      compose(
        replace(new RegExp(`_\\(${key}\\)`, 'g'), mappings[key]),
        replace(new RegExp(`_a\\(${key}\\)`, 'g'), articlize(mappings[key])),
      )(acc),
    message,
    keys(mappings),
  )

const interpolateLocales = mappings => map(transformMessages(mappings), enLocale.messages)

export default interpolateLocales
