export default {
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },
  colors: {
    // Black
    black50: '#f5f7fa',
    black100: '#f8f9fb',
    black150: '#B2BAC7',
    black200: '#f3f5f9',
    black300: '#e6eaf1',
    black400: '#cad0dc',
    black500: '#aab3c5',
    black700: '#525f7f',
    black800: '#586A86',
    black900: '#13284C',
    black1000: '#172949',
    // Green
    green100: '#effaf3',
    green300: '#d5f2e0',
    green400: '#abe4c1',
    green500: '#6bd092',
    green1000: '#2cbc64',
    // Grey
    grey30: '#f0f0f0',
    grey400: '#dde2e8',
    // Orange
    orange100: '#fff5eb',
    orange400: '#ffe3c3',
    orange500: '#ffd09b',
    orange1000: '#ffa238',
    // Yellow
    yellow500: '#f9da36',
    // Red
    red100: '#ffeeee',
    red400: '#ffcece',
    red500: '#ffaeae',
    red1000: '#ff5d5d',
    // State colors
    accentGreen: '#18a850',
    primaryBtnHover: '#65cb8e',
    primaryBtnActive: '#18A850',
    secondaryWhite: '#c4c9d1',
    accentBlack: '#0e1d38',
    disabledWhite: '#8b94a4',
    // Main Overlay
    overlayColor: '15, 29, 53',
    // Box shadow
    boxShadowColor: '82, 97, 115',
  },
  sizes: {
    servicesListTopOffset: '16px',
    servicesListTopOffsetTablet: '24px',
    servicesListTopOffsetDesktop: '32px',
    headerHeight: '48px',
    headerHeightDesktop: '56px',
    containerMaxWidth: '1200px',
  },
  fonts: {
    raleway: 'Raleway, sans-serif',
    inter: 'Inter, sans-serif',
    interTight: '"Inter Tight", sans-serif',
  },
  // Modal z-index
  modalZindex: 999,
  mainWidth: '1056',
}
