import React from 'react'
import PropTypes from 'prop-types'

import * as S from './styled'

const MobileCloseWidgetBtn = ({ onCloseWidget }) => (
  <S.MobileCloseButton size={36} onClick={onCloseWidget}>
    <S.Icon size={20} iconName="iconCross" />
  </S.MobileCloseButton>
)

MobileCloseWidgetBtn.propTypes = {
  onCloseWidget: PropTypes.func.isRequired,
}

export default MobileCloseWidgetBtn
