import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import * as S from './styled'

const OnlineAppointment = ({ title, description, onSelect }) => (
  <S.CardWrap onClick={onSelect} data-cy="online-location-item">
    <S.VideoIcon iconName="video" size={24} />
    <S.LinkInfo>
      <S.LinkTitle data-cy="online-location-title">
        <S.VideoIconMobile iconName="video" size={24} />
        <FormattedMessage {...title} />
      </S.LinkTitle>
      <S.LinkDescription data-cy="online-location-description">
        <FormattedMessage {...description} />
      </S.LinkDescription>
    </S.LinkInfo>
    <S.ArrowIcon iconName="rightArrow" size={24} />
  </S.CardWrap>
)

OnlineAppointment.propTypes = {
  title: PropTypes.shape({ id: PropTypes.string }).isRequired,
  description: PropTypes.shape({ id: PropTypes.string }).isRequired,
  onSelect: PropTypes.func.isRequired,
}

export default OnlineAppointment
