import React, { memo } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { is } from 'ramda'
import { rgba } from 'polished'

const SelectableButton = memo(
  ({ id, time, isSmall, text, intl, onClick, dataCy, widthAuto, disabled, className, isTimeslot, noMinWidth }) => (
    <SelectableButton.Wrapper
      className={className}
      isSmall={isSmall}
      noMinWidth={noMinWidth}
      isTimeslot={isTimeslot}
      widthAuto={widthAuto}
      onClick={id ? onClick(id, time) : onClick}
      disabled={disabled}
      data-cy={dataCy}
    >
      <SelectableButton.Text>{is(Object, text) ? intl.formatMessage(text) : text}</SelectableButton.Text>
    </SelectableButton.Wrapper>
  ),
)

SelectableButton.displayName = 'SelectableButton'

SelectableButton.defaultProps = {
  isTimeslot: false,
  id: undefined,
  time: undefined,
  isSmall: false,
  noMinWidth: false,
  text: null,
  dataCy: 'selectable-button',
  widthAuto: false,
  disabled: false,
  className: '',
  onClick: () => {},
}

SelectableButton.propTypes = {
  id: PropTypes.string,
  time: PropTypes.string,
  widthAuto: PropTypes.bool,
  isSmall: PropTypes.bool,
  noMinWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  isTimeslot: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
  dataCy: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
  intl: PropTypes.shape().isRequired,
}

SelectableButton.Wrapper = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 2px;
  padding-left: 2px;
  width: ${({ widthAuto }) => (widthAuto ? 'auto' : '100%')};
  min-height: ${({ isSmall }) => (isSmall ? '32px' : '40px')};
  min-width: ${({ noMinWidth }) => (noMinWidth ? 'unset' : '108px')};
  border-radius: 2px;
  border: 1px solid transparent;
  cursor: pointer;
  transition:
    background-color 0.15s,
    color 0.15s,
    border-color 0.15s;

  ${({ isTimeslot, theme }) =>
    isTimeslot
      ? `
      min-width: unset;
      color: ${theme.colors.black1000};
      border-color: ${theme.colors.black300};
      text-transform: lowercase;
    `
      : `
      background-color: ${theme.colors.black200};
      color: ${theme.settings.color};
    `}

  &:hover {
    color: ${({ theme }) => theme.settings.color};
    border-color: ${({ theme }) => theme.settings.color};
    background-color: ${({ theme }) => rgba(theme.settings.color, 0.1)};
  }

  &:disabled {
    opacity: 0.4;
    pointer-events: none;
    user-select: none;
  }
`

SelectableButton.Text = styled.span`
  line-height: ${20 / 14};
  font-size: 14px;
  font-weight: 600;
`

export default SelectableButton
