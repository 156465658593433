import cookies from 'component-cookie'

const tokenInterceptor = config => {
  if (config.headers.Authorization) return config

  const { tokens } = cookies()
  const accessToken = tokens ? JSON.parse(tokens)?.access : null

  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = accessToken
  return config
}

export default tokenInterceptor
