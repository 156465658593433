import React from 'react'
import PropTypes from 'prop-types'
import { Elements } from '@stripe/react-stripe-js'
import { FormattedMessage } from 'react-intl'

import { ELEMENTS_OPTIONS } from 'constants/stripe'
import Theme from 'constants/Theme'
import MainContainer from 'views/shared/MainContainer'
import Header from 'views/shared/Header'
import DesktopPageTitle from 'views/shared/DesktopPageTitle'
import MobilePageTitle from 'views/shared/MobilePageTitle'
import Stepper from 'views/shared/Stepper'
import PriceBar from 'views/shared/PriceBar'
import MobileStepperCounter from 'views/shared/MobileStepperCounter'

import cancellationPhrase from 'utils/cancellationPhrase'
import BookingFormInjected from './BookingFormInjected'
import BookingForm from './BookingFormInjected/BookingForm'
import * as S from './styled'

const ReviewBookingViewComponent = ({
  reservationTime,
  stripe,
  paypal,
  isWithPayment,
  bookingSettings,
  lastStepNumber,
}) => (
  <MainContainer bgColor={Theme.colors.black200}>
    <Header withBackButton />
    <S.Wrapper fluid>
      <S.StyledContainer>
        <S.InnerWrapper>
          <DesktopPageTitle text={{ id: 'reviewBookingView.title' }} />
          <S.StyledRow>
            <S.StyledCol md={4}>
              <S.ScrolledBlock>
                <Stepper />
                <PriceBar />
                {bookingSettings && (
                  <S.CancellationReason>
                    <FormattedMessage {...cancellationPhrase(bookingSettings, isWithPayment)} />
                  </S.CancellationReason>
                )}
                <MobileStepperCounter number={lastStepNumber} />
                <MobilePageTitle text={{ id: 'reviewBookingView.MobileTitle' }} />
              </S.ScrolledBlock>
            </S.StyledCol>
            <S.StyledCol md={8}>
              {isWithPayment ? (
                <S.StuckContainer>
                  <Elements stripe={stripe} options={ELEMENTS_OPTIONS}>
                    <BookingFormInjected
                      paypal={paypal}
                      reservationTime={reservationTime}
                      bookingSettings={bookingSettings}
                    />
                  </Elements>
                </S.StuckContainer>
              ) : (
                <S.StuckContainer>
                  <BookingForm reservationTime={reservationTime} bookingSettings={bookingSettings} />
                </S.StuckContainer>
              )}
            </S.StyledCol>
          </S.StyledRow>
        </S.InnerWrapper>
      </S.StyledContainer>
    </S.Wrapper>
  </MainContainer>
)

ReviewBookingViewComponent.defaultProps = {
  reservationTime: null,
  stripe: null,
  paypal: null,
  isWithPayment: false,
}

ReviewBookingViewComponent.propTypes = {
  bookingSettings: PropTypes.shape().isRequired,
  stripe: PropTypes.shape(),
  paypal: PropTypes.shape(),
  reservationTime: PropTypes.string,
  isWithPayment: PropTypes.bool,
  lastStepNumber: PropTypes.number.isRequired,
}

export default ReviewBookingViewComponent
