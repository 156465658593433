import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import assignFormErrors from 'utils/form/assignFormErrors'
import { isErrorStatusUnprocessableEntity } from 'utils/getErrorStatus'
import { workspaceIdSelector } from 'state/concepts/widget/selectors'
import { dataApiRequest, dataApiSuccess, dataApiFailure } from 'state/data/actions'
import { CREATE_WAITLIST_REQUEST } from 'state/concepts/waitlistRequest/types'
import { providerIdSelector } from 'state/concepts/providers/selectors'
import { serviceIdSelector } from 'state/concepts/services/selectors'
import { timezoneSelector } from 'state/concepts/time/selectors'
import { locationIdSelector } from 'state/concepts/locations/selectors'
import { buildCreateParams } from 'utils/waitlistRequests/buildWaitlistParams'
import { availabilityPreferencesSelector } from 'state/concepts/waitlistRequest/selectors'
import validateEmail from 'utils/validateEmail'
import { setWaitlistRequest } from 'state/concepts/waitlistRequest/actions'
import { currentClientTimezoneOffsetSelector } from 'state/concepts/session/selectors'
import formatJsonApiErrors from 'utils/form/formatJsonApiErrors'
import makeShowBookingErrorModal from 'utils/makeShowBookingErrorModal'
import { createWaitlistRequestEndpoint } from '../endpoints'

const createWaitlistRequestOperation = createLogic({
  type: CREATE_WAITLIST_REQUEST,
  latest: true,

  async process({ action: { values, form, history, abortStep }, httpClient, getState, action }, dispatch, done) {
    const state = getState()
    const workspaceId = workspaceIdSelector(state)
    const timezoneOffset = currentClientTimezoneOffsetSelector(state)

    const isValidEmail = await validateEmail({ ...action, email: values.email })

    if (!isValidEmail) {
      done()
      return
    }

    const { endpoint, url } = createWaitlistRequestEndpoint(workspaceId)

    dispatch(dataApiRequest({ endpoint }))

    try {
      const params = buildCreateParams({
        userProfileId: providerIdSelector(state),
        serviceId: serviceIdSelector(state),
        locationId: locationIdSelector(state),
        timezone: timezoneSelector(state).id,
        clientProfile: values,
        availabilityPreferences: availabilityPreferencesSelector(state),
        timezoneOffset,
      })

      const { data } = await httpClient.post(url, params)

      dispatch(dataApiSuccess({ endpoint, response: normalize(data, { endpoint }) }))
      dispatch(setWaitlistRequest(data.data.id))

      history.push('/waitlist_request_confirmation')
    } catch (error) {
      dispatch(dataApiFailure({ endpoint }))
      assignFormErrors(form, error)

      if (isErrorStatusUnprocessableEntity(error)) {
        const { availabilityPreferencesBase, base } = formatJsonApiErrors(error.response.data.errors)

        if (base) {
          makeShowBookingErrorModal({ dispatch, baseError: base })
        } else if (availabilityPreferencesBase) {
          makeShowBookingErrorModal({
            dispatch,
            baseError: availabilityPreferencesBase,
            title: { id: 'waitlistRequest.errorModal.title' },
            submitText: { id: 'waitlistRequest.errorModal.submit' },
            onSubmit: abortStep,
          })
        }
      }
    }

    form.setSubmitting(false)

    done()
  },
})

export default createWaitlistRequestOperation
