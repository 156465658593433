import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import build from 'redux-object'
import { head, propOr } from 'ramda'

import { FETCH_WIDGET_DETAILS } from 'state/concepts/widget/types'
import { dataApiRequest, dataApiSuccess, dataApiFailure } from 'state/data/actions'
import { setWidgetPreviewSettings, setWidget, setWorkspace, resolveWidgetState } from 'state/concepts/widget/actions'
import { fetchWidgetDetailsEndpoint } from 'state/concepts/widget/endpoints'
import { widgetFiltersSelector } from 'state/concepts/widget/selectors'

const fetchWidgetDetailsOperation = createLogic({
  type: FETCH_WIDGET_DETAILS,
  latest: true,

  async process({ action: { widgetSettings, history, widgetId, workspaceId }, httpClient, getState }, dispatch, done) {
    const { serviceId, providerId } = widgetFiltersSelector(getState())
    if (widgetSettings) {
      dispatch(setWidgetPreviewSettings(widgetSettings))
      dispatch(setWidget(propOr(null, 'id', widgetSettings)))
    } else {
      dispatch(setWidget(widgetId))
    }
    dispatch(setWorkspace(workspaceId))

    const { endpoint, url } = fetchWidgetDetailsEndpoint(workspaceId, widgetId)

    dispatch(dataApiRequest({ endpoint }))

    try {
      const params = {
        filter: {
          service_id: serviceId,
          user_profile_id: providerId,
        },
        include: [
          'provider',
          'provider.user-profile-service',
          'service',
          'service.service-category',
          'service.user-profile-service',
          'workspace',
          'workspace.client-portal',
          'location',
        ],
      }
      const { data } = await httpClient.get(url, { params })
      const response = normalize(data)
      const widget = head(build(response, 'widget'))

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(resolveWidgetState({ navigateTo: history.push, widget }))
    } catch {
      dispatch(dataApiFailure({ endpoint }))
    }
    done()
  },
})

export default fetchWidgetDetailsOperation
