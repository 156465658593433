import styled from 'styled-components'
import { Container, media } from 'styled-bootstrap-grid'

const ScrollWrapper = styled.div`
  flex-grow: 1;
  overflow-y: auto;
`
const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: ${({ theme }) => theme.sizes.containerMaxWidth};
  padding-top: ${({ theme }) => theme.sizes.servicesListTopOffset};
  padding-bottom: 8px;

  ${media.tablet`
    padding-top: ${({ theme }) => theme.sizes.servicesListTopOffsetTablet};
    padding-right: 36px;
    padding-left: 36px;
  `}

  ${media.desktop`
    padding-top: ${({ theme }) => theme.sizes.servicesListTopOffsetDesktop};
  `}
`

export { StyledContainer, ScrollWrapper }
