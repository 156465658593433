import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import { dataApiFailure, dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { GET_CLIENT_OWN_PROFILE } from 'state/concepts/session/types'
import { loginSuccess } from 'state/concepts/session/actions'
import { workspaceIdSelector } from 'state/concepts/widget/selectors'
import { getClientOwnProfileEndpoint } from '../endpoints'

const getClientOwnProfileOperation = createLogic({
  type: GET_CLIENT_OWN_PROFILE,
  latest: true,

  async process({ httpClient, getState }, dispatch, done) {
    const workspaceId = workspaceIdSelector(getState())
    const { endpoint, url } = getClientOwnProfileEndpoint(workspaceId)

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.get(url)

      dispatch(loginSuccess(data.data.id))
      dispatch(dataApiSuccess({ response: normalize(data), endpoint }))
    } catch (error) {
      dispatch(dataApiFailure({ endpoint }))
    }
    done()
  },
})

export default getClientOwnProfileOperation
