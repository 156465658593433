import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import build from 'redux-object'
import { head } from 'ramda'

import { GET_LOCATION_DETAILS } from 'state/concepts/locations/types'
import { dataApiRequest, dataApiSuccess, dataApiFailure } from 'state/data/actions'
import { workspaceIdSelector, widgetIdSelector, widgetFiltersSelector } from 'state/concepts/widget/selectors'
import { resolveWidgetState } from 'state/concepts/widget/actions'
import { getLocationDetailsEndpoint } from 'state/concepts/locations/endpoints'
import { setLocation } from 'state/concepts/locations/actions'

const getLocationDetailsOperation = createLogic({
  type: GET_LOCATION_DETAILS,
  latest: true,

  async process({ action: { locationId, history }, httpClient, getState }, dispatch, done) {
    const state = getState()
    const workspaceId = workspaceIdSelector(state)
    const widgetId = widgetIdSelector(state)
    const { serviceId, providerId } = widgetFiltersSelector(state)
    const { endpoint, url } = getLocationDetailsEndpoint(workspaceId, widgetId)

    dispatch(dataApiRequest({ endpoint }))

    try {
      const params = {
        include: [
          'provider',
          'provider.user-profile-service',
          'service',
          'service.service-category',
          'service.user-profile-service',
        ],
        filter: { location_id: locationId, service_id: serviceId, user_profile_id: providerId },
      }
      const { data } = await httpClient.get(url, { params })
      const response = normalize(data)
      const widget = head(build(response, 'widget'))

      dispatch(setLocation(locationId))
      dispatch(resolveWidgetState({ navigateTo: history.push, widget }))
      dispatch(dataApiSuccess({ response, endpoint }))
    } catch {
      dispatch(dataApiFailure({ endpoint }))
    }
    done()
  },
})

export default getLocationDetailsOperation
