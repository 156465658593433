import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { media } from 'styled-bootstrap-grid'

import { MAX_NAME_LENGTH_IN_STEPPER } from 'constants'
import Duration from 'views/shared/Card/Duration'
import TruncatedText from 'views/shared/TruncatedText'
import ModalContext from 'views/Modal/ModalContext'
import ServiceModal from 'views/ServicesListView/ServiceModal'

const ServiceInfo = ({ property: service }) => {
  const { showModal } = useContext(ModalContext)

  return (
    <ServiceInfo.Wrap data-cy="widget-stepper-service">
      <ServiceInfo.StepName>
        <FormattedMessage id="stepper.service" />
      </ServiceInfo.StepName>
      {service && (
        <>
          <ServiceInfo.Title onClick={showModal(ServiceModal, { service })} data-cy="widget-stepper-service-name">
            <TruncatedText className="service-name" phrase={service.name} maxChars={MAX_NAME_LENGTH_IN_STEPPER} />
          </ServiceInfo.Title>
          <ServiceInfo.Duration data-cy="widget-stepper-service-duration">
            <ServiceInfo.DurationMobile visibleMobile isStepper seconds={service.duration} />
            <ServiceInfo.DurationTablet isStepper seconds={service.duration} />
          </ServiceInfo.Duration>
        </>
      )}
    </ServiceInfo.Wrap>
  )
}

ServiceInfo.defaultProps = {
  property: null,
}

ServiceInfo.propTypes = {
  property: PropTypes.shape({
    name: PropTypes.string.isRequired,
    duration: PropTypes.number.isRequired,
  }),
}

ServiceInfo.Wrap = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;

  ${media.tablet`
    display: block;
  `}

  .service-duration {
    margin-bottom: 0;
  }
`

ServiceInfo.StepName = styled.p`
  display: none;
  margin-top: 6px;
  margin-bottom: 6px;
  font-family: ${({ theme }) => theme.fonts.interTight};
  font-size: 16px;
  line-height: ${24 / 16};
  font-weight: 600;
  color: ${({ theme }) => theme.colors.black700};

  ${media.tablet`
    display: block;
  `}
`

ServiceInfo.Title = styled.div`
  line-height: ${20 / 14};
  margin-bottom: 0;
  margin-right: 8px;
  color: ${({ theme }) => theme.settings.color};
  cursor: pointer;
  flex-grow: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  ${media.tablet`
    display: block;
    margin-bottom: 2px;
    margin-right: 0;
  `}

  .service-name {
    color: ${({ theme }) => theme.settings.color};
  }
`

ServiceInfo.Duration = styled.div`
  flex-shrink: 0;
`

ServiceInfo.DurationMobile = styled(Duration)`
  ${media.tablet`
    display: none;
  `}
`

ServiceInfo.DurationTablet = styled(Duration)`
  display: none;

  ${media.tablet`
    display: block;
  `}
`

export default ServiceInfo
