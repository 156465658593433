import styled, { css } from 'styled-components'
import { media } from 'styled-bootstrap-grid'

import SvgIcon from 'views/shared/Icons'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  min-height: 96px;
  padding: 16px;
  margin-bottom: 16px;
  border: 1px solid ${({ theme }) => theme.colors.black400};
  cursor: ${({ withHover }) => (withHover ? 'pointer' : 'default')};
  background-color: #fff;

  ${({ withHover }) =>
    withHover &&
    css`
      &:hover {
        border-color: ${({ theme }) => theme.settings.color};
        transition: 0.4s;
      }
    `}

  ${media.tablet`
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 48px 24px 36px;
    height: 320px;
  `}
`

const ImageWrapper = styled.div`
  margin-right: 16px;
  max-width: 64px;
  height: 64px;
  flex-shrink: 0;
  color: ${({ theme }) => theme.settings.color};

  ${media.tablet`
    max-width: 128px;
    height: 128px;
    margin-right: 0;
  `}
`

const Image = styled.img`
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
`

const Circle = styled.span`
  position: absolute;
  border-radius: 50%;
  width: 23px;
  height: 23px;
  background-color: ${({ theme }) => theme.settings.color};
  top: -2px;
  right: -2px;

  ${media.tablet`
    width: 30px;
    height: 30px;
  `}
`

const Content = styled.div`
  flex-grow: 1;

  ${media.tablet`
    flex-grow: unset;
    text-align: center;
    margin-bottom: 24px;
  `}
`

const Title = styled.h2`
  font-family: ${({ theme }) => theme.fonts.interTight};
  font-size: 20px;
  line-height: ${32 / 20};
  color: ${({ theme }) => theme.settings.color};
  margin-bottom: 4px;
  margin-top: 16px;

  ${media.tablet`
    font-size: 24px;
    line-height: ${36 / 24};
  `}
`

const Description = styled.p`
  font-size: 16px;
  line-height: ${24 / 16};
  color: ${({ theme }) => theme.colors.black400};
`

const CardTitle = styled(Title)`
  align-items: flex-start;
  flex-direction: column;
  line-height: ${28 / 16};

  ${media.tablet`
    font-size: 12px;
  `}

  ${media.desktop`
    align-items: center;
    font-size: 16px;
  `}
`

const ArrowIcon = styled(SvgIcon)`
  display: none;

  ${media.tablet`
    display: inline-block;
    fill: ${({ theme }) => theme.settings.color};
    width: 0;
    transition: fill .7s, width .3s;
  `}

  ${/* sc-selector */ Wrapper}:hover & {
    ${media.tablet`
      width: 20px;
      margin-left: 8px;
    `}
  }
`

const MobileArrowIcon = styled(SvgIcon)`
  fill: ${({ theme }) => theme.colors.black400};

  ${media.tablet`
    display: none;
  `}
`

const CheckMark = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  top: 8px;
  right: 8px;

  ${media.tablet`
    width: 32px;
    height: 32px;
    top: 16px;
    right: 16px;
  `}

  ${media.desktop`
    top: 20px;
    left: 20px;
  `}
`

const Name = styled.h3`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  align-self: flex-start;

  ${media.desktop`
    margin-top: -20px;
    align-self: center;
  `}
`

const NameWrap = styled.div`
  max-width: calc(100% - 48px);

  ${media.desktop`
    max-width: 100%;
  `}
`

const Info = styled.p`
  display: flex;
  align-items: center;
  font-weight: normal;
  line-height: ${24 / 16};

  ${media.desktop`
    justify-content: center;
  `}
`

export {
  Wrapper,
  ImageWrapper,
  Image,
  Circle,
  Title,
  CardTitle,
  CheckMark,
  Name,
  Info,
  ArrowIcon,
  NameWrap,
  Description,
  Content,
  MobileArrowIcon,
}
