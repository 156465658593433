import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { compose } from 'ramda'
import { connect } from 'react-redux'

import { currentHumanStepNumberSelector, lastStepNumberSelector } from 'state/steps/selectors'
import MobileStepperCounterComponent from './component'

class MobileStepperCounter extends React.Component {
  get stepNumber() {
    const { number, currentHumanStepNumber } = this.props
    return number || currentHumanStepNumber
  }

  render = () => <MobileStepperCounterComponent {...this.props} stepNumber={this.stepNumber} />
}

MobileStepperCounter.propTypes = {
  number: PropTypes.number,
  currentHumanStepNumber: PropTypes.number,
}

MobileStepperCounter.defaultProps = {
  number: null,
  currentHumanStepNumber: null,
}

const mapStateToProps = state => ({
  currentHumanStepNumber: currentHumanStepNumberSelector(state),
  totalSteps: lastStepNumberSelector(state),
})

export { MobileStepperCounter as MobileStepperCounterContainer }
export default compose(connect(mapStateToProps, null), withRouter)(MobileStepperCounter)
