import build from 'redux-object'
import { isEmpty, curry } from 'ramda'

export default curry((objectType, ids, data) => {
  if (isEmpty(ids)) {
    return []
  }

  return build(data, objectType, ids) || []
})
