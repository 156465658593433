import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

import SvgIcon from 'views/shared/Icons'

const StepComponent = memo(({ stepNumber, isCompleteStep, isFutureStep, iconName, children, lastStepNumber }) => (
  <StepComponent.Container disabled={isFutureStep} isCompleteStep={isCompleteStep}>
    <StepComponent.IconWrap>
      <StepComponent.IconWrapTablet disabled={isFutureStep}>
        {isCompleteStep && <StepComponent.CompleteIcon iconName="checked" size={24} />}
        {!isCompleteStep && <StepComponent.StepIcon iconName={iconName} size={22} color="black700" />}
      </StepComponent.IconWrapTablet>

      <StepComponent.IconWrapMobile>
        {isCompleteStep && <StepComponent.StepIcon iconName={iconName} size={22} color="black700" />}
      </StepComponent.IconWrapMobile>
      {stepNumber !== lastStepNumber && <StepComponent.Line disabled={isFutureStep} complete={isCompleteStep} />}
    </StepComponent.IconWrap>

    {children}
  </StepComponent.Container>
))

StepComponent.displayName = 'Step'

StepComponent.defaultProps = {
  isFutureStep: false,
  isCompleteStep: false,
}

StepComponent.propTypes = {
  isCompleteStep: PropTypes.bool,
  isFutureStep: PropTypes.bool,
  stepNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  iconName: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  lastStepNumber: PropTypes.number.isRequired,
}

StepComponent.Container = styled.div`
  display: flex;
  margin-bottom: 24px;
  font-size: 16px;
  line-height: ${24 / 16};
  ${({ disabled }) => disabled && 'opacity: .4'};
  ${({ isCompleteStep }) => !isCompleteStep && 'display: none'};

  ${media.tablet`
    ${({ isCompleteStep }) => !isCompleteStep && 'display: flex'};
    margin-bottom: 16px;
    font-size: 14px;
    line-height: ${20 / 14};
    align-items: stretch;
    margin-bottom: ${({ isCompleteStep }) => (isCompleteStep ? '24px' : '40px')};
  `}
`

StepComponent.IconWrap = styled.div`
  position: relative;
  flex-shrink: 0;
  margin-right: 16px;
`

StepComponent.Line = styled.div`
  display: none;
  content: '';
  position: absolute;
  left: 50%;
  top: 40px;
  width: 1px;
  height: calc(100% - 4px);
  transform: translateX(-50%);
  background-color: ${({ complete, theme }) => (complete ? theme.settings.color : theme.colors.black300)};
  ${({ theme, disabled }) => disabled && `background-color: ${theme.colors.black400}`};

  ${media.tablet`
    display: block;
  `}
`

StepComponent.IconWrapTablet = styled.div`
  display: none;

  ${media.tablet`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: white;
  `}
`

StepComponent.IconWrapMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: white;

  ${media.tablet`
    display: none;
  `}
`

StepComponent.CompleteIcon = styled(SvgIcon)`
  fill: ${({ theme }) => theme.settings.color};
  width: 16px;

  ${media.tablet`
    width: 24px;
  `}
`

StepComponent.StepIcon = styled(SvgIcon)`
  width: 12px;

  ${media.tablet`
    width: 16px;
  `}
`

export default StepComponent
