import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose, filter, includes, toLower, length } from 'ramda'
import { withRouter } from 'react-router-dom'

import { STEP_TYPE_PROPERTIES } from 'constants'
import { proceedStep as proceedStepAction } from 'state/steps/actions'
import { loadingSelector } from 'state/data/selectors'
import {
  fetchLocations as fetchLocationsAction,
  getLocationDetails as getLocationDetailsAction,
} from 'state/concepts/locations/actions'
import { locationsSelector, fetchLocationsEndpointSelector } from 'state/concepts/locations/selectors'
import { widgetSelector } from 'state/concepts/widget/selectors'
import { serviceSelector } from 'state/concepts/services/selectors'
import { providerSelector } from 'state/concepts/providers/selectors'
import LocationsListListViewComponent from './component'

class LocationsListListView extends React.Component {
  state = {
    locations: this.props.locations,
  }

  get showOnlineLocation() {
    const { service, provider, widget } = this.props
    const preselectedEntity = service || provider

    return preselectedEntity ? preselectedEntity.online : widget.hasOnlineServices
  }

  componentDidMount() {
    const {
      fetchLocations,
      proceedStep,
      history: { replace },
      location: { state, pathname },
    } = this.props

    if (state?.skipLoad) {
      replace(pathname)
    } else {
      fetchLocations()
    }

    proceedStep(STEP_TYPE_PROPERTIES.location)
  }

  componentDidUpdate(prevProps) {
    const { locations } = this.props

    if (length(prevProps.locations) !== length(locations)) {
      this.setState({ locations })
    }
  }

  handleSearch = ({ name }) => {
    const { locations } = this.props

    this.setState({
      locations: filter(location => includes(toLower(name), toLower(location.name)), locations),
    })
  }

  handleSelect =
    (locationId = null) =>
    () => {
      const { getLocationDetails, history } = this.props

      getLocationDetails(locationId, history)
    }

  render = () => (
    <LocationsListListViewComponent
      {...this.props}
      {...this.state}
      onSearch={this.handleSearch}
      handleSelect={this.handleSelect}
      showOnlineLocation={this.showOnlineLocation}
    />
  )
}

LocationsListListView.defaultProps = {
  locations: [],
  service: null,
  provider: null,
}

LocationsListListView.propTypes = {
  fetchLocations: PropTypes.func.isRequired,
  locations: PropTypes.arrayOf(PropTypes.shape()),
  getLocationDetails: PropTypes.func.isRequired,
  history: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired,
  widget: PropTypes.shape().isRequired,
  proceedStep: PropTypes.func.isRequired,
  service: PropTypes.shape(),
  provider: PropTypes.shape(),
}

const makeMapStateToProps = initialState => {
  const endpoint = fetchLocationsEndpointSelector(initialState)

  return state => ({
    locations: locationsSelector(state),
    widget: widgetSelector(state),
    service: serviceSelector(state),
    provider: providerSelector(state),
    isLoading: loadingSelector(state, endpoint),
  })
}

const mapDispatchToProps = {
  fetchLocations: fetchLocationsAction,
  getLocationDetails: getLocationDetailsAction,
  proceedStep: proceedStepAction,
}

export { LocationsListListView as LocationsListListViewContainer }
export default compose(connect(makeMapStateToProps, mapDispatchToProps), withRouter)(LocationsListListView)
