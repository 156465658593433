import React from 'react'
import PropTypes from 'prop-types'
import { Formik, Field } from 'formik'
import { FormattedMessage } from 'react-intl'
import { prop } from 'ramda'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

import { login, resendCode, setLoginEmail } from 'state/concepts/session/actions'
import Alert from 'views/shared/Alert'
import InputField from 'views/shared/InputField'
import Button from 'views/shared/Button'
import useFormSubmit from 'tools/hooks/useFormSubmit'
import useDispatchWithArgsAction from 'tools/hooks/useDispatchWithArgsAction'
import { loginCodeSchema } from 'schemas/login'
import SubmitFormButton from 'views/shared/SubmitFormButton'
import Recaptcha from './Recaptcha'

const CodeForm = ({ email }) => {
  const history = useHistory()
  const handleSubmit = useFormSubmit(login, { history })
  const handleResend = useDispatchWithArgsAction(resendCode, email)
  const handleClearEmail = useDispatchWithArgsAction(setLoginEmail, null)

  return (
    <Formik initialValues={{ email, loginCode: '' }} validationSchema={loginCodeSchema} onSubmit={handleSubmit}>
      {({ status, isSubmitting }) => (
        <>
          <Field
            id="email"
            name="email"
            type="text"
            component={InputField}
            label={{ id: 'login.emailAddress' }}
            className="input-wrap"
            disabled
            data-cy="email-input"
            allowClear
            onClear={handleClearEmail}
          />
          <CodeForm.Description>
            <FormattedMessage id="login.codeForm.description" />
          </CodeForm.Description>
          {prop('base', status) && (
            <Alert view="error" iconClass="close" className="auth__alert" message={prop('base', status)} />
          )}
          <Field
            name="loginCode"
            id="loginCode"
            component={InputField}
            label={{ id: 'login.loginCode' }}
            placeholder={{ id: 'login.loginCode.placeholder' }}
            icon="alert"
            autoComplete="off"
            className="input-wrap"
            data-cy="login-code-input"
          />
          <Recaptcha action="login" isButton>
            <CodeForm.Button
              isDisabled={isSubmitting}
              dataCy="submit-login-code"
              isFullWith
              text={{ id: 'login.codeForm.submit' }}
            />
          </Recaptcha>
          <CodeForm.Resend>
            <FormattedMessage id="login.codeForm.resend.description" />
            <CodeForm.ResendButton
              dataCy="resend-login-code"
              onClick={handleResend}
              text={{ id: 'login.codeForm.resend.button' }}
            />
          </CodeForm.Resend>
        </>
      )}
    </Formik>
  )
}

CodeForm.Description = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.black700};
  margin-top: 12px;
  margin-bottom: 24px;
`

CodeForm.Button = styled(SubmitFormButton)`
  background-color: ${({ theme }) => theme.settings.color};
`

CodeForm.ResendButton = styled(Button)`
  background-color: transparent;
  color: ${({ theme }) => theme.settings.color};
`

CodeForm.Resend = styled.div`
  margin-top: 32px;
`

CodeForm.propTypes = {
  email: PropTypes.string.isRequired,
}

export default CodeForm
