import { combineReducers } from 'redux'
import { uniq } from 'ramda'

import { SET_PROVIDER, SET_PROVIDERS, RESET_PROVIDERS, SET_PROVIDERS_PAGE, SET_PROVIDERS_FILTER_PARAMS } from './types'

const providerId = (state = null, action) => {
  switch (action.type) {
    case SET_PROVIDER:
      return action.providerId
    default:
      return state
  }
}

const providerIds = (state = [], action) => {
  switch (action.type) {
    case SET_PROVIDERS:
      return uniq([...state, ...action.providerIds])
    case RESET_PROVIDERS:
      return []
    default:
      return state
  }
}

const pagination = (state = { number: 1, size: 20 }, action) => {
  switch (action.type) {
    case SET_PROVIDERS_PAGE:
      return { ...state, number: action.pageNumber }
    default:
      return state
  }
}

const filters = (state = { name: '' }, action) => {
  switch (action.type) {
    case SET_PROVIDERS_FILTER_PARAMS:
      return { ...state, ...action.filterParams }
    default:
      return state
  }
}

export default combineReducers({
  providerId,
  providerIds,
  pagination,
  filters,
})
