import React from 'react'

import DescriptionComponent from './component'

class Description extends React.Component {
  state = {
    isCollapsed: true,
  }

  handleToggleClick = () => {
    const { isCollapsed } = this.state

    this.setState({ isCollapsed: !isCollapsed })
  }

  render() {
    return <DescriptionComponent {...this.props} {...this.state} onToggleClick={this.handleToggleClick} />
  }
}

export default Description
