import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import Theme from 'constants/Theme'
import Availability from 'views/AvailabilityPreferences/Availability'
import { MAX_AVAILABILITY_PREFERENCES, TIME_PREFERENCES_TYPES } from 'constants/availabilityPreferences'
import Button from 'views/shared/Button'

const AvailabilityPreferenceFields = ({
  push,
  remove,
  form: {
    values: { availabilityPreferences },
  },
}) => {
  const addAvailability = () => {
    push({
      dateRange: null,
      timeType: TIME_PREFERENCES_TYPES.anyTime,
      startTime: null,
      endTime: null,
    })
  }

  return (
    <>
      {availabilityPreferences.map((availability, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index} data-cy={`availability-row-${index}`}>
          {index !== 0 && <AvailabilityPreferenceFields.FormDivider />}
          <Availability
            availability={availability}
            onRemove={() => remove(index)}
            rowIndex={index}
            canRemove={availabilityPreferences.length > 1}
          />
        </div>
      ))}
      <AvailabilityPreferenceFields.ButtonWrap>
        <Button
          isFullWith
          bgColor={Theme.colors.black200}
          textColorSetting
          text={{ id: 'availabilityPreferences.addNewPreference' }}
          onClick={addAvailability}
          disabled={availabilityPreferences.length >= MAX_AVAILABILITY_PREFERENCES}
        />
      </AvailabilityPreferenceFields.ButtonWrap>
    </>
  )
}

AvailabilityPreferenceFields.propTypes = {
  push: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  form: PropTypes.shape({
    values: PropTypes.shape({
      availabilityPreferences: PropTypes.arrayOf(PropTypes.shape()),
    }).isRequired,
  }).isRequired,
}

AvailabilityPreferenceFields.FormDivider = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.black300};
  margin-top: 16px;
  margin-bottom: 24px;
`

AvailabilityPreferenceFields.FormItem = styled.div`
  position: relative;
`

AvailabilityPreferenceFields.ButtonWrap = styled.div`
  margin-top: 16px;
`

export default AvailabilityPreferenceFields
