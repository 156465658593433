import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Col, Row } from 'styled-bootstrap-grid'

import Theme from 'constants/Theme'
import Footer from 'views/shared/Footer'
import Header from 'views/shared/Header'
import MainContainer from 'views/shared/MainContainer'
import { VIDEO_CALLS_PROVIDERS } from 'constants/videoCalls'
import AttendeesCard from './AttendeesCard'
import BookingSummary from './BookingSummary'
import DocumentsCard from './DocumentsCard'
import * as S from './styled'
import GlobalStyles from './GlobalStyles'

const ConfirmationViewComponent = ({
  email,
  booking,
  service,
  provider,
  time,
  timezone,
  bookingSettings,
  resetSelection,
  isWithPayment,
  isWithPriceAndWithoutStripe,
  workspaceName,
  price,
}) => {
  const isAttendeesShown =
    booking.groupVideoCallsEnabled && booking.videoCallProvider === VIDEO_CALLS_PROVIDERS.expertbox

  return (
    <>
      <GlobalStyles />
      <MainContainer bgColor={Theme.colors.black200}>
        <Header />
        <S.Main>
          <S.GrowContainer fluid>
            <S.Title justifyContent="center" text={{ id: 'confirmationView.youHaveBookedAppointment' }} />
            <S.EmailWrap data-cy="booking-summary-block">
              <S.EmailDesc data-cy="booking-summary-email-description">
                <FormattedMessage id="confirmationView.weSentEmailTo" />
              </S.EmailDesc>
              <S.Email data-cy="booking-summary-email">{email}</S.Email>
            </S.EmailWrap>
            <Row>
              <Col md={10} lg={8} mdOffset={1} lgOffset={2}>
                <S.SummaryWrap>
                  <BookingSummary
                    {...booking}
                    service={service}
                    provider={provider}
                    time={time}
                    timezone={timezone}
                    bookingSettings={bookingSettings}
                    isWithPayment={isWithPayment}
                    isWithPriceAndWithoutStripe={isWithPriceAndWithoutStripe}
                    workspaceName={workspaceName}
                    price={price}
                  />

                  <DocumentsCard booking={booking} />
                  {isAttendeesShown && <AttendeesCard />}
                </S.SummaryWrap>

                <S.BookWrapper>
                  <S.BookText>
                    <FormattedMessage id="confirmationView.wantToBookAnewAppointment" />
                  </S.BookText>
                  <S.BookButtonWrap>
                    <S.BookButton
                      dataCy="book-new-consultation-button"
                      text={{ id: 'confirmationView.bookNewAppointment' }}
                      onClick={resetSelection}
                      filled
                    />
                  </S.BookButtonWrap>
                </S.BookWrapper>
              </Col>
            </Row>
          </S.GrowContainer>
          <Footer />
        </S.Main>
      </MainContainer>
    </>
  )
}

ConfirmationViewComponent.propTypes = {
  email: PropTypes.string.isRequired,
  booking: PropTypes.shape().isRequired,
  service: PropTypes.shape().isRequired,
  provider: PropTypes.shape().isRequired,
  time: PropTypes.string.isRequired,
  timezone: PropTypes.shape().isRequired,
  bookingSettings: PropTypes.shape().isRequired,
  resetSelection: PropTypes.func.isRequired,
  isWithPayment: PropTypes.bool.isRequired,
  isWithPriceAndWithoutStripe: PropTypes.bool.isRequired,
  workspaceName: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
}

export default ConfirmationViewComponent
