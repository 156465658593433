import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Frame, { FrameContextConsumer } from 'react-frame-component'

import Widget from 'views/Widget'

const Content = ({ settings, handleCloseWidget }) => (
  <Content.Frame
    head={
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no, maximum-scale=1.0, user-scalable=no"
      />
    }
  >
    <FrameContextConsumer>
      {({ document, window }) => (
        <Widget {...settings} contentDocument={document} contentWindow={window} handleCloseWidget={handleCloseWidget} />
      )}
    </FrameContextConsumer>
  </Content.Frame>
)

Content.propTypes = {
  settings: PropTypes.shape().isRequired,
  handleCloseWidget: PropTypes.func.isRequired,
}

Content.Frame = styled(Frame)`
  width: 100%;
  height: 100%;
  border-width: 0;
  background-color: white;
`

export default Content
