import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import build from 'redux-object'
import { head } from 'ramda'

import responseIds from 'utils/responseIds'
import { FETCH_SERVICE_CATEGORIES } from 'state/concepts/services/types'
import { providerIdSelector } from 'state/concepts/providers/selectors'
import { dataApiRequest, dataApiSuccess, dataApiFailure } from 'state/data/actions'
import { workspaceIdSelector, widgetIdSelector } from 'state/concepts/widget/selectors'
import { locationIdSelector } from 'state/concepts/locations/selectors'
import { setFilterParams, setServiceCategories } from '../actions'
import { fetchServiceCategoriesEndpoint } from '../endpoints'

const fetchServiceCategoriesOperation = createLogic({
  type: FETCH_SERVICE_CATEGORIES,
  latest: true,

  async process({ httpClient, getState }, dispatch, done) {
    const state = getState()
    const workspaceId = workspaceIdSelector(state)
    const widgetId = widgetIdSelector(state)
    const { endpoint, url } = fetchServiceCategoriesEndpoint(workspaceId, widgetId)
    const providerId = providerIdSelector(state)
    const locationId = locationIdSelector(state)

    const params = {
      filter: {
        user_profile_id: providerId,
        ...(locationId ? { location_id: locationId } : { online_location: true }),
      },
    }

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.get(url, { params })
      const response = normalize(data, { endpoint })
      const categories = build(response, 'serviceCategory')

      if (categories.length === 1) {
        dispatch(setFilterParams({ category: head(categories).id }))
      }

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(setServiceCategories(responseIds(data)))
    } catch {
      dispatch(dataApiFailure({ endpoint }))
    }
    done()
  },
})

export default fetchServiceCategoriesOperation
