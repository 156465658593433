import { connect } from 'react-redux'

import { locationSelector } from 'state/concepts/locations/selectors'
import { hasLocationsSelector } from 'state/concepts/widget/selectors'
import LocationCardComponent from './component'

const mapStateToProps = state => ({
  location: locationSelector(state),
  hasLocations: hasLocationsSelector(state),
})

export default connect(mapStateToProps)(LocationCardComponent)
