import React from 'react'
import PropTypes from 'prop-types'

import Overlay from 'views/shared/Overlay'
import Header from './Header'
import Body from './Body'
import Footer from './Footer'
import * as S from './styled'

const DetailsModalComponent = ({
  children,
  price,
  handleClose,
  handleSelect,
  disableButton,
  dataCy,
  actionButtonText,
}) => (
  <>
    <S.Container data-cy={dataCy}>
      <Header handleClose={handleClose} />
      <Body>{children}</Body>
      <Footer
        price={price}
        handleClose={handleClose}
        handleSelect={handleSelect}
        disableButton={disableButton}
        actionButtonText={actionButtonText}
      />
    </S.Container>
    <Overlay onClick={handleClose} />
  </>
)

DetailsModalComponent.defaultProps = {
  dataCy: 'modal-container',
  children: null,
  price: null,
  handleSelect: undefined,
  disableButton: false,
  actionButtonText: { id: 'shared.select' },
}

DetailsModalComponent.propTypes = {
  disableButton: PropTypes.bool,
  dataCy: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  price: PropTypes.node,
  handleClose: PropTypes.func.isRequired,
  handleSelect: PropTypes.func,
  actionButtonText: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
}

export default DetailsModalComponent
