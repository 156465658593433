import Theme from 'constants/Theme'

import getWidgetOptions from 'utils/getWidgetOptions'

export const PORTAL_HOST = process.env.PORTAL_HOST || 'http://localhost:4000'
export const API_HOST = getWidgetOptions().apiHost || process.env.API_HOST || 'http://localhost:3000'
export const MAIN_SITE_DOMAIN = 'expertbox.io'

export const GOOGLE_MAPS_API_KEY = process.env.GOOGLE_MAPS_API_KEY || 'AIzaSyAYbLSnIN-MHm9SSRM63xCHvyaIgEUgeOs'

export const STEP_TYPE_PROPERTIES = {
  location: 'location',
  service: 'service',
  provider: 'provider',
  time: 'time',
  review: 'review',
}

export const STEP_TYPE_PATHS = {
  service: '/services',
  provider: '/providers',
  time: '/time',
  availabilityPreferences: '/availability_preferences',
  reviewwWaitlistRequest: '/review_waitlist_request',
  review: '/review',
  location: '/locations',
}

export const TYPES_BY_ROUTES = {
  [STEP_TYPE_PATHS.location]: STEP_TYPE_PROPERTIES.location,
  [STEP_TYPE_PATHS.service]: STEP_TYPE_PROPERTIES.service,
  [STEP_TYPE_PATHS.provider]: STEP_TYPE_PROPERTIES.provider,
  [STEP_TYPE_PATHS.time]: STEP_TYPE_PROPERTIES.time,
  [STEP_TYPE_PATHS.availabilityPreferences]: STEP_TYPE_PROPERTIES.time,
  [STEP_TYPE_PATHS.review]: STEP_TYPE_PROPERTIES.review,
  [STEP_TYPE_PATHS.reviewwWaitlistRequest]: STEP_TYPE_PROPERTIES.review,
}

export const STRIPE_BASE_STYLES = {
  style: {
    base: {
      color: Theme.colors.black700,
      '::placeholder': {
        color: Theme.colors.black400,
      },
      fontFamily: '"Inter", sans-serif',
    },
    invalid: {
      color: Theme.colors.red1000,
    },
  },
}

export const SERVICE_CANCELLATION_OPTIONS = {
  never: 'never',
  anytime: 'anytime',
  upTo: 'up_to_x_before_consultation',
}

export const SERVICE_FILTERS_INITIAL_STATE = {
  name: '',
  category: null,
}

export const CLIENT_BOOKING_PATH = '/client/bookings/login/{code}'

export const MAX_NAME_LENGTH = 50

export const MAX_NAME_LENGTH_IN_STEPPER = 35

export const MAX_CATEGORY_NAME_LENGTH = 40

export const TWILIO_MAX_PARTICIPANTS = 12

export const GOOGLE_PLAY_LINK = 'https://play.google.com/store/apps'
export const APP_STORE_LINK = 'https://www.apple.com/ios/app-store'

export const STRIPE_SCRIPT_LINK = 'https://js.stripe.com/v3/'

export const ERROR_REASONS = {
  startTime: 'start-time',
  providerId: 'user-profile-id',
  serviceId: 'service-id',
  base: 'base',
}

export const WIDGET_STATUS = {
  active: 'active',
  inactive: 'inactive',
}

export const FREE_PRICE = '0.0'

export const HOW_TO_BOOK_APPOINTMENT_LINK = 'https://expert-box.helpscoutdocs.com/article/17-how-to-book-an-appointment'

export const EXPERT_BOX_LINK = 'https://expertbox.io/'

export const TERMS_OF_USE_LINK = 'https://www.expertbox.io/terms-of-use'

export const DATA_PROCESSING_POLICY_LINK = 'https://www.expertbox.io/policies'

export const DEFAULT_MOUNT_TO = 'expertbox-widget'

export const RECAPTCHA_SITE_KEY = process.env.RECAPTCHA_SITE_KEY || '6LdC3QAgAAAAAOE6z--ajujTCQ6wHdyRWcV9GBqD'
export const RECAPTCHA_SCRIPT_URL = `https://www.google.com/recaptcha/enterprise.js?render=${RECAPTCHA_SITE_KEY}`

export const HTTP_STATUSES = {
  unauthorized: 401,
  forbidden: 403,
  notFound: 404,
  gone: 410,
  unprocessableEntity: 422,
}

export const MAX_REVIEW_RATING = 5

export const PROVIDER_MODAL_TABS = {
  about: 'about',
  reviews: 'reviews',
}

export const CLIENT_PORTAL_STATUSES = {
  enabled: 'enabled',
  disabled: 'disabled',
}

export const MAX_PHONE_NUMBER_LENGTH = 15
