import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import CardTitle from 'views/shared/Card/CardTitle'
import CardPosition from 'views/shared/Card/CardPosition'
import Avatar from 'views/shared/Avatar'

const ConsultantInfo = ({
  avatarUrls,
  firstName,
  lastName,
  position,
  offsetBottom,
  offsetTop,
  className,
  isSmall,
  avatarSize,
  titleSize,
  lineHeight,
}) => (
  <ConsultantInfo.Container offsetBottom={offsetBottom} offsetTop={offsetTop} className={className}>
    <Avatar isSmall={isSmall} avatarUrls={avatarUrls} size={avatarSize} />
    <ConsultantInfo.CardTitleWrap>
      <CardTitle textSize={titleSize} lineHeight={lineHeight} text={`${firstName} ${lastName}`} offsetBottom={0} />
      <CardPosition text={position} />
    </ConsultantInfo.CardTitleWrap>
  </ConsultantInfo.Container>
)

ConsultantInfo.defaultProps = {
  className: '',
  offsetBottom: null,
  offsetTop: null,
  avatarUrls: {},
  isSmall: false,
  titleSize: 20,
  lineHeight: 32,
}

ConsultantInfo.propTypes = {
  className: PropTypes.string,
  offsetBottom: PropTypes.number,
  offsetTop: PropTypes.number,
  titleSize: PropTypes.number,
  lineHeight: PropTypes.number,
  isSmall: PropTypes.bool,
  avatarUrls: PropTypes.shape(),
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  avatarSize: PropTypes.number.isRequired,
}

ConsultantInfo.Container = styled.div`
  display: flex;
  align-items: center;
  ${({ offsetBottom }) => offsetBottom && `margin-bottom: ${offsetBottom}px;`}
  ${({ offsetTop }) => offsetTop && `margin-top: ${offsetTop}px;`}
`

ConsultantInfo.CardTitleWrap = styled.div`
  /* 48px - is avatar size + margin-right */
  max-width: calc(100% - 64px - 16px);
`

export default ConsultantInfo
