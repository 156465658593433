import styled, { css } from 'styled-components'
import { Col, Container, media, Row } from 'styled-bootstrap-grid'

const LoaderWrapper = styled(Container)`
  padding: 33px;
`

const ScrollWrapper = styled.div`
  flex-grow: 1;
  overflow-y: auto;
`

const Wrapper = styled(Container)`
  flex-grow: 1;
  max-width: ${({ theme }) => theme.sizes.containerMaxWidth};
  height: ${({ theme }) => `calc(100vh - ${theme.sizes.headerHeight})`};
  padding-top: 16px;
  padding-bottom: 8px;

  ${media.tablet`
    padding-top: 32px;
    padding-right: 36px;
    padding-left: 36px;
  `}

  ${media.desktop`
    height: ${({ theme }) => `calc(100vh - ${theme.sizes.headerHeightDesktop})`};
  `}
`

const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const EmptyDescription = styled.span`
  margin-bottom: 20px;
  color: ${({ theme }) => theme.colors.black700};
  line-height: ${20 / 14};
  font-size: 16px;
`

const ImageWrapper = styled.div`
  max-width: 64px;
  flex-shrink: 0;

  ${media.tablet`
    max-width: 96px;
  `}

  ${media.desktop`
    max-width: 140px;
    height: 140px;
    margin-bottom: 40px;
  `}
`

const StyledRow = styled(Row)`
  ${media.desktop`
    height: calc(100% - 86px);
  `}
`

/* stylelint-disable */
const StyledCol = styled(Col)`
  position: relative;

  ${({ isLoading }) =>
    isLoading &&
    css`
      display: flex;
      justify-content: center;
      align-items: start;
    `};

  ${media.tablet`
    margin-bottom: 24px;
  `}
`

const ScrolledBlock = styled.div`
  ${media.tablet`
    position: sticky;
    top: 4px;
  `}
`

const StyledLeftCol = styled(Col)`
  position: relative;
`

const WrapperShadow = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 0 8px 1px rgba(${({ theme }) => theme.colors.boxShadowColor}, 0.1);
`

const DateTimeCard = styled.div`
  background-color: white;
  padding: 24px 24px;
  margin-left: -16px;
  margin-right: -16px;

  ${media.tablet`
    width: 100%;
    border: 1px solid ${({ theme }) => theme.colors.black300};
    padding: 24px;
    margin-left: 0;
    margin-right: 0;
  `}

  ${media.desktop`
    padding: 32px;
  `}
`

const AvailabilityOnDate = styled.p`
  font-family: ${({ theme }) => theme.fonts.interTight};
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 12px;
`

const TimeslotsTabletWrapper = styled(Row)`
  display: none;

  ${media.tablet`
    display: flex;
  `}
`

const TimeslotsMobileWrapper = styled.div`
  ${media.tablet`
    display: none;
  `}
`
const WaitlistTitle = styled.p`
  font-family: ${({ theme }) => theme.fonts.interTight};
  font-size: 16px;
  font-weight: 600;
  margin-top: 32px;
  margin-bottom: 16px;
`

export {
  Wrapper,
  WrapperShadow,
  EmptyWrapper,
  EmptyDescription,
  ImageWrapper,
  StyledRow,
  StyledCol,
  StyledLeftCol,
  DateTimeCard,
  AvailabilityOnDate,
  LoaderWrapper,
  TimeslotsTabletWrapper,
  ScrollWrapper,
  TimeslotsMobileWrapper,
  ScrolledBlock,
  WaitlistTitle,
}
