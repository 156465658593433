import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Container, media } from 'styled-bootstrap-grid'

import MainContainer from 'views/shared/MainContainer'
import MainContainerContent from 'views/shared/MainContainerContent'
import Header from 'views/shared/Header'
import Footer from 'views/shared/Footer'
import PageTitle from 'views/shared/PageTitle'
import Loader from 'views/shared/Loader'
import ConsultantSvg from 'views/shared/SvgIcons/Consultant'
import ServiceSvg from 'views/shared/SvgIcons/Service'
import Error from 'views/shared/Error'
import LocationCard from './LocationCard'
import WidgetEmpty from './WidgetEmptyState'
import Card from './Card'

import * as S from './styled'

const BookingView = ({
  isLoading,
  isEmpty,
  isErrorShown,
  isBookingShown,
  servicesCount,
  providersCount,
  hasManyLocations,
}) => (
  <MainContainer>
    <Header withBackButton={hasManyLocations} />
    {isBookingShown && <LocationCard />}
    <MainContainerContent isGrayBg>
      {isLoading && (
        <BookingView.Loading>
          <Loader size={72} />
        </BookingView.Loading>
      )}
      {isEmpty && <WidgetEmpty />}
      {isErrorShown && <Error isBgTransition />}
      {isBookingShown && (
        <BookingView.Container fluid>
          <PageTitle text={{ id: 'mainView.title' }} />
          <S.NoWrapRow justifyContent="center">
            <>
              <Card
                text={{ id: 'mainView.selectService' }}
                description={{ id: 'mainView.servicesCount', values: { count: servicesCount } }}
                to="/services"
              >
                <ServiceSvg />
              </Card>
              <Card
                text={{ id: 'mainView.selectExpert' }}
                description={{ id: 'mainView.providersCount', values: { count: providersCount } }}
                to="/providers"
              >
                <ConsultantSvg />
              </Card>
            </>
          </S.NoWrapRow>
        </BookingView.Container>
      )}
    </MainContainerContent>
    <Footer />
  </MainContainer>
)

BookingView.defaultProps = {
  isLoading: undefined,
  servicesCount: null,
  providersCount: null,
  hasManyLocations: false,
}

BookingView.propTypes = {
  isLoading: PropTypes.bool,
  servicesCount: PropTypes.number,
  providersCount: PropTypes.number,
  isEmpty: PropTypes.bool.isRequired,
  isErrorShown: PropTypes.bool.isRequired,
  isBookingShown: PropTypes.bool.isRequired,
  hasManyLocations: PropTypes.bool,
}

BookingView.Container = styled(Container)`
  flex-grow: 1;
  padding-top: 20px;
  max-width: ${({ theme }) => theme.sizes.containerMaxWidth};
  background-color: ${({ theme }) => theme.colors.black200};

  ${media.tablet`
    padding-top: 32px;
    padding-right: 36px;
    padding-left: 36px;
  `}
`

BookingView.Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`

export default BookingView
