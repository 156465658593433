import { combineReducers } from 'redux'

import { SET_TIME, SET_TIMEZONE } from './types'

const time = (state = null, action) => {
  switch (action.type) {
    case SET_TIME:
      return action.time
    default:
      return state
  }
}

const timezoneId = (state = null, action) => {
  switch (action.type) {
    case SET_TIMEZONE:
      return action.timezoneId
    default:
      return state
  }
}

export default combineReducers({
  time,
  timezoneId,
})
