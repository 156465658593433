import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

const Container = styled.div`
  margin-bottom: 16px;

  ${media.desktop`
    margin-bottom: 24px;
  `}
`

const Text = styled.div`
  color: ${({ theme }) => theme.colors.black700};
  font-size: 16px;
  line-height: ${24 / 16};

  ${media.tablet`
    font-size: 16px;
    line-height: ${24 / 16};
  `}
`

const FullText = styled.div`
  padding-bottom: 12px;
  white-space: pre-line;
`

const ShowMore = styled.button`
  display: inline-block;
  color: ${({ theme }) => theme.settings.color};
`

const ButtonWrapper = styled.div`
  display: inline;
`

export { Container, Text, ShowMore, ButtonWrapper, FullText }
