import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import TabsContext from '../TabsContext'

const TabHeader = ({ tab, children }) => {
  const { activeTab, onChangeActiveTab } = useContext(TabsContext)

  return React.cloneElement(children, {
    isActive: tab === activeTab,
    onClick: onChangeActiveTab(tab),
  })
}

TabHeader.propTypes = {
  tab: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default TabHeader
