import React, { useContext } from 'react'

import ModalContext from './ModalContext'

const ModalRoot = () => {
  const { component: Component, props, hideModal } = useContext(ModalContext)

  return Component ? <Component {...props} hideModal={hideModal} /> : null
}

export default ModalRoot
