import * as types from './types'

export const abortStep = goBack => ({
  type: types.ABORT_STEP,
  goBack,
})

export const updateSteps = steps => ({
  type: types.UPDATE_STEPS,
  steps,
})

export const proceedStep = stepType => ({
  type: types.PROCEED_STEP,
  stepType,
})

export const setStepsOrder = steps => ({
  type: types.SET_STEPS_ORDER,
  steps,
})

export const resolveStepsOrder = firstStepType => ({
  type: types.RESOLVE_STEPS_ORDER,
  firstStepType,
})
