import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { is } from 'ramda'

import Theme from 'constants/Theme'
import Loader from 'views/shared/Loader'

const Button = ({
  id,
  text,
  type,
  isBlock,
  isSmall,
  isFullWith,
  disabled,
  filled,
  className,
  onClick,
  offsetBottom,
  offsetLeft,
  isSubmitting,
  minWidth,
  bgColor,
  dataCy,
  textColor,
  textColorSetting,
}) => {
  const textMessage = is(Object, text) ? <FormattedMessage {...text} /> : text

  return (
    <Button.Wrapper
      type={type}
      isSmall={isSmall}
      isBlock={isBlock}
      isFullWith={isFullWith}
      disabled={disabled}
      filled={filled}
      onClick={id ? onClick(id) : onClick}
      className={className}
      offsetBottom={offsetBottom}
      offsetLeft={offsetLeft}
      minWidth={minWidth}
      bgColor={bgColor}
      data-cy={dataCy}
      textColor={textColor}
      textColorSetting={textColorSetting}
    >
      {isSubmitting ? <Loader isLoading={isSubmitting} color="white" /> : textMessage}
    </Button.Wrapper>
  )
}

Button.defaultProps = {
  id: undefined,
  type: 'button',
  className: '',
  color: Theme.colors.black700,
  isBlock: false,
  isFullWith: false,
  isSmall: false,
  disabled: false,
  filled: false,
  onClick: undefined,
  dataCy: 'primary-button',
  offsetBottom: null,
  offsetLeft: null,
  isSubmitting: false,
  minWidth: 96,
  bgColor: null,
  textColor: null,
  textColorSetting: false,
}

Button.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit']),
  color: PropTypes.string,
  isBlock: PropTypes.bool,
  isFullWith: PropTypes.bool,
  isSmall: PropTypes.bool,
  disabled: PropTypes.bool,
  filled: PropTypes.bool,
  dataCy: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]).isRequired,
  onClick: PropTypes.func,
  offsetBottom: PropTypes.number,
  offsetLeft: PropTypes.number,
  minWidth: PropTypes.number,
  isSubmitting: PropTypes.bool,
  bgColor: PropTypes.string,
  textColor: PropTypes.string,
  textColorSetting: PropTypes.bool,
}
/* eslint-disable no-unneeded-ternary */
Button.Wrapper = styled.button`
  position: relative;
  display: ${({ isBlock }) => (isBlock ? 'flex' : 'inline-flex')};
  justify-content: center;
  align-items: center;
  min-width: ${({ minWidth }) => minWidth}px;
  min-height: ${({ isSmall }) => (isSmall ? '40px' : '48px')};
  padding-right: 16px;
  padding-left: 16px;
  font-size: 14px;
  line-height: ${20 / 14};
  font-weight: 600;
  border: none;
  border-radius: 3px;
  color: ${({ textColor, textColorSetting }) => (textColor && !textColorSetting ? textColor : 'white')};
  color: ${({ textColorSetting, theme }) => textColorSetting && theme.settings.color};
  background-color: ${({ theme, bgColor }) => bgColor || theme.settings.color};
  z-index: 0;
  ${({ isFullWith }) => isFullWith && 'width: 100%'};
  ${({ offsetBottom }) => offsetBottom && `margin-bottom: ${offsetBottom}px;`}
  ${({ offsetLeft }) => offsetLeft && `margin-left: ${offsetLeft}px;`}

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border-radius: 3px;
    transition: background-color 0.15s;
    z-index: -1;
  }

  &:hover {
    border: none;

    &::after {
      background-color: rgba(255, 255, 255, 0.15);
    }
  }

  &:active::after {
    background-color: rgba(0, 0, 0, 0.15);
  }

  &:disabled {
    border: none;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.black400};
    background-color: ${({ theme }) => theme.colors.black200};
    pointer-events: none;
    user-select: none;
  }
`

export default Button
