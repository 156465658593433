import { connect } from 'react-redux'

import { deleteBookingLoadingSelector } from 'state/concepts/booking/selectors'

import DiscardDataModal from './component'

const makeMapStateToProps = state => ({
  isLoading: deleteBookingLoadingSelector(state),
})

export default connect(makeMapStateToProps)(DiscardDataModal)
