import React, { memo } from 'react'
import PropTypes from 'prop-types'

import Loader from 'views/shared/Loader'
import { AbsoluteWrapper } from '../styled'

const CalendarLoader = ({ size, className }) => (
  <AbsoluteWrapper>
    <Loader.StyledLoader iconName="loading" size={size} className={className} />
  </AbsoluteWrapper>
)

CalendarLoader.defaultProps = {
  size: 20,
  className: undefined,
}

CalendarLoader.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
}

export default memo(CalendarLoader)
