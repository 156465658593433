import styled from 'styled-components'

import SvgIcon from 'views/shared/Icons'
import { media } from 'styled-bootstrap-grid'

const Dropdown = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 326px;
  max-width: 326px;
  background: white;
  border-radius: 4px;
  box-shadow: 0 8px 16px 0 ${({ theme }) => theme.colors.black400};
  z-index: 999;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};

  ${media.tablet`
      transform: translateX(50%);
  `}

  .react-calendar__month-view__days,
  .react-calendar__tile {
    border: none;
  }

  .react-calendar__tile,
  .react-calendar__month-view__weekdays__weekday {
    line-height: 32px;
    height: 32px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.black800};
  }

  .react-calendar {
    padding: 24px;
    margin: 0;
  }

  .react-calendar__month-view__days__day > abbr {
    line-height: 32px;
    border-radius: 50%;
    width: 32px;
  }
`

const DropdownFooter = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${({ theme }) => theme.colors.grey30};
  padding: 16px;
`

const Wrap = styled.div`
  position: relative;

  input {
    cursor: pointer;

    &::placeholder {
      color: ${({ theme }) => theme.colors.black500};
    }

    &:hover {
      border-color: ${({ theme }) => theme.colors.black400};
    }

    &:focus {
      box-shadow: none;
      border-color: ${({ theme }) => theme.settings.color};
    }
  }

  &.is-open .calendar-arrow {
    color: ${({ theme }) => theme.settings.color};
    fill: ${({ theme }) => theme.settings.color};
    transform: rotate(180deg);
  }
`

const Input = styled.div`
  position: relative;
`

const DownArrowIcon = styled(SvgIcon)`
  fill: ${({ theme }) => theme.colors.black500};
  position: absolute;
  top: 42px;
  right: 16px;
  transform: ${({ isOpen }) => isOpen && 'rotate(180deg)'};
  cursor: pointer;
`

export { Dropdown, DropdownFooter, Wrap, Input, DownArrowIcon }
