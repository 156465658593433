import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import profileName from 'utils/profileName'
import { formatReviewDate } from 'utils/dateTime'
import Avatar from 'views/shared/Avatar'
import ReviewStarRating from 'views/shared/ReviewStarRating'
import TruncatedText from './TruncatedText'
import * as S from './styled'

const ReviewCard = ({ rating, text, updatedAt, clientProfile, service }) => (
  <S.ReviewCardWrap>
    <S.ReviewPersonInfo>
      <Avatar size={40} avatarUrls={clientProfile.avatarUrls} />
      <S.ReviewPersonName>{profileName(clientProfile)}</S.ReviewPersonName>
    </S.ReviewPersonInfo>
    <S.ReviewRating>
      <ReviewStarRating rating={rating} />
      <S.ReviewTimestamp>
        <FormattedMessage {...formatReviewDate(updatedAt)} />
      </S.ReviewTimestamp>
    </S.ReviewRating>
    <S.ReviewInterview>
      <S.ReviewInterviewIcon iconName="bag" offsetRight={8} size={20} />
      <span>{service.name}</span>
    </S.ReviewInterview>
    <TruncatedText text={text} />
  </S.ReviewCardWrap>
)

ReviewCard.propTypes = {
  rating: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  clientProfile: PropTypes.shape().isRequired,
  service: PropTypes.shape().isRequired,
}

export default ReviewCard
