import { createSelector } from 'reselect'
import { compose, map, values, prop } from 'ramda'

import buildObject from 'utils/buildObject'
import findTimezone from 'utils/findTimezone'

const dataSelector = prop('data')

const timezonesDataSelector = createSelector(dataSelector, compose(values, prop('timezone')))

export const timezonesSelector = createSelector(
  timezonesDataSelector,
  map(({ id, attributes: { name } }) => ({ key: id, value: id, label: name })),
)

export const localTimezoneIdSelector = createSelector(timezonesDataSelector, compose(prop('id'), findTimezone))

export const localTimezoneSelector = createSelector(localTimezoneIdSelector, dataSelector, buildObject('timezone'))
