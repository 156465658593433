import styled from 'styled-components'

const TimePhrase = styled.p`
  color: ${({ theme }) => theme.colors.black700};
  ${({ offsetBottom }) => offsetBottom && `margin-bottom: ${offsetBottom}px`};
  ${({ offsetLeft }) => offsetLeft && `margin-left: ${offsetLeft}px`};
`

const Timer = styled.span`
  font-weight: 600;
`

export { TimePhrase, Timer }
