import React, { useEffect, useRef, useState } from 'react'
import { TimePicker } from 'antd'
import dayjs from 'dayjs'
import { getIn } from 'formik'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'
import { is } from 'ramda'
import { FormattedMessage, injectIntl } from 'react-intl'
import { useBoolean } from 'react-use'

import FormattedOrRawMessage from 'views/shared/FormattedOrRawMessage'
import * as InputFieldStyled from 'views/shared/InputField/styled'
import * as S from './styled'

const TimePickerField = ({ id, field, form, intl, placeholder, label, ...props }) => {
  const [isOpen, setIsOpen] = useBoolean(false)
  const [defaultValue, setDefaultValue] = useState(null)

  const handleOpenChange = open => {
    if (!open) form.setFieldTouched(field.name, true, false)
    setIsOpen()
  }

  const handleChange = time => {
    const date = DateTime.fromJSDate(time.toDate())

    setDefaultValue(time)
    field.onChange({ target: { name: field.name, value: date } })
  }

  const container = useRef()

  const fieldTouched = getIn(form.touched, field.name)
  const fieldErrors = getIn(form.errors, field.name)

  useEffect(() => {
    setDefaultValue(field.value ? dayjs(field.value) : null)
  }, [field.value])

  return (
    <InputFieldStyled.Wrapper label={label}>
      {label && (
        <InputFieldStyled.Label htmlFor={id}>
          <FormattedMessage {...label} />
        </InputFieldStyled.Label>
      )}
      <S.TimePickerWrapper>
        <InputFieldStyled.FieldWrapper ref={container}>
          <TimePicker
            data-cy="time-picker"
            value={defaultValue}
            onChange={handleChange}
            use12Hours
            format="h:mm a"
            minuteStep={15}
            inputReadOnly
            allowClear={false}
            showNow={false}
            onOpenChange={handleOpenChange}
            transitionName=""
            placeholder={is(Object, placeholder) ? intl.formatMessage(placeholder) : placeholder}
            suffixIcon={
              isOpen ? (
                <S.UpArrowIcon dataCy="arrow-up-icon" iconName="iconUpArrow" className="in-gray-700" size={10} />
              ) : (
                <S.DownArrowIcon dataCy="arrow-down-icon" iconName="iconDownArrow" className="in-gray-700" size={10} />
              )
            }
            getPopupContainer={() => container.current}
            {...props}
          />
        </InputFieldStyled.FieldWrapper>
      </S.TimePickerWrapper>

      {fieldTouched && fieldErrors && (
        <InputFieldStyled.Message condition="error">
          <InputFieldStyled.MessageIcon condition="error" iconName="close" size={14} />
          <FormattedOrRawMessage message={fieldErrors} />
        </InputFieldStyled.Message>
      )}
    </InputFieldStyled.Wrapper>
  )
}

TimePickerField.defaultProps = {
  placeholder: null,
  label: null,
}

TimePickerField.propTypes = {
  field: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
    name: PropTypes.string,
  }).isRequired,
  form: PropTypes.shape({
    errors: PropTypes.shape({}),
    touched: PropTypes.shape({}),
    status: PropTypes.shape({}),
    setFieldTouched: PropTypes.func,
  }).isRequired,
  placeholder: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
  label: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
  intl: PropTypes.shape().isRequired,
  id: PropTypes.string.isRequired,
}

export default injectIntl(TimePickerField)
