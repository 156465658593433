import makeFormSubmitAction from 'utils/form/makeFormSubmitAction'
import * as types from './types'

export const sendCode = makeFormSubmitAction(types.SEND_CODE)
export const login = makeFormSubmitAction(types.LOGIN)
export const logout = () => ({
  type: types.LOGOUT,
})

export const logoutSuccess = () => ({
  type: types.LOGOUT_SUCCESS,
})

export const resendCode = email => ({
  type: types.SEND_CODE,
  values: { email },
})

export const loginSuccess = currentClientId => ({
  type: types.LOGIN_SUCCESS,
  currentClientId,
})

export const setLoginEmail = email => ({
  type: types.SET_LOGIN_EMAIL,
  email,
})

export const getClientOwnProfile = () => ({
  type: types.GET_CLIENT_OWN_PROFILE,
})

export const setPassedCurrentClient = passedCurrentClient => ({
  type: types.SET_PASSED_CURRENT_CLIENT,
  passedCurrentClient,
})
