import { createSelector } from 'reselect'
import { prop, path, compose, equals, sortBy, propOr } from 'ramda'

import { FREE_PRICE } from 'constants'
import buildObject from 'utils/buildObject'
import { workspaceIdSelector, priceSelector } from 'state/concepts/widget/selectors'
import { loadingSelector, endpointMetaSelector } from 'state/data/selectors'
import { createBookingEndpoint, updateBookingEndpoint, deleteBookingEndpoint } from './endpoints'

const dataSelector = prop('data')

export const bookingIdSelector = path(['booking', 'bookingId'])
export const stripeErrorSelector = path(['booking', 'stripeError'])

export const bookingSelector = createSelector(bookingIdSelector, dataSelector, buildObject('booking'))

export const bookingUniqCodeSelector = createSelector(bookingSelector, prop('uniqCode'))

export const bookingFormsSelector = createSelector(bookingSelector, compose(sortBy(prop('name')), prop('forms')))

export const createBookingLoadingSelector = createSelector(
  workspaceIdSelector,
  state => state,
  (workspaceId, state) => loadingSelector(state, createBookingEndpoint(workspaceId).endpoint),
)

export const deleteBookingLoadingSelector = createSelector(
  workspaceIdSelector,
  bookingUniqCodeSelector,
  state => state,
  (workspaceId, uniqCode, state) => loadingSelector(state, deleteBookingEndpoint(workspaceId, uniqCode).endpoint),
)

export const bookingFillOutFormsLinkSelector = createSelector(
  workspaceIdSelector,
  bookingSelector,
  state => state,
  (workspaceId, booking, state) => {
    const { endpoint } = updateBookingEndpoint(workspaceId, booking.id)
    const meta = endpointMetaSelector(state, endpoint)

    return prop('fillOutFormsLink', meta)
  },
)

export const bookingSettingsSelector = createSelector(bookingSelector, prop('bookingSetting'))

export const bookingPriceSelector = createSelector(priceSelector, bookingSelector, (servicePrice, booking) =>
  propOr(servicePrice, 'price', booking),
)

export const isWithPaymentSelector = createSelector(
  bookingSettingsSelector,
  bookingPriceSelector,
  (settings, price) => prop('paymentRequired', settings) && !equals(price, FREE_PRICE),
)

export const isWithPriceAndWithoutStripeSelector = createSelector(
  bookingSettingsSelector,
  bookingPriceSelector,
  (settings, price) => !prop('paymentRequired', settings) && !equals(price, FREE_PRICE),
)

export const paypalSelector = createSelector(bookingSelector, prop('paypal'))
