import React from 'react'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import { debounce } from 'lodash'
import { trim } from 'ramda'

import SearchComponent from './component'

class Search extends React.Component {
  handleSearch = debounce(searchQuery => {
    this.props.filterEntities({ name: searchQuery })
  }, 500)

  static propTypes = {
    shouldReset: PropTypes.bool,
    filterEntities: PropTypes.func.isRequired,
  }

  static defaultProps = {
    shouldReset: false,
  }

  state = {
    searchQuery: '',
  }

  componentDidUpdate(prevProps) {
    const { shouldReset } = this.props

    if (shouldReset && prevProps.shouldReset !== shouldReset) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ searchQuery: '' })
    }
  }

  handleChange = ({ target: { value } }) => {
    this.setState({ searchQuery: value })
    this.handleSearch(trim(value))
  }

  handleClear = () => {
    this.setState({ searchQuery: '' })
    this.handleSearch('')
  }

  render() {
    const { searchQuery } = this.state

    return (
      <SearchComponent {...this.props} value={searchQuery} onChange={this.handleChange} onClear={this.handleClear} />
    )
  }
}

export default injectIntl(Search)
