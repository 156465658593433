import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import LinesEllipsis from 'react-lines-ellipsis'

import { MAX_NAME_LENGTH } from 'constants'
import Tooltip from 'views/shared/Tooltip'

const CardTitle = ({ className, text, offsetBottom, textSize, lineHeight, textSizeMobile }) => (
  <CardTitle.Container
    className={className}
    offsetBottom={offsetBottom}
    textSize={textSize}
    textSizeMobile={textSizeMobile}
    lineHeight={lineHeight}
    data-cy="card-title-mobile"
  >
    {text.length > MAX_NAME_LENGTH ? (
      <Tooltip
        placement="bottom"
        title={
          <CardTitle.Text>
            <LinesEllipsis text={text} maxLine={2} ellipsis="..." />
          </CardTitle.Text>
        }
        content={text}
      />
    ) : (
      text
    )}
  </CardTitle.Container>
)

CardTitle.defaultProps = {
  className: '',
  textSizeMobile: 16,
  textSize: 20,
  lineHeight: 32,
  offsetBottom: 0,
}

CardTitle.propTypes = {
  offsetBottom: PropTypes.number,
  textSize: PropTypes.number,
  textSizeMobile: PropTypes.number,
  lineHeight: PropTypes.number,
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
}

CardTitle.Container = styled.div`
  font-family: ${({ theme }) => theme.fonts.interTight};
  font-size: ${({ textSizeMobile }) => textSizeMobile}px;
  font-weight: 600;
  line-height: ${({ lineHeight, textSize }) => lineHeight / textSize};
  color: ${({ theme }) => theme.colors.black1000};

  ${media.tablet`
    font-size: ${({ textSize }) => textSize}px;
  `}

  ${media.desktop`
    margin-bottom: 4px;
  `}
`

CardTitle.Text = styled.div`
  color: ${({ theme }) => theme.colors.black1000};
`

export default CardTitle
