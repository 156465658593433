import styled from 'styled-components'
import { Container, media } from 'styled-bootstrap-grid'

import PageTitle from 'views/shared/PageTitle'
import SecondaryButton from 'views/shared/Button/Secondary'
import Button from 'views/shared/Button'

const Main = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-top: 16px;
  background-color: ${({ theme }) => theme.colors.black100};

  ${media.tablet`
    padding-top: 24px;
  `}

  ${media.desktop`
    padding-top: 32px;
  `}
`

const SummaryWrap = styled.div`
  margin-right: -16px;
  margin-left: -16px;

  ${media.tablet`
    margin-right: 0;
    margin-left: 0;
  `}
`

const GrowContainer = styled(Container)`
  flex-grow: 1;
  max-width: ${({ theme }) => theme.sizes.containerMaxWidth};
  background-color: ${({ theme }) => theme.colors.black100};
`

const ImageWrap = styled.div`
  position: relative;
  margin-bottom: 24px;
  text-align: center;
`

const Title = styled(PageTitle)`
  margin-bottom: 12px;
  justify-content: center;

  ${media.tablet`
    &&& {
      margin-bottom: 8px;
    }
  `}

  h1 {
    margin-right: 0;
    font-size: 20px;
    ${media.tablet`
      font-size: 36px;
      line-height: ${54 / 36};
    `}
  }
`

const Image = styled.img`
  position: relative;
  z-index: 1;
  width: 72px;
  height: 72px;

  ${media.tablet`
    width: 65px;
    height: 65px;
  `}
`

const Circle = styled.span`
  position: absolute;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.settings.color};
  top: -2px;
  left: calc(50% + 2px);
  transform: translateX(-50%);
  width: 67px;
  height: 67px;

  ${media.tablet`
    width: 58px;
    height: 58px;
  `}
`

const EmailWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  font-size: 16px;
  line-height: ${28 / 16};
  text-align: center;

  ${media.tablet`
    margin-bottom: 24px;
  `}

  ${media.desktop`
    justify-content: center;
    flex-direction: row;
  `}
`

const EmailDesc = styled.span`
  color: ${({ theme }) => theme.colors.black700};
  line-height: 24px;

  ${media.tablet`
    margin-right: 4px;
  `}
`

const Email = styled.span`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.black1000};
  line-height: 24px;
`

const Actions = styled.div`
  ${media.tablet`
    display: flex;
    justify-content: flex-end;
  `}
`

const SmartphoneButton = styled(SecondaryButton)`
  width: 100%;
  margin-bottom: 16px;

  ${media.tablet`
    width: auto;
    margin-right: 16px;
  `}
`

const GoToButton = styled(SecondaryButton)`
  width: 100%;
  margin-bottom: 16px;

  ${media.tablet`
    width: auto;
  `}
`

const BookWrapper = styled.div`
  padding-top: 24px;
  padding-bottom: 24px;
  margin-left: -12px;
  margin-right: -12px;

  ${media.tablet`
    padding-top: 16px;
    padding-bottom: 28px;
  `}

  ${media.desktop`
    padding-top: 36px;
    padding-bottom: 16px;
  `}
`

const BookText = styled.div`
  font-family: ${({ theme }) => theme.fonts.interTight};
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  color: ${({ theme }) => theme.colors.black1000};
  margin-bottom: 16px;
  text-align: center;

  ${media.tablet`
    font-size: 24px;
    line-height: 36px;
  `}
`

const BookButtonWrap = styled.div`
  display: flex;
  justify-content: center;
`

const BookButton = styled(Button)`
  border-radius: 2px;

  ${media.tablet`
    width: auto;
  `}
`

export {
  Main,
  GrowContainer,
  ImageWrap,
  Title,
  Image,
  EmailWrap,
  Circle,
  EmailDesc,
  Email,
  Actions,
  SmartphoneButton,
  GoToButton,
  BookWrapper,
  BookText,
  BookButton,
  BookButtonWrap,
  SummaryWrap,
}
