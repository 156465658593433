import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

import Description from 'views/shared/Card/Description'
import SvgIcon from 'views/shared/Icons'

const priceWidth = '176px'

export const Card = styled.div`
  padding: 16px 16px 12px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.black300};
  margin-bottom: 8px;
  background-color: white;
  transition: border-color 0.15s;

  &:hover {
    ${media.tablet`
      border-color: ${({ theme }) => theme.settings.color};
    `}

    ${media.desktop`
      padding: 24px 32px;
    `}
  }

  ${media.tablet`
    margin-bottom: 16px;
    padding-right: 24px;
    padding-bottom: 16px;
    padding-left: 24px;
  `}

  ${media.desktop`
    margin-bottom: 24px;
    padding: 24px 32px;
  `}
`

export const CardTop = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;

  ${media.desktop`
    flex-direction: row;
    margin-bottom: 16px;
  `}
`

export const CardTopLeft = styled.div`
  flex-shrink: 0;
  flex-grow: 1;

  ${media.desktop`
    flex-shrink: 1;
    margin-bottom: 0;
    margin-right: 16px;
    width: calc(100% - ${priceWidth});
  `}
`

export const CardTopRight = styled.div`
  display: flex;
  align-items: center;

  ${media.desktop`
    flex-direction: column;
    flex-shrink: 1;
    align-items: flex-end;
    width: ${priceWidth};
  `}
`

export const Price = styled.p`
  display: ${({ isVisibleDesktop }) => (isVisibleDesktop ? 'none' : 'block')};
  font-family: ${({ theme }) => theme.fonts.interTight};
  color: ${({ theme }) => theme.colors.black1000};
  font-size: 16px;
  line-height: ${24 / 16};
  text-align: right;
  font-weight: 600;

  ${media.desktop`
    display: ${({ isVisibleDesktop }) => (isVisibleDesktop ? 'block' : 'none')};
    font-size: 20px;
    line-height: ${32 / 20};
    width: ${priceWidth};
  `}
`

export const CardDescription = styled(Description)`
  margin-bottom: 16px;

  ${media.desktop`
    margin-bottom: 24px;
  `}
`

export const CardBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  ${media.desktop`
    flex-wrap: nowrap;
  `}
`

export const ServiceAvailable = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.black700};
  margin-top: 16px;
  padding-top: 12px;
  width: 100%;
  order: 3;

  &::before {
    content: '';
    position: absolute;
    width: calc(100% + 32px);
    height: 1px;
    top: 0;
    left: -16px;
    background: ${({ theme }) => theme.colors.black300};

    ${media.tablet`
      width: 100%;
      left: 0;
    `}
  }

  ${media.tablet`
    padding-top: 16px;
  `}

  ${media.desktop`
    margin-top: 0;
    padding-top: 0;
    border-top-width: 0;
    order: 0;
    width: auto;
    &:before{
      background: transparent;
    }
  `}
`

export const ServiceCount = styled.span`
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  margin-right: 4px;

  ${media.desktop`
    font-size: 16px;
    line-height: 24px;
  `}
`

export const ServiceCountDesc = styled.span`
  font-size: 14px;
  line-height: 20px;

  ${media.desktop`
    font-size: 16px;
    line-height: 24px;
  `}
`

export const ServiceIcon = styled(SvgIcon)`
  fill: ${({ theme }) => theme.settings.color};
`
