import React from 'react'
import PropTypes from 'prop-types'

import Card from 'views/shared/Card'

const ServiceCards = ({ services, selectedProvider, makeSelectHandler }) =>
  services.map(service => (
    <Card
      dataCy="service-card"
      key={service.id}
      selectedProvider={selectedProvider}
      handleSelect={makeSelectHandler}
      service={service}
    />
  ))

ServiceCards.defaultProps = {
  selectedProvider: null,
}

ServiceCards.propTypes = {
  selectedProvider: PropTypes.shape(),
  services: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
  makeSelectHandler: PropTypes.func.isRequired,
}

export default ServiceCards
