import React from 'react'
import PropTypes from 'prop-types'

import TabsContext from '../TabsContext'

class TabsProvider extends React.Component {
  state = {
    activeTab: null,
  }

  constructor(props) {
    super(props)
    const { activeTab } = this.props

    this.state = { activeTab }
  }

  get contextValue() {
    return {
      ...this.state,
      onChangeActiveTab: this.handleChangeActiveTab,
    }
  }

  handleChangeActiveTab = key => () => {
    this.setState({ activeTab: key })
  }

  render = () => {
    const { children } = this.props

    return <TabsContext.Provider value={this.contextValue}>{children}</TabsContext.Provider>
  }
}

TabsProvider.propTypes = {
  activeTab: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default TabsProvider
