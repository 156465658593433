import endpoint from 'utils/endpoint'
import { bookingsRoute, bookingRoute, bookingPendingClientRoute } from 'tools/apiRoutes'
import { CREATE_BOOKING, UPDATE_BOOKING, DELETE_BOOKING, UPDATE_PENDING_CLIENT } from './types'

export const createBookingEndpoint = workspaceId => endpoint(CREATE_BOOKING, 'POST', bookingsRoute(workspaceId))

export const updateBookingEndpoint = (workspaceId, bookingId) =>
  endpoint(UPDATE_BOOKING, 'PUT', bookingRoute(workspaceId, bookingId))

export const deleteBookingEndpoint = (workspaceId, uniqCode) =>
  endpoint(DELETE_BOOKING, 'DELETE', bookingRoute(workspaceId, uniqCode))

export const updatePendingClientEndpoint = (workspaceId, bookingId) =>
  endpoint(UPDATE_PENDING_CLIENT, 'PUT', bookingPendingClientRoute(workspaceId, bookingId))
