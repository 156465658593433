import styled from 'styled-components'

// eslint-disable-next-line import/prefer-default-export
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(${({ theme }) => theme.colors.boxShadowColor}, 0.1);
  height: 100%;
  left: 50%;
  z-index: ${({ theme }) => theme.modalZindex};
`
