import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { is, prop } from 'ramda'

import SvgIcon from 'views/shared/Icons'
import * as S from './styled'

const CheckboxFieldComponent = ({ label, disabled, field: { value }, onChange, dataCy, ...props }) => (
  <S.Label disabled={disabled} data-cy={dataCy}>
    <S.Input type="checkbox" disabled={disabled} checked={value} onChange={onChange} {...props} />
    <SvgIcon size={18} color={`${value ? 'black1000' : 'black500'}`} iconName={`square${value ? 'Checked' : 'O'}`} />
    {label && <S.Text>{is(Object, label) && prop('id', label) ? <FormattedMessage {...label} /> : label}</S.Text>}
  </S.Label>
)

CheckboxFieldComponent.defaultProps = {
  disabled: false,
  className: null,
  label: null,
  dataCy: 'checkbox-field',
}

CheckboxFieldComponent.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.shape(), PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  className: PropTypes.string,
  dataCy: PropTypes.string,
  field: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
}

export default CheckboxFieldComponent
