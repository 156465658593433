import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { compose } from 'ramda'
import { connect } from 'react-redux'

import { STEP_TYPE_PATHS } from 'constants'
import { abortStep as abortStepAction } from 'state/steps/actions'
import { deleteBooking as deleteBookingAction } from 'state/concepts/booking/actions'
import * as modalActions from 'state/modal/actions'
import { isWithPaymentSelector } from 'state/concepts/booking/selectors'
import { servicesCountSelector, providersCountSelector, widgetLogoSelector } from 'state/concepts/widget/selectors'
import HeaderComponent from './component'

class Header extends React.Component {
  handleAbortStep = () => {
    const {
      history: { goBack },
      abortStep,
    } = this.props

    abortStep(goBack)
  }

  abortStepAndHideModal = () => {
    const { hideModal } = this.props

    this.handleAbortStep()
    hideModal()
  }

  handleDiscard = () => {
    const { deleteBooking } = this.props

    deleteBooking(this.abortStepAndHideModal)
  }

  handleShowModal = () => {
    const { showModal, isWithPayment } = this.props

    showModal({
      modalType: 'DISCARD_DATA',
      modalProps: { onDiscard: this.handleDiscard, isWithPayment },
    })
  }

  handleGoBack = () => {
    const {
      location: { pathname },
      history: { goBack },
    } = this.props

    if (pathname === STEP_TYPE_PATHS.review) {
      this.handleShowModal()
    } else if (pathname === '/login') {
      goBack()
    } else {
      this.handleAbortStep()
    }
  }

  render = () => (
    <HeaderComponent {...this.props} onGoBack={this.handleGoBack} isCloseButtonShown={this.isCloseButtonShown} />
  )
}

Header.defaultProps = {
  servicesCount: null,
  providersCount: null,
  isWithPayment: false,
}

Header.propTypes = {
  abortStep: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  servicesCount: PropTypes.number,
  providersCount: PropTypes.number,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  deleteBooking: PropTypes.func.isRequired,
  isWithPayment: PropTypes.bool,
}

const mapStateToProps = state => ({
  servicesCount: servicesCountSelector(state),
  providersCount: providersCountSelector(state),
  isWithPayment: isWithPaymentSelector(state),
  widgetLogo: widgetLogoSelector(state),
})

const mapDispatchToProps = {
  abortStep: abortStepAction,
  deleteBooking: deleteBookingAction,
  ...modalActions,
}

export { Header as HeaderContainer }
export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(Header)
