import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose, path } from 'ramda'
import { withRouter } from 'react-router-dom'

import { BOOKING_SUCCESS_EVENT } from 'constants/postMessageEvents'
import withModalContext from 'views/Modal/withModalContext'
import { resetSelection as resetSelectionAction } from 'state/store/actions'
import { providerSelector } from 'state/concepts/providers/selectors'
import { serviceSelector } from 'state/concepts/services/selectors'
import {
  bookingSelector,
  bookingSettingsSelector,
  isWithPaymentSelector,
  isWithPriceAndWithoutStripeSelector,
} from 'state/concepts/booking/selectors'
import { timeSelector, timezoneSelector } from 'state/concepts/time/selectors'
import { workspaceNameSelector, priceSelector } from 'state/concepts/widget/selectors'
import ConfirmationViewComponent from './component'

class ConfirmationView extends React.Component {
  get email() {
    return path(['location', 'state', 'email'], this.props)
  }

  componentDidMount = () => {
    window.parent.postMessage(BOOKING_SUCCESS_EVENT, '*')
  }

  handleResetSelection = () => {
    const { resetSelection, history } = this.props

    resetSelection()
    history.replace('/')
  }

  render = () => (
    <ConfirmationViewComponent {...this.props} email={this.email} resetSelection={this.handleResetSelection} />
  )
}

ConfirmationView.propTypes = {
  resetSelection: PropTypes.func.isRequired,
  history: PropTypes.shape().isRequired,
}

const mapStateToProps = state => ({
  booking: bookingSelector(state),
  service: serviceSelector(state),
  provider: providerSelector(state),
  time: timeSelector(state),
  timezone: timezoneSelector(state),
  bookingSettings: bookingSettingsSelector(state),
  isWithPayment: isWithPaymentSelector(state),
  isWithPriceAndWithoutStripe: isWithPriceAndWithoutStripeSelector(state),
  workspaceName: workspaceNameSelector(state),
  price: priceSelector(state),
})

const mapDispatchToProps = {
  resetSelection: resetSelectionAction,
}

export { ConfirmationView as ConfirmationViewContainer }
export default compose(connect(mapStateToProps, mapDispatchToProps), withModalContext, withRouter)(ConfirmationView)
