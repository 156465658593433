import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import Search from 'views/shared/Search'
import * as S from './styled'

const SearchWrap = ({ title, count, onSearch }) => (
  <S.SearchWrap>
    <S.Info>
      <S.LocationIcon iconName="location" size={24} />
      <S.Title>
        <FormattedMessage {...title} />
      </S.Title>
      <S.Count data-cy="count">{count}</S.Count>
    </S.Info>
    <S.Search>
      <Search className="main-search" placeholder={{ id: 'shared.search' }} filterEntities={onSearch} />
    </S.Search>
  </S.SearchWrap>
)

SearchWrap.propTypes = {
  title: PropTypes.shape({ id: PropTypes.string }).isRequired,
  count: PropTypes.number.isRequired,
  onSearch: PropTypes.func.isRequired,
}

export default SearchWrap
