import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import { buildStaticMapUrl, buildMapSearchUrl } from 'utils/locations'
import * as S from './styled'

const LocationItem = ({ title, address, coordinate, onSelect }) => (
  <S.CardWrap onClick={onSelect} data-cy="location-item">
    <S.Card>
      <S.CardInfo>
        <S.CardTitle data-cy="location-item-name">{title}</S.CardTitle>
        <S.CardAddress data-cy="location-item-address">{address}</S.CardAddress>
        <S.CardLink target="_blank" href={buildMapSearchUrl(coordinate)} data-cy="location-item-address-link">
          <FormattedMessage id="locationsListView.viewOnGoogleMaps" />
        </S.CardLink>
      </S.CardInfo>
      <S.ArrowIcon iconName="rightArrow" size={24} />
    </S.Card>
    <S.Map src={buildStaticMapUrl([{ coordinate }], { width: 835, height: 152 })} />
  </S.CardWrap>
)

LocationItem.propTypes = {
  title: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  coordinate: PropTypes.shape().isRequired,
  onSelect: PropTypes.func.isRequired,
}

export default LocationItem
