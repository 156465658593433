import { combineReducers } from 'redux'
import { uniq } from 'ramda'
import { SERVICE_FILTERS_INITIAL_STATE } from 'constants'

import {
  SET_SERVICE,
  SET_SERVICES,
  RESET_SERVICES,
  SET_SERVICES_PAGE,
  SET_SERVICE_CATEGORIES,
  SET_SERVICES_FILTER_PARAMS,
} from './types'

const serviceId = (state = null, action) => {
  switch (action.type) {
    case SET_SERVICE:
      return action.serviceId
    default:
      return state
  }
}

const categoryIds = (state = [], action) => {
  switch (action.type) {
    case SET_SERVICE_CATEGORIES:
      return action.categoryIds
    default:
      return state
  }
}

const serviceIds = (state = [], action) => {
  switch (action.type) {
    case SET_SERVICES:
      return uniq([...state, ...action.serviceIds])
    case RESET_SERVICES:
      return []
    default:
      return state
  }
}

const pagination = (state = { number: 1, size: 20 }, action) => {
  switch (action.type) {
    case SET_SERVICES_PAGE:
      return { ...state, number: action.pageNumber }
    default:
      return state
  }
}

const filters = (state = SERVICE_FILTERS_INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_SERVICES_FILTER_PARAMS:
      return { ...state, ...action.filterParams }
    default:
      return state
  }
}

export default combineReducers({
  categoryIds,
  serviceIds,
  pagination,
  serviceId,
  filters,
})
