import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import CategoryDropdownComponent from './component'

class CategoryDropdown extends React.Component {
  state = {
    options: [],
    visible: false,
  }

  targetEl = React.createRef()

  static propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    makeFilterServices: PropTypes.func.isRequired,
    resetCategory: PropTypes.func.isRequired,
  }

  componentDidMount = () => {
    document.addEventListener('click', this.handleClickOutside, true)
  }

  componentWillUnmount = () => {
    document.removeEventListener('click', this.handleClickOutside, true)
  }

  handleClickOutside = event => {
    const { visible } = this.state

    if (visible && !this.targetEl.current.contains(event.target)) {
      this.handleClose()
    }
  }

  handleClose = () => {
    this.setState({ visible: false })
  }

  handleOpen = () => {
    const { options } = this.props

    this.setState({ options, visible: true })
  }

  handleVisibleChange = () => (!this.state.visible ? this.handleOpen() : this.handleClose())

  makeHandleSelect = option => () => {
    const { makeFilterServices, resetCategory } = this.props

    option.id ? makeFilterServices(option)() : resetCategory()
    this.handleClose()
  }

  render = () => (
    <CategoryDropdownComponent
      ref={this.targetEl}
      {...this.props}
      {...this.state}
      onVisibleChange={this.handleVisibleChange}
      makeHandleSelect={this.makeHandleSelect}
    />
  )
}

export default injectIntl(CategoryDropdown)
