import { useContext } from 'react'
import PropTypes from 'prop-types'

import TabsContext from '../TabsContext'

const TabContent = ({ tab, children }) => {
  const { activeTab } = useContext(TabsContext)

  return tab === activeTab && children
}

TabContent.propTypes = {
  tab: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default TabContent
