import styled from 'styled-components'

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`

const Text = styled.div`
  margin-top: 16px;
  font-size: 16px;
  line-height: ${24 / 16};
  color: ${({ theme }) => theme.colors.black700};
`

export { Wrapper, Text }
