import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

import Avatar from 'views/shared/Avatar'

export const ExpertMain = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-right: 16px;
  padding-left: 16px;
`

export const ExpertInfoContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.black200};
  flex-shrink: 0;
`

export const ExpertInfoWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 20px;

  ${media.tablet`
    padding-top: 24px;
  `}
`

export const ExpertImage = styled(Avatar)`
  margin-bottom: 8px;
  margin-right: 0;
`

export const ExpertName = styled.p`
  font-family: ${({ theme }) => theme.fonts.interTight};
  font-size: 20px;
  line-height: ${32 / 20};
  color: ${({ theme }) => theme.colors.black1000};
  font-weight: 600;
`

export const ExpertPosition = styled.p`
  font-size: 16px;
  line-height: ${24 / 16};
  color: ${({ theme }) => theme.colors.black700};
`

export const ExpertContent = styled.div`
  flex-grow: 1;
  border-top: 1px solid ${({ theme }) => theme.colors.black300};
  padding-top: 16px;
  padding-bottom: 80px;
  ${media.tablet`
    padding-top: 24px;
  `}
`

export const ExpertContainer = styled.div`
  padding-right: 16px;
  padding-left: 16px;

  ${media.tablet`
    padding-right: 36px;
    padding-left: 36px;
  `}

  ${media.desktop`
    max-width: 648px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 0;
    padding-left: 0;
  `}
`

export const ExpertTabsWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -16px;
  margin-right: -16px;
`

export const ExpertTab = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme, isActive }) => (isActive ? theme.colors.black1000 : theme.colors.black700)};
  font-weight: ${({ isActive }) => (isActive ? 700 : 400)};
  border-bottom-width: 2px;
  border-style: solid;
  border-color: ${({ theme, isActive }) => (isActive ? theme.settings.color : 'transparent')};
  font-size: 14px;
  line-height: ${20 / 14};
  padding: 8px 12px;
  cursor: pointer;

  ${media.tablet`
    max-width: 160px;
  `}
`

export const ExpertTabQuant = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-left: 8px;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.colors.black300};
  color: ${({ theme }) => theme.colors.black700};
  font-size: 12px;
  font-weight: 600;
`
