import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { prop, propOr } from 'ramda'

import Option from './Option'
import * as S from '../styled'

const CategoryDropdownComponent = forwardRef(
  ({ intl, value, totalServicesCount, options, visible, onVisibleChange, makeHandleSelect }, ref) => (
    <S.CatDropdownWrap ref={ref}>
      {/* tabindex indicates that its element can be focused.
    It adds outline for search zone when category dropdown is opened */}
      <S.CatDropdownBtn tabIndex="-1" role="button" onClick={onVisibleChange} data-cy="categories-dropdown">
        <S.CatDropdownCurrent>
          <S.CatDropdownText>
            {propOr(intl.formatMessage({ id: 'servicesListView.allCategories' }), 'name', value)}
          </S.CatDropdownText>
          <S.CatDropdownAll data-cy="categories-dropdown-service-count">
            {propOr(totalServicesCount, 'servicesCount', value)}
          </S.CatDropdownAll>
        </S.CatDropdownCurrent>
        <S.CatDropdownIcon active={visible} iconName={visible ? 'iconUpArrow' : 'iconDownArrow'} size={10} />
      </S.CatDropdownBtn>
      <S.CatDropdownList isOpen={visible} data-cy="categories-dropdown-list">
        {[
          { name: intl.formatMessage({ id: 'servicesListView.allCategories' }), servicesCount: totalServicesCount },
          ...options,
        ].map(option => (
          <Option key={prop('name', option)} option={option} value={value} onClick={makeHandleSelect} />
        ))}
      </S.CatDropdownList>
    </S.CatDropdownWrap>
  ),
)

CategoryDropdownComponent.displayName = 'CategoryDropdownComponent'

CategoryDropdownComponent.defaultProps = {
  value: undefined,
  visible: false,
}

CategoryDropdownComponent.propTypes = {
  value: PropTypes.shape(),
  visible: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onVisibleChange: PropTypes.func.isRequired,
  makeHandleSelect: PropTypes.func.isRequired,
  totalServicesCount: PropTypes.number.isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func }).isRequired,
}

export default CategoryDropdownComponent
