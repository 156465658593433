import React from 'react'
import PropTypes from 'prop-types'
import { equals } from 'ramda'
import { FormattedNumber } from 'react-intl'

import FormattedPrice from 'views/shared/FormattedPrice'

const FormattedRange = ({ priceMin, priceMax }) =>
  equals(priceMin, priceMax) ? (
    <FormattedPrice price={priceMin} />
  ) : (
    <>
      <FormattedPrice price={priceMin} /> -{' '}
      <FormattedNumber
        value={priceMax}
        // eslint-disable-next-line react/style-prop-object
        style="currency"
        currency="USD"
      />
    </>
  )

FormattedRange.defaultProps = {
  priceMin: null,
  priceMax: null,
}

FormattedRange.propTypes = {
  priceMin: PropTypes.string,
  priceMax: PropTypes.string,
}

export default FormattedRange
