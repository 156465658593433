import React from 'react'
import PropTypes from 'prop-types'
import { prop } from 'ramda'

import OptionComponent from './component'

class Option extends React.Component {
  static propTypes = {
    option: PropTypes.shape().isRequired,
    value: PropTypes.string.isRequired,
  }

  get isSelected() {
    const { value, option } = this.props

    return value === prop('label', option)
  }

  render = () => <OptionComponent {...this.props} isSelected={this.isSelected} />
}

export default Option
