import { createLogic } from 'redux-logic'
import { splitAt, cond, equals, T, always } from 'ramda'

import { STEP_TYPE_PROPERTIES } from 'constants'
import { resetWidgetFilters } from 'state/concepts/widget/actions'
import { setService } from 'state/concepts/services/actions'
import { setProvider } from 'state/concepts/providers/actions'
import { setTime } from 'state/concepts/time/actions'
import { setAvailabilityPreferences } from 'state/concepts/waitlistRequest/actions'
import { currentStepSelector, stepsOrderSelector, findStepByNumberSelector } from '../selectors'
import { updateSteps } from '../actions'
import { ABORT_STEP } from '../types'

const abortStepOperation = createLogic({
  type: ABORT_STEP,
  latest: true,

  async process({ action: { goBack }, getState }, dispatch, done) {
    const state = getState()
    const currentStep = currentStepSelector(state)
    const stepsOrder = stepsOrderSelector(state)

    if (currentStep && goBack) {
      const [stepNumber] = currentStep
      const previousStep = findStepByNumberSelector(state, stepNumber - 1)

      if (previousStep) {
        const [, { type }] = previousStep
        const [, stepsToReset] = splitAt(stepsOrder.indexOf(type), stepsOrder)

        const resetActions = cond([
          [equals(STEP_TYPE_PROPERTIES.service), always([setService, setAvailabilityPreferences])],
          [equals(STEP_TYPE_PROPERTIES.provider), always([setProvider, setAvailabilityPreferences])],
          [equals(STEP_TYPE_PROPERTIES.time), always([setTime])],
          [T, always([])],
        ])
        stepsToReset.forEach(step => {
          resetActions(step).forEach(resetAction => dispatch(resetAction(null)))
        })
      }

      dispatch(updateSteps({ [stepNumber]: null }))
      dispatch(resetWidgetFilters())
      goBack()
    }

    done()
  },
})

export default abortStepOperation
