import { createGlobalStyle } from 'styled-components'
import Reset from 'styled-reset'

import CustomReset from './CustomReset'
import Base from './Base'

const GlobalStyles = createGlobalStyle`
  ${Reset}
  ${Base}
  ${CustomReset}
`

export default GlobalStyles
