import React from 'react'
import PropTypes from 'prop-types'

import { buildStaticMapUrl } from 'utils/locations'
import Loader from 'views/shared/Loader'
import SearchWrap from './SearchWrap'
import LocationItem from './LocationItem'
import * as S from './styled'

const OfflineLocations = ({ isLoading, onSearch, locationsCount, locations, onSelect }) => (
  <S.ContainerWrap>
    <S.SearchContainerWrap data-cy="offline-locations">
      <SearchWrap
        title={{ id: 'locationsListView.onlineLocations.title' }}
        count={locationsCount}
        onSearch={onSearch}
      />
    </S.SearchContainerWrap>

    {isLoading !== false ? (
      <Loader size={72} isCenter />
    ) : (
      <>
        <S.LocationsWrap>
          {locations.map(location => (
            <LocationItem
              key={location.id}
              title={location.name}
              address={location.addressLine}
              coordinate={location.coordinate}
              onSelect={onSelect(location.id)}
              dataCy="location-item"
            />
          ))}
        </S.LocationsWrap>

        <S.MapWrap data-cy="offline-locations-map">
          <S.Map src={buildStaticMapUrl(locations, { width: 491, height: 392 })} />
        </S.MapWrap>
      </>
    )}
  </S.ContainerWrap>
)

OfflineLocations.defaultProps = {
  locations: [],
  isLoading: undefined,
}

OfflineLocations.propTypes = {
  onSearch: PropTypes.func.isRequired,
  locations: PropTypes.arrayOf(PropTypes.shape()),
  isLoading: PropTypes.bool,
  locationsCount: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
}

export default OfflineLocations
