import React from 'react'

const withOutsideClick = WrappedComponent => {
  class WithOutsideClick extends React.Component {
    targetEl = React.createRef()

    static displayName = `withOutsideClick(${WrappedComponent.name})`

    componentDidMount = () => {
      document.addEventListener('click', this.handleClickOutside, true)
    }

    componentWillUnmount = () => {
      document.removeEventListener('click', this.handleClickOutside, true)
    }

    handleClickOutside = event => {
      // eslint-disable-next-line no-underscore-dangle
      if (!this.targetEl.current.__domNode.contains(event.target)) {
        this.targetEl.current.handleClickOutside(event)
      }
    }

    render = () => <WrappedComponent {...this.props} ref={this.targetEl} />
  }

  return WithOutsideClick
}

export default withOutsideClick
