import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { prop } from 'ramda'

import isPresent from 'utils/isPresent'
import CardTitle from 'views/shared/Card/CardTitle'
import Description from 'views/shared/Card/Description'
import Duration from 'views/shared/Card/Duration'
import FormattedPriceOrRange from 'views/shared/FormattedPriceOrRange'
import SelectableButton from 'views/shared/SelectableButton'
import * as S from './styled'

const Card = memo(({ service, handleSelect, selectedProvider, dataCy }) => {
  const isNotAvailableSlots = selectedProvider && !isPresent(prop('nearestTimeSlots', service))
  const hasAvailableExperts = prop('activeProvidersCount', service) > 0

  return (
    <S.Wrapper data-cy={dataCy}>
      <S.Container>
        <S.ContainerLeft>
          <CardTitle text={prop('name', service)} />
          <Duration seconds={prop('duration', service)} />
        </S.ContainerLeft>

        <S.ContainerRight>
          <Duration visibleMobile seconds={prop('duration', service)} />
          <S.PriceContainer>
            <S.Price data-cy="service-price">
              <S.PriceValue>
                <FormattedPriceOrRange {...service} />
              </S.PriceValue>
            </S.Price>
          </S.PriceContainer>
        </S.ContainerRight>
      </S.Container>
      <Description description={prop('description', service)} />
      <S.CardBottom>
        {!selectedProvider && hasAvailableExperts ? (
          <S.ExpertAvailable>
            <S.ExpertIcon iconName="userO" offsetRight={8} size={18} />
            <S.ExpertCount data-cy="experts-count">{prop('activeProvidersCount', service)}</S.ExpertCount>
            <S.ExpertCountDescr>
              <FormattedMessage
                id="servicesListView.expertsAvailable"
                values={{ count: prop('activeProvidersCount', service) }}
              />
            </S.ExpertCountDescr>
            {service.paymentRequired && (
              <>
                <S.DotDivider />
                <S.PaymentRequired data-cy="prepayment-required">
                  <FormattedMessage id="servicesListView.prepaymentRequired" />
                </S.PaymentRequired>
              </>
            )}
          </S.ExpertAvailable>
        ) : (
          <div>
            {service.paymentRequired && (
              <S.PaymentRequired data-cy="prepayment-required">
                <FormattedMessage id="servicesListView.prepaymentRequired" />
              </S.PaymentRequired>
            )}
          </div>
        )}
        {isNotAvailableSlots && (
          <S.SlotsTitle>
            <FormattedMessage id="providersListView.noAvailableTimeSlots" />
          </S.SlotsTitle>
        )}
        <S.PriceButtonWrap>
          <S.PriceValue visibleMobile>
            <FormattedPriceOrRange priceMax={prop('priceMax', service)} priceMin={prop('priceMin', service)} />
          </S.PriceValue>
          <SelectableButton text={{ id: 'shared.selectService' }} onClick={handleSelect(service)} widthAuto />
        </S.PriceButtonWrap>
      </S.CardBottom>
    </S.Wrapper>
  )
})

Card.displayName = 'Card'

Card.defaultProps = {
  dataCy: undefined,
  selectedProvider: null,
  handleSelect: () => {},
}

Card.propTypes = {
  dataCy: PropTypes.string,
  selectedProvider: PropTypes.shape(),
  handleSelect: PropTypes.func,
  service: PropTypes.shape().isRequired,
}

export default Card
