import styled from 'styled-components'
import { rgba } from 'polished'
import { media } from 'styled-bootstrap-grid'

import SvgIcon from 'views/shared/Icons'

const CardWrap = styled.div`
  padding: 12px 24px;
  background-color: white;
  border-bottom: 1px solid ${props => props.theme.colors.black300};
  border-top: 1px solid transparent;
  color: ${({ theme }) => theme.colors.black400};
  transition: 0.7s;
  cursor: pointer;

  &:first-child {
    border-top-color: transparent;
    padding-top: 0;
  }
  ${media.tablet`
    padding: 12px 32px;
    &:first-child {
      padding-top: 12px;
    }
    &:hover {
      border-color: ${({ theme }) => theme.settings.color};
      background-color: ${({ theme }) => rgba(theme.settings.color, 0.1)};
      color: ${({ theme }) => theme.settings.color};
    }
  `}
`
const Card = styled.div`
  display: flex;
  align-items: center;
`
const CardInfo = styled.div`
  width: calc(100% - 32px);
`
const CardTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: ${28 / 16};
  color: ${props => props.theme.colors.black1000};
`
const CardAddress = styled.p`
  font-size: 16px;
  line-height: ${28 / 16};
  color: ${props => props.theme.colors.black700};
`
const ArrowIcon = styled(SvgIcon)`
  fill: currentColor;
  margin-left: auto;
`
const CardLink = styled.a`
  font-size: 16px;
  line-height: ${28 / 16};
  color: ${({ theme }) => theme.settings.color};
`
const Map = styled.img`
  display: block;
  width: 100%;
  margin-top: 12px;
  ${media.desktop`
    display: none;
  `}
`
export { CardWrap, Card, CardInfo, CardTitle, CardAddress, ArrowIcon, CardLink, Map }
