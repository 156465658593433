// eslint-disable-next-line import/prefer-default-export
export const DEFAULT_MAPPINGS = {
  Expert: 'Expert',
  Experts: 'Experts',
  expert: 'expert',
  experts: 'experts',
  Service: 'Service',
  Services: 'Services',
  service: 'service',
  services: 'services',
  Client: 'Client',
  Clients: 'Clients',
  client: 'client',
  clients: 'clients',
  Appointment: 'Appointment',
  Appointments: 'Appointments',
  appointment: 'appointment',
  appointments: 'appointments',
  'Intake form': 'Intake form',
  'Intake forms': 'Intake forms',
  'intake form': 'intake form',
  'intake forms': 'intake forms',
  'Service agreement': 'Service agreement',
  'Service agreements': 'Service agreements',
  'service agreement': 'service agreement',
  'service agreements': 'service agreements',
}
