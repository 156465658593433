import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Body = ({ children }) => <Body.Wrapper>{children}</Body.Wrapper>

Body.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
}

Body.Wrapper = styled.div`
  overflow-y: auto;
`

export default Body
