import { createLogic } from 'redux-logic'

import { STEP_TYPE_PROPERTIES } from 'constants'
import { serviceIdSelector } from 'state/concepts/services/selectors'
import { providerIdSelector } from 'state/concepts/providers/selectors'
import { hasLocationsSelector } from 'state/concepts/widget/selectors'
import { setStepsOrder } from '../actions'
import { RESOLVE_STEPS_ORDER } from '../types'

const resolveStepsOrderOperation = createLogic({
  type: RESOLVE_STEPS_ORDER,
  warnTimeout: 0,

  async process({ action: { firstStepType }, getState }, dispatch, done) {
    const state = getState()
    const serviceId = serviceIdSelector(state)
    const providerId = providerIdSelector(state)
    const hasLocations = hasLocationsSelector(state)
    const isProviderStepFirst = !serviceId && (providerId || firstStepType === STEP_TYPE_PROPERTIES.provider)

    const stepsOrder = [
      ...(hasLocations ? [STEP_TYPE_PROPERTIES.location] : []),
      ...(isProviderStepFirst
        ? [STEP_TYPE_PROPERTIES.provider, STEP_TYPE_PROPERTIES.service]
        : [STEP_TYPE_PROPERTIES.service, STEP_TYPE_PROPERTIES.provider]),
      STEP_TYPE_PROPERTIES.time,
      STEP_TYPE_PROPERTIES.review,
    ]

    dispatch(setStepsOrder(stepsOrder))

    done()
  },
})

export default resolveStepsOrderOperation
