import { showModal } from 'state/modal/actions'

const makeShowBookingErrorModal = ({ dispatch, ...props }) => {
  dispatch(
    showModal({
      modalType: 'BOOKING_ERROR',
      modalProps: props,
    }),
  )
}

export default makeShowBookingErrorModal
