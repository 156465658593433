import React from 'react'
import PropTypes from 'prop-types'
import { always, cond, is } from 'ramda'
import { getIn } from 'formik'
import { FormattedMessage, useIntl } from 'react-intl'
import flags from 'react-phone-number-input/flags'
import PhoneInput from 'react-phone-number-input'

import { handleInputEvent } from 'utils/inputHelpers'
import FormattedOrRawMessage from 'views/shared/FormattedOrRawMessage'
import * as InputFieldStyled from 'views/shared/InputField/styled'
import Select from './Select'
import * as S from './styled'

const PhoneInputField = ({ placeholder, id, label, field, form: { touched, errors, status }, ...props }) => {
  const intl = useIntl()
  const fieldTouched = getIn(touched, field.name)
  const fieldErrors = getIn(errors, field.name)
  const fieldStatus = getIn(status, field.name)
  const kind = cond([
    [() => fieldTouched && fieldErrors, always('error')],
    [() => fieldTouched && fieldStatus, always('info')],
  ])()

  return (
    <S.Wrapper kind={kind}>
      {label && (
        <InputFieldStyled.Label htmlFor={id}>
          <FormattedMessage {...label} />
        </InputFieldStyled.Label>
      )}
      <PhoneInput
        style={{ position: 'relative' }}
        value={field.value}
        placeholder={is(Object, placeholder) ? intl.formatMessage(placeholder) : placeholder}
        onChange={handleInputEvent(field, field.onChange, '')}
        onBlur={handleInputEvent(field, field.onBlur, '')}
        numberInputProps={{ className: 'main-input__field' }}
        countrySelectComponent={Select}
        defaultCountry="US"
        international
        addInternationalOption={false}
        flags={flags}
        {...props}
      />
      {fieldTouched && fieldErrors && (
        <InputFieldStyled.Message condition={kind}>
          <InputFieldStyled.MessageIcon condition={kind} iconName="close" size={14} />
          <FormattedOrRawMessage message={fieldErrors} />
        </InputFieldStyled.Message>
      )}
    </S.Wrapper>
  )
}

PhoneInputField.defaultProps = {
  id: null,
  label: null,
  placeholder: null,
}

PhoneInputField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.shape({
    id: PropTypes.string.isRequired,
    values: PropTypes.shape(),
  }),
  field: PropTypes.shape().isRequired,
  form: PropTypes.shape({
    touched: PropTypes.shape().isRequired,
    errors: PropTypes.shape().isRequired,
    status: PropTypes.shape(),
  }).isRequired,
  placeholder: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
}

export default PhoneInputField
