import React from 'react'
import PropTypes from 'prop-types'
import { is, cond, always } from 'ramda'
import { FormattedMessage } from 'react-intl'
import { getIn } from 'formik'

import SvgIcon from 'views/shared/Icons'
import FormattedOrRawMessage from 'views/shared/FormattedOrRawMessage'
import * as S from './styled'

const InputFieldComponent = ({
  className,
  placeholder,
  id,
  label,
  type,
  message,
  leftClass,
  rightClass,
  disabled,
  autoComplete,
  field,
  form: { touched, errors, status },
  flexGrow,
  intl,
  allowClear,
  onClear,
  ...props
}) => {
  const fieldTouched = getIn(touched, field.name)
  const fieldErrors = getIn(errors, field.name)
  const fieldStatus = getIn(status, field.name)
  const condition = cond([
    [() => fieldTouched && fieldErrors, always('error')],
    [() => fieldTouched && fieldStatus, always('info')],
  ])()

  return (
    <S.Wrapper className={className} label={label}>
      {label && (
        <S.Label htmlFor={id}>
          <FormattedMessage {...label} />
        </S.Label>
      )}
      <S.FieldWrapper>
        {leftClass && <S.LeftIcon size={22} iconName={leftClass} />}
        <S.Input
          type={type}
          condition={condition}
          leftClass={leftClass}
          rightClass={rightClass}
          autoComplete={autoComplete}
          placeholder={is(Object, placeholder) ? intl.formatMessage(placeholder) : placeholder}
          {...{ disabled, id, ...field, ...props }}
        />
        {allowClear && (
          <span role="button" onClick={onClear}>
            <S.ClearIcon dataCy="input-clear-button" iconName="circleCancel" size={20} color="black500" />
          </span>
        )}
        {rightClass && (
          <S.RightButton>
            <SvgIcon iconName={rightClass} />
          </S.RightButton>
        )}
      </S.FieldWrapper>
      {fieldTouched && fieldErrors && (
        <S.Message condition={condition}>
          <S.MessageIcon condition={condition} iconName="close" size={14} />
          <FormattedOrRawMessage message={fieldErrors} />
        </S.Message>
      )}
    </S.Wrapper>
  )
}

InputFieldComponent.defaultProps = {
  id: null,
  label: null,
  type: 'text',
  className: '',
  message: null,
  leftClass: null,
  disabled: false,
  rightClass: null,
  placeholder: null,
  autoComplete: 'off',
  flexGrow: false,
  allowClear: false,
  onClear: undefined,
}

InputFieldComponent.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.shape({
    id: PropTypes.string.isRequired,
    values: PropTypes.shape(),
  }),
  field: PropTypes.shape().isRequired,
  form: PropTypes.shape({
    touched: PropTypes.shape().isRequired,
    errors: PropTypes.shape().isRequired,
    status: PropTypes.shape(),
  }).isRequired,
  disabled: PropTypes.bool,
  flexGrow: PropTypes.bool,
  message: PropTypes.string,
  leftClass: PropTypes.string,
  className: PropTypes.string,
  rightClass: PropTypes.string,
  autoComplete: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
  intl: PropTypes.shape().isRequired,
  allowClear: PropTypes.bool,
  onClear: PropTypes.func,
}

export default InputFieldComponent
