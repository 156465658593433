export default `
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: normal;
    src: url('${ASSET_CDN}/assets/fonts/inter/inter-v13-latin-regular.woff2') format('woff2'),
      url('${ASSET_CDN}/assets/fonts/inter/inter-v13-latin-regular.woff') format('woff');
    font-display: swap;
  }

  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    src: url('${ASSET_CDN}/assets/fonts/inter/inter-v13-latin-500.woff2') format('woff2'),
      url('${ASSET_CDN}/assets/fonts/inter/inter-v13-latin-500.woff') format('woff');
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    src: url('${ASSET_CDN}/assets/fonts/inter/inter-v13-latin-600.woff2') format('woff2'),
      url('${ASSET_CDN}/assets/fonts/inter/inter-v13-latin-600.woff') format('woff');
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Inter Tight';
    font-style: normal;
    font-weight: normal;
    src: url('${ASSET_CDN}/assets/fonts/interTight/inter-tight-v7-latin-regular.woff2') format('woff2'),
      url('${ASSET_CDN}/assets/fonts/interTight/inter-tight-v7-latin-regular.woff') format('woff');
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Inter Tight';
    font-style: normal;
    font-weight: 600;
    src: url('${ASSET_CDN}/assets/fonts/interTight/inter-tight-v7-latin-600.woff2') format('woff2'),
      url('${ASSET_CDN}/assets/fonts/interTight/inter-tight-v7-latin-600.woff') format('woff');
    font-display: swap;
  }

  @font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: normal;
    src: url('${ASSET_CDN}/assets/fonts/raleway/Raleway-Regular.eot') format('eot'),
      url('${ASSET_CDN}/assets/fonts/raleway/Raleway-Regular.woff') format('woff'),
      url('${ASSET_CDN}/assets/fonts/raleway/Raleway-Regular.ttf') format('truetype');
    font-display: swap;
  }

  @font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    src: url('${ASSET_CDN}/assets/fonts/raleway/Raleway-Bold.eot') format('eot'),
      url('${ASSET_CDN}/assets/fonts/raleway/Raleway-Bold.woff') format('woff'),
      url('${ASSET_CDN}/assets/fonts/raleway/Raleway-Bold.ttf') format('truetype');
    font-display: swap;
  }

  html {
    box-sizing: border-box;
    height: 100%;
    min-height: 100vh;
    /* stylelint-disable */
    min-height: -webkit-fill-available;
    font-family: sans-serif;
    font-size: 62.5% !important;
    line-height: 1;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(black, 0);
    overflow: hidden;
  }

  body {
    height: 100%;
    font-family: 'Inter', sans-serif !important;
    font-size: 1.4rem !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .frame-root,
  .frame-content {
    height: 100%;
  }

  input {
    font-family: 'Inter', sans-serif;
  }

  @media (max-width: 767px) {
    select,
    textarea,
    input {
      font-size: max(16px, 1em);
    }
  }

  ::-webkit-scrollbar {
    width: 12px;
  }
  ::-webkit-scrollbar-thumb {
    border: 4px solid transparent;
    border-radius: 12px;
    background-clip: content-box;
    background-color: rgba(82, 97, 115, 0.25);
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(82, 97, 115, 0.5);
  }

  input::placeholder {
    color: #cad0dc;
  }
`
