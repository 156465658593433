import styled from 'styled-components'

const Wrapper = styled.div`
  display: inline-flex;
  min-width: 108px;
`

const Input = styled.input`
  display: none;

  &:checked + label > i {
    border-color: ${({ theme }) => theme.colors.black1000};

    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: 8px;
      height: 8px;
      margin-top: -4px;
      margin-left: -4px;
      background-color: ${({ theme }) => theme.colors.black1000};
      border-radius: 50%;
      content: '';
    }
  }
`

const Label = styled.label`
  position: relative;
  margin-right: 8px;
`

const Icon = styled.i`
  display: block;
  width: 18px;
  height: 18px;
  background-color: white;
  border-color: ${({ theme }) => theme.colors.black500};
  border-style: solid;
  border-width: 2px;
  border-radius: 50%;
  margin: 3px;
  cursor: pointer;
  transition: border-color 0.3s ease 0s;

  &:hover {
    border-color: ${({ theme }) => theme.settings.color};
  }
`

const Text = styled.span`
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.black1000};
`

export { Wrapper, Input, Label, Icon, Text }
