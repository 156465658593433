import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

import SvgIcon from 'views/shared/Icons'

export const ExpertLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.black700};
  margin-bottom: 12px;
`

export const ServiceIcon = styled(SvgIcon)`
  fill: ${({ theme }) => theme.settings.color};
`

export const ExpertLabelText = styled.span`
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  margin-right: 4px;

  ${media.desktop`
    font-size: 16px;
    line-height: 24px;
  `}
`

export const ExpertLabelDesc = styled.span`
  font-size: 14px;
  line-height: 20px;

  ${media.desktop`
    font-size: 16px;
    line-height: 24px;
  `}
`

export const ExpertDescr = styled.p`
  font-size: 14px;
  line-height: ${20 / 14};
  color: ${({ theme }) => theme.colors.black700};
  white-space: pre-line;

  ${media.tablet`
    font-size: 16px;
    line-height: ${24 / 16};
  `}
`
