import React from 'react'
import PropTypes from 'prop-types'

const Link = ({ onClick, children }) => (
  <div role="button" onClick={onClick}>
    {children}
  </div>
)

Link.defaultProps = {
  children: null,
}

Link.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
}

export default Link
