import styled, { css } from 'styled-components'
import { media } from 'styled-bootstrap-grid'

const ButtonContainer = styled.div`
  max-height: 40px;
  width: 100%;
  margin-top: 4px;
  ${media.tablet`
    width: auto;
    margin-top: 0;
    margin-left: 16px;
  `}
  ${props =>
    props.disabled &&
    css`
      position: relative;
      filter: grayscale(1);
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: white;
        opacity: 0.8;
        z-index: 1000;
      }
    `}
`

// eslint-disable-next-line import/prefer-default-export
export { ButtonContainer }
