import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose, prop, find, propEq } from 'ramda'
import { DateTime } from 'luxon'
import { withRouter } from 'react-router-dom'

import {
  fetchAvailability as fetchAvailabilityAction,
  setDate as setDateAction,
  resetTimeSlots as resetTimeSlotsAction,
} from 'state/concepts/availability/actions'
import { setTime as setTimeAction, setTimezone as setTimezoneAction } from 'state/concepts/time/actions'
import { createBooking as createBookingAction } from 'state/concepts/booking/actions'
import { dateSelector, timeSlotsSelector, availabilityLoadingSelector } from 'state/concepts/availability/selectors'
import {
  hasManyLocationsSelector,
  hasSingleRelationshipSelector,
  widgetSelector,
} from 'state/concepts/widget/selectors'
import { timezonesSelector } from 'state/concepts/timezones/selectors'
import { timezoneSelector } from 'state/concepts/time/selectors'
import { lastStepNumberSelector } from 'state/steps/selectors'
import TimeSlotsViewComponent from './component'

class TimeSlotsView extends React.Component {
  state = {
    minDate: DateTime.local(),
    timezone: null,
  }

  componentDidMount = () => {
    this.props.fetchAvailability({ date: this.props.date, shouldSetDate: true })
  }

  componentWillUnmount = () => {
    this.props.resetTimeSlots()
  }

  get currentTimeSlots() {
    const { timeSlots, date } = this.props
    return timeSlots && timeSlots[date.toISODate()]
  }

  get timezoneLabel() {
    const { timezones, timezone } = this.props

    return prop('label', find(propEq(prop('id', timezone), 'value'), timezones))
  }

  get stepNumber() {
    const { lastStepNumber } = this.props

    return lastStepNumber - 1
  }

  get isWithBackButton() {
    const { hasSingleRelationship, hasManyLocations } = this.props

    return !hasSingleRelationship || hasManyLocations
  }

  onDateChange = nextDate => {
    const date = DateTime.isDateTime(nextDate) ? nextDate : DateTime.fromJSDate(nextDate)
    this.props.setDate(date)
  }

  onPrevNextClick = ({ activeStartDate }) => {
    this.props.fetchAvailability({ date: DateTime.fromJSDate(activeStartDate) })
  }

  onTimezoneChange = ({ key }) => {
    this.props.resetTimeSlots()
    this.props.setTimezone(key)
    this.props.fetchAvailability({ date: this.props.date })
  }

  redirectToReview = () => {
    const { history } = this.props

    history.push('/review')
  }

  makeSelectHandler = time => () => {
    const { createBooking, setTime } = this.props

    setTime(time)
    createBooking(this.redirectToReview)
  }

  // method for react-calendar to dynamically define date tile state
  setTileDisable = ({ date }) => {
    const { timeSlots } = this.props
    const activeDate = DateTime.fromJSDate(date)

    return Boolean(!(timeSlots && timeSlots[activeDate.toISODate()]))
  }

  onJoinWaitlist = () => {
    const { history } = this.props

    history.replace('/availability_preferences')
  }

  render = () => (
    <TimeSlotsViewComponent
      {...this.state}
      {...this.props}
      onDateChange={this.onDateChange}
      setTileDisable={this.setTileDisable}
      onPrevNextClick={this.onPrevNextClick}
      onTimezoneChange={this.onTimezoneChange}
      makeSelectHandler={this.makeSelectHandler}
      timeSlots={this.currentTimeSlots}
      timezoneLabel={this.timezoneLabel}
      stepNumber={this.stepNumber}
      isWithBackButton={this.isWithBackButton}
      onJoinWaitlist={this.onJoinWaitlist}
    />
  )
}

TimeSlotsView.defaultProps = {
  timeSlots: null,
  timezones: [],
  timezone: null,
  hasManyLocations: false,
}

TimeSlotsView.propTypes = {
  date: PropTypes.shape().isRequired,
  timeSlots: PropTypes.shape(),
  fetchAvailability: PropTypes.func.isRequired,
  setDate: PropTypes.func.isRequired,
  resetTimeSlots: PropTypes.func.isRequired,
  setTime: PropTypes.func.isRequired,
  setTimezone: PropTypes.func.isRequired,
  timezones: PropTypes.arrayOf(PropTypes.shape()),
  timezone: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
  }).isRequired,
  createBooking: PropTypes.func.isRequired,
  lastStepNumber: PropTypes.number.isRequired,
  hasSingleRelationship: PropTypes.bool.isRequired,
  hasManyLocations: PropTypes.bool,
}

const mapStateToProps = state => ({
  date: DateTime.fromISO(dateSelector(state)),
  timeSlots: timeSlotsSelector(state),
  timezones: timezonesSelector(state),
  timezone: timezoneSelector(state),
  hasSingleRelationship: hasSingleRelationshipSelector(state),
  isLoading: availabilityLoadingSelector(state),
  lastStepNumber: lastStepNumberSelector(state),
  hasManyLocations: hasManyLocationsSelector(state),
  widget: widgetSelector(state),
})

const mapDispatchToProps = {
  fetchAvailability: fetchAvailabilityAction,
  setDate: setDateAction,
  resetTimeSlots: resetTimeSlotsAction,
  createBooking: createBookingAction,
  setTimezone: setTimezoneAction,
  setTime: setTimeAction,
}

export { TimeSlotsView as TimeSlotsViewContainer }
export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(TimeSlotsView)
