import { combineReducers } from 'redux'

import { SET_WAITLIST_REQUEST, SET_AVAILABILITY_PREFERENCES } from 'state/concepts/waitlistRequest/types'

const waitlistRequestId = (state = null, action) => {
  switch (action.type) {
    case SET_WAITLIST_REQUEST:
      return action.waitlistRequestId
    default:
      return state
  }
}

const availabilityPreferences = (state = null, action) => {
  switch (action.type) {
    case SET_AVAILABILITY_PREFERENCES:
      return action.availabilityPreferences
    default:
      return state
  }
}

export default combineReducers({
  waitlistRequestId,
  availabilityPreferences,
})
