import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'

import StyledSelect from './styled'

const SelectComponent = forwardRef(({ options, searchable, ...props }, ref) => (
  <StyledSelect ref={ref} options={options} searchable={searchable} {...props} />
))

SelectComponent.displayName = 'SelectComponent'

SelectComponent.defaultProps = {
  options: [],
  searchable: false,
}

SelectComponent.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.node.isRequired,
    }),
  ),
  searchable: PropTypes.bool,
}

export default SelectComponent
