import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import build from 'redux-object'
import { DateTime } from 'luxon'

import { firstDateOfCalendarISO, lastDateOfCalendarISO, nearestSlotsDate } from 'utils/dateTime'
import { dataApiRequest, dataApiSuccess, dataApiFailure } from 'state/data/actions'
import { workspaceIdSelector } from 'state/concepts/widget/selectors'
import { serviceIdSelector } from 'state/concepts/services/selectors'
import { providerIdSelector } from 'state/concepts/providers/selectors'
import { timezoneSelector } from 'state/concepts/time/selectors'
import { locationIdSelector } from 'state/concepts/locations/selectors'
import { FETCH_AVAILABILITY } from '../types'
import { addTimeSlots, setDate } from '../actions'
import { fetchAvailabilityEndpoint } from '../endpoints'

const fetchAvailabilityOperation = createLogic({
  type: FETCH_AVAILABILITY,
  latest: true,

  async process({ action: { date, shouldSetDate }, httpClient, getState }, dispatch, done) {
    const state = getState()
    const workspaceId = workspaceIdSelector(state)
    const serviceId = serviceIdSelector(state)

    const { endpoint, url } = fetchAvailabilityEndpoint(workspaceId, serviceId)

    const minDate = DateTime.local()
    const startDate = date.hasSame(DateTime.local(), 'month') ? minDate : date

    const params = {
      filter: {
        user_profile_id: providerIdSelector(state),
        start_date: firstDateOfCalendarISO(startDate),
        end_date: lastDateOfCalendarISO(startDate),
        timezone: timezoneSelector(state).id,
        location_id: locationIdSelector(state),
      },
    }

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.get(url, { params })

      const response = normalize(data, { endpoint, camelizeKeys: false })
      const { 'users-time-slots': timeSlots } = build(response, 'service', serviceId)

      if (shouldSetDate) {
        const nextDate = nearestSlotsDate(timeSlots)
        const currentDate = nextDate.invalid ? date : nextDate

        dispatch(setDate(currentDate.toISODate()))
      }

      dispatch(addTimeSlots(timeSlots))
      dispatch(dataApiSuccess({ response, endpoint }))
    } catch (error) {
      dispatch(dataApiFailure({ endpoint }))
    }
    done()
  },
})

export default fetchAvailabilityOperation
