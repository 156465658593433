import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import TabsProvider from 'views/shared/tabs/TabsProvider'
import TabHeader from 'views/shared/tabs/TabHeader'
import TabContent from 'views/shared/tabs/TabContent'
import DayPartCard from '../DayPartCard'
import * as S from './styled'

const TimeslotsMobile = ({ timeSlots, tabs, makeSelectHandler }) => (
  <TabsProvider activeTab={tabs[0]}>
    <S.TimeslotsTabs>
      {tabs.map(tab => (
        <TabHeader tab={tab} key={tab}>
          <S.TimeslotsTab data-cy="day-part-tab-mobile">
            <FormattedMessage id={`timeSlotsView.${tab}`} />
          </S.TimeslotsTab>
        </TabHeader>
      ))}
    </S.TimeslotsTabs>

    <S.TimeslotsTabContent>
      {tabs.map(tab => (
        <TabContent tab={tab} key={tab} data-cy="time-slot-button">
          <DayPartCard slots={timeSlots[tab]} title={{ id: `timeSlotsView.${tab}` }} handleSelect={makeSelectHandler} />
        </TabContent>
      ))}
    </S.TimeslotsTabContent>
  </TabsProvider>
)

TimeslotsMobile.defaultProps = {
  timeSlots: null,
}

TimeslotsMobile.propTypes = {
  timeSlots: PropTypes.shape(),
  makeSelectHandler: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default TimeslotsMobile
