import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

const Title = styled.p`
  font-family: ${({ theme }) => theme.fonts.interTight};
  font-size: 16px;
  line-height: ${28 / 16};
  color: ${({ theme }) => theme.colors.black1000};
  font-weight: 600;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 100%;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(${({ theme }) => theme.colors.boxShadowColor}, 0.1);
  max-height: calc(100vh - 100px);
  z-index: ${({ theme }) => theme.modalZindex};

  ${media.tablet`
    left: 50%;
    top: 50%;
    bottom: auto;
    transform: translate(-50%, -50%);
    max-width: ${({ maxWidth }) => `${maxWidth || 530}px`};
  `}
`

const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 24px;
`

const CancelButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 80px;
  min-height: 48px;
  padding-right: 16px;
  padding-left: 16px;
  font-size: 14px;
  border: none;
  border-radius: 3px;
  margin-right: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.black800};
  transition: background-color 0.15s;

  &:hover {
    border: none;
    background-color: ${({ theme }) => theme.colors.black50};
  }
`

const TextContainer = styled.div`
  min-height: 105px;
  padding: 24px;
`

const Text = styled.p`
  font-size: 14px;
  line-height: ${20 / 14};
  color: ${({ theme }) => theme.colors.black1000};
  ${({ offsetBottom }) => offsetBottom && `margin-bottom: ${offsetBottom}px`}
`

export { ActionButtons, CancelButton, Title, TextContainer, Text, Container }
