import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: ${({ offsetTop }) => offsetTop || '72px'};
`

const TextWrap = styled.div`
  max-width: 200px;
  margin-top: 8px;
  font-size: 14px;
  line-height: ${20 / 14};
  text-align: center;
`

const TextTitle = styled.p`
  color: ${({ theme }) => theme.colors.black1000};
  font-weight: 600;
  margin-bottom: 8px;
`

const Text = styled.p`
  color: ${({ theme }) => theme.colors.black700};
`

const ImageWrapper = styled.div`
  max-width: 64px;

  ${media.desktop`
    max-width: 136px;
    height: 136px;
  `}
`

export { Wrapper, TextWrap, TextTitle, Text, ImageWrapper }
