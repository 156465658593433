import React from 'react'
import PropTypes from 'prop-types'

import { STEP_TYPE_PROPERTIES } from 'constants'
import Step from '../Step'
import ServiceInfo from './ServiceInfo'

const ServiceStep = ({ steps, service }) => (
  <Step iconName="bag" type={STEP_TYPE_PROPERTIES.service} steps={steps}>
    <ServiceInfo property={service} />
  </Step>
)

ServiceStep.defaultProps = {
  service: null,
}

ServiceStep.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
  service: PropTypes.shape(),
}

export default ServiceStep
