import { createLogic } from 'redux-logic'
import { xor } from 'ramda'

import { STEP_TYPE_PATHS } from 'constants'
import { RESOLVE_WIDGET_STATE } from 'state/concepts/widget/types'
import { setService } from 'state/concepts/services/actions'
import { setProvider } from 'state/concepts/providers/actions'
import { setIsWidgetLocked } from 'state/concepts/widget/actions'
import { setLocation } from 'state/concepts/locations/actions'

const resolveWidgetStateOperation = createLogic({
  type: RESOLVE_WIDGET_STATE,
  latest: true,

  async process({ action: { navigateTo, widget } }, dispatch, done) {
    const {
      service,
      provider,
      servicesCount,
      providersCount,
      workspaceLocked,
      locationsCount,
      hasOnlineServices,
      onlineLocation,
      location,
    } = widget

    if (workspaceLocked !== undefined) {
      dispatch(setIsWidgetLocked(workspaceLocked))
    }

    if (servicesCount === 0 || workspaceLocked) {
      navigateTo('/')

      done()
      return
    }

    if (service) {
      dispatch(setService(service.id))
    }

    if (provider) {
      dispatch(setProvider(provider.id))
    }

    if (xor(location, onlineLocation)) {
      if (locationsCount > 1 || (locationsCount === 1 && hasOnlineServices)) {
        navigateTo(STEP_TYPE_PATHS.location, { skipLoad: true })

        if (providersCount !== 1 && servicesCount !== 1) {
          navigateTo('/')
        }
      }

      dispatch(setLocation(location?.id || null))
    } else if (locationsCount > 1 || (locationsCount === 1 && hasOnlineServices)) {
      navigateTo(STEP_TYPE_PATHS.location)

      done()
      return
    }

    if ((locationsCount === undefined && providersCount !== 1 && servicesCount !== 1) || (!service && !provider)) {
      navigateTo('/')
    }

    if (service) {
      if (servicesCount > 1) {
        navigateTo(STEP_TYPE_PATHS.service, { skipLoad: true })
      }

      if (!provider) {
        navigateTo(STEP_TYPE_PATHS.provider)
      }
    }

    if (provider) {
      if (providersCount > 1) {
        navigateTo(STEP_TYPE_PATHS.provider, { skipLoad: true })
      }

      if (!service) {
        navigateTo(STEP_TYPE_PATHS.service)
      }
    }

    if (service && provider) {
      navigateTo(STEP_TYPE_PATHS.time)
    }

    done()
  },
})

export default resolveWidgetStateOperation
