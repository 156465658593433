import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import Theme from 'constants/Theme'
import { formattedTime } from 'utils/dateTime'
import Header from 'views/shared/ModalHeader'
import Body from 'views/shared/DetailsModal/Body'
import Button from 'views/shared/Button'
import Overlay from 'views/shared/Overlay'
import FormattedOrRawMessage from 'views/shared/FormattedOrRawMessage'
import * as S from './styled'

const BookingErrorModal = ({ time, baseError, onClose, onRedirect, dataCy, title, submitText }) => (
  <>
    <S.Container data-cy={dataCy}>
      <Header
        header={
          <S.Title>
            <FormattedMessage {...title} />
          </S.Title>
        }
        handleClose={onClose}
      />
      <Body>
        <S.TextContainer>
          <S.Text>
            {baseError ? (
              <FormattedOrRawMessage message={baseError} />
            ) : (
              <FormattedMessage
                id={time ? 'reviewBookingView.timeIsNotAvailable' : 'shared.unknownError'}
                values={time ? { time: formattedTime(time) } : null}
              />
            )}
          </S.Text>
        </S.TextContainer>
        <S.ActionButtons>
          <Button
            minWidth={136}
            text={submitText}
            bgColor={Theme.colors.red1000}
            onClick={onRedirect}
            dataCy="okay-button"
          />
        </S.ActionButtons>
      </Body>
    </S.Container>
    <Overlay onClick={onClose} />
  </>
)

BookingErrorModal.defaultProps = {
  time: null,
  baseError: null,
  title: { id: 'shared.errorOccurred' },
  submitText: { id: 'shared.okay' },
  dataCy: 'error-modal-container',
}

BookingErrorModal.propTypes = {
  dataCy: PropTypes.string,
  title: PropTypes.shape(),
  submitText: PropTypes.shape(),
  onClose: PropTypes.func.isRequired,
  onRedirect: PropTypes.func.isRequired,
  time: PropTypes.string,
  baseError: PropTypes.string,
}

export default BookingErrorModal
