import { createSelector } from 'reselect'
import { prop, path, pipe } from 'ramda'

import buildCollection from 'utils/buildCollection'
import { pageCountSelector } from 'state/data/selectors'
import { workspaceIdSelector } from 'state/concepts/widget/selectors'
import { fetchReviewsEndpoint } from 'state/concepts/reviews/endpoints'

const dataSelector = prop('data')

export const reviewIdsSelector = path(['reviews', 'reviewIds'])
export const paginationSelector = path(['reviews', 'pagination'])

export const fetchReviewsEndpointSelector = createSelector(
  workspaceIdSelector,
  (_, providerId) => providerId,
  pipe(fetchReviewsEndpoint, prop('endpoint')),
)

export const reviewsSelector = createSelector(reviewIdsSelector, dataSelector, buildCollection('review'))

export const currentPageSelector = createSelector(paginationSelector, prop('number'))

export const hasMoreSelector = createSelector(
  pageCountSelector,
  currentPageSelector,
  (count, currentPage) => count > currentPage,
)
