import * as types from './types'

export const fetchAvailability = ({ date, shouldSetDate }) => ({
  type: types.FETCH_AVAILABILITY,
  date,
  shouldSetDate,
})

export const setDate = date => ({
  type: types.SET_DATE,
  date,
})

export const addTimeSlots = timeSlots => ({
  type: types.ADD_TIME_SLOTS,
  timeSlots,
})

export const resetTimeSlots = () => ({
  type: types.RESET_TIME_SLOTS,
})
