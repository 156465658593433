import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { bookingFormsSelector, bookingFillOutFormsLinkSelector } from 'state/concepts/booking/selectors'
import DocumentsCardComponent from './component'

class DocumentsCard extends React.Component {
  static propTypes = {
    booking: PropTypes.shape().isRequired,
  }

  get pendingIntakeFormsCount() {
    const {
      booking: { intakeFormsCount },
    } = this.props

    return intakeFormsCount
  }

  get pendingAgreementsCount() {
    const {
      booking: { agreementFormsCount },
    } = this.props

    return agreementFormsCount
  }

  get hasAgreements() {
    return this.pendingAgreementsCount > 0
  }

  get hasIntakeForms() {
    return this.pendingIntakeFormsCount > 0
  }

  get hasDocuments() {
    return this.hasAgreements || this.hasIntakeForms
  }

  render() {
    return (
      <DocumentsCardComponent
        pendingIntakeFormsCount={this.pendingIntakeFormsCount}
        pendingAgreementsCount={this.pendingAgreementsCount}
        hasDocuments={this.hasDocuments}
        hasAgreements={this.hasAgreements}
        hasIntakeForms={this.hasIntakeForms}
        {...this.props}
      />
    )
  }
}

const mapStateToProps = state => ({
  forms: bookingFormsSelector(state),
  fillOutFormsLink: bookingFillOutFormsLinkSelector(state),
})

export { DocumentsCard as DocumentsCardContainer }
export default connect(mapStateToProps)(DocumentsCard)
