import * as yup from 'yup'

import yupLocale from 'locales/yup'
import { phoneNumber } from './validations/phoneNumber'

yup.setLocale(yupLocale)

yup.addMethod(yup.string, 'phoneNumber', phoneNumber)

export default yup
