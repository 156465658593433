import React from 'react'
import { FormattedMessage } from 'react-intl'

import { HOW_TO_BOOK_APPOINTMENT_LINK } from 'constants'
import { LinkWrap } from './styled'

const TooltipContent = () => (
  <>
    <FormattedMessage id="mainView.tooltipContent" />
    <LinkWrap>
      <a href={HOW_TO_BOOK_APPOINTMENT_LINK} target="_blank" rel="noopener noreferrer">
        <FormattedMessage id="servicesListView.learnMore" />
      </a>
    </LinkWrap>
  </>
)

export default TooltipContent
