import { createLogic } from 'redux-logic'

import { UPDATE_PENDING_CLIENT } from 'state/concepts/booking/types'
import { workspaceIdSelector } from 'state/concepts/widget/selectors'
import { bookingSelector } from 'state/concepts/booking/selectors'
import { dataApiRequest, dataApiSuccess, dataApiFailure } from 'state/data/actions'
import { updatePendingClientEndpoint } from '../endpoints'

const updatePendingClientOperation = createLogic({
  type: UPDATE_PENDING_CLIENT,

  async process({ action: { firstName, lastName, email }, httpClient, getState }, dispatch, done) {
    const state = getState()
    const workspaceId = workspaceIdSelector(state)
    const { id } = bookingSelector(state)
    const { endpoint, url } = updatePendingClientEndpoint(workspaceId, id)

    dispatch(dataApiRequest({ endpoint }))

    try {
      const params = {
        first_name: firstName,
        last_name: lastName,
        email,
      }

      await httpClient.put(url, params)

      dispatch(dataApiSuccess({ endpoint }))
    } catch (error) {
      dispatch(dataApiFailure({ endpoint }))
    }
    done()
  },
})

export default updatePendingClientOperation
