export default {
  mixed: {
    required: { id: 'yup.mixed.required' },
  },

  string: {
    email: { id: 'yup.string.email' },
    max: ({ max }) => ({ id: 'yup.string.max', values: { max } }),
  },
}
