import enLocale from 'locales/en'
import * as types from './types'

const initialState = {
  ...enLocale,
}

const intlReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_LOCALES:
      return { ...state, messages: action.messages }
    default:
      return state
  }
}

export default intlReducer
