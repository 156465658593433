import styled from 'styled-components'

const Overlay = styled.div`
  position: absolute;
  top: 0;
  ${({ theme }) => theme.settings.position}: 0;
  z-index: 5;
  width: 100%;
  max-width: 100%;
  height: 100%;
  background-color: rgba(${({ theme }) => theme.colors.overlayColor}, 0.2);
`

export default Overlay
