import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import isPresent from 'utils/isPresent'
import SvgIcon from 'views/shared/Icons'

const Avatar = ({ avatarUrls, isSmall, size, offsetRight, className }) => (
  <Avatar.Wrapper className={className} size={size} offsetRight={offsetRight}>
    {isPresent(avatarUrls) ? (
      <Avatar.Image src={isSmall ? avatarUrls.small : avatarUrls.original} />
    ) : (
      <Avatar.Empty className="avatar-empty-icon-wrap">
        <SvgIcon className="avatar-empty-icon" iconName="user" size={size / 2} color="black500" />
      </Avatar.Empty>
    )}
  </Avatar.Wrapper>
)

Avatar.defaultProps = {
  avatarUrls: {},
  isSmall: false,
  offsetRight: 16,
  className: '',
}

Avatar.propTypes = {
  avatarUrls: PropTypes.shape(),
  isSmall: PropTypes.bool,
  offsetRight: PropTypes.number,
  size: PropTypes.number.isRequired,
  className: PropTypes.string,
}

Avatar.Wrapper = styled.div`
  flex-shrink: 0;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  margin-right: ${({ offsetRight }) => offsetRight}px;
  border-radius: 50%;
  overflow: hidden;
`

Avatar.Image = styled.img`
  width: 100%;
  max-height: none;
`

Avatar.Empty = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  border: 1px solid ${({ theme }) => theme.colors.black300};
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.black200};
`

export default Avatar
