import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

import SvgIcon from 'views/shared/Icons'

const DocumentWrap = styled.section`
  display: flex;
  border-top: 1px solid ${({ theme }) => theme.colors.black300};
  padding: 14px 20px;

  ${media.tablet`
    padding: 22px 28px;
  `}

  ${media.lg`
    padding: 22px 36px;
  `}
`

const DocumentIcon = styled(SvgIcon)`
  flex-shrink: 0;
  margin-right: 16px;
`

const DocumentText = styled.p`
  line-height: ${20 / 14};
  color: ${({ theme }) => theme.colors.black1000};
  font-weight: 600;
  word-wrap: break-word;
  word-break: break-all;
`

export { DocumentWrap, DocumentIcon, DocumentText }
