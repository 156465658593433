import React from 'react'
import PropTypes from 'prop-types'
import { is, cond, always } from 'ramda'
import { FormattedMessage } from 'react-intl'
import { getIn } from 'formik'

import FormattedOrRawMessage from 'views/shared/FormattedOrRawMessage'
import Select from 'views/shared/Select'
import * as S from 'views/shared/InputField/styled'

const SelectFieldComponent = ({
  placeholder,
  id,
  label,
  message,
  field,
  form: { touched, errors, status },
  onChange,
  onClear,
  onOpen,
  intl,
  ...props
}) => {
  const fieldTouched = getIn(touched, field.name)
  const fieldErrors = getIn(errors, field.name)
  const fieldStatus = getIn(status, field.name)
  const condition = cond([
    [() => fieldTouched && fieldErrors, always('error')],
    [() => fieldTouched && fieldStatus, always('info')],
  ])()

  return (
    <S.Wrapper label={label}>
      {label && (
        <S.Label htmlFor={id}>
          <FormattedMessage {...label} />
        </S.Label>
      )}
      <S.FieldWrapper>
        <Select
          id={id}
          value={field.value}
          name={field.name}
          condition={condition}
          placeholder={is(Object, placeholder) ? intl.formatMessage(placeholder) : placeholder}
          onChange={onChange}
          onInputClear={onClear}
          onOpen={onOpen}
          noResultsText="No data found"
          {...props}
        />
      </S.FieldWrapper>
      {fieldTouched && fieldErrors && (
        <S.Message condition={condition}>
          <S.MessageIcon condition={condition} iconName="close" size={14} />
          <FormattedOrRawMessage message={fieldErrors} />
        </S.Message>
      )}
    </S.Wrapper>
  )
}

SelectFieldComponent.defaultProps = {
  label: null,
  message: null,
  placeholder: null,
}

SelectFieldComponent.propTypes = {
  label: PropTypes.shape({
    id: PropTypes.string,
    values: PropTypes.shape(),
  }),
  id: PropTypes.string.isRequired,
  field: PropTypes.shape().isRequired,
  form: PropTypes.shape({
    touched: PropTypes.shape().isRequired,
    errors: PropTypes.shape().isRequired,
    status: PropTypes.shape(),
  }).isRequired,
  message: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
  intl: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
}

export default SelectFieldComponent
