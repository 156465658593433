import { createSelector } from 'reselect'
import { path } from 'ramda'

import { fetchAvailabilityEndpoint } from 'state/concepts/availability/endpoints'
import { workspaceIdSelector } from 'state/concepts/widget/selectors'
import { serviceIdSelector } from 'state/concepts/services/selectors'
import { loadingSelector } from 'state/data/selectors'

export const dateSelector = path(['availability', 'date'])
export const timeSlotsSelector = path(['availability', 'timeSlots'])

export const availabilityLoadingSelector = createSelector(
  workspaceIdSelector,
  serviceIdSelector,
  state => state,
  (workspaceId, serviceId, state) => loadingSelector(state, fetchAvailabilityEndpoint(workspaceId, serviceId).endpoint),
)
