import styled, { css } from 'styled-components'
import { Container, Row, Col, media } from 'styled-bootstrap-grid'

const ScrollWrapper = styled.div`
  flex-grow: 1;
  overflow-y: auto;
`

const Wrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: ${({ theme }) => theme.sizes.containerMaxWidth};
  padding-top: ${({ theme }) => theme.sizes.servicesListTopOffset};
  padding-bottom: 8px;

  ${media.tablet`
    padding-top: ${({ theme }) => theme.sizes.servicesListTopOffsetTablet};
    padding-right: 36px;
    padding-left: 36px;
  `}

  ${media.desktop`
    padding-top: ${({ theme }) => theme.sizes.servicesListTopOffsetDesktop};
  `}
`

const Service = styled.div`
  margin-bottom: 20px;
`

const Text = styled.span`
  color: ${({ theme }) => theme.colors.black700};
  line-height: ${20 / 14};
`

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-left: -16px;
  margin-right: -16px;

  ${media.tablet`
    margin-left: 0;
    margin-right: 0;
  `}
`

const StyledCol = styled(Col)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

const ScrolledBlock = styled.div`
  ${media.tablet`
    position: sticky;
    top: 4px;
  `}
`

const SearchWrap = styled.div`
  margin-right: -16px;
  margin-bottom: 8px;
  margin-left: -16px;
  border-radius: 2px;
  padding: 12px 16px;
  background-color: white;

  ${media.tablet`
    border-radius: 2px;
    background-color: transparent;
    outline: 1px solid ${({ theme }) => theme.colors.black400};
    margin-right: 0;
    margin-bottom: 16px;
    margin-left: 0;
    padding: 0;

    &:focus,
    &:focus-within {
      outline-width: 2px;
      outline-color: ${({ theme }) => theme.settings.color};
    }
  `}

  .main-search {
    margin-bottom: 0;
    border-radius: 2px;
    outline: 1px solid ${({ theme }) => theme.colors.black400};

    &:focus,
    &:focus-within {
      outline-width: 2px;
      outline-color: ${({ theme }) => theme.settings.color};
    }

    ${media.tablet`
      outline: none;
      margin-bottom: 0;
      border-width: 0;
    `}
  }
`

const ListTitle = styled.div`
  width: 100%;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 600;
  line-height: ${20 / 14};
  color: ${({ theme }) => theme.colors.black1000};
`

const StyledRowShadow = styled(Row)`
  display: block;
  flex-grow: unset;
  margin-right: -12px;
  margin-left: -12px;

  ${media.desktop`
    margin-right: -12px;
    margin-left: -12px;
  `}

  ${({ isScrolled }) =>
    isScrolled &&
    css`
      position: relative;
      z-index: 1;
    `}
`

export {
  ScrollWrapper,
  Wrapper,
  Service,
  Text,
  StyledCol,
  StyledRowShadow,
  ListWrapper,
  ListTitle,
  SearchWrap,
  ScrolledBlock,
}
