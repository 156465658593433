import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

import SvgIcon from 'views/shared/Icons'

const Wrapper = styled.div`
  position: relative;
  flex-grow: 1;
  margin-bottom: 16px;

  ${media.tablet`
    margin-bottom: 0;
  `}
`

const Input = styled.input`
  width: 100%;
  background-color: white;
  border-width: 0;
  border-style: solid;
  font-size: 14px;
  padding: 12px 23px 12px 40px;
  line-height: ${20 / 14};
  color: ${({ theme }) => theme.colors.black1000};
  transition: border-color 0.3s ease 0s;
  /* stylelint-disable */
  -webkit-appearance: none;

  &::placeholder {
    color: ${({ theme }) => theme.colors.black700};
  }

  ${media.tablet`
    padding: 13px 40px 13px;
  `}
`

const LeftIcon = styled(SvgIcon)`
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
  fill: ${({ theme }) => theme.colors.black500};
`

const RightButton = styled.button`
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  border: none;
  background-color: transparent;
`

export { Wrapper, Input, LeftIcon, RightButton }
