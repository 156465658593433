import React from 'react'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styled from 'styled-components'
import { rgba } from 'polished'
import { useToggle } from 'react-use'
import { useSelector } from 'react-redux'

import SvgIcon from 'views/shared/Icons'
import profileName from 'utils/profileName'
import Avatar from 'views/shared/Avatar'
import useDispatchAction from 'tools/hooks/useDispatchAction'
import { logout as logoutAction } from 'state/concepts/session/actions'
import getPortalUrl from 'utils/getPortalUrl'
import { widgetSelector } from 'state/concepts/widget/selectors'

const UserInfo = ({ currentClient: { email, avatarUrls }, currentClient }) => {
  const [open, toggleOpen] = useToggle(false)
  const logout = useDispatchAction(logoutAction)
  const {
    workspace: { url, uniqCode },
  } = useSelector(widgetSelector)

  return (
    <UserInfo.AccountWrap data-cy="dropdown-button" onClick={toggleOpen} className={classnames({ open })}>
      <Avatar avatarUrls={avatarUrls} size={32} isSmall />
      <UserInfo.AccountName>{profileName(currentClient)}</UserInfo.AccountName>
      <UserInfo.ArrowIcon className="icon-arrow" iconName="iconDownArrow" size={10} color="black400" />
      {open && (
        <UserInfo.DropdownListWrap data-cy="dropdown-list-wrap">
          <UserInfo.DropdownHeader>
            <Avatar avatarUrls={avatarUrls} size={32} isSmall />
            <UserInfo.ClientAccountInfo>
              <UserInfo.AccountName data-cy="user-account-name">{profileName(currentClient)}</UserInfo.AccountName>
              <UserInfo.AccountEmail data-cy="user-account-email">{email}</UserInfo.AccountEmail>
            </UserInfo.ClientAccountInfo>
          </UserInfo.DropdownHeader>
          <UserInfo.DropdownList>
            <UserInfo.DropdownItem>
              <UserInfo.DropdownItemLink target="_blank" href={`${getPortalUrl(url)}/client/${uniqCode}/bookings`}>
                <UserInfo.LinkIcon iconName="portal" size={20} color="black150" />
                <FormattedMessage id="userAccount.clientPortal" />
              </UserInfo.DropdownItemLink>
            </UserInfo.DropdownItem>
            <UserInfo.Divider />
            <UserInfo.DropdownItem>
              <UserInfo.DropdownFooter data-cy="logout-button" onClick={logout}>
                <FormattedMessage id="userAccount.logout" />
              </UserInfo.DropdownFooter>
            </UserInfo.DropdownItem>
          </UserInfo.DropdownList>
        </UserInfo.DropdownListWrap>
      )}
    </UserInfo.AccountWrap>
  )
}

UserInfo.propTypes = {
  currentClient: PropTypes.shape({
    email: PropTypes.string.isRequired,
    avatarUrls: PropTypes.shape().isRequired,
  }).isRequired,
}

UserInfo.AccountWrap = styled.div`
  position: absolute;
  right: 24px;
  top: 50%;
  z-index: 2;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border-radius: 8px;
  transition: background-color 0.18s cubic-bezier(0.4, 0, 0.6, 1);
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => rgba(theme.settings.color, 0.1)};
  }

  &.open {
    background-color: ${({ theme }) => rgba(theme.settings.color, 0.1)};

    .icon-arrow {
      fill: ${({ theme }) => theme.settings.color};
      transform: rotate(180deg);
    }
  }
`

UserInfo.ArrowIcon = styled(SvgIcon)`
  margin-left: 12px;
  color: ${({ theme }) => theme.settings.color};
`

UserInfo.AccountName = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`

UserInfo.DropdownListWrap = styled.div`
  position: absolute;
  top: calc(100% + 4px);
  right: 0;
  box-shadow: 0 10px 20px rgba(${({ theme }) => theme.colors.boxShadowColor}, 0.1);
  border-radius: 4px;
  background: white;
  width: 280px;
  cursor: default;
`

UserInfo.DropdownHeader = styled.div`
  padding: 16px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.black300};
`

UserInfo.ClientAccountInfo = styled.div``

UserInfo.DropdownFooter = styled.button`
  display: block;
  width: 100%;
  color: ${({ theme }) => theme.colors.red1000};
  text-align: left;
`

UserInfo.AccountEmail = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.black800};
`

UserInfo.DropdownList = styled.div`
  padding: 8px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.black300};
`

UserInfo.DropdownItem = styled.div`
  padding: 10px 16px;

  &:hover {
    background-color: ${({ theme }) => rgba(theme.settings.color, 0.1)};
  }
`

UserInfo.DropdownItemLink = styled.a`
  display: block;
  color: ${({ theme }) => theme.colors.black900};
`

UserInfo.LinkIcon = styled(SvgIcon)`
  margin-right: 10px;
`

UserInfo.Divider = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.black300};
`

export default UserInfo
