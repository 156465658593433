import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

const Wrapper = styled.div`
  color: ${({ theme }) => theme.colors.black700};
  margin-bottom: 8px;

  ${media.tablet`
    display: none;
  `}
`

export default Wrapper
