import * as types from './types'

export const setIsReset = isReset => ({
  type: types.SET_IS_RESET,
  isReset,
})

export const setIsWidgetLocked = isLocked => ({
  type: types.SET_IS_LOCKED,
  isLocked,
})

export const setIsPreviewMode = isPreviewMode => ({
  type: types.SET_IS_PREVIEW_MODE,
  isPreviewMode,
})

export const setWidget = widgetId => ({
  type: types.SET_WIDGET,
  widgetId,
})

export const setWorkspace = workspaceId => ({
  type: types.SET_WORKSPACE,
  workspaceId,
})

export const fetchWidgetDetails = ({ widgetSettings, history, widgetId, workspaceId, serviceId, providerId }) => ({
  type: types.FETCH_WIDGET_DETAILS,
  widgetSettings,
  history,
  widgetId,
  workspaceId,
  serviceId,
  providerId,
})

export const setWidgetPreviewSettings = settings => ({
  type: types.SET_WIDGET_PREVIEW_SETTINGS,
  settings,
})

export const resolveWidgetState = ({ navigateTo, widget }) => ({
  type: types.RESOLVE_WIDGET_STATE,
  navigateTo,
  widget,
})

export const setWidgetFilters = filters => ({
  type: types.SET_WIDGET_FILTERS,
  filters,
})

export const resetWidgetFilters = () => ({
  type: types.RESET_WIDGET_FILTERS,
})
