import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js'
import { prop } from 'ramda'
import { Field } from 'formik'

import { PAYMENT_METHODS } from 'constants/payments'
import countries from 'constants/countries'
import InputField from 'views/shared/InputField'
import PaymentField from 'views/shared/PaymentField'
import SelectField from 'views/shared/SelectField'
import RadioButtonRound from 'views/shared/RadioButtonRound'
import Alert from 'views/shared/Alert'
import * as S from './styled'

const StripePaymentForm = ({
  stripeError,
  onAlertClose,
  onStripeChange,
  onStripeBlur,
  stripeForm,
  onPaymentMethodChange,
  paymentMethod,
  isStripeAndPayPalConnected,
  isPayPalConnected,
  isStripeConnected,
}) => (
  <S.FormBlock>
    <S.FormBlockHeader>
      <S.FormTitle className="mb-0">
        <FormattedMessage id="reviewBookingView.paymentDetails" />
      </S.FormTitle>
    </S.FormBlockHeader>
    <S.FormDivider />

    {isStripeConnected && (
      <>
        <S.CollapseHeader>
          <S.HeaderWrap>
            {isStripeAndPayPalConnected ? (
              <RadioButtonRound
                id="stripePaymentMethod"
                name="paymentMethod"
                value={PAYMENT_METHODS.stripe}
                text={{ id: 'stripe.title' }}
                onChange={onPaymentMethodChange}
                checked={paymentMethod === PAYMENT_METHODS.stripe}
              />
            ) : (
              <S.PaymentTitle>
                <FormattedMessage id="stripe.title" />
              </S.PaymentTitle>
            )}
            <S.ImagesWrap>
              <S.LogoImg src={`${ASSET_CDN}/assets/images/payments/visa_logo.svg`} />
              <S.LogoImg src={`${ASSET_CDN}/assets/images/payments/mastercard_logo.svg`} />
              <S.LogoImg src={`${ASSET_CDN}/assets/images/payments/maestro_logo.svg`} />
              <S.LogoImg src={`${ASSET_CDN}/assets/images/payments/american_express_logo.svg`} />
              <S.LogoImg src={`${ASSET_CDN}/assets/images/payments/diners_logo.svg`} />
              <S.LogoImg src={`${ASSET_CDN}/assets/images/payments/cartes_bancaires_logo.svg`} />
              <S.LogoImg src={`${ASSET_CDN}/assets/images/payments/discover_logo.svg`} />
            </S.ImagesWrap>
          </S.HeaderWrap>
          <S.CardHint single={!isStripeAndPayPalConnected} expanded={paymentMethod === PAYMENT_METHODS.stripe}>
            <FormattedMessage id="stripe.hint" />
          </S.CardHint>
          {stripeError && (
            <Alert
              view="error"
              iconClass="close"
              message={prop('message', stripeError)}
              onClose={onAlertClose}
              canClose
              isFullWidth
            />
          )}
        </S.CollapseHeader>
        <S.CollapseBody single={!isStripeAndPayPalConnected} expanded={paymentMethod === PAYMENT_METHODS.stripe}>
          <S.FormRow>
            <S.FormColDouble>
              <PaymentField
                id="cardNumber"
                component={CardNumberElement}
                label={{ id: 'reviewBookingView.payments.cardNumber' }}
                placeholder={{ id: 'reviewBookingView.placeholder.cardNumber' }}
                onChange={onStripeChange}
                onBlur={onStripeBlur}
                form={stripeForm}
              />
            </S.FormColDouble>
            <S.FormColQuarter>
              <PaymentField
                id="cardExpiry"
                component={CardExpiryElement}
                label={{ id: 'reviewBookingView.payments.expirationDate' }}
                placeholder={{ id: 'reviewBookingView.placeholder.expirationDate' }}
                onChange={onStripeChange}
                onBlur={onStripeBlur}
                form={stripeForm}
              />
            </S.FormColQuarter>
            <S.FormColQuarter>
              <PaymentField
                id="cardCvc"
                component={CardCvcElement}
                label={{ id: 'reviewBookingView.payments.cvv' }}
                placeholder={{ id: 'reviewBookingView.placeholder.cvv' }}
                onChange={onStripeChange}
                onBlur={onStripeBlur}
                form={stripeForm}
              />
            </S.FormColQuarter>
          </S.FormRow>
          <S.FormDivider className="mt-8 mb-24" />
          <S.FormRow>
            <S.FormCol>
              <Field
                id="address"
                name="address"
                type="text"
                component={InputField}
                label={{ id: 'reviewBookingView.client.address' }}
              />
            </S.FormCol>
          </S.FormRow>
          <S.FormRowAddress>
            <S.FormColDouble>
              <Field
                id="city"
                name="city"
                type="text"
                component={InputField}
                label={{ id: 'reviewBookingView.client.city' }}
              />
            </S.FormColDouble>
            <S.FormColIndex>
              <Field
                id="index"
                name="index"
                type="text"
                component={InputField}
                label={{ id: 'reviewBookingView.client.zipCode' }}
              />
            </S.FormColIndex>
          </S.FormRowAddress>
          <S.FormRow>
            <S.FormColDouble>
              <Field
                id="country"
                name="country"
                type="text"
                component={SelectField}
                label={{ id: 'reviewBookingView.client.country' }}
                placeholder={{ id: 'reviewBookingView.client.selectCountry' }}
                options={countries.en}
                searchable
              />
            </S.FormColDouble>
            <S.FormColDouble>
              <Field
                id="state"
                name="state"
                type="text"
                component={InputField}
                label={{ id: 'reviewBookingView.client.state' }}
              />
            </S.FormColDouble>
          </S.FormRow>
        </S.CollapseBody>
      </>
    )}

    {isPayPalConnected && (
      <>
        <S.CollapseHeader>
          <S.HeaderWrap>
            {isStripeAndPayPalConnected ? (
              <RadioButtonRound
                id="paypalPaymentMethod"
                value={PAYMENT_METHODS.paypal}
                name="paymentMethod"
                text={{ id: 'paypal.title' }}
                onChange={onPaymentMethodChange}
                checked={paymentMethod === PAYMENT_METHODS.paypal}
              />
            ) : (
              <S.PaymentTitle>
                <FormattedMessage id="paypal.title" />
              </S.PaymentTitle>
            )}
            <S.ImagesWrap>
              <S.LogoPaypal src={`${ASSET_CDN}/assets/images/payments/paypal.svg`} />
            </S.ImagesWrap>
          </S.HeaderWrap>
        </S.CollapseHeader>
        <S.CollapseBodyPaypal expanded={paymentMethod === PAYMENT_METHODS.paypal}>
          <S.PayPalHint>
            <FormattedMessage id="paypal.hint" />
          </S.PayPalHint>
        </S.CollapseBodyPaypal>
      </>
    )}
  </S.FormBlock>
)

StripePaymentForm.defaultProps = {
  stripeError: null,
}

StripePaymentForm.propTypes = {
  onAlertClose: PropTypes.func.isRequired,
  onStripeChange: PropTypes.func.isRequired,
  onStripeBlur: PropTypes.func.isRequired,
  stripeError: PropTypes.shape(),
  stripeForm: PropTypes.shape().isRequired,
  onPaymentMethodChange: PropTypes.func.isRequired,
  paymentMethod: PropTypes.string.isRequired,
  isStripeAndPayPalConnected: PropTypes.bool.isRequired,
  isPayPalConnected: PropTypes.bool.isRequired,
  isStripeConnected: PropTypes.bool.isRequired,
}

export default StripePaymentForm
