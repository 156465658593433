import React from 'react'

import PreviewComponent from './component'

class Preview extends React.PureComponent {
  state = {
    isExpanded: false,
    settings: {},
  }

  async componentDidMount() {
    if (!window.ExpertBox) {
      window.ExpertBox = {}
    }

    window.ExpertBox.boot = this.handleOpenWidget
    window.ExpertBox.close = this.handleCloseWidget
  }

  componentWillUnmount = () => {
    delete window.ExpertBox.boot
    delete window.ExpertBox.close
  }

  handleOpenWidget = (settings, isExpanded = false) => {
    this.setState({ settings, isExpanded })
  }

  handleCloseWidget = () => {
    this.setState({ isExpanded: false })
  }

  render = () => <PreviewComponent {...this.state} handleCloseWidget={this.handleCloseWidget} />
}

export default Preview
