import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { Col } from 'styled-bootstrap-grid'
import { keys, prop } from 'ramda'
import { FormattedMessage } from 'react-intl'

import { formattedMonthTime } from 'utils/dateTime'
import useScrollTracking from 'tools/hooks/useScrollTracking'
import Theme from 'constants/Theme'
import isPresent from 'utils/isPresent'
import MainContainer from 'views/shared/MainContainer'
import Header from 'views/shared/Header'
import DesktopPageTitle from 'views/shared/DesktopPageTitle'
import MobilePageTitle from 'views/shared/MobilePageTitle'
import DatePicker from 'views/shared/DatePicker'
import SearchEmptySvg from 'views/shared/SvgIcons/SearchEmpty'
import Stepper from 'views/shared/Stepper'
import PriceBar from 'views/shared/PriceBar'
import MobileStepperCounter from 'views/shared/MobileStepperCounter'
import Button from 'views/shared/Button'
import DayPartCard from './DayPartCard'
import TimeslotsMobile from './TimeslotsMobile'
import TimezoneDropdown from './TimezoneDropdown'
import * as S from './styled'

const TimeSlotsViewComponent = ({
  date,
  timeSlots,
  isLoading,
  onDateChange,
  minDate,
  makeSelectHandler,
  onPrevNextClick,
  setTileDisable,
  timezones,
  timezoneLabel,
  onTimezoneChange,
  stepNumber,
  isWithBackButton,
  onJoinWaitlist,
  widget,
}) => {
  const scrollRef = useRef(null)
  const isScrolled = useScrollTracking(scrollRef.current)

  return (
    <MainContainer bgColor={Theme.colors.black200}>
      <Header withBackButton={isWithBackButton} />
      <S.ScrollWrapper>
        <S.Wrapper fluid>
          <DesktopPageTitle text={{ id: 'timeSlotsView.title' }} />
          <S.StyledRow>
            <S.StyledLeftCol md={4}>
              <S.ScrolledBlock>
                <Stepper />
                <PriceBar />
                <MobileStepperCounter number={stepNumber} />
                <MobilePageTitle text={{ id: 'timeSlotsView.title' }} />
              </S.ScrolledBlock>
            </S.StyledLeftCol>
            <S.StyledCol ref={scrollRef} isScrolled={isScrolled} isLoading={isLoading} md={8}>
              {isScrolled && <S.WrapperShadow />}
              <S.DateTimeCard>
                <DatePicker
                  date={date}
                  minDate={minDate}
                  onChange={onDateChange}
                  setTileDisable={setTileDisable}
                  onPrevNextClick={onPrevNextClick}
                  isLoading={isLoading}
                />
                <S.AvailabilityOnDate data-cy="widget-availability-on-date">
                  <FormattedMessage id="timeSlotsView.availabilityOnDate" values={{ date: formattedMonthTime(date) }} />
                </S.AvailabilityOnDate>

                <TimezoneDropdown options={timezones} value={timezoneLabel} onTimezoneChange={onTimezoneChange} />

                {isPresent(timeSlots) && (
                  <>
                    <S.TimeslotsMobileWrapper>
                      <TimeslotsMobile
                        timeSlots={timeSlots}
                        tabs={keys(timeSlots)}
                        makeSelectHandler={makeSelectHandler}
                      />
                    </S.TimeslotsMobileWrapper>

                    <S.TimeslotsTabletWrapper>
                      {keys(timeSlots).map(part => (
                        <Col key={part} sm={4}>
                          <DayPartCard
                            isTitleShown
                            slots={timeSlots[part]}
                            title={{ id: `timeSlotsView.${part}` }}
                            handleSelect={makeSelectHandler}
                          />
                        </Col>
                      ))}
                    </S.TimeslotsTabletWrapper>
                  </>
                )}

                {!isPresent(timeSlots) && (
                  <S.EmptyWrapper>
                    <S.ImageWrapper>
                      <SearchEmptySvg />
                    </S.ImageWrapper>
                    <S.EmptyDescription data-cy="no-available-time-slots">
                      <FormattedMessage id="timeSlotsView.noAvailableSlots" />
                    </S.EmptyDescription>
                  </S.EmptyWrapper>
                )}
                {prop('waitlistEnabled', widget) && (
                  <>
                    <S.WaitlistTitle>
                      <FormattedMessage id="availabilityPreferences.description" />
                    </S.WaitlistTitle>
                    <Button
                      minWidth={113}
                      text={{ id: 'availabilityPreferences.joinWaitlist' }}
                      onClick={onJoinWaitlist}
                    />
                  </>
                )}
              </S.DateTimeCard>
            </S.StyledCol>
          </S.StyledRow>
        </S.Wrapper>
      </S.ScrollWrapper>
    </MainContainer>
  )
}

TimeSlotsViewComponent.defaultProps = {
  isLoading: undefined,
  timezones: [],
  timeSlots: null,
}

TimeSlotsViewComponent.propTypes = {
  isLoading: PropTypes.bool,
  date: PropTypes.shape().isRequired,
  minDate: PropTypes.shape().isRequired,
  onDateChange: PropTypes.func.isRequired,
  setTileDisable: PropTypes.func.isRequired,
  onPrevNextClick: PropTypes.func.isRequired,
  onTimezoneChange: PropTypes.func.isRequired,
  makeSelectHandler: PropTypes.func.isRequired,
  isWithBackButton: PropTypes.bool.isRequired,
  timezones: PropTypes.arrayOf(PropTypes.shape()),
  timezoneLabel: PropTypes.string.isRequired,
  timeSlots: PropTypes.shape(),
  widget: PropTypes.shape().isRequired,
  stepNumber: PropTypes.number.isRequired,
  onJoinWaitlist: PropTypes.func.isRequired,
}

export default TimeSlotsViewComponent
