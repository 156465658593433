import { combineReducers } from 'redux'
import { values } from 'ramda'

import { STEP_TYPE_PROPERTIES } from 'constants'
import { UPDATE_STEPS, SET_STEPS_ORDER } from './types'

const stepsHistory = (state = { 1: null, 2: null, 3: null, 4: null, 5: null }, action) => {
  switch (action.type) {
    case UPDATE_STEPS:
      return { ...state, ...action.steps }
    default:
      return state
  }
}

const stepsOrder = (state = values(STEP_TYPE_PROPERTIES), action) => {
  switch (action.type) {
    case SET_STEPS_ORDER:
      return action.steps
    default:
      return state
  }
}

export default combineReducers({
  stepsHistory,
  stepsOrder,
})
