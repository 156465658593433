import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Field, Form } from 'formik'

import { PAYMENT_METHODS } from 'constants/payments'
import cancellationPhrase from 'utils/cancellationPhrase'
import CheckboxField from 'views/shared/CheckboxField'
import ClientInformationForm from 'views/shared/ClientInformationForm'
import Timer from '../../Timer'
import Agreements from './Agreements'
import PaymentForm from './PaymentForm'
import PaypalButton from './PaypalButton'
import * as S from './styled'

const BookingFormComponent = ({
  stripeError,
  stripeForm,
  onSubmit,
  onAlertClose,
  onStripeChange,
  onStripeBlur,
  isSubmitDisabled,
  isSubmitting,
  reservationTime,
  isWithPayment,
  bookingSettings,
  buttonText,
  paypal,
  onPaymentMethodChange,
  paymentMethod,
  booking,
  isStripeAndPayPalConnected,
  isPayPalConnected,
  isStripeConnected,
  onValidateEmail,
  isPreviewMode,
}) => (
  <Form>
    <ClientInformationForm />
    {isWithPayment && (
      <PaymentForm
        stripeError={stripeError}
        onAlertClose={onAlertClose}
        onStripeChange={onStripeChange}
        onStripeBlur={onStripeBlur}
        stripeForm={stripeForm}
        onPaymentMethodChange={onPaymentMethodChange}
        paymentMethod={paymentMethod}
        isStripeAndPayPalConnected={isStripeAndPayPalConnected}
        isPayPalConnected={isPayPalConnected}
        isStripeConnected={isStripeConnected}
      />
    )}
    <S.Agreements>
      <Field
        id="agreements"
        name="agreements"
        component={CheckboxField}
        label={<Agreements />}
        dataCy="agreements-checkbox-field"
      />
    </S.Agreements>
    {bookingSettings && (
      <S.CancellationReason>
        <FormattedMessage {...cancellationPhrase(bookingSettings, isWithPayment)} />
      </S.CancellationReason>
    )}
    <S.ButtonWrapper>
      <S.ButtonContainer>
        {reservationTime && <Timer time={reservationTime} />}
        {paymentMethod === PAYMENT_METHODS.paypal && paypal && !isPreviewMode ? (
          <PaypalButton booking={booking} paypal={paypal} onSubmit={onSubmit} onValidateEmail={onValidateEmail} />
        ) : (
          <S.SubmitButton
            minWidth={160}
            type="submit"
            text={buttonText}
            isSubmitting={isSubmitting}
            disabled={isSubmitDisabled}
            onClick={onSubmit}
          />
        )}
      </S.ButtonContainer>
    </S.ButtonWrapper>
  </Form>
)

BookingFormComponent.defaultProps = {
  stripeError: null,
  reservationTime: null,
  isSubmitDisabled: false,
  isWithPayment: false,
  paypal: null,
}

BookingFormComponent.propTypes = {
  isSubmitDisabled: PropTypes.bool,
  reservationTime: PropTypes.string,
  stripeError: PropTypes.shape(),
  stripeForm: PropTypes.shape().isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onAlertClose: PropTypes.func.isRequired,
  onStripeChange: PropTypes.func.isRequired,
  onStripeBlur: PropTypes.func.isRequired,
  isWithPayment: PropTypes.bool,
  bookingSettings: PropTypes.shape().isRequired,
  buttonText: PropTypes.shape().isRequired,
  onPaymentMethodChange: PropTypes.func.isRequired,
  paymentMethod: PropTypes.string.isRequired,
  paypal: PropTypes.shape(),
  booking: PropTypes.shape().isRequired,
  isStripeAndPayPalConnected: PropTypes.bool.isRequired,
  isPayPalConnected: PropTypes.bool.isRequired,
  isStripeConnected: PropTypes.bool.isRequired,
  onValidateEmail: PropTypes.func.isRequired,
  isPreviewMode: PropTypes.bool.isRequired,
}

export default BookingFormComponent
