import { Duration } from 'luxon'
import { pipe, filter, toPairs, flatten } from 'ramda'

const SECONDS_PER_HOUR = 3600
const SECONDS_PER_MINUTE = 60

export const hoursFromSeconds = seconds => Math.floor(seconds / SECONDS_PER_HOUR)

export const minutesFromSeconds = seconds =>
  Math.floor((seconds - hoursFromSeconds(seconds) * SECONDS_PER_HOUR) / SECONDS_PER_MINUTE)

export const formatDuration = (seconds, { omitZeroes } = { omitZeroes: true }) => {
  const hours = hoursFromSeconds(seconds)
  const minutes = minutesFromSeconds(seconds)

  const hideHours = omitZeroes && hours === 0
  const hideMinutes = omitZeroes && minutes === 0

  const formattedHours = hideHours ? '' : `${hours}h`
  const formattedMinutes = hideMinutes ? '' : `${minutes}m`
  const separator = hideHours || hideMinutes ? '' : ' '

  return `${formattedHours}${separator}${formattedMinutes}`
}

export const secondsToValueWithUnits = seconds => {
  const units = Duration.fromObject({ seconds }).shiftTo('minutes', 'hours', 'days').toObject()
  const [unit = 'minutes', value = 0] = pipe(
    filter(item => item !== 0),
    toPairs,
    flatten,
  )(units)

  return { value, unit }
}

export const formatDistance = (time, otherTime) =>
  time.diff(otherTime).toFormat("m' min' s' sec'").replace('0 min ', '')
