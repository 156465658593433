import React from 'react'
import { FormattedMessage } from 'react-intl'

import { TWILIO_MAX_PARTICIPANTS } from 'constants'
import * as S from './styled'

const AttendeesCard = () => (
  <S.Wrapper data-cy="info-block">
    <S.Title data-cy="info-block-text">
      <S.AttendeesIcon iconName="userAdd" size={21} />
      <FormattedMessage id="confirmationView.attendeesAllowed" values={{ count: TWILIO_MAX_PARTICIPANTS }} />
    </S.Title>
    <S.Description data-cy="info-block-description">
      <FormattedMessage id="confirmationView.youWillBeAble" />
    </S.Description>
  </S.Wrapper>
)

export default AttendeesCard
