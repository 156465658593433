import React from 'react'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'

import Wrapper from './styled'

const MobileStepperCounter = ({ stepNumber, totalSteps }) => (
  <Wrapper>
    <FormattedMessage id="stepper.step" values={{ stepNumber, totalSteps }} />
  </Wrapper>
)

MobileStepperCounter.propTypes = {
  stepNumber: PropTypes.number.isRequired,
  totalSteps: PropTypes.number.isRequired,
}

export default MobileStepperCounter
