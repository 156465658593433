import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

const Duration = styled.div`
  display: ${({ visibleMobile, isStepper }) => {
    if (isStepper || visibleMobile) {
      return 'flex'
    }
    return 'none'
  }};
  align-items: center;
  flex-basis: 100%;
  margin-bottom: ${({ isStepper }) => (isStepper ? '0' : '12px')};
  color: ${({ theme }) => theme.colors.black700};
  line-height: ${20 / 14};

  ${media.desktop`
  display:
    ${({ visibleMobile, isStepper }) => {
      if (isStepper || !visibleMobile) {
        return 'block'
      }
      return 'none'
    }};
  `}
`

// eslint-disable-next-line import/prefer-default-export
export { Duration }
