import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

import { STEP_TYPE_PROPERTIES } from 'constants'
import Step from '../Step'

const ReviewStep = ({ steps }) => (
  <Step iconName="info" type={STEP_TYPE_PROPERTIES.review} steps={steps}>
    <ReviewStep.StepName>
      <FormattedMessage id="stepper.yourDetails" />
    </ReviewStep.StepName>
  </Step>
)

ReviewStep.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
}

ReviewStep.StepName = styled.p`
  display: none;
  margin-top: 6px;
  margin-bottom: 6px;
  font-family: ${({ theme }) => theme.fonts.interTight};
  font-size: 16px;
  line-height: ${24 / 16};
  font-weight: 600;
  color: ${({ theme }) => theme.colors.black700};

  ${media.tablet`
    display: block;
  `}
`

export default ReviewStep
