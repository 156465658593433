import { combineReducers } from 'redux'
import { assocPath, compose } from 'ramda'

import * as conceptsReducers from '../concepts'
import dataReducer from '../data/reducer'
import intlReducer from '../intl/reducer'
import stepsReducer from '../steps/reducer'
import modalReducer from '../modal'
import { RESET_SELECTION } from './types'

const appReducer = combineReducers({
  ...conceptsReducers,
  data: dataReducer,
  intl: intlReducer,
  steps: stepsReducer,
  modal: modalReducer,
})

export default (state, action) => {
  if (action.type === RESET_SELECTION) {
    return appReducer(
      compose(
        assocPath(['widget', 'isReset'], true),
        assocPath(['widget', 'isPreviewMode'], state.widget.isPreviewMode),
        assocPath(['widget', 'previewSettings'], state.widget.previewSettings),
        assocPath(['widget', 'workspaceId'], state.widget.workspaceId),
        assocPath(['widget', 'widgetId'], state.widget.widgetId),
        assocPath(['data', 'widget'], state.data.widget),
        assocPath(['data', 'clientProfile'], state.data.clientProfile),
        assocPath(['session'], state.session),
        assocPath(['intl'], state.intl),
      )({}),
      action,
    )
  }

  return appReducer(state, action)
}
