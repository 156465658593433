import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

import SvgIcon from 'views/shared/Icons'

export const ReviewsInformation = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  cursor: pointer;
`

export const ReviewStarIcon = styled(SvgIcon)`
  width: 18px;
  fill: ${({ theme, isFilled }) => (isFilled ? theme.colors.yellow500 : theme.colors.grey30)};
  margin-right: 6px;

  ${media.tablet`
    width: 20px;
  `}
`
