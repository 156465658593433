import { createLogic } from 'redux-logic'

import { FILTER_PROVIDERS } from '../types'
import { setFilterParams, setCurrentPage, fetchProviders } from '../actions'

const filterProvidersOperation = createLogic({
  type: FILTER_PROVIDERS,
  latest: true,

  async process({ action: { filters } }, dispatch, done) {
    dispatch(setFilterParams(filters))
    dispatch(setCurrentPage(1))
    dispatch(fetchProviders())
    done()
  },
})

export default filterProvidersOperation
