import React from 'react'

import PropTypes from 'prop-types'
import styled, { css, keyframes } from 'styled-components'

import SvgIcon from 'views/shared/Icons'

const Loader = ({ isCenter, size, className, color }) => (
  <Loader.Wrapper isCenter={isCenter} data-cy="loader-wrapper">
    <Loader.StyledLoader iconName="loading" size={size} color={color} className={className} />
  </Loader.Wrapper>
)

const loading = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

Loader.Wrapper = styled.div`
  position: relative;
  display: inline-flex;
  align-self: center;

  ${({ isCenter }) =>
    isCenter &&
    css`
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    `}
`

Loader.StyledLoader = styled(SvgIcon)`
  fill: ${({ theme, color }) => color || theme.settings.color};
  animation: ${loading} 1s infinite cubic-bezier(0.6, -0.25, 0.6, 1.25);
`

Loader.defaultProps = {
  size: 20,
  className: undefined,
  isCenter: false,
  color: 'black500',
}

Loader.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
  isCenter: PropTypes.bool,
  color: PropTypes.string,
}

export default Loader
