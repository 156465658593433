import { createSelector } from 'reselect'
import { path, pick, pipe, equals, prop, propOr, mergeRight, keys, find, pathOr, both, propEq } from 'ramda'

import buildObject from 'utils/buildObject'
import buildCollection from 'utils/buildCollection'
import { fetchWidgetDetailsEndpoint } from 'state/concepts/widget/endpoints'
import { loadingSelector } from 'state/data/selectors'

const dataSelector = prop('data')

export const serviceIdSelector = path(['services', 'serviceId'])
export const providerIdSelector = path(['providers', 'providerId'])

export const isPreviewModeSelector = path(['widget', 'isPreviewMode'])
export const isResetSelector = path(['widget', 'isReset'])
export const isLockedSelector = path(['widget', 'isLocked'])
export const widgetIdSelector = path(['widget', 'widgetId'])
export const workspaceIdSelector = path(['widget', 'workspaceId'])
export const widgetPreviewSettingsSelector = path(['widget', 'previewSettings'])
export const widgetFiltersSelector = path(['widget', 'filters'])

export const widgetSelector = createSelector(widgetIdSelector, dataSelector, buildObject('widget'))

export const widgetSettingsSelector = createSelector(
  widgetSelector,
  widgetPreviewSettingsSelector,
  pipe(
    mergeRight,
    pick([
      'id',
      'color',
      'status',
      'logoUrl',
      'selector',
      'position',
      'buttonText',
      'buttonColor',
      'buttonAsIcon',
      'buttonPosition',
      'buttonIconColor',
      'buttonTextColor',
      'installationType',
      'clientPortalEnable',
    ]),
  ),
)

export const widgetPreviewClientPortalStatusSelector = createSelector(
  widgetSettingsSelector,
  prop('clientPortalEnable'),
)

export const widgetClientPortalStatusSelector = createSelector(
  widgetSelector,
  path(['workspace', 'clientPortal', 'status']),
)

export const widgetStatusSelector = createSelector(widgetSettingsSelector, prop('status'))

export const widgetLogoSelector = createSelector(
  widgetSettingsSelector,
  propOr(`${ASSET_CDN}/assets/images/main_logo.svg`, 'logoUrl'),
)

export const workspaceClientPortalWidgetSelector = createSelector(
  widgetSelector,
  path(['workspace', 'clientPortal', 'widget']),
)

export const isClientPortalWidgetAssignedSelector = createSelector(
  widgetSelector,
  workspaceClientPortalWidgetSelector,
  (widget, clientPortalWidget) => equals(prop('id', widget), prop('id', clientPortalWidget)),
)

export const isReviewsFeatureEnabledSelector = createSelector(widgetSelector, prop('reviewsEnabled'))

export const servicesCountSelector = createSelector(widgetSelector, prop('servicesCount'))

export const providersCountSelector = createSelector(widgetSelector, prop('providersCount'))

export const hasSingleRelationshipSelector = createSelector(
  servicesCountSelector,
  providersCountSelector,
  (servicesCount, providersCount) => equals(servicesCount, 1) && equals(providersCount, 1),
)

export const workspaceNameSelector = createSelector(widgetSelector, prop('workspaceName'))

export const workspaceLockedSelector = createSelector(widgetSelector, prop('workspaceLocked'))

export const publishableApiKeySelector = createSelector(widgetSelector, prop('publishableApiKey'))

export const stripeAccountSelector = createSelector(widgetSelector, prop('stripeAccount'))

export const widgetLoadingSelector = createSelector(
  workspaceIdSelector,
  widgetIdSelector,
  state => state,
  (workspaceId, widgetId, state) => loadingSelector(state, fetchWidgetDetailsEndpoint(workspaceId, widgetId).endpoint),
)

export const allUserProfileServicesSelector = createSelector(
  pipe(pathOr({}, ['data', 'userProfileService']), keys),
  dataSelector,
  buildCollection('userProfileService'),
)

export const priceSelector = createSelector(
  allUserProfileServicesSelector,
  serviceIdSelector,
  providerIdSelector,
  (userProfileServices, serviceId, providerId) => {
    const userProfileService = find(
      both(propEq(Number(serviceId), 'serviceId'), propEq(Number(providerId), 'userProfileId')),
    )(userProfileServices)

    return prop('price', userProfileService)
  },
)

export const hasLocationsSelector = createSelector(widgetSelector, widget => widget?.locationsCount > 0)

export const hasManyLocationsSelector = createSelector(
  widgetSelector,
  widget => widget?.locationsCount > 1 || (widget?.locationsCount === 1 && widget?.hasOnlineServices),
)
