import React from 'react'
import PropTypes from 'prop-types'

import PageTitle from 'views/shared/PageTitle'
import * as S from './styled'

const DesktopPageTitle = ({ text }) => (
  <S.Wrapper>
    <PageTitle justifyContent="flex-start" text={text} />
  </S.Wrapper>
)

DesktopPageTitle.propTypes = {
  text: PropTypes.shape().isRequired,
}

export default DesktopPageTitle
