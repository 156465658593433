import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import LinesEllipsis from 'react-lines-ellipsis'

import isPresent from 'utils/isPresent'
import * as S from './styled'

const TruncatedTextComponent = ({ text, maxLine, onToggleClick, isCollapsed, dataCy, className }) =>
  isPresent(text) && (
    <S.Container className={className} data-сy="review-text">
      <S.Text>
        {isCollapsed ? (
          <LinesEllipsis
            style={{ whiteSpace: 'pre-wrap' }}
            text={text}
            maxLine={maxLine}
            basedOn="words"
            ellipsis={
              <>
                ...
                <S.ShowMore onClick={onToggleClick} data-cy={dataCy}>
                  <FormattedMessage id="shared.showMore" />
                </S.ShowMore>
              </>
            }
          />
        ) : (
          <>
            <S.FullText>{text}</S.FullText>
            <S.ShowMore onClick={onToggleClick} data-cy={dataCy}>
              <FormattedMessage id="shared.showLess" />
            </S.ShowMore>
          </>
        )}
      </S.Text>
    </S.Container>
  )

TruncatedTextComponent.defaultProps = {
  text: '',
  dataCy: 'show-more-button',
  maxLine: 3,
  className: '',
}

TruncatedTextComponent.propTypes = {
  onToggleClick: PropTypes.func.isRequired,
  isCollapsed: PropTypes.bool.isRequired,
  text: PropTypes.string,
  dataCy: PropTypes.string,
  className: PropTypes.string,
  maxLine: PropTypes.number,
}

export default TruncatedTextComponent
