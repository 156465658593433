import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import SvgIcon from 'views/shared/Icons'

const Title = styled.p`
  font-size: 16px;
  line-height: ${28 / 16};
  font-weight: 600;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.colors.black1000};
  ${media.tablet`
    font-size: 20px;
    line-height: ${32 / 20};
    margin-bottom: 12px;
  `}
`

const TopWrap = styled.div`
  margin-bottom: 18px;
`

const Info = styled.div`
  display: flex;
  flex-direction: column;
  ${media.tablet`
    flex-direction: row;
  `}
`

const LocationName = styled.span`
  font-size: 14px;
  line-height: ${18 / 14};
  color: ${({ theme }) => theme.colors.black700};
  ${media.tablet`
    font-size: 16px;
    line-height: ${24 / 16};
  `}
`

const LocationWrap = styled.div`
  width: 100%;
  display: flex;
  ${media.tablet`
    width: 50%;
  `}
`

const Icon = styled(SvgIcon)`
  margin-top: 2px;
  margin-right: 8px;
  fill: ${({ theme }) => theme.colors.black400};
`

const LinkWrap = styled.div`
  width: 100%;
  font-size: 14px;
  line-height: ${18 / 14};
  padding-left: 0;
  margin-top: 8px;
  ${media.tablet`
    width: 50%;
    padding-left: 12px;
    font-size: 16px;
    line-height: ${24 / 16};
    margin-top: 0;
  `}
`

const Link = styled.a`
  color: ${({ theme }) => theme.settings.color};
`

const Wrap = styled.div`
  max-width: 648px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 24px;
  ${media.tablet`
    padding-left: 0;
    padding-right: 0;
  `}
`

const Map = styled.img`
  width: 100%;
  max-height: 392px;
`

export { Title, TopWrap, Info, LocationName, LocationWrap, Icon, LinkWrap, Link, Wrap, Map }
