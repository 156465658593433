import React from 'react'
import PropTypes from 'prop-types'
import { FormattedNumber, FormattedMessage } from 'react-intl'

import { FREE_PRICE } from 'constants'

const FormattedPrice = ({ price }) =>
  price &&
  (price === FREE_PRICE ? (
    <FormattedMessage id="shared.free" />
  ) : (
    <FormattedNumber
      value={price}
      // eslint-disable-next-line react/style-prop-object
      style="currency"
      currency="USD"
    />
  ))

FormattedPrice.defaultProps = {
  price: null,
}

FormattedPrice.propTypes = {
  price: PropTypes.string,
}

export default FormattedPrice
