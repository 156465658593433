import { createSelector } from 'reselect'
import { prop, path, compose } from 'ramda'

import buildObject from 'utils/buildObject'
import buildCollection from 'utils/buildCollection'
import { workspaceIdSelector, widgetIdSelector } from 'state/concepts/widget/selectors'
import { fetchLocationsEndpoint } from 'state/concepts/locations/endpoints'

const dataSelector = prop('data')

export const locationIdsSelector = path(['locations', 'locationIds'])
export const locationIdSelector = path(['locations', 'locationId'])
export const locationsSelector = createSelector(locationIdsSelector, dataSelector, buildCollection('location'))
export const fetchLocationsEndpointSelector = createSelector(
  workspaceIdSelector,
  widgetIdSelector,
  compose(prop('endpoint'), fetchLocationsEndpoint),
)

export const locationSelector = createSelector(locationIdSelector, dataSelector, buildObject('location'))
