import makeFormSubmitAction from 'utils/form/makeFormSubmitAction'
import * as types from './types'

export const createWaitlistRequest = makeFormSubmitAction(types.CREATE_WAITLIST_REQUEST)

export const setWaitlistRequest = waitlistRequestId => ({
  type: types.SET_WAITLIST_REQUEST,
  waitlistRequestId,
})

export const setAvailabilityPreferences = availabilityPreferences => ({
  type: types.SET_AVAILABILITY_PREFERENCES,
  availabilityPreferences,
})
