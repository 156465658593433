import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import { rgba } from 'polished'
import Calendar from 'react-calendar'

const DatePicker = styled(Calendar)`
  margin-bottom: 32px;

  .react-calendar__month-view__weekdays__weekday {
    font-size: 14px;
    height: 56px;
    line-height: 56px;
    text-align: center;
    color: ${({ theme }) => theme.colors.black700};
    font-weight: 600;

    abbr {
      text-decoration: none;
    }
  }

  .react-calendar__month-view__days__day {
    &:disabled,
    &:disabled:hover {
      color: ${({ theme }) => theme.colors.black400};
      cursor: not-allowed;
    }

    & > abbr {
      width: 32px;
      display: block;
      margin-right: auto;
      margin-left: auto;
      line-height: 32px;
      border-radius: 2px;

      ${media.tablet`
        width: 100%;
        height: 100%;
        border-radius: 0;
        line-height: 56px;
      `}
    }
  }

  .react-calendar__tile {
    font-size: 16px;
    line-height: 48px;
    color: ${({ theme }) => theme.colors.black1000};
    height: 48px;

    &:hover {
      color: ${({ theme }) => theme.settings.color};
    }

    ${media.tablet`
      border-right: 1px solid ${({ theme }) => theme.colors.black300};
      border-bottom: 1px solid ${({ theme }) => theme.colors.black300};
      line-height: 56px;
      height: 56px;
    `}
  }

  .react-calendar__month-view__days {
    ${({ isLoading }) =>
      isLoading
        ? `
    position: relative;
    &::before {
      content:'';
      position: absolute;
      top: -1px;
      left: -1px;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      background-color: white;
      opacity: 0.73;
    }`
        : ''}
    ${media.tablet`
      border-top: 1px solid ${({ theme }) => theme.colors.black300};
      border-left: 1px solid ${({ theme }) => theme.colors.black300};
    `}
  }

  .react-calendar__navigation__label {
    font-size: 16px;
    line-height: ${28 / 16};

    .icon {
      display: inline-block;
    }

    &:disabled .icon {
      transform: rotate(180deg);
    }
  }

  .react-calendar__navigation__label__labelText {
    display: inline-flex;
    align-items: center;
  }

  .react-calendar__navigation__arrow {
    align-items: center;
    height: auto;

    &:disabled svg {
      fill: ${({ theme }) => theme.colors.black400};
      cursor: not-allowed;
    }
  }

  .react-calendar__tile--now > abbr {
    background-color: ${({ theme }) => rgba(theme.settings.color, 0.1)};
    color: ${({ theme }) => theme.settings.color};

    &:hover {
      color: ${({ theme }) => theme.settings.color};
    }
  }
  /* stylelint-disable */
  .react-calendar__tile--active > abbr {
    background-color: ${({ theme }) => theme.settings.color};
    color: white;

    &:hover {
      color: white;
    }
  }

  .react-calendar__navigation {
    display: flex;
    margin-bottom: 4px;
    color: ${({ theme }) => theme.colors.black1000};
  }

  .react-calendar__navigation__prev-button,
  .react-calendar__navigation__next-button {
    display: flex;
    justify-content: center;
    width: 48px;
    line-height: 48px;
    font-size: 32px;
    fill: ${({ theme }) => theme.colors.black1000};
  }

  .react-calendar__year-view__months__month {
    color: ${({ theme }) => theme.colors.black700};

    &.react-calendar__tile--hasActive {
      color: ${({ theme }) => theme.colors.black1000};
      font-weight: 600;
    }

    :disabled {
      color: ${({ theme }) => theme.colors.black400};
    }
  }
`

const AbsoluteWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 60%;
  transform: translateX(-50%);
  z-index: 1;
`

const Container = styled.div`
  position: relative;
`

export { DatePicker, AbsoluteWrapper, Container }
