import { TIME_UNITS_MINUTE, TIME_UNITS_HOUR, TIME_UNITS_DAY, TIME_UNITS_MONTH } from 'constants/timeUnits'

const timeUnitsNamesEn = {
  [TIME_UNITS_MINUTE]: { singular: 'minute', plural: 'minutes' },
  [TIME_UNITS_HOUR]: { singular: 'hour', plural: 'hours' },
  [TIME_UNITS_DAY]: { singular: 'day', plural: 'days' },
  [TIME_UNITS_MONTH]: { singular: 'month', plural: 'months' },
}

const makeDurationUnitError = (messageStart, key, messageEnd) => {
  const unitsOptions = [TIME_UNITS_MINUTE, TIME_UNITS_HOUR, TIME_UNITS_DAY, TIME_UNITS_MONTH].map(
    unit =>
      ` ${unit} {{${key}, plural, one {${timeUnitsNamesEn[unit].singular}} other {${timeUnitsNamesEn[unit].plural}}}}`,
  )
  return `${messageStart} {${key}} {unit, select,${unitsOptions.join(' ')} other {}} ${messageEnd}`
}

export default {
  locale: 'en',
  messages: {
    'yup.mixed.required': "Can't be blank",
    'yup.string.email': 'Email is invalid',
    'yup.string.max': 'Must be ≤ {max} characters',
    'yup.string.maxDigits': "Can't be longer than {max} digits",
    'yup.string.phone': 'Is not a valid phone number',
    'yup.number.typeError': 'Must be numeric characters',
    'yup.date.endTime.min': 'The end time should be greater than the start time',

    'mainView.title': 'Book _a(appointment)',
    'mainView.selectService': 'Select _(service)',
    'mainView.servicesCount': '{count} _(services) available',
    'mainView.selectExpert': 'Select _(expert)',
    'mainView.providersCount': '{count} _(experts) available',
    'mainView.tooltipContent': 'Book _a(appointment) with one of our _(Experts), Select _(Service) and Time',
    'mainView.empty': '_(Service) is unavailable. Please try again later.',

    'duration.hoursShort': '{hours}h',
    'duration.hoursLong': '{hours, plural, one {{hours} hour} other {{hours} hours}}',
    'duration.minutesShort': '{minutes}m',
    'duration.minutesLong': '{minutes, plural, one {{minutes} minute} other {{minutes} minutes}}',

    'servicesListView.title': 'Select _(service)',
    'servicesListView.noSearchResults': 'Try another search or filter by category',
    'servicesListView.allCategories': 'All categories',
    'servicesListView.allServices': 'All _(services)',
    'servicesListView.learnMore': 'Learn more',
    'servicesListView.selectedExpert': '- selected _(expert)',
    'servicesListView.serviceDetails': '_(Service) details',
    'servicesListView.services': '_(Services)',
    'servicesListView.expertsAvailable': '{count, plural, one {_(expert) available} other {_(experts) available}}',
    'servicesListView.prepaymentRequired': 'Prepayment required',

    'providersListView.title': 'Select _a(expert)',
    'providersListView.selectedService': ' - selected _(service)',
    'providersListView.nearestSlotsToday': 'Nearest available time today',
    'providersListView.nearestSlotsTomorrow': 'Nearest available time tomorrow',
    'providersListView.nearestSlotsDate': 'Nearest available time on {date}',
    'providersListView.experts': '_(Experts)',
    'providersListView.expertDetails': '_(Expert) profile',
    'providersListView.noAvailableTimeSlots': 'No available time in the nearest 2 weeks',
    'providersListView.seeOtherOptions': 'See other options',
    'providersListView.servicesAvailable': '{count, plural, one {_(service) available} other {_(services) available}}',
    'providersListView.noSearchResults': 'Try another _(expert) name',
    'providersListView.searchPlaceholder': 'Search by name or position',
    'providersListView.modal.aboutTab': 'About _(expert)',
    'providersListView.modal.reviewsTab': 'Reviews',
    'providersListView.modal.empty.title': 'No reviews yet',
    'providersListView.modal.empty.description': "This is where you'll see reviews about this _(expert).",

    'timeSlotsView.title': 'Select date and time',
    'timeSlotsView.morning': 'Morning',
    'timeSlotsView.afternoon': 'Afternoon',
    'timeSlotsView.evening': 'Evening',
    'timeSlotsView.noAvailableSlots': 'There are no available time slots.',
    'timeSlotsView.goToNearestSlots': 'Go to nearest time slots',
    'timeSlotsView.timezoneSearchPlaceholder': 'Search',
    'timeSlotsView.timezoneSearchEmpty': 'No timezones matching your search',
    'timeSlotsView.noAvailableHours': 'No available hours',
    'timeSlotsView.availabilityOnDate': 'Availability on {date}',

    'reviewBookingView.title': 'Add your details',
    'reviewBookingView.MobileTitle': 'Your details',
    'reviewBookingView.completeInfo': 'Complete your information',
    'reviewBookingView.paymentDetails': 'Payment details',
    'reviewBookingView.bookAppointment': 'Book _(appointment)',
    'reviewBookingView.previewDone': 'Got it',
    'reviewBookingView.bookPaidAppointment': 'Pay {price}',
    'reviewBookingView.online.expertbox': 'ExpertBox video call',
    'reviewBookingView.online.google_meet': 'Google Meet video call',
    'reviewBookingView.online.microsoft_teams': 'Microsoft Teams video call',
    'reviewBookingView.online.zoom': 'Zoom video call',
    'reviewBookingView.cancelUpTo': makeDurationUnitError(
      'You can cancel the _(appointment) up to',
      'value',
      'before it starts and get a full refund',
    ),
    'reviewBookingView.cancelAnytimeWithFullRefund':
      'You can cancel the _(appointment) anytime before it starts and get a full refund',
    'reviewBookingView.cancelWithoutRefund': 'If you cancel this _(appointment), you won’t be refunded',
    'reviewBookingView.cancelAnytime': 'You can cancel the _(appointment) anytime before it starts',
    'reviewBookingView.timeSlotReservedFor': 'Time slot is reserved for ',
    'reviewBookingView.timeIsNotAvailable': 'Selected time {time} is not available.',
    'reviewBookingView.cvvTooltipText':
      'Card Verification Value/Code is used to validate that a genuine card is being used during a transaction. With a Visa or MasterCard CVV is a three-digit security code printed on the back of the card. On a American Express card, it is four digits shown on the front of the card.',
    'reviewBookingView.timezone': 'Timezone',
    'reviewBookingView.getTheLink':
      'This is an online _(appointment). You`ll get the link to your video call in a confirmation email.',
    'reviewBookingView.googleLink': 'Add to Google Calendar',
    'reviewBookingView.outlookOrAppleLink': 'Add to Outlook/Apple Calendar',

    'reviewBookingView.client.firstName': 'First name',
    'reviewBookingView.client.lastName': 'Last name',
    'reviewBookingView.client.email': 'Email',
    'reviewBookingView.client.address': 'Billing address',
    'reviewBookingView.client.zipCode': 'Postal / ZIP code',
    'reviewBookingView.client.country': 'Country',
    'reviewBookingView.client.selectCountry': 'Select country',
    'reviewBookingView.client.state': 'State',
    'reviewBookingView.client.city': 'City',
    'reviewBookingView.client.phone': 'Phone number',

    'reviewBookingView.payments.cardNumber': 'Card number',
    'reviewBookingView.placeholder.cardNumber': '0000 0000 0000 0000',
    'reviewBookingView.payments.expirationDate': 'Expiration date',
    'reviewBookingView.payments.cvv': 'CVC/CVV',
    'reviewBookingView.placeholder.expirationDate': 'MM/YY',
    'reviewBookingView.placeholder.cvv': '***',
    'reviewBookingView.agreementStart': 'Yes, I agree to the ',
    'reviewBookingView.terms': 'Terms & Conditions',
    'reviewBookingView.agreementContinuation': ' and the ',
    'reviewBookingView.privacyPolicy': 'Privacy Policy',
    'reviewBookingView.agreementEnd': ' of ExpertBox.',

    'confirmationView.addToGoogleCalendar': 'Add to Google Calendar',
    'confirmationView.addToOutlookApple': 'Add to Outlook / Apple Calendar',
    'confirmationView.youHaveBookedAppointment': 'Your _(appointment) is booked!',
    'confirmationView.weSentEmailTo': 'We’ve sent you an email with all the details to',
    'confirmationView.bookNewAppointment': 'Book _a(appointment)',
    'confirmationView.goToConsultation': 'Go to Meeting page',
    'confirmationView.openOnSmartphone': 'Open on smartphone',
    'confirmationView.wantToBookAnewAppointment': 'Want to book a new _(appointment)?',
    'confirmationView.scanCode': 'Scan this QR Code with GO Consulted app on your phone.',
    'confirmationView.copyCode': 'Or copy this code and enter it in the app',
    'confirmationView.consultationCode': 'Consultation Code',
    'confirmationView.copy': 'Copy',
    'confirmationView.copied': 'Copied',
    'confirmationView.attendeesAllowed': '{count} attendies allowed',
    'confirmationView.youWillBeAble':
      'You will be able to send email invitation from your _(appointment) page, at no extra charge. ',
    'confirmationView.youWillPayToExpert': 'You’ll pay to your _(expert)',

    'footer.agreement': 'Powered by {expertBoxLink} Copyright © {year}',
    'footer.agreementLink': 'ExpertBox ',

    'shared.back': 'Back',
    'shared.select': 'Select',
    'shared.apply': 'Apply',
    'shared.selected': 'Selected',
    'shared.selectExpert': 'Select _(expert)',
    'shared.selectService': 'Select _(service)',
    'shared.otherTime': 'Other options',
    'shared.next': 'Next',
    'shared.cancel': 'Cancel',
    'shared.done': 'Done',
    'shared.search': 'Search',
    'shared.free': 'Free',
    'shared.searchServices': 'Search for _(services)',
    'shared.searchProviders': 'Search by Name, Surname or Position',
    'shared.noSearchResultsTitle': 'No results found',
    'shared.noSearchResults': 'There are no results for your search request.',
    'shared.errorOccurred': 'Error occurred',
    'shared.okay': 'Ok',
    'shared.unknownError': 'Something went wrong.',
    'shared.price': 'Price',
    'shared.today': 'Today',
    'shared.yesterday': 'Yesterday',
    'shared.weekAgo': 'a week ago',
    'shared.otherDay': '{date}',
    'shared.formatTimeDays': '{count} {count, plural, one {day} other {days}} ago',
    'shared.showMore': 'Show more',
    'shared.showLessText': 'Show less',
    'shared.showLess': 'Hide',

    'stepper.service': '_(Service)',
    'stepper.expert': '_(Expert)',
    'stepper.dateAndTime': 'Date and time',
    'stepper.yourDetails': 'Your details',
    'stepper.step': 'Step {stepNumber}/{totalSteps}',

    'validation.mixed.required': "Can't be blank",
    'validation.string.email': 'Email is invalid',
    'validation.number': 'Must be a number',
    'validation.string.max': 'Must be ≥ {max} characters',

    'modal.discard.title': 'Discard data',
    'modal.discard.paidBooking.body': 'Are you sure you want to discard data and back to order details?',
    'modal.discard.freeBooking.body':
      'Are you sure you want to go back to booking details? The changes you’ve made won’t be saved.',
    'modal.discard.button': 'Discard',

    'modal.reservationExpired.title': 'Reservation expired',
    'modal.reservationExpired.keepIfAvailable': 'Keep this time if available',
    'modal.reservationExpired.selectAnotherTime': 'Select another time',
    'modal.reservationExpired.body.text1': 'The time when we can keep selected time slot reserved has run out.',
    'modal.reservationExpired.body.text2':
      'If is still available, we can keep it reserved for 10 more minutes till you finish your booking.',
    'modal.reservationExpired.body.text3':
      'If it has been taken, you can select another time slot and finish your booking.',

    'widgetPage.errorPage.weAreSorry': 'We are sorry, the page you requested cannot be found.',
    'widgetPage.errorPage.urlMayBeMisspelled':
      'The URL may be misspelled or the page you are looking for is no longer available.',
    'widgetPage.footer.agreement': 'Powered by {expertBoxUrl} Copyright © 2023',
    'widgetPage.footer.agreementLink': 'ExpertBox ',

    'bookingSummary.agreementsAndFormsToComplete':
      '{agreementsCount} {agreementsCount, plural, one {_(service agreement)} other {_(service agreements)}} and {intakeFormsCount} {intakeFormsCount, plural, one {_(intake form)} other {_(intake forms)}} to complete',
    'bookingSummary.intakeFormsToComplete':
      '{count} {count, plural, one {_(intake form)} other {_(intake forms)}} to complete',
    'bookingSummary.agreementsToComplete':
      '{count} {count, plural, one {_(service agreement)} other {_(service agreements)}} to complete',
    'bookingSummary.reviewAndFillOut':
      'Please take a moment to review and fill out these documents before the _(appointment).',
    'bookingSummary.fillOutDocuments': 'Fill out documents',
    'bookingSummary.paymentInstructions': 'Pay with following payment instructions:',

    'locationsListView.title': 'Select location',
    'locationsListView.onlineAppointment.title': 'Online _(appointment)',
    'locationsListView.onlineAppointment.description':
      'Book _(appointment) online and have an online call with the selected _(expert) at the appointed time.',
    'locationsListView.onlineLocations.title': 'Offline locations',
    'locationsListView.viewOnGoogleMaps': 'View on Google Maps',
    'location.onlineAppointment': 'Online _(appointment)',
    'location.title': 'Location',
    'paypal.hint': 'PayPal external payment method. Click to “Pay with PayPal” to finish payment.',
    'paypal.title': 'PayPal',
    'stripe.title': 'Card',
    'stripe.hint': 'Credit or debit',

    'login.authorize': 'Log in',
    'login.title': 'Log in to {workspace} _(client) portal',
    'login.description': 'Please enter your email address to get the link.',
    'login.emailAddress': 'Email address',
    'login.privacyPolicy': 'By logging in, you agree to our {termsLink} and that you have read our {privacyLink}.',
    'login.terms.link': 'Terms & Conditions',
    'login.privacy.link': 'Privacy Policy',
    'login.emailForm.submit': 'Get login code',
    'login.codeForm.description': 'We just sent you a temporary login code. Please check your inbox.',
    'login.loginCode': 'Login code',
    'login.loginCode.placeholder': 'Enter login code',
    'login.loginCode.validation.incorrectCode': 'Incorrect code',
    'login.codeForm.submit': 'Continue with login code',
    'login.codeForm.resend.description': 'Didn’t get the code?',
    'login.codeForm.resend.button': 'Resend code',

    'userAccount.logout': 'Log out',
    'userAccount.clientPortal': '_(Client) portal',

    'availabilityPreferences.title': 'Availability preferences',
    'availabilityPreferences.confirm': 'Confirm preference',
    'availabilityPreferences.addNewPreference': 'Add new preference',
    'availabilityPreferences.time': 'Time',
    'availabilityPreferences.date': 'Date',
    'availabilityPreferences.startTime': 'Start time',
    'availabilityPreferences.endTime': 'End time',
    'availabilityPreferences.joinWaitlist': 'Join waitlist',
    'availabilityPreferences.description': 'Don’t see your preferred date and time?',
    'availabilityPreferences.selectDate': 'Select date',
    'availabilityPreferences.addToWaitlist': 'Add to waitlist',

    'addAvailabilityPreferences.timeOption.anyTime': 'Any time',
    'addAvailabilityPreferences.timeOption.before': 'Before',
    'addAvailabilityPreferences.timeOption.after': 'After',
    'addAvailabilityPreferences.timeOption.between': 'Between',

    'availabilityPreferences.anyDate': 'Any date',
    'availabilityPreferences.datePreference': '{date}',
    'availabilityPreferences.time.any_time': 'Any time',
    'availabilityPreferences.time.before': 'Before {time}',
    'availabilityPreferences.time.after': 'After {time}',
    'availabilityPreferences.time.between': 'Between {time}',

    'waitlistRequestConfirmation.title': 'Your requested _(appointment)!',
    'waitlistRequestConfirmation.price': 'You’ll pay once the _(appointment) has booked and accepted.',
    'waitlistRequestConfirmation.cancelAppointment': 'You can cancel your _(appointment) request anytime.',
    'waitlistRequestConfirmation.bookNewAppointment': 'Book _a(appointment)',
    'waitlistRequestConfirmation.wantToBookAnewAppointment': 'Want to book a new _(appointment)?',
    'waitlistRequestConfirmation.weSentEmailTo': 'We’ve sent you an email with all the details to',
    'waitlistRequestConfirmation.availabilityPreference': 'Availability preference',
    'waitlistRequestConfirmation.availabilityPreferenceItem': '{time}, {date}',
    'waitlistRequestConfirmation.availabilityPreferenceItems': '{time}, {date}; .....',

    'waitlistRequest.errorModal.title': 'Availability preferences have expired',
    'waitlistRequest.errorModal.submit': 'Edit availability preferences',
  },
}
