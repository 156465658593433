import { createLogic } from 'redux-logic'
import { isNil } from 'ramda'

import { dataApiSuccess } from 'state/data/actions'
import { FETCH_TIMEZONES_CACHED } from '../types'
import { fetchTimezones } from '../actions'

const SECONDS_PER_WEEK = 604800000

const fetchTimezonesCachedOperation = createLogic({
  type: FETCH_TIMEZONES_CACHED,
  latest: true,

  async process(_, dispatch, done) {
    const timezonesCachedAt = parseInt(window.localStorage.getItem('timezonesCachedAt'), 10) || null
    const isCacheStale = timezonesCachedAt + SECONDS_PER_WEEK < Date.now()

    if (!(isNil(timezonesCachedAt) || isCacheStale)) {
      const response = JSON.parse(window.localStorage.getItem('timezones'))

      dispatch(dataApiSuccess({ response }))

      done()
      return
    }

    dispatch(fetchTimezones())
    done()
  },
})

export default fetchTimezonesCachedOperation
