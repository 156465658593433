import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Col } from 'styled-bootstrap-grid'
import { FormattedMessage } from 'react-intl'

import Link from 'views/shared/Link'
import * as S from './styled'

const CardComponent = memo(({ children, text, description, to }) => (
  <Col data-cy="booking-card">
    <Link to={to}>
      <S.Wrapper withHover>
        <S.ImageWrapper>{children}</S.ImageWrapper>

        <S.Content>
          <S.Title>
            <FormattedMessage {...text} />
            <S.ArrowIcon iconName="rightArrowLong" />
          </S.Title>
          <S.Description>
            <FormattedMessage {...description} />
          </S.Description>
        </S.Content>

        <S.MobileArrowIcon iconName="rightArrow" />
      </S.Wrapper>
    </Link>
  </Col>
))

CardComponent.displayName = 'CardComponent'

CardComponent.propTypes = {
  text: PropTypes.shape().isRequired,
  description: PropTypes.shape().isRequired,
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
}

export default CardComponent
