import React from 'react'
import PropTypes from 'prop-types'

import InfiniteScroll from 'views/shared/InfiniteScroll'
import SearchEmpty from 'views/shared/Search/SearchEmptyState'
import ReviewCard from './ReviewCard'

const ReviewsTab = ({ provider: { publishedReviewsCount }, reviews, isLoading, hasMore, handleLoadMore }) =>
  publishedReviewsCount > 0 ? (
    <>
      {reviews.map(review => (
        <ReviewCard key={review.id} {...review} />
      ))}
      <InfiniteScroll size={18} hasMore={hasMore} onLoadMore={handleLoadMore} isLoading={isLoading} />
    </>
  ) : (
    <SearchEmpty
      icon="reviews"
      title={{ id: 'providersListView.modal.empty.title' }}
      noSearchResults={{ id: 'providersListView.modal.empty.description' }}
      offsetTop="24px"
    />
  )

ReviewsTab.defaultProps = {
  reviews: [],
  isLoading: undefined,
  hasMore: true,
}

ReviewsTab.propTypes = {
  handleLoadMore: PropTypes.func.isRequired,
  provider: PropTypes.shape().isRequired,
  reviews: PropTypes.arrayOf(PropTypes.shape()),
  isLoading: PropTypes.bool,
  hasMore: PropTypes.bool,
}

export default ReviewsTab
