import * as types from './types'

export const fetchServices = () => ({
  type: types.FETCH_SERVICES,
})

export const fetchServiceCategories = () => ({
  type: types.FETCH_SERVICE_CATEGORIES,
})

export const setServices = serviceIds => ({
  type: types.SET_SERVICES,
  serviceIds,
})

export const setServiceCategories = categoryIds => ({
  type: types.SET_SERVICE_CATEGORIES,
  categoryIds,
})

export const setService = serviceId => ({
  type: types.SET_SERVICE,
  serviceId,
})

export const setFilterParams = filterParams => ({
  type: types.SET_SERVICES_FILTER_PARAMS,
  filterParams,
})

export const filterServices = filters => ({
  type: types.FILTER_SERVICES,
  filters,
})

export const setCurrentPage = pageNumber => ({
  type: types.SET_SERVICES_PAGE,
  pageNumber,
})

export const resetServices = () => ({
  type: types.RESET_SERVICES,
})
