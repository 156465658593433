import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import Button from 'views/shared/Button'

const Container = styled.div`
  position: fixed;
  bottom: 0;
  ${({ theme }) => theme.settings.position}: 0;
  right: 0;
  width: 100vw;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  border-top: 1px solid ${({ theme }) => theme.colors.black300};

  ${media.tablet`
    justify-content: flex-end;
    padding-right: 36px;
    padding-left: 36px;
  `}
`

const Price = styled.p`
  font-size: 16px;
  line-height: ${24 / 16};
  font-weight: 600;
  color: ${({ theme }) => theme.colors.black1000};

  ${media.tablet`
    margin-right: 24px;
  `}
`

const ButtonContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  width: 100%;
  height: 100%;
  margin: auto;

  ${media.tablet`
    justify-content: flex-end;
    padding-right: 36px;
    padding-left: 36px;
  `}
`

const DoneButton = styled(Button)`
  margin-left: auto;
`

export { Container, ButtonContainer, Price, DoneButton }
