import { path } from 'ramda'
import { createSelector } from 'reselect'

import buildObject from 'utils/buildObject'
import { dataSelector } from 'state/data/selectors'

export const waitlistRequestIdSelector = path(['waitlistRequest', 'waitlistRequestId'])
export const availabilityPreferencesSelector = path(['waitlistRequest', 'availabilityPreferences'])

export const waitlistRequestSelector = createSelector(
  waitlistRequestIdSelector,
  dataSelector,
  buildObject('waitlistRequest'),
)
