import { DateTime } from 'luxon'

import { TIME_PREFERENCES_TYPES } from 'constants/availabilityPreferences'

const dateFromJSDate = date => (date ? DateTime.fromJSDate(date) : undefined)

const requestDateTime = (date, timezoneOffset) => {
  if (!date) return undefined

  return timezoneOffset ? date.setZone(`UTC${timezoneOffset}`, { keepLocalTime: true }).toISO() : date.toISO()
}

const buildAvailabilityParams = ({ id, dateRange, timeType, startTime, endTime }, timezoneOffset) => {
  const isAnyTime = timeType === TIME_PREFERENCES_TYPES.anyTime

  return {
    id,
    time_type: timeType,
    start_date: requestDateTime(dateFromJSDate(dateRange[0]), timezoneOffset),
    end_date: requestDateTime(dateFromJSDate(dateRange[1]), timezoneOffset),
    ...(!isAnyTime && {
      start_time: requestDateTime(startTime, timezoneOffset),
      end_time: requestDateTime(endTime, timezoneOffset),
    }),
  }
}

// eslint-disable-next-line import/prefer-default-export
export const buildCreateParams = ({
  userProfileId,
  serviceId,
  locationId,
  availabilityPreferences,
  clientProfile,
  timezone,
  timezoneOffset,
}) => ({
  user_profile_id: userProfileId,
  service_id: serviceId,
  location_id: locationId,
  availability_preferences: availabilityPreferences.map(availability =>
    buildAvailabilityParams(availability, timezoneOffset),
  ),
  client_profile: {
    first_name: clientProfile.firstName,
    last_name: clientProfile.lastName,
    email: clientProfile.email,
    phone_number: clientProfile.phoneNumber,
    timezone,
  },
  include: ['service', 'location', 'user-profile', 'availability-preferences', 'client-profile'],
})
