const namespace = 'widget'

export const SET_WIDGET = `${namespace}/SET_WIDGET`
export const SET_WORKSPACE = `${namespace}/SET_WORKSPACE`
export const SET_IS_RESET = `${namespace}/SET_IS_RESET`
export const SET_IS_PREVIEW_MODE = `${namespace}/SET_IS_PREVIEW_MODE`
export const SET_WIDGET_PREVIEW_SETTINGS = `${namespace}/SET_WIDGET_PREVIEW_SETTINGS`
export const SET_IS_LOCKED = `${namespace}/SET_IS_LOCKED`
export const OPEN_WIDGET = `${namespace}/OPEN_WIDGET`
export const RESOLVE_WIDGET_STATE = `${namespace}/RESOLVE_WIDGET_STATE`
export const FETCH_WIDGET_DETAILS = `${namespace}/FETCH_WIDGET_DETAILS`
export const SET_WIDGET_FILTERS = `${namespace}/SET_WIDGET_FILTERS`
export const RESET_WIDGET_FILTERS = `${namespace}/RESET_WIDGET_FILTERS`
