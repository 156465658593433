import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

import SvgIcon from 'views/shared/Icons'

const Wrapper = styled.div`
  position: relative;
  background: transparent;
  color: ${({ theme }) => theme.colors.black500};
`

const TooltipIcon = styled(SvgIcon)`
  font-size: ${({ size }) => size}px;
  fill: ${({ theme }) => theme.colors.black500};

  &:hover {
    fill: ${({ theme }) => theme.settings.color};
  }

  ${media.tablet`
    cursor: pointer;
  `}
`

const Container = styled.div`
  display: none;

  ${/* sc-selector */ Wrapper}:hover & {
    display: block;
    padding: 16px 16px 8px;
    background: ${({ theme }) => theme.colors.black1000};
    width: 172px;
    position: absolute;
    z-index: 20;

    ${media.tablet`
      width: 200px;
    `}

    &::before {
      content: '';
      position: absolute;
      border-style: solid;
      border-width: 6px;
    }
  }
`

const ContainerTop = styled(Container)`
  ${/* sc-selector */ Wrapper}:hover & {
    bottom: calc(100% + 6px);
    left: 50%;
    transform: translateX(-50%);

    &::before {
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      border-color: ${({ theme }) => theme.colors.black1000} transparent transparent transparent;
    }
  }
`

const ContainerRight = styled(Container)`
  ${/* sc-selector */ Wrapper}:hover & {
    top: 50%;
    left: calc(100% + 6px);
    transform: translateY(-50%);

    &::before {
      top: 50%;
      right: 100%;
      transform: translateY(-50%);
      border-color: transparent ${({ theme }) => theme.colors.black1000} transparent transparent;
    }
  }
`
const ContainerBottom = styled(Container)`
  ${/* sc-selector */ Wrapper}:hover & {
    top: calc(100% + 6px);
    left: 50%;
    transform: translateX(-50%);

    &::before {
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
      border-color: transparent transparent ${({ theme }) => theme.colors.black1000} transparent;
    }
  }
`

const ContainerLeft = styled(Container)`
  ${/* sc-selector */ Wrapper}:hover & {
    top: 50%;
    right: calc(100% + 6px);
    transform: translateY(-50%);

    &::before {
      top: 50%;
      left: 100%;
      transform: translateY(-50%);
      border-color: transparent transparent transparent ${({ theme }) => theme.colors.black1000};
    }
  }
`

const Content = styled.div`
  color: white;
  font-size: 12px;
  margin-bottom: 8px;
  line-height: ${16 / 12};
  word-break: break-word;
`

export { Wrapper, TooltipIcon, Content, Container, ContainerTop, ContainerRight, ContainerBottom, ContainerLeft }
