import React from 'react'
import PropTypes from 'prop-types'

import FormattedPrice from 'views/shared/FormattedPrice'
import FormattedRange from 'views/shared/FormattedRange'

const FormattedPriceOrRange = ({ price, priceMin, priceMax }) =>
  price ? <FormattedPrice price={price} /> : <FormattedRange priceMin={priceMin} priceMax={priceMax} />

FormattedPriceOrRange.defaultProps = {
  price: null,
  priceMin: null,
  priceMax: null,
}

FormattedPriceOrRange.propTypes = {
  price: PropTypes.string,
  priceMin: PropTypes.string,
  priceMax: PropTypes.string,
}

export default FormattedPriceOrRange
