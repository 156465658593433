import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import { rgba } from 'polished'

import WidgetButton from 'views/shared/WidgetButton'
import SvgIcon from 'views/shared/Icons'

export const MobileCloseButton = styled(WidgetButton)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
  background-color: ${({ theme }) => rgba(theme.settings.color, 0.1)} !important;
  border-radius: 2px;

  ${media.desktop`
    display: none;
  `}
`

export const Icon = styled(SvgIcon)`
  font-size: 24px;
  fill: ${({ theme }) => theme.settings.color} !important;
`
