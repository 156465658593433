/* eslint-disable no-use-before-define */
import { useState, useEffect, useCallback } from 'react'

const useScrollTracking = element => {
  const [isScrolled, setIsScrolled] = useState(false)
  useEffect(() => {
    if (element) {
      element.addEventListener('scroll', handleScroll, true)
    }
    return () => {
      if (element) {
        element.removeEventListener('scroll', handleScroll)
      }
    }
  }, [element, handleScroll])

  const handleScroll = useCallback(({ target }) => {
    setIsScrolled(target.scrollTop > 0)
  }, [])

  return isScrolled
}

export default useScrollTracking
