/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'
import Loader from 'views/shared/Loader'

export const LoaderWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const LoaderIcon = styled(Loader)`
  fill: ${({ theme, color }) => color || theme.settings.color};
`
