import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { media } from 'styled-bootstrap-grid'

import { MAX_NAME_LENGTH_IN_STEPPER } from 'constants'
import profileName from 'utils/profileName'
import ModalContext from 'views/Modal/ModalContext'
import TruncatedText from 'views/shared/TruncatedText'
import Avatar from 'views/shared/Avatar'
import ProviderModal from 'views/ProvidersListView/ProviderModal'

const ProviderInfo = ({ property: provider }) => {
  const { showModal } = useContext(ModalContext)

  return (
    <ProviderInfo.Wrap data-cy="widget-stepper-provider">
      <ProviderInfo.StepName data-cy="widget-stepper-provider-step-name">
        <FormattedMessage id="stepper.expert" />
      </ProviderInfo.StepName>
      {provider && (
        <ProviderInfo.Details>
          <ProviderInfo.Avatar isSmall size={36} avatarUrls={provider.avatarUrls} />
          <ProviderInfo.TitleWrap>
            <ProviderInfo.Title data-cy="widget-stepper-provider-name" onClick={showModal(ProviderModal, { provider })}>
              <TruncatedText phrase={profileName(provider)} maxChars={MAX_NAME_LENGTH_IN_STEPPER} />
            </ProviderInfo.Title>
            <ProviderInfo.Desc data-cy="widget-stepper-provider-position">
              <TruncatedText textColor="black700" phrase={provider.position} maxChars={MAX_NAME_LENGTH_IN_STEPPER} />
            </ProviderInfo.Desc>
          </ProviderInfo.TitleWrap>
        </ProviderInfo.Details>
      )}
    </ProviderInfo.Wrap>
  )
}

ProviderInfo.defaultProps = {
  property: null,
}

ProviderInfo.propTypes = {
  property: PropTypes.shape(),
}

ProviderInfo.StepName = styled.p`
  display: none;
  margin-top: 6px;
  margin-bottom: 6px;
  font-family: ${({ theme }) => theme.fonts.interTight};
  font-size: 16px;
  line-height: ${24 / 16};
  font-weight: 600;
  color: ${({ theme }) => theme.colors.black700};

  ${media.tablet`
    display: block;
  `}
`

ProviderInfo.Wrap = styled.div`
  min-width: 0;
`

ProviderInfo.Details = styled.div`
  display: flex;
`

ProviderInfo.Avatar = styled(Avatar)`
  width: 24px;
  height: 24px;
  margin-right: 12px;

  ${media.tablet`
    width: 36px;
    height: 36px;
    margin-right: 16px;
  `}

  .avatar-empty-icon-wrap {
    background-color: white;
  }

  .avatar-empty-icon {
    width: 12px;
    height: auto;

    ${media.tablet`
      width: 18px;
    `}
  }
`

ProviderInfo.TitleWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-width: 0;

  ${media.tablet`
    align-items: flex-start;
    flex-direction: column;
  `}
`

ProviderInfo.Title = styled.div`
  margin-bottom: 0;
  margin-right: 8px;
  color: ${({ theme }) => theme.settings.color};
  cursor: pointer;

  > div > div {
    color: ${({ theme }) => theme.settings.color};
  }

  ${media.tablet`
    margin-right: 0;
    margin-bottom: 2px;
  `}
`

ProviderInfo.Desc = styled.div`
  color: ${({ theme }) => theme.colors.black700};
`

export default ProviderInfo
