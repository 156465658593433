import BookingErrorModal from 'views/ReviewBookingView/BookingErrorModal'
import DiscardDataModal from 'views/ReviewBookingView/DiscardDataModal'
import ReservationExpiredModal from 'views/ReviewBookingView/ReservationExpiredModal'

const MODAL_COMPONENTS = {
  BOOKING_ERROR: BookingErrorModal,
  DISCARD_DATA: DiscardDataModal,
  RESERVATION_EXPIRED: ReservationExpiredModal,
}

export default MODAL_COMPONENTS
