import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

import SvgIcon from 'views/shared/Icons'

const Wrapper = styled.div`
  position: relative;
  margin-bottom: 24px;
`

const Content = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.black1000};
  cursor: pointer;
`

const DownArrowIcon = styled(SvgIcon)`
  fill: ${({ theme }) => theme.colors.black500};
`

const UpArrowIcon = styled(SvgIcon)`
  transform: rotate(180deg);
  fill: ${({ theme }) => theme.settings.color};
`

const Value = styled.span`
  margin-left: 8px;
  margin-right: 8px;
`

const Dropdown = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0 4px 12px 0 rgba(${({ theme }) => theme.colors.boxShadowColor}, 0.1);
  max-height: 264px;
  width: 100%;
  position: absolute;
  top: 30px;
  left: 0;
  z-index: 1;
  ${media.tablet`
    width: 356px;
  `}
`

const Search = styled.div`
  margin: 12px 16px;
  border-radius: 3px;
  flex-shrink: 0;
  position: relative;
  outline: 1px solid ${({ theme }) => theme.colors.black300};
  transition: outline-color 0.3s ease 0s;

  &:hover,
  &:focus-within {
    outline-width: 2px;
    outline-color: ${({ theme }) => theme.settings.color};
  }
`

const SearchInput = styled.input`
  width: 100%;
  padding: 6px 30px;
  border: none;
  font-size: 14px;
  line-height: ${20 / 14};
  color: ${({ theme }) => theme.colors.black1000};

  &::placeholder {
    color: ${({ theme }) => theme.colors.black700};
  }
`

const DropdownList = styled.ul`
  flex-grow: 1;
  overflow-y: auto;
`

const SearchIcon = styled(SvgIcon)`
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
  fill: ${({ theme }) => theme.colors.black500};
`

const ClearButton = styled.button`
  position: absolute;
  top: 50%;
  right: 24px;
  transform: translateY(-50%);
  border: none;
  background-color: transparent;
`

const EmptyState = styled.li`
  padding: 14px 16px;
  line-height: ${20 / 14};
  color: ${({ theme }) => theme.colors.black700};
`

export {
  Wrapper,
  Content,
  DownArrowIcon,
  UpArrowIcon,
  Dropdown,
  Search,
  SearchInput,
  DropdownList,
  SearchIcon,
  Value,
  EmptyState,
  ClearButton,
}
