import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose, equals } from 'ramda'
import { withRouter } from 'react-router-dom'
import cookies from 'component-cookie'

import { STRIPE_SCRIPT_LINK } from 'constants'
import { resetSelection as resetSelectionAction } from 'state/store/actions'
import * as widgetActions from 'state/concepts/widget/actions'
import { fetchTimezonesCached as fetchTimezonesCachedAction } from 'state/concepts/timezones/actions'
import { isResetSelector, widgetSettingsSelector } from 'state/concepts/widget/selectors'
import {
  getClientOwnProfile as getClientOwnProfileAction,
  setPassedCurrentClient as setPassedCurrentClientAction,
} from 'state/concepts/session/actions'
import WidgetComponent from './component'

class Widget extends React.PureComponent {
  componentDidMount = () => {
    const {
      isPreviewMode,
      setIsPreviewMode,
      fetchTimezonesCached,
      getClientOwnProfile,
      currentClient,
      setPassedCurrentClient,
      serviceId,
      providerId,
      setWidgetFilters,
    } = this.props

    this.appendStripeScript()
    setIsPreviewMode(isPreviewMode)
    setWidgetFilters({ serviceId, providerId })
    fetchTimezonesCached()
    this.fetchWidgetDetails()

    if (currentClient) {
      setPassedCurrentClient(currentClient)
    }
    if (cookies('tokens') && !currentClient) {
      getClientOwnProfile()
    }
  }

  componentDidUpdate = prevProps => {
    const {
      isReset,
      setIsReset,
      fetchTimezonesCached,
      widgetSettings,
      setWidgetPreviewSettings,
      isPreviewMode,
      serviceId,
      providerId,
      resetSelection,
      history,
      setWidgetFilters,
    } = this.props

    if (!prevProps.isReset && isReset) {
      setIsReset(false)
      this.fetchWidgetDetails()
      fetchTimezonesCached()
    } else if (prevProps.serviceId !== serviceId || prevProps.providerId !== providerId) {
      history.replace('/')
      resetSelection()
      setWidgetFilters({ serviceId, providerId })
    } else if (isPreviewMode && !equals(prevProps.widgetSettings, widgetSettings)) {
      setWidgetPreviewSettings(widgetSettings)
    }
  }

  get contentWindow() {
    const { contentWindow } = this.props

    return contentWindow || window
  }

  get contentDocument() {
    const { contentDocument } = this.props

    return contentDocument || document
  }

  appendStripeScript = () => {
    const script = this.contentDocument.createElement('script')
    script.async = true
    script.src = STRIPE_SCRIPT_LINK
    this.contentDocument.head.appendChild(script)
  }

  fetchWidgetDetails = () => {
    const { fetchWidgetDetails, widgetSettings, history, workspaceId, appId: widgetId } = this.props

    fetchWidgetDetails({
      widgetSettings,
      history,
      widgetId,
      workspaceId,
    })
  }

  render = () => (
    <WidgetComponent {...this.props} contentDocument={this.contentDocument} contentWindow={this.contentWindow} />
  )
}

Widget.propTypes = {
  appId: PropTypes.string.isRequired,
  workspaceId: PropTypes.string.isRequired,
  serviceId: PropTypes.string,
  providerId: PropTypes.string,
  fetchTimezonesCached: PropTypes.func.isRequired,
  setIsPreviewMode: PropTypes.func.isRequired,
  setIsReset: PropTypes.func.isRequired,
  isPreviewMode: PropTypes.bool,
  isReset: PropTypes.bool.isRequired,
  widgetSettings: PropTypes.shape(),
  setWidgetPreviewSettings: PropTypes.func,
  settings: PropTypes.shape({
    installationType: PropTypes.string,
    selector: PropTypes.string,
  }),
  history: PropTypes.shape().isRequired,
  contentDocument: PropTypes.shape(),
  contentWindow: PropTypes.shape(),
  fetchWidgetDetails: PropTypes.func.isRequired,
  getClientOwnProfile: PropTypes.func.isRequired,
  currentClient: PropTypes.shape(),
  setPassedCurrentClient: PropTypes.func.isRequired,
  resetSelection: PropTypes.func.isRequired,
  setWidgetFilters: PropTypes.func.isRequired,
}

Widget.defaultProps = {
  widgetSettings: undefined,
  isPreviewMode: false,
  settings: null,
  setWidgetPreviewSettings: undefined,
  contentDocument: null,
  contentWindow: null,
  currentClient: undefined,
  serviceId: undefined,
  providerId: undefined,
}

const mapStateToProps = state => ({
  isReset: isResetSelector(state),
  settings: widgetSettingsSelector(state),
})

const mapDispatchToProps = {
  ...widgetActions,
  fetchTimezonesCached: fetchTimezonesCachedAction,
  getClientOwnProfile: getClientOwnProfileAction,
  setPassedCurrentClient: setPassedCurrentClientAction,
  resetSelection: resetSelectionAction,
}

export { Widget as WidgetContainer }
export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(Widget)
