import styled from 'styled-components'

import SvgIcon from 'views/shared/Icons'

export const Option = styled.li`
  padding: 14px 16px;
  line-height: ${20 / 14};
  color: ${({ theme }) => theme.colors.black1000};
  cursor: pointer;
  position: relative;
  background-color: transparent;
  transition: background-color 0.15s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.black100};
  }
`

export const OptionActive = styled(SvgIcon)`
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  cursor: pointer;
  fill: ${({ theme }) => theme.settings.color};
`
