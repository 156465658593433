import React from 'react'
import { injectIntl } from 'react-intl'
import { Field, useFormikContext } from 'formik'
import { path } from 'ramda'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'

import RangePickerField from 'views/AvailabilityPreferences/RangePickerField'
import { TIME_PREFERENCES_TYPES, TIME_TYPE_OPTIONS } from 'constants/availabilityPreferences'
import SelectField from 'views/shared/SelectField'
import translateOptions from 'utils/translateOptions'
import TimePickerField from 'views/shared/TimePickerField'
import disabledTimeOptions from 'utils/disabledTimeOptions'
import isPresent from 'utils/isPresent'
import * as S from './styled'

const Availability = ({ intl, onRemove, rowIndex, canRemove }) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext()
  const timeType = path(['availabilityPreferences', rowIndex, 'timeType'], values)
  const [startDate] = path(['availabilityPreferences', rowIndex, 'dateRange'], values) || []
  const isAnyTime = timeType === TIME_PREFERENCES_TYPES.anyTime
  const isBetweenTime = timeType === TIME_PREFERENCES_TYPES.between
  const isAfterTime = timeType === TIME_PREFERENCES_TYPES.after
  const isAnyDate = !isPresent(startDate)
  const now = DateTime.now()

  const start =
    !isPresent(startDate) || now.endOf('day').equals(DateTime.fromJSDate(startDate).endOf('day'))
      ? now
      : DateTime.fromJSDate(startDate)

  /* istanbul ignore next */
  const sanitizeTimeFields = () => {
    setFieldValue(`availabilityPreferences.${rowIndex}.startTime`, undefined)
    setFieldValue(`availabilityPreferences.${rowIndex}.endTime`, undefined)
    setFieldTouched(`availabilityPreferences.${rowIndex}.startTime`, false)
    setFieldTouched(`availabilityPreferences.${rowIndex}.endTime`, false)
  }

  const disabledTime = isAfterTime || isAnyDate ? null : () => disabledTimeOptions(start)

  return (
    <S.FormItem>
      <Field
        id={`availabilityPreferences.${rowIndex}.dateRange`}
        name={`availabilityPreferences.${rowIndex}.dateRange`}
        component={RangePickerField}
        onChange={sanitizeTimeFields}
      />
      <S.FormRow>
        <S.FormColThirdParth>
          <Field
            id={`availabilityPreferences.${rowIndex}.timeType`}
            name={`availabilityPreferences.${rowIndex}.timeType`}
            component={SelectField}
            label={{ id: 'availabilityPreferences.time' }}
            placeholder={{ id: 'shared.select' }}
            options={translateOptions(intl, TIME_TYPE_OPTIONS)}
            onChange={sanitizeTimeFields}
          />
        </S.FormColThirdParth>
        {!isAnyTime && (
          <>
            <S.FormColThirdParth>
              <Field
                id={`availabilityPreferences.${rowIndex}.startTime`}
                name={`availabilityPreferences.${rowIndex}.startTime`}
                component={TimePickerField}
                label={{ id: 'availabilityPreferences.startTime' }}
                placeholder={{ id: 'shared.select' }}
                disabledTime={disabledTime}
              />
            </S.FormColThirdParth>
            {isBetweenTime && (
              <S.FormColThirdParth>
                <Field
                  id={`availabilityPreferences.${rowIndex}.endTime`}
                  name={`availabilityPreferences.${rowIndex}.endTime`}
                  component={TimePickerField}
                  label={{ id: 'availabilityPreferences.endTime' }}
                  placeholder={{ id: 'shared.select' }}
                  disabledTime={disabledTime}
                />
              </S.FormColThirdParth>
            )}
          </>
        )}
      </S.FormRow>
      {canRemove && (
        <S.ClearBtnWrap>
          <button type="button" onClick={onRemove}>
            <S.ClearBtn size={20} iconName="remove" dataCy="remove-button" />
          </button>
        </S.ClearBtnWrap>
      )}
    </S.FormItem>
  )
}

Availability.propTypes = {
  intl: PropTypes.shape().isRequired,
  onRemove: PropTypes.func.isRequired,
  rowIndex: PropTypes.number.isRequired,
  canRemove: PropTypes.bool.isRequired,
}

export default injectIntl(Availability)
