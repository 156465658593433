import { combineReducers } from 'redux'

import {
  SET_IS_RESET,
  SET_IS_LOCKED,
  SET_WIDGET_PREVIEW_SETTINGS,
  SET_IS_PREVIEW_MODE,
  SET_WIDGET,
  SET_WORKSPACE,
  SET_WIDGET_FILTERS,
  RESET_WIDGET_FILTERS,
} from './types'

const isReset = (state = false, action) => {
  switch (action.type) {
    case SET_IS_RESET:
      return action.isReset
    default:
      return state
  }
}

const isPreviewMode = (state = false, action) => {
  switch (action.type) {
    case SET_IS_PREVIEW_MODE:
      return action.isPreviewMode
    default:
      return state
  }
}

const previewSettings = (state = null, action) => {
  switch (action.type) {
    case SET_WIDGET_PREVIEW_SETTINGS:
      return action.settings
    default:
      return state
  }
}

const isLocked = (state = false, action) => {
  switch (action.type) {
    case SET_IS_LOCKED:
      return action.isLocked
    default:
      return state
  }
}

const widgetId = (state = null, action) => {
  switch (action.type) {
    case SET_WIDGET:
      return action.widgetId
    default:
      return state
  }
}

const workspaceId = (state = null, action) => {
  switch (action.type) {
    case SET_WORKSPACE:
      return action.workspaceId
    default:
      return state
  }
}

const filters = (state = {}, action) => {
  switch (action.type) {
    case SET_WIDGET_FILTERS:
      return action.filters
    case RESET_WIDGET_FILTERS:
      return {}
    default:
      return state
  }
}

export default combineReducers({
  isPreviewMode,
  isLocked,
  isReset,
  previewSettings,
  widgetId,
  workspaceId,
  filters,
})
