import PropTypes from 'prop-types'
import { filter, pipe } from 'ramda'
import React from 'react'
import { getCountryCallingCode } from 'react-phone-number-input'
import { useClickAway, useToggle } from 'react-use'
import { FormattedMessage } from 'react-intl'

import Search from 'views/shared/Search'
import * as S from './styled'

const Select = ({ options, onChange, value, disabled }) => {
  const [filteredOptions, setFilteredOptions] = React.useState(options)

  const [isOpen, toggleIsOpen] = useToggle(false)

  const ref = React.useRef(null)

  useClickAway(ref, () => toggleIsOpen(false))

  const handleFilterOptions = React.useCallback(
    optionValue =>
      pipe(
        filter(({ label }) => label.toLowerCase().includes(optionValue.name?.toLowerCase())),
        setFilteredOptions,
      )(options),
    [options],
  )

  const handleClickButton = event => {
    event.preventDefault()
    toggleIsOpen()
  }

  const handleChange = React.useCallback(
    newValue => () => {
      setFilteredOptions(options)
      onChange(newValue)
      toggleIsOpen(false)
    },
    [onChange, options, toggleIsOpen],
  )

  return (
    <S.Wrap ref={ref} data-cy="select">
      <S.Selected onClick={handleClickButton} data-cy="select--selected" disabled={disabled}>
        <S.SelectedInfo>
          <S.ImageFlag src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${value}.svg`} alt="#" />
        </S.SelectedInfo>
        <S.DownArrowIcon isOpen={isOpen} size={8} iconName="iconDownArrow" />
      </S.Selected>
      {isOpen && (
        <S.Dropdown>
          <Search
            className="main-search"
            placeholder={{ id: 'shared.search' }}
            filterEntities={handleFilterOptions}
            dataCy="select--search-input"
          />
          <S.List>
            {filteredOptions.map(({ label, value: optionValue }) => (
              <S.ListItem key={optionValue} onClick={handleChange(optionValue)}>
                <S.ImageFlag
                  src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${optionValue}.svg`}
                  alt="#"
                />
                <S.Code>{label}</S.Code>
                <S.CodeGray>+{getCountryCallingCode(optionValue)}</S.CodeGray>
              </S.ListItem>
            ))}
            {filteredOptions.length === 0 && (
              <S.NoResults>
                <FormattedMessage id="shared.noSearchResultsTitle" />
              </S.NoResults>
            )}
          </S.List>
        </S.Dropdown>
      )}
    </S.Wrap>
  )
}

Select.defaultProps = {
  disabled: false,
}

Select.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
}

export default Select
