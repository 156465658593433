import cookies from 'component-cookie'

const setCookie = (currentClient, jwt, cookieOptions) => {
  cookies('tokens', JSON.stringify(jwt), cookieOptions)
  cookies('currentClientId', currentClient.id, cookieOptions)
  cookies('currentWorkspaceId', currentClient.workspace.id, cookieOptions)
  cookies('currentWorkspaceCode', currentClient.workspace.uniqCode, cookieOptions)
  cookies('path', cookieOptions.path, cookieOptions)
}

const setClientCookies = (currentClient, jwt, cookieOptions = { path: '/' }) => {
  setCookie(currentClient, jwt, cookieOptions)
}

export default setClientCookies
