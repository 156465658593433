// ---Session---
export const sessionRoute = workspaceId => `/workspaces/${workspaceId}/clients/session`
export const sessionSendCodeRoute = workspaceId => `/workspaces/${workspaceId}/clients/session/send_code`
export const sessionRefreshRoute = workspaceId => `/workspaces/${workspaceId}/clients/session/refresh`
export const clientAccountProfileRoute = workspaceId => `/workspaces/${workspaceId}/clients/account/profile`

// ---Widget settings---
export const widgetRoute = (workspaceId, widgetId) => `/workspaces/${workspaceId}/public/widgets/${widgetId}`

export const widgetDetailsRoute = (workspaceId, widgetId) =>
  `/workspaces/${workspaceId}/public/widgets/${widgetId}/widget_details`

export const widgetLocationDetailsRoute = (workspaceId, widgetId) =>
  `/workspaces/${workspaceId}/public/widgets/${widgetId}/location_details`

export const widgetLocationsRoute = (workspaceId, widgetId) =>
  `/workspaces/${workspaceId}/public/widgets/${widgetId}/locations`

// ---Services---
export const widgetsServicesRoute = (workspaceId, widgetId) =>
  `/workspaces/${workspaceId}/public/widgets/${widgetId}/services`

// ---Service categories---
export const widgetsServiceCategoriesRoute = (workspaceId, widgetId) =>
  `/workspaces/${workspaceId}/public/widgets/${widgetId}/service_categories`

// ---Service providers---
export const widgetsProvidersRoute = (workspaceId, widgetId) =>
  `/workspaces/${workspaceId}/public/widgets/${widgetId}/providers`

// ---Service availability---
export const serviceAvailabilityRoute = (workspaceId, serviceId) =>
  `/workspaces/${workspaceId}/public/services/${serviceId}/availability`

// ---Provider reviews---
export const providerReviewsRoute = (workspaceId, providerId) =>
  `/workspaces/${workspaceId}/public/providers/${providerId}/reviews`

// ---Bookings---
export const bookingsRoute = workspaceId => `/workspaces/${workspaceId}/public/bookings`
export const bookingRoute = (workspaceId, bookingId) => `/workspaces/${workspaceId}/public/bookings/${bookingId}`
export const bookingPendingClientRoute = (workspaceId, bookingId) =>
  `/workspaces/${workspaceId}/public/bookings/${bookingId}/pending_client`

// ---Timezones---
export const timezonesRoute = '/timezones'

// ---Email validation---
export const emailValidationRoute = '/email/validation'

// ---Custom names---
export const customNamesMappingRoute = workspaceId => `/workspaces/${workspaceId}/custom_names/mapping`

export const waitlistRequestsRoute = workspaceId => `/workspaces/${workspaceId}/public/waitlist_requests`
