import { createLogic } from 'redux-logic'
import cookies from 'component-cookie'

import { LOGOUT } from 'state/concepts/session/types'
import { logoutSuccess } from 'state/concepts/session/actions'
import removeClientCookies from 'utils/removeClientCookies'
import getCookiesDomain from 'utils/getCookiesDomain'
import { widgetSelector } from 'state/concepts/widget/selectors'
import { sessionRoute } from 'tools/apiRoutes'

const logoutOperation = createLogic({
  type: LOGOUT,
  latest: true,

  async process({ httpClient, getState }, dispatch, done) {
    const { workspace } = widgetSelector(getState())
    const { refresh: refreshToken } = JSON.parse(cookies('tokens'))

    await httpClient.delete(sessionRoute(workspace.uniqCode), { headers: { 'X-Refresh-Token': refreshToken } })

    removeClientCookies()
    removeClientCookies({
      path: `/client/${workspace.uniqCode}`,
      domain: getCookiesDomain(),
    })
    dispatch(logoutSuccess())
    done()
  },
})

export default logoutOperation
