import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import { buildStaticMapUrl, buildMapSearchUrl } from 'utils/locations'
import DetailsModal from 'views/shared/DetailsModal'
import * as S from './styled'

const LocationDetailsModal = ({ hideModal, location }) => (
  <DetailsModal handleClose={hideModal}>
    <S.Wrap>
      <S.TopWrap data-cy="location-details-modal">
        <S.Title data-cy="location-details-name">{location.name}</S.Title>
        <S.Info>
          <S.LocationWrap data-cy="location-details-address">
            <S.Icon iconName="location" size={20} />
            <S.LocationName>{location.addressLine}</S.LocationName>
          </S.LocationWrap>
          <S.LinkWrap data-cy="location-details-address-link">
            <S.Link target="_blank" href={buildMapSearchUrl(location.coordinate)}>
              <FormattedMessage id="locationsListView.viewOnGoogleMaps" />
            </S.Link>
          </S.LinkWrap>
        </S.Info>
      </S.TopWrap>
      <S.Map src={buildStaticMapUrl([location], { width: 648, height: 392 })} data-cy="location-details-map" />
    </S.Wrap>
  </DetailsModal>
)

LocationDetailsModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  location: PropTypes.shape().isRequired,
}

export default LocationDetailsModal
