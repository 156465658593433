import React from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'

const ReviewsEmpty = () => (
  <ReviewsEmpty.Wrapper>
    <svg width="136" height="136" viewBox="0 0 136 136" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1041_30058)">
        <path
          d="M59.93 62.72C60.0747 62.797 60.212 62.8874 60.34 62.99C61.87 64.19 64.23 66.87 66.25 66.99C68.27 67.11 71.16 65.52 73.1 64.85C73.265 64.7715 73.4515 64.7504 73.63 64.79C74 64.93 73.96 65.47 73.84 65.85C73.28 67.7433 72.6666 69.61 72 71.45C71.7795 71.9583 71.6639 72.5059 71.66 73.06C71.8145 73.9203 72.2507 74.7048 72.9 75.29L76 78.83C76.1271 78.94 76.2091 79.0932 76.23 79.26C76.23 79.63 75.75 79.77 75.38 79.79C73.8887 79.8516 72.3951 79.8082 70.91 79.66C70.1685 79.5349 69.4114 79.5349 68.67 79.66C68.029 79.8773 67.4388 80.2224 66.9352 80.6744C66.4315 81.1265 66.0249 81.6761 65.74 82.29C65.1741 83.5163 64.4803 84.6795 63.67 85.76C63.6016 85.8618 63.5114 85.9469 63.4058 86.0093C63.3003 86.0716 63.1821 86.1094 63.06 86.12C62.69 86.12 62.5 85.68 62.39 85.32C61.69 82.93 61.39 78.91 58.74 77.93C57.05 77.31 55.24 76.93 53.51 76.42C51.57 75.85 53.51 74.77 54.25 74.23C55.6607 73.42 56.999 72.4899 58.25 71.45C59.92 69.77 59 65.9 58.95 63.74C58.95 63.32 58.95 62.8 59.39 62.66C59.4791 62.6311 59.5733 62.6215 59.6664 62.6318C59.7595 62.6422 59.8494 62.6722 59.93 62.72Z"
          fill="currentColor"
        />
        <path
          d="M89.69 3.23998C88.62 1.89998 86.55 1.42998 84.83 1.13998C84.27 0.999985 83.7 0.999985 83.14 0.919985C73.12 -1.51992e-05 61 0.829985 50.94 0.929985C39.62 1.04998 29.94 1.24998 18.64 0.839985C14.18 0.679985 6.12 -1.33002 3 3.09998C2.07 4.43998 2.15 6.31998 2.11 7.99998C2.11 9.99998 1.78 12 1.75 14C2.2 13 16.34 13.86 17.61 13.86C23.61 13.86 90.42 15.74 90.42 12.73C90.42 9.71998 91.71 5.77998 89.69 3.23998Z"
          fill="#172949"
        />
        <path
          d="M84.8299 0.999972C84.2699 0.899972 83.6999 0.849972 83.1399 0.799972C73.1199 0.0799721 60.9999 0.729972 50.9399 0.809972C39.6199 0.909972 29.9399 1.06997 18.6399 0.739972C14.1799 0.609972 6.27991 -0.890028 3.17991 2.67997C2.38509 3.77645 2.00585 5.11972 2.10991 6.46997C1.78991 16.42 0.709912 26.35 0.579912 36.3C0.579912 39.96 -1.11009 46.12 3.57991 48.19C7.07991 49.73 11.7399 48.85 15.5099 48.74C22.1599 48.55 28.7999 48.28 35.4399 48.18C37.9999 48.18 40.8199 48.61 42.4399 50.25C44.6599 52.54 44.1499 56.19 44.3699 58.91C44.5301 60.0521 44.4414 61.2153 44.1099 62.32C43.9152 62.9357 43.4933 63.4542 42.9299 63.77C42.6172 63.941 42.2613 64.0169 41.906 63.9885C41.5507 63.9601 41.2115 63.8285 40.9299 63.61C40.2099 62.88 41.0599 61.84 41.9299 61.2C46.6099 57.65 50.9299 52.43 56.6799 50.1C61.7899 48 67.5899 48.05 73.1799 48.25C77.3399 48.4 84.7999 49.68 87.5899 46.25C88.3208 45.1817 88.7048 43.9142 88.6899 42.62C89.6199 31.85 90.4499 21.04 90.4499 10.24C90.4499 7.83997 91.3399 4.79997 89.3199 2.74997C88.0487 1.70188 86.4751 1.08858 84.8299 0.999972V0.999972Z"
          stroke="#172949"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M10.6199 9.81006C11.9896 9.81006 13.0999 8.69077 13.0999 7.31006C13.0999 5.92935 11.9896 4.81006 10.6199 4.81006C9.25023 4.81006 8.13989 5.92935 8.13989 7.31006C8.13989 8.69077 9.25023 9.81006 10.6199 9.81006Z"
          fill="white"
        />
        <path
          d="M17.24 9.81006C18.6097 9.81006 19.72 8.69077 19.72 7.31006C19.72 5.92935 18.6097 4.81006 17.24 4.81006C15.8703 4.81006 14.76 5.92935 14.76 7.31006C14.76 8.69077 15.8703 9.81006 17.24 9.81006Z"
          fill="white"
        />
        <path
          d="M109.43 66.7C108.919 65.9992 108.365 65.3312 107.77 64.7C107.38 64.29 106.83 63.86 106.3 64.07C105.61 64.34 105.7 65.34 105.94 66.07C106.94 69.07 108.81 71.75 109.69 74.79C109.21 75.04 108.91 74.79 108.61 74.33C106.53 71.21 104.97 67.79 103.12 64.57C101.27 61.35 98.28 58.57 95.66 56.08C95.02 55.46 93.8099 54.3 92.9199 55.34C92.8096 55.4911 92.7306 55.6627 92.6876 55.8447C92.6447 56.0268 92.6387 56.2156 92.6699 56.4C92.9227 57.6996 93.5242 58.906 94.41 59.89C96.2519 62.0959 97.9648 64.4064 99.54 66.81C100.46 68.37 102.11 70.45 102.32 72.29C102.69 75.55 100.06 72.44 99.32 71.09C95.84 64.77 91.19 57.84 83.9 55.64C83.5228 55.4763 83.1161 55.3918 82.705 55.3918C82.2938 55.3918 81.8871 55.4763 81.51 55.64C79.92 56.58 81.59 58.21 82.51 58.82C87.51 62.21 92.84 67.53 94.51 73.48C94.7873 74.2247 94.8119 75.04 94.58 75.8C93.58 78.38 89.86 75.8 88.44 75.14C84.6166 73.1262 80.5639 71.5817 76.37 70.54C75.5575 70.3277 74.7101 70.2835 73.8799 70.41C73.4729 70.4785 73.0888 70.6455 72.7611 70.8965C72.4334 71.1475 72.1721 71.4749 72 71.85C71.45 73.36 73.0899 74.29 74.3399 74.45C77.4848 74.9109 80.4464 76.2138 82.911 78.2207C85.3756 80.2276 87.2515 82.8638 88.3399 85.85C88.9224 87.2169 89.0142 88.7432 88.5999 90.17C87.2199 93.89 83.0199 93.17 80.28 91.53C78.1357 90.2042 76.3771 88.3387 75.18 86.12C74.46 84.77 73.94 80.05 72.26 79.7C70.86 79.4 70.0899 81.35 69.8499 82.43C68.6999 87.72 70.5 93.31 73.53 97.79C76.5599 102.27 80.7299 105.85 84.8399 109.37L93.76 117C95.1465 118.262 96.6462 119.393 98.2399 120.38C101.24 122.08 104.79 123.45 107.89 121.63C114.267 117.871 119.6 112.573 123.4 106.22C123.789 105.485 124.284 104.811 124.87 104.22C126.21 102.99 126.63 102.53 126.27 100.86C125.76 99.1556 125.143 97.4856 124.42 95.86C123.15 92.43 121.69 89.08 120.09 85.79C118.22 81.93 116.16 78.16 114.01 74.45C112.615 71.7911 111.086 69.2045 109.43 66.7Z"
          fill="white"
          stroke="#172949"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M129.29 98.65C128.951 98.6735 128.616 98.7338 128.29 98.83C126.51 99.32 125.03 101.08 123.53 102.14C121.25 103.74 119.04 105.44 116.83 107.14C112.022 110.857 107.406 114.815 103 119C99.86 122 96.73 124.17 97.72 128.95C98.59 133.17 102.9 133.16 104.96 136H136V109.44C134.53 106.196 132.86 103.047 131 100.01C130.41 99.1 130.12 98.63 129.29 98.65Z"
          fill="currentColor"
        />
        <path
          d="M102.99 128.27C104.448 128.27 105.63 127.088 105.63 125.63C105.63 124.172 104.448 122.99 102.99 122.99C101.532 122.99 100.35 124.172 100.35 125.63C100.35 127.088 101.532 128.27 102.99 128.27Z"
          fill="white"
        />
        <path
          d="M37.5299 68.15C26.1199 75.46 28.4499 93.3 42.4699 94.3C43.9467 94.4576 45.4401 94.332 46.8699 93.93C49.8699 92.99 52.0699 90.02 51.3399 87.93C50.6099 85.84 47.0899 85.15 44.2599 86.51C42.2897 87.5377 40.7095 89.1811 39.7599 91.19C30.6999 108.93 56.7599 114.47 70.7599 111.97"
          stroke="#172949"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M20.5001 20.63C20.5798 20.7316 20.6469 20.8424 20.7001 20.96C21.3501 22.37 22.0501 25.11 23.4301 25.96C24.8101 26.81 27.4301 26.68 29.0501 26.91C29.1967 26.9128 29.3379 26.9657 29.4501 27.06C29.6601 27.29 29.4501 27.66 29.2101 27.89C28.1201 29 27.0001 30.09 25.8701 31.15C25.5312 31.4237 25.2521 31.7641 25.0501 32.15C24.8604 32.8072 24.8849 33.5077 25.1201 34.15L26.0701 37.79C26.1099 37.9136 26.1099 38.0465 26.0701 38.17C25.9401 38.44 25.5401 38.37 25.2701 38.24C24.194 37.7487 23.1547 37.1806 22.1601 36.54C21.6791 36.1855 21.1415 35.915 20.5701 35.74C20.042 35.6667 19.5045 35.6997 18.9893 35.8371C18.4741 35.9745 17.9916 36.2135 17.5701 36.54C16.7304 37.2079 15.8219 37.7845 14.8601 38.26C14.772 38.2992 14.6766 38.3194 14.5801 38.3194C14.4837 38.3194 14.3883 38.2992 14.3001 38.26C14.0401 38.11 14.0601 37.74 14.1101 37.45C14.4801 35.45 15.7301 32.56 14.1901 30.9C13.1901 29.9 12.0801 28.9 11.0401 27.95C10.0001 27 11.6001 26.76 12.3501 26.66C13.6429 26.5997 14.9279 26.4257 16.1901 26.14C17.9801 25.55 18.7201 22.48 19.4701 20.94C19.6101 20.64 19.8401 20.29 20.1701 20.33C20.2439 20.3524 20.3118 20.391 20.3689 20.4429C20.426 20.4948 20.4708 20.5587 20.5001 20.63V20.63Z"
          stroke="#172949"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M43.3399 20.63C43.4196 20.7316 43.4868 20.8424 43.5399 20.96C44.1899 22.37 44.8899 25.11 46.2699 25.96C47.6499 26.81 50.2699 26.68 51.8899 26.91C52.0365 26.9127 52.1777 26.9657 52.2899 27.06C52.4999 27.29 52.2899 27.66 52.0499 27.89C50.9999 29 49.8499 30.09 48.7099 31.15C48.371 31.4236 48.0919 31.764 47.8899 32.15C47.7003 32.8072 47.7248 33.5077 47.9599 34.15L48.9099 37.79C48.9551 37.9126 48.9551 38.0473 48.9099 38.17C48.7799 38.44 48.3799 38.37 48.1099 38.24C47.0361 37.7594 45.9969 37.2047 44.9999 36.58C44.5189 36.2254 43.9813 35.9549 43.4099 35.78C42.8818 35.7067 42.3444 35.7397 41.8292 35.877C41.314 36.0144 40.8314 36.2534 40.4099 36.58C39.5652 37.2458 38.6536 37.8222 37.6899 38.3C37.6018 38.3391 37.5064 38.3594 37.4099 38.3594C37.3135 38.3594 37.2181 38.3391 37.1299 38.3C36.8699 38.15 36.8899 37.78 36.9399 37.49C37.3099 35.49 38.5599 32.6 37.0199 30.94C36.0199 29.94 34.9099 28.94 33.8699 27.99C32.8299 27.04 34.4299 26.8 35.1799 26.7C36.4651 26.6484 37.7433 26.4844 38.9999 26.21C40.7899 25.62 41.5399 22.55 42.2799 21.01C42.4199 20.71 42.6499 20.36 42.9799 20.4C43.0528 20.4106 43.1226 20.4367 43.1847 20.4763C43.2467 20.516 43.2996 20.5683 43.3399 20.63V20.63Z"
          stroke="#172949"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M66.51 20.63C66.5865 20.7319 66.6503 20.8427 66.7 20.96C67.35 22.37 68.05 25.11 69.43 25.96C70.81 26.81 73.43 26.68 75.05 26.91C75.1969 26.9111 75.3386 26.9642 75.45 27.06C75.66 27.29 75.45 27.66 75.22 27.89C74.13 29 73 30.09 71.87 31.15C71.531 31.4237 71.2519 31.7641 71.05 32.15C70.8603 32.8072 70.8848 33.5077 71.12 34.15L72.07 37.79C72.12 37.9118 72.12 38.0483 72.07 38.17C71.95 38.44 71.55 38.37 71.27 38.24C70.195 37.7465 69.1558 37.1785 68.16 36.54C67.6802 36.1835 67.1422 35.9128 66.57 35.74C66.0418 35.6667 65.5044 35.6997 64.9892 35.8371C64.474 35.9745 63.9915 36.2135 63.57 36.54C62.7252 37.2058 61.8137 37.7823 60.85 38.26C60.7618 38.2992 60.6664 38.3194 60.57 38.3194C60.4735 38.3194 60.3781 38.2992 60.29 38.26C60.03 38.11 60.05 37.74 60.1 37.45C60.47 35.45 61.72 32.56 60.18 30.9C59.18 29.9 58.07 28.9 57.03 27.95C55.99 27 57.59 26.76 58.35 26.66C59.6394 26.5991 60.921 26.425 62.18 26.14C63.97 25.55 64.72 22.48 65.46 20.94C65.61 20.64 65.83 20.29 66.16 20.33C66.2368 20.3511 66.3081 20.389 66.3686 20.4408C66.4291 20.4927 66.4774 20.5573 66.51 20.63V20.63Z"
          stroke="#172949"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_1041_30058">
          <rect width="136" height="136" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </ReviewsEmpty.Wrapper>
)

ReviewsEmpty.Wrapper = styled.div`
  color: ${({ theme }) => theme.settings.color};
`

ReviewsEmpty.Light = styled.path`
  fill: ${({ theme }) => rgba(theme.settings.color, 0.5)};
`

export default ReviewsEmpty
