import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { prop } from 'ramda'
import { FormattedMessage } from 'react-intl'

import { PROVIDER_MODAL_TABS } from 'constants'
import { isReviewsFeatureEnabledSelector } from 'state/concepts/widget/selectors'
import profileName from 'utils/profileName'
import DetailsModal from 'views/shared/DetailsModal'
import TabsProvider from 'views/shared/tabs/TabsProvider'
import TabHeader from 'views/shared/tabs/TabHeader'
import TabContent from 'views/shared/tabs/TabContent'
import FormattedPrice from 'views/shared/FormattedPrice'
import About from './About'
import Reviews from './Reviews'
import * as S from './styled'

const ProviderModal = ({ provider, hideModal, handleSelect, activeTab }) => {
  const isReviewsFeatureEnabled = useSelector(isReviewsFeatureEnabledSelector)

  return (
    <DetailsModal
      price={<FormattedPrice {...provider} />}
      handleClose={hideModal}
      handleSelect={handleSelect?.(provider)}
      actionButtonText={{ id: 'shared.selectExpert' }}
    >
      <TabsProvider activeTab={activeTab}>
        <S.ExpertInfoContainer>
          <S.ExpertMain>
            <S.ExpertInfoWrap>
              <S.ExpertImage avatarUrls={provider.avatarUrls} size={80} data-cy="provider-profile-avatar" />
              <S.ExpertName data-cy="provider-profile-provider-name">{profileName(provider)}</S.ExpertName>
              <S.ExpertPosition data-cy="provider-profile-provider-position">{provider.position}</S.ExpertPosition>
            </S.ExpertInfoWrap>
            <S.ExpertTabsWrap>
              <TabHeader tab={PROVIDER_MODAL_TABS.about}>
                <S.ExpertTab data-cy="provider-modal-about-tab">
                  <span>
                    <FormattedMessage id="providersListView.modal.aboutTab" />
                  </span>
                </S.ExpertTab>
              </TabHeader>
              {isReviewsFeatureEnabled && (
                <TabHeader tab={PROVIDER_MODAL_TABS.reviews}>
                  <S.ExpertTab data-cy="provider-modal-reviews-tab">
                    <span>
                      <FormattedMessage id="providersListView.modal.reviewsTab" />
                    </span>
                    <S.ExpertTabQuant>{prop('publishedReviewsCount', provider)}</S.ExpertTabQuant>
                  </S.ExpertTab>
                </TabHeader>
              )}
            </S.ExpertTabsWrap>
          </S.ExpertMain>
        </S.ExpertInfoContainer>
        <S.ExpertContent>
          <S.ExpertContainer>
            <TabContent tab={PROVIDER_MODAL_TABS.about}>
              <About provider={provider} handleSelect={handleSelect} />
            </TabContent>
            <TabContent tab={PROVIDER_MODAL_TABS.reviews}>
              <Reviews provider={provider} />
            </TabContent>
          </S.ExpertContainer>
        </S.ExpertContent>
      </TabsProvider>
    </DetailsModal>
  )
}

ProviderModal.defaultProps = {
  handleSelect: undefined,
  activeTab: PROVIDER_MODAL_TABS.about,
}

ProviderModal.propTypes = {
  provider: PropTypes.shape().isRequired,
  hideModal: PropTypes.func.isRequired,
  handleSelect: PropTypes.func,
  activeTab: PropTypes.string,
}

export default ProviderModal
