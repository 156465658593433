import { find } from 'ramda'

const findTimezoneByName = allTimezones => {
  const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  return find(({ attributes: { tzinfoName } }) => tzinfoName === browserTimezone, allTimezones)
}

const getTimezoneOffset = () => {
  const offsetMinutes = new Date().getTimezoneOffset() / -60
  let offsetHours = parseInt(Math.abs(offsetMinutes), 10)

  if (offsetHours < 10) {
    offsetHours = `0${offsetHours}`
  }

  return offsetMinutes < 0 ? `-${offsetHours}:00` : `+${offsetHours}:00`
}

const findTimeZoneByOffset = allTimezones => {
  const timezoneOffset = getTimezoneOffset()

  return find(({ attributes: { offset } }) => offset === timezoneOffset, allTimezones)
}

export default allTimezones => findTimezoneByName(allTimezones) || findTimeZoneByOffset(allTimezones)
