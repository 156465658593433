import React from 'react'
import PropTypes from 'prop-types'

import SvgIcon from 'views/shared/Icons'
import CalendarLoader from './CalendarLoader'
import * as S from './styled'

const DatePicker = ({ date, onChange, minDate, setTileDisable, onPrevNextClick, isLoading, selectRange, ...props }) => (
  <S.Container>
    {isLoading && <CalendarLoader size={22} />}
    <S.DatePicker
      {...props}
      value={date}
      minDate={minDate.toJSDate()}
      minDetail="month"
      calendarType="gregory"
      onChange={onChange}
      tileDisabled={setTileDisable}
      locale="en"
      onActiveStartDateChange={onPrevNextClick}
      prevLabel={<SvgIcon iconName="leftArrow" size={22} />}
      nextLabel={<SvgIcon iconName="rightArrow" size={22} />}
      prev2Label={null}
      next2Label={null}
      isLoading={isLoading}
      selectRange={selectRange}
      allowPartialRange={selectRange}
    />
  </S.Container>
)

DatePicker.defaultProps = {
  isLoading: undefined,
  onPrevNextClick: undefined,
  date: null,
  selectRange: false,
}

DatePicker.propTypes = {
  date: PropTypes.shape({
    toJSDate: PropTypes.func.isRequired,
  }),
  minDate: PropTypes.shape({
    toJSDate: PropTypes.func.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  setTileDisable: PropTypes.func.isRequired,
  onPrevNextClick: PropTypes.func,
  isLoading: PropTypes.bool,
  selectRange: PropTypes.bool,
}

export default DatePicker
