import styled from 'styled-components'

import SvgIcon from 'views/shared/Icons'

const Wrapper = styled.div`
  position: fixed;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  ${({ theme }) => theme.settings.position}: 30px;
  ${({ theme }) => theme.settings.buttonPosition}: 30px;
  z-index: 99999;
`

const ButtonIcon = styled(SvgIcon)`
  &&& {
    fill: ${({ theme }) => theme.settings.buttonIconColor};
  }
`

const ButtonText = styled.p`
  color: ${({ theme }) => theme.settings.buttonTextColor};
`

export { Wrapper, ButtonText, ButtonIcon }
