import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import { rgba } from 'polished'
import SvgIcon from 'views/shared/Icons'

export const BackButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
  cursor: pointer;
  width: 36px;
  height: 36px;
  background-color: ${({ theme }) => rgba(theme.settings.color, 0.1)};
  border-radius: 2px;

  ${media.tablet`
    left: 36px; 
  `}

  ${media.desktop`
    width: auto;
    height: auto;
    background-color: transparent;
  `}
`

export const BackText = styled.span`
  line-height: ${20 / 14};
  color: ${({ theme }) => theme.colors.black700};
  font-weight: 600;
  display: none;

  ${media.desktop`
    display: block;
  `}
`

export const BackIcon = styled(SvgIcon)`
  transform: rotate(90deg);
  fill: ${({ theme }) => theme.settings.color} !important;

  ${media.desktop`
    margin-right: 12px;
    fill: ${({ theme }) => theme.colors.black400} !important;
  `}
`
