import React from 'react'
import PropTypes from 'prop-types'
import { prop } from 'ramda'

import * as S from './styled'

const OptionComponent = ({ option, onClick, isSelected }) => (
  <S.Option onClick={onClick} data-cy="select-option">
    {prop('label', option)}
    {isSelected && <S.OptionActive iconName="checked" size={18} />}
  </S.Option>
)

OptionComponent.displayName = 'OptionComponent'

OptionComponent.propTypes = {
  option: PropTypes.shape().isRequired,
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default OptionComponent
