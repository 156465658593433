import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import * as S from './styled'

const LocationCardComponent = ({ location, hasLocations }) =>
  hasLocations && (
    <S.TopWrap>
      <S.StyledContainer fluid>
        <S.TopIcon src={`${ASSET_CDN}/assets/images/location.svg`} />
        <S.TopInfo>
          <div data-cy="location-card-title">
            <S.TopTitle>Location</S.TopTitle>
          </div>
          <S.TopDescription>
            {location ? (
              <>
                <div data-cy="location-card-name">
                  <S.TopName>{location?.name}</S.TopName>
                </div>
                <S.TopAddressWrap>
                  <S.Icon iconName="location" size={20} />
                  <div data-cy="location-card-address">
                    <S.TopAddress>{location?.addressLine}</S.TopAddress>
                  </div>
                </S.TopAddressWrap>
              </>
            ) : (
              <>
                <S.TopName data-cy="online-location-card-description">
                  <FormattedMessage id="location.onlineAppointment" />
                </S.TopName>
                <S.Icon iconName="video" size={20} />
              </>
            )}
          </S.TopDescription>
        </S.TopInfo>
      </S.StyledContainer>
    </S.TopWrap>
  )

LocationCardComponent.defaultProps = {
  location: null,
}

LocationCardComponent.propTypes = {
  location: PropTypes.shape(),
  hasLocations: PropTypes.bool.isRequired,
}

export default LocationCardComponent
