import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled, { ThemeProvider } from 'styled-components'
import { Transition } from 'react-transition-group'

import applyTheme from 'utils/applyTheme'
import isPresent from 'utils/isPresent'
import Loader from 'views/shared/Loader'
import OpenButton from 'views/OpenButton'
import ContentLayout from './ContentLayout'

const AnimatedLayout = memo(
  ({ children, settings, isExpanded, isEverOpened, handleAnimationEntered, handleOpenWidget, handleCloseWidget }) => (
    <ThemeProvider theme={applyTheme(settings)}>
      {isPresent(settings) && (
        <>
          {handleOpenWidget && ['button', 'both'].includes(settings.installationType) && (
            <OpenButton {...settings} onClick={handleOpenWidget} />
          )}
          {isExpanded && <AnimatedLayout.Overlay />}
          <Transition in={isExpanded} timeout={400} onEntered={handleAnimationEntered}>
            {state => (
              <AnimatedLayout.Wrapper isExpanded={isExpanded}>
                <ContentLayout handleCloseWidget={handleCloseWidget}>
                  {!isEverOpened && state === 'entering' && (
                    <AnimatedLayout.LoadingWrapper>
                      <Loader size={72} />
                    </AnimatedLayout.LoadingWrapper>
                  )}
                  {(isEverOpened || state === 'entered') && children}
                </ContentLayout>
              </AnimatedLayout.Wrapper>
            )}
          </Transition>
        </>
      )}
    </ThemeProvider>
  ),
)
AnimatedLayout.displayName = 'AnimatedLayout'

AnimatedLayout.defaultProps = {
  settings: null,
  handleOpenWidget: undefined,
  handleCloseWidget: undefined,
}

AnimatedLayout.propTypes = {
  children: PropTypes.node.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  isEverOpened: PropTypes.bool.isRequired,
  settings: PropTypes.shape({
    installationType: PropTypes.string,
  }),
  handleOpenWidget: PropTypes.func,
  handleCloseWidget: PropTypes.func,
  handleAnimationEntered: PropTypes.func.isRequired,
}

AnimatedLayout.Wrapper = memo(styled.div`
  position: fixed;
  top: 0;
  ${({ theme }) => theme.settings.position}: 0;
  width: 100vw;
  max-width: 1120px;
  height: 100%;
  /* stylelint-disable */
  transition: ${({ isExpanded }) => {
    if (isExpanded) {
      return 'transform .4s linear'
    }
    return 'transform .2s linear, opacity .0s .2s linear'
  }};
  opacity: ${({ isExpanded }) => (isExpanded ? '1' : '0')};
  will-change: transform;
  transform: ${({ isExpanded, theme }) => {
    if (isExpanded) {
      return 'translateX(0)'
    }
    return theme.settings.position === 'right' ? 'translateX(100%)' : 'translateX(-100%)'
  }};
  z-index: 99999;
`)

AnimatedLayout.Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(${({ theme }) => theme.colors.overlayColor}, 0.5);
  z-index: 99990;
`

AnimatedLayout.LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  max-width: ${({ theme }) => theme.mainWidth}px;
  background: white;
`

export default AnimatedLayout
