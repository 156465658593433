import * as types from './types'

export const fetchLocations = () => ({
  type: types.FETCH_LOCATIONS,
})

export const setLocations = locationIds => ({
  type: types.SET_LOCATIONS,
  locationIds,
})

export const setLocation = locationId => ({
  type: types.SET_LOCATION,
  locationId,
})

export const getLocationDetails = (locationId, history) => ({
  type: types.GET_LOCATION_DETAILS,
  locationId,
  history,
})
