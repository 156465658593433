const namespace = 'services'

export const SET_SERVICE = `${namespace}/SET_SERVICE`
export const FETCH_SERVICE_CATEGORIES = `${namespace}/FETCH_SERVICE_CATEGORIES`
export const SET_SERVICE_CATEGORIES = `${namespace}/SET_SERVICE_CATEGORIES`
export const FETCH_SERVICES = `${namespace}/FETCH_SERVICES`
export const SET_SERVICES = `${namespace}/SET_SERVICES`
export const SET_SERVICES_PAGE = `${namespace}/SET_SERVICES_PAGE`
export const SET_SERVICES_FILTER_PARAMS = `${namespace}/SET_SERVICES_FILTER_PARAMS`
export const FILTER_SERVICES = `${namespace}/FILTER_SERVICES`
export const RESET_SERVICES = `${namespace}/RESET_SERVICES`
