import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

import SvgIcon from 'views/shared/Icons'

const Wrapper = styled.div`
  padding: 16px;
  padding-bottom: 12px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.black300};
  margin-bottom: 8px;
  background-color: white;
  transition: border-color 0.15s;

  &:hover {
    ${media.tablet`
      border-color: ${({ theme }) => theme.settings.color};
    `}

    ${media.desktop`
      padding: 24px 32px;
    `}
  }

  ${media.tablet`
    padding-right: 24px;
    padding-left: 24px;
    padding-bottom: 16px;
    margin-bottom: 16px;
  `}

  ${media.desktop`
    padding: 24px 32px;
  `}
`

const Container = styled.div`
  display: flex;
  margin-bottom: 16px;
  flex-direction: column;

  ${media.desktop`
    flex-direction: row;
  `}
`

const ContainerLeft = styled.div`
  margin-bottom: 4px;
  flex-shrink: 0;

  ${media.desktop`
    flex-grow: 1;
    flex-shrink: 1;
    margin-bottom: 0;
    padding-right: 12px;
    max-width: calc(100% - 176px);
  `}

  .service-duration {
    margin-bottom: 0;
    font-size: 14px;
    line-height: ${20 / 14};

    ${media.tablet`
      font-size: 16px;
      line-height: ${24 / 16};
    `}
  }
`

const ContainerRight = styled.div`
  display: flex;
  align-items: center;

  ${media.desktop`
    flex-direction: column;
    flex-shrink: 1;
    align-items: flex-end;
    width: 176px;
  `}

  .service-duration {
    margin-bottom: 0;
  }
`

const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: space-between;
  flex-shrink: 0;

  ${media.desktop`
    flex-grow: 0;
    flex-direction: column;
    align-items: flex-end;
  `}
`

const Price = styled.div`
  display: flex;
  align-items: center;

  ${media.tablet`
    margin-right: 12px;
  `}

  ${media.desktop`
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 24px;
    margin-right: 0;
  `}
`

const PriceValue = styled.p`
  font-family: ${({ theme }) => theme.fonts.interTight};
  color: ${({ theme }) => theme.colors.black1000};
  font-size: 16px;
  text-align: right;
  font-weight: 600;
  display: ${({ visibleMobile }) => (visibleMobile ? 'block' : 'none')};

  ${media.desktop`
    display: ${({ visibleMobile }) => (visibleMobile ? 'none' : 'block')};
    font-size: 20px;
    line-height: 1.6;
  `}
`

const PriceButtonWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  ${media.desktop`
    width: auto;
  `}
`

const SlotsTitle = styled.p`
  margin-bottom: 8px;
  line-height: ${20 / 14};
  font-weight: 600;
  color: ${({ theme }) => theme.colors.black700};
`

const CardBottom = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: space-between;

  ${media.desktop`
    flex-direction: row;
  `}
`

const ExpertAvailable = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.black700};
  margin-top: 16px;
  padding-top: 12px;
  width: 100%;

  &::before {
    content: '';
    position: absolute;
    width: calc(100% + 32px);
    height: 1px;
    top: 0;
    left: -16px;
    background: ${({ theme }) => theme.colors.black300};
  }

  ${media.tablet`
    padding-top: 16px;
  `}

  ${media.desktop`
    border-top-width: 0;
    margin-top: 0;
    padding-top: 0;
    &:before{
      background: transparent;
    }
  `}
`

const ExpertCount = styled.span`
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  margin-right: 4px;

  ${media.desktop`
    font-size: 16px;
    line-height: 24px;
  `}
`

const ExpertCountDescr = styled.span`
  font-size: 14px;
  line-height: 20px;

  ${media.desktop`
    font-size: 16px;
    line-height: 24px;
  `}
`

const ExpertIcon = styled(SvgIcon)`
  fill: ${({ theme }) => theme.settings.color};
`

const DurationIcon = styled(SvgIcon)`
  margin-right: 4px;
  font-size: ${({ visibleMobile }) => (visibleMobile ? 20 : 24)}px;
  fill: ${({ theme }) => theme.colors.black500};
`

const PaymentRequired = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.black700};

  ${media.desktop`
    font-size: 16px;
    line-height: 24px;
  `}
`

const DotDivider = styled.span`
  width: 4px;
  height: 4px;
  background: ${props => props.theme.colors.black500};
  display: inline-block;
  margin-left: 12px;
  margin-right: 12px;
  border-radius: 50%;
`

export {
  Wrapper,
  ContainerLeft,
  ContainerRight,
  PriceContainer,
  Container,
  PriceButtonWrap,
  Price,
  PriceValue,
  SlotsTitle,
  CardBottom,
  ExpertAvailable,
  ExpertCount,
  ExpertIcon,
  DurationIcon,
  ExpertCountDescr,
  PaymentRequired,
  DotDivider,
}
