import styled from 'styled-components'

import SvgIcon from 'views/shared/Icons'

const Info = styled.section`
  display: flex;
  margin-bottom: ${({ offsetBottom }) => offsetBottom}px;
  align-items: self-start;
`
const VideoCallImage = styled.img`
  fill: ${({ theme }) => theme.settings.color};
  margin-right: 16px;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
`

const InfoIcon = styled(SvgIcon)`
  fill: ${({ theme }) => theme.settings.color};
  margin-right: 16px;
  flex-shrink: 0;
`

const InfoValue = styled.p`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.black1000};
  line-height: ${24 / 16};
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 4px;
`

const InfoDescription = styled.p`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.black700};
  line-height: ${24 / 16};
  overflow: hidden;
  text-overflow: ellipsis;
`
const InfoLink = styled.a`
  font-size: 16px;
  color: ${({ theme }) => theme.settings.color};
  line-height: ${24 / 16};
  overflow: hidden;
  text-overflow: ellipsis;
`

export { Info, InfoIcon, InfoDescription, InfoValue, InfoLink, VideoCallImage }
