import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { isEqual } from 'lodash'
import noop from 'lodash/noop'

import SelectFieldComponent from './component'

class SelectField extends React.Component {
  static propTypes = {
    form: PropTypes.shape({
      setFieldTouched: PropTypes.func.isRequired,
    }).isRequired,
    field: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
      name: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.number,
        PropTypes.shape(),
        PropTypes.arrayOf(PropTypes.string),
      ]),
    }).isRequired,
    onChange: PropTypes.func,
  }

  static defaultProps = {
    onChange: noop,
  }

  shouldComponentUpdate(nextProps) {
    return !isEqual(nextProps, this.props)
  }

  handleChange = value => {
    const { field, onChange } = this.props
    onChange(value, field.value)
    field.onChange({ target: { name: field.name, value } })
  }

  handleClear = () => {
    const { field } = this.props
    field.onChange({ target: { name: field.name, value: '' } })
  }

  handleOpen = () => {
    const { field, form } = this.props
    form.setFieldTouched(field.name, true, true)
  }

  render = () => (
    <SelectFieldComponent
      {...this.props}
      onChange={this.handleChange}
      onClear={this.handleClear}
      onOpen={this.handleOpen}
    />
  )
}

export { SelectField as SelectFieldContainer }
export default injectIntl(SelectField)
