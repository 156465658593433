import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

import SvgIcon from 'views/shared/Icons'

const Wrapper = styled.section`
  padding: 12px 16px;
  max-width: 456px;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  border: 1px solid ${({ theme }) => theme.colors.black300};

  ${media.tablet`
    padding: 24px;
  `}

  ${media.lg`
    padding: 24px 32px;
  `}

  ${media.desktop`
    max-width: initial;
    margin-left: initial;
    margin-right: initial;
  `}
`

const Title = styled.p`
  font-size: 16px;
  line-height: ${24 / 16};
  font-weight: 600;
  margin-bottom: 12px;
  color: ${({ theme }) => theme.colors.black1000};
`

const AttendeesIcon = styled(SvgIcon)`
  fill: ${({ theme }) => theme.settings.color};
  margin-right: 16px;
`

const Description = styled.p`
  line-height: ${20 / 14};
  margin-bottom: 0;
  color: ${({ theme }) => theme.colors.black700};
`

export { Wrapper, Title, Description, AttendeesIcon }
