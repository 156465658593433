import { createLogic } from 'redux-logic'
import { pathEq } from 'ramda'

import { STEP_TYPE_PROPERTIES } from 'constants'
import { updateSteps, resolveStepsOrder } from '../actions'
import { PROCEED_STEP } from '../types'
import { findStepByTypeSelector, findStepByNumberSelector } from '../selectors'

const proceedStepOperation = createLogic({
  type: PROCEED_STEP,
  warnTimeout: 0,

  async process({ action: { stepType }, getState }, dispatch, done) {
    const state = getState()

    const existingStep = findStepByTypeSelector(state, stepType)

    if (!existingStep) {
      const nextEmpty = findStepByTypeSelector(state, null)

      if (nextEmpty) {
        const [key] = nextEmpty
        const previousStep = findStepByNumberSelector(state, key - 1)

        if (key === '1' || pathEq(STEP_TYPE_PROPERTIES.location, [1, 'type'], previousStep)) {
          dispatch(resolveStepsOrder(stepType))
        }

        dispatch(updateSteps({ [key]: { type: stepType } }))
      }
    }

    done()
  },
})

export default proceedStepOperation
