import React from 'react'
import { PropTypes } from 'prop-types'
import { connect } from 'react-redux'

import { WIDGET_STATUS } from 'constants'
import {
  servicesCountSelector,
  providersCountSelector,
  workspaceLockedSelector,
  widgetStatusSelector,
  widgetLoadingSelector,
  hasManyLocationsSelector,
} from 'state/concepts/widget/selectors'
import BookingViewComponent from './component'

class BookingView extends React.Component {
  get isWidgetEnabled() {
    const { workspaceLocked, servicesCount } = this.props
    const atLeastOneServiceExist = servicesCount > 0
    const workspaceIsNotLocked = !workspaceLocked

    return atLeastOneServiceExist && workspaceIsNotLocked
  }

  get isActive() {
    const { widgetStatus } = this.props
    return widgetStatus === WIDGET_STATUS.active
  }

  get isEmpty() {
    const { isLoading } = this.props
    return !isLoading && !this.isWidgetEnabled && this.isActive
  }

  get isErrorShown() {
    const { isLoading } = this.props
    return !isLoading && !this.isWidgetEnabled && !this.isActive
  }

  get isBookingShown() {
    const { isLoading } = this.props
    return !isLoading && !this.isEmpty && !this.isErrorShown
  }

  render = () => (
    <BookingViewComponent
      {...this.props}
      isEmpty={this.isEmpty}
      isErrorShown={this.isErrorShown}
      isBookingShown={this.isBookingShown}
    />
  )
}

BookingView.defaultProps = {
  servicesCount: null,
  isLoading: undefined,
  workspaceLocked: undefined,
}

BookingView.propTypes = {
  servicesCount: PropTypes.number,
  isLoading: PropTypes.bool,
  workspaceLocked: PropTypes.bool,
  widgetStatus: PropTypes.string.isRequired,
}

const mapStateToProps = state => ({
  isLoading: widgetLoadingSelector(state),
  servicesCount: servicesCountSelector(state),
  providersCount: providersCountSelector(state),
  workspaceLocked: workspaceLockedSelector(state),
  widgetStatus: widgetStatusSelector(state),
  hasManyLocations: hasManyLocationsSelector(state),
})

export { BookingView as BookingViewContainer }
export default connect(mapStateToProps)(BookingView)
