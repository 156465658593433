import { widgetOperations } from './widget'
import { servicesOperations } from './services'
import { providersOperations } from './providers'
import { timezonesOperations } from './timezones'
import { availabilityOperations } from './availability'
import { bookingOperations } from './booking'
import { reviewsOperations } from './reviews'
import { locationsOperations } from './locations'
import { sessionOperations } from './session'
import { customNamesOperations } from './customNames'
import { waitlistRequestOperations } from './waitlistRequest'

export default [
  ...widgetOperations,
  ...servicesOperations,
  ...providersOperations,
  ...timezonesOperations,
  ...availabilityOperations,
  ...bookingOperations,
  ...reviewsOperations,
  ...locationsOperations,
  ...sessionOperations,
  ...customNamesOperations,
  ...waitlistRequestOperations,
]
