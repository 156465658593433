import React from 'react'
import PropTypes from 'prop-types'
import { isNil } from 'ramda'
import { useFormikContext } from 'formik'

import isSubmitDisabledFunc from 'utils/form/isSubmitDisabled'
import Button from 'views/shared/Button'

const SubmitFormButton = ({ isSubmitting, allowPristine, isDisabled, ...rest }) => {
  const formikCtx = useFormikContext()
  const isFormSubmitting = !isNil(isSubmitting) ? isSubmitting : formikCtx.isSubmitting
  const isSubmitDisabled = !isNil(isDisabled) ? isDisabled : isSubmitDisabledFunc(formikCtx, { allowPristine })

  return (
    <Button
      {...rest}
      onClick={formikCtx.handleSubmit}
      type="submit"
      isSubmitting={isFormSubmitting}
      disabled={isSubmitDisabled}
    />
  )
}

SubmitFormButton.defaultProps = {
  isDisabled: null,
  isSubmitting: false,
  allowPristine: false,
}

SubmitFormButton.propTypes = {
  isDisabled: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  allowPristine: PropTypes.bool,
}

export default SubmitFormButton
