import React from 'react'
import PropTypes from 'prop-types'
import { times } from 'lodash'

import { MAX_REVIEW_RATING } from 'constants'
import * as S from './styled'

const ReviewStarRating = ({ rating }) => (
  <>
    {times(rating, key => (
      <S.ReviewStarIcon key={key} iconName="starSolid" isFilled dataCy="review-star-is-filled" />
    ))}
    {times(MAX_REVIEW_RATING - rating, key => (
      <S.ReviewStarIcon key={key} iconName="starSolid" dataCy="review-star-is-not-filled" />
    ))}
  </>
)

ReviewStarRating.propTypes = {
  rating: PropTypes.number.isRequired,
}

export default ReviewStarRating
