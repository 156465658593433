import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import splitMessage from 'utils/splitMessage'

const PaymentInstructions = ({ instructions }) => (
  <>
    <FormattedMessage id="bookingSummary.paymentInstructions" />
    {splitMessage(instructions).map((text, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <p key={index}>{text}</p>
    ))}
  </>
)

PaymentInstructions.propTypes = {
  instructions: PropTypes.string.isRequired,
}

export default PaymentInstructions
