import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { prop } from 'ramda'

import cancellationPhrase from 'utils/cancellationPhrase'
import profileName from 'utils/profileName'
import { formattedDay, formattedMonth, formattedDate } from 'utils/dateTime'
import { formatDuration } from 'utils/duration'
import Avatar from 'views/shared/Avatar'
import FormattedPrice from 'views/shared/FormattedPrice'
import { buildMapSearchUrl } from 'utils/locations'
import { ONLINE_LOCATION_ICONS } from 'constants/videoCalls'
import InfoItem from './InfoItem'
import * as S from './styled'
import PaymentInstructions from './PaymentInstructions'

const BookingSummary = ({
  service,
  provider,
  time,
  bookingSettings,
  isWithPayment,
  calendarLinks,
  isWithPriceAndWithoutStripe,
  price,
  location,
  workspace,
  videoCallProvider,
}) => (
  <S.Wrapper>
    <S.SummaryInfo>
      <S.Col leftColumn>
        <S.SummaryInfoDate>
          <S.SummaryInfoDayWrap>
            <S.SummaryInfoDay data-cy="summary-info-day">
              {formattedDay(time, prop('duration', service))}
            </S.SummaryInfoDay>
            <S.SummaryInfoMonth data-cy="summary-info-month">
              {formattedMonth(time, prop('duration', service))}
            </S.SummaryInfoMonth>
          </S.SummaryInfoDayWrap>
          <S.SummaryInfoTime data-cy="summary-info-time">
            {formattedDate(time, prop('duration', service))}
          </S.SummaryInfoTime>
        </S.SummaryInfoDate>
      </S.Col>
      <S.Col>
        <InfoItem
          dataCy="info-item-service"
          offsetBottom={24}
          value={prop('name', service)}
          description={formatDuration(prop('duration', service))}
          icon="bag"
        />
        <S.ConsultantAva>
          <S.ConsultantIcon iconName="expertRounded" size={20} />
          <Avatar avatarUrls={prop('avatarUrls', provider)} size={24} offsetRight={12} />
          <S.ConsultantName data-cy="summary-info-provider-name">{profileName(provider)}</S.ConsultantName>
        </S.ConsultantAva>
        <S.Consultant>
          {(isWithPayment || isWithPriceAndWithoutStripe) && (
            <S.Price>
              <S.PriceIcon iconName="price" size={20} />
              <div>
                <S.PriceValue>
                  <FormattedPrice price={price} />
                </S.PriceValue>
                {isWithPriceAndWithoutStripe && (
                  <S.PriceDescription>
                    {workspace.paymentInstructions ? (
                      <PaymentInstructions instructions={workspace.paymentInstructions} />
                    ) : (
                      <FormattedMessage id="confirmationView.youWillPayToExpert" />
                    )}
                  </S.PriceDescription>
                )}
              </div>
            </S.Price>
          )}
        </S.Consultant>
        {location ? (
          <InfoItem
            offsetBottom={24}
            value={location.name}
            description={location.addressLine}
            icon="location"
            link={buildMapSearchUrl(location.coordinate)}
            linkText={{ id: 'locationsListView.viewOnGoogleMaps' }}
          />
        ) : (
          <InfoItem
            offsetBottom={0}
            value={{ id: `reviewBookingView.online.${videoCallProvider}` }}
            description={{ id: 'reviewBookingView.getTheLink' }}
            imgUrl={ONLINE_LOCATION_ICONS[videoCallProvider]}
          />
        )}
      </S.Col>
    </S.SummaryInfo>
    <S.ActionInfoWrapper>
      <S.SummaryCalendarButtons>
        <S.SummaryCalendarButton href={calendarLinks.googleCalendarLink} target="_blank">
          <S.SummaryCalendarLinkText>
            <FormattedMessage id="reviewBookingView.googleLink" />
          </S.SummaryCalendarLinkText>
        </S.SummaryCalendarButton>
        <S.SummaryCalendarButton href={calendarLinks.icalendarLink} target="_blank">
          <S.SummaryCalendarLinkText>
            <FormattedMessage id="reviewBookingView.outlookOrAppleLink" />
          </S.SummaryCalendarLinkText>
        </S.SummaryCalendarButton>
      </S.SummaryCalendarButtons>
      <S.InfoTitle as="p">
        <FormattedMessage {...cancellationPhrase(bookingSettings, isWithPayment)} />
      </S.InfoTitle>
    </S.ActionInfoWrapper>
  </S.Wrapper>
)

BookingSummary.defaultProps = {
  location: null,
}

BookingSummary.propTypes = {
  service: PropTypes.shape().isRequired,
  provider: PropTypes.shape().isRequired,
  time: PropTypes.string.isRequired,
  bookingSettings: PropTypes.shape().isRequired,
  calendarLinks: PropTypes.shape({
    googleCalendarLink: PropTypes.string.isRequired,
    icalendarLink: PropTypes.string.isRequired,
  }).isRequired,
  isWithPayment: PropTypes.bool.isRequired,
  isWithPriceAndWithoutStripe: PropTypes.bool.isRequired,
  price: PropTypes.string.isRequired,
  videoCallProvider: PropTypes.string.isRequired,
  location: PropTypes.shape(),
  workspace: PropTypes.shape().isRequired,
}

export default BookingSummary
