import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { useBoolean } from 'react-use'

import { formattedMonthTime, formattedDate } from 'utils/dateTime'
import { getDateText, getTimeText } from 'utils/availabilityPreferences/timeFormatter'
import { currentStepSelector } from 'state/steps/selectors'
import { STEP_TYPE_PROPERTIES } from 'constants'
import { timezoneOffsetSelector } from 'state/concepts/time/selectors'
import * as S from './styled'

const TimeInfo = ({ time, availabilityPreferences }) => {
  const currentStep = useSelector(currentStepSelector)
  const timezoneOffset = useSelector(timezoneOffsetSelector)

  const [, { type: currentType }] = currentStep || [{}, {}]
  const isActive = STEP_TYPE_PROPERTIES.time === currentType
  const [morePreferences, toggleMorePreferences] = useBoolean(false)
  const visiblePreferences = morePreferences ? availabilityPreferences : availabilityPreferences?.slice(0, 3)

  return (
    <S.TimeStepInfo data-cy="widget-stepper-time">
      <S.StepName>
        <FormattedMessage id="stepper.dateAndTime" />
      </S.StepName>
      {time && (
        <>
          <S.Title>
            <span>{`${formattedDate(time)}`}</span>
          </S.Title>
          <S.Desc>
            <span>{formattedMonthTime(time)}</span>
          </S.Desc>
        </>
      )}
      {visiblePreferences && !isActive && (
        <>
          {visiblePreferences.map((availability, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index}>
              <S.Title>
                <FormattedMessage {...getDateText(availability)} />
              </S.Title>
              <S.Desc>
                <FormattedMessage {...getTimeText(availability, timezoneOffset)} />
              </S.Desc>
            </div>
          ))}
          {availabilityPreferences.length > 3 && (
            <S.ShowButton onClick={toggleMorePreferences}>
              <FormattedMessage id={morePreferences ? 'shared.showLessText' : 'shared.showMore'} />
            </S.ShowButton>
          )}
        </>
      )}
    </S.TimeStepInfo>
  )
}

TimeInfo.defaultProps = {
  time: null,
  availabilityPreferences: null,
}

TimeInfo.propTypes = {
  time: PropTypes.string,
  availabilityPreferences: PropTypes.arrayOf(PropTypes.shape()),
}

export default TimeInfo
