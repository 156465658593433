import React from 'react'
import PropTypes from 'prop-types'

import FormattedOrRawMessage from 'views/shared/FormattedOrRawMessage'
import * as S from './styled'

const InfoItem = ({ icon, value, description, offsetBottom, dataCy, linkText, link, imgUrl }) => (
  <S.Info offsetBottom={offsetBottom} data-cy={dataCy}>
    {imgUrl ? <S.VideoCallImage src={imgUrl} /> : <S.InfoIcon iconName={icon} size={20} />}
    <div>
      {value && (
        <S.InfoValue data-cy="info-item-value">
          <FormattedOrRawMessage message={value} />
        </S.InfoValue>
      )}
      <S.InfoDescription data-cy="info-item-description">
        <FormattedOrRawMessage message={description} />
      </S.InfoDescription>
      {linkText && (
        <S.InfoLink target="_blank" href={link} data-cy="info-item-address-link">
          <FormattedOrRawMessage message={linkText} />
        </S.InfoLink>
      )}
    </div>
  </S.Info>
)

InfoItem.defaultProps = {
  offsetBottom: 16,
  value: null,
  dataCy: null,
  link: null,
  linkText: null,
  imgUrl: null,
  icon: null,
}

InfoItem.propTypes = {
  dataCy: PropTypes.string,
  offsetBottom: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  icon: PropTypes.string,
  imgUrl: PropTypes.string,
  link: PropTypes.string,
  linkText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export default InfoItem
