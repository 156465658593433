import React, { memo, useContext } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import { useSelector } from 'react-redux'

import WidgetContext from 'views/Widget/WidgetContext'
import BackButton from 'views/shared/BackButton'
import Button from 'views/shared/Button'
import MobileCloseWidgetBtn from 'views/shared/MobileCloseWidgetBtn'
import {
  isPreviewModeSelector,
  isClientPortalWidgetAssignedSelector,
  widgetClientPortalStatusSelector,
  widgetPreviewClientPortalStatusSelector,
} from 'state/concepts/widget/selectors'
import { passedCurrentClientSelector } from 'state/concepts/session/selectors'
import { CLIENT_PORTAL_STATUSES } from 'constants'
import User from './User'

const Header = memo(({ withBackButton, onGoBack, widgetLogo, isUserHidden, history }) => {
  const { handleCloseWidget } = useContext(WidgetContext)
  const isPreviewMode = useSelector(isPreviewModeSelector)
  const clientPortalStatus = useSelector(widgetClientPortalStatusSelector)
  const previewClientPortalEnable = useSelector(widgetPreviewClientPortalStatusSelector)
  const passedCurrentClient = useSelector(passedCurrentClientSelector)
  const isClientPortalWidgetAssigned = useSelector(isClientPortalWidgetAssignedSelector)
  const isClientPortalEnabled = isPreviewMode
    ? previewClientPortalEnable
    : clientPortalStatus === CLIENT_PORTAL_STATUSES.enabled && isClientPortalWidgetAssigned
  const isUserShown = !isUserHidden && isClientPortalEnabled && !passedCurrentClient

  return (
    <Header.Container>
      <Header.ButtonContainer>
        {handleCloseWidget && <MobileCloseWidgetBtn onCloseWidget={handleCloseWidget} />}
        {withBackButton && <BackButton onGoBack={onGoBack} />}
        <Header.Logo src={widgetLogo} />
        {isUserShown && <User history={history} />}
      </Header.ButtonContainer>
    </Header.Container>
  )
})

Header.displayName = 'Header'

Header.defaultProps = {
  withBackButton: false,
  isUserHidden: false,
}

Header.propTypes = {
  withBackButton: PropTypes.bool,
  onGoBack: PropTypes.func.isRequired,
  widgetLogo: PropTypes.string.isRequired,
  isUserHidden: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
}

Header.Container = styled.header`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ theme }) => theme.sizes.headerHeightDesktop};
  border-bottom: 1px solid ${({ theme }) => theme.colors.black300};
  background: white;
  flex-shrink: 0;
`

Header.ButtonContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  width: 100%;
  height: 100%;
  margin: auto;
`

Header.Logo = styled.img`
  max-width: 196px;
  width: auto;
  max-height: 28px;
  ${media.desktop`
    max-width: 224px;
    max-height: 32px;
  `}
`

Header.HeaderLoginBtnWrapper = styled.div`
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
`

Header.HeaderLoginBtn = styled(Button)`
  color: ${({ theme }) => theme.settings.color};
`

export default Header
