import * as types from './types'

export const fetchReviews = providerId => ({
  type: types.FETCH_REVIEWS,
  providerId,
})

export const addReviews = reviewIds => ({
  type: types.ADD_REVIEWS,
  reviewIds,
})

export const setCurrentPage = pageNumber => ({
  type: types.SET_REVIEWS_PAGE,
  pageNumber,
})

export const resetReviews = () => ({
  type: types.RESET_REVIEWS,
})
