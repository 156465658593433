import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

import SvgIcon from 'views/shared/Icons'
import WidgetButton from 'views/shared/WidgetButton'

const ContentLayout = ({ children, handleCloseWidget }) => (
  <ContentLayout.Wrapper>
    <ContentLayout.ButtonContainer>
      <WidgetButton onClick={handleCloseWidget} dataCy="widget-close-button" bgColor="#fff">
        <SvgIcon iconName="iconCross" size={20} color="black700" />
      </WidgetButton>
    </ContentLayout.ButtonContainer>
    {children}
  </ContentLayout.Wrapper>
)

ContentLayout.propTypes = {
  children: PropTypes.node.isRequired,
  handleCloseWidget: PropTypes.func.isRequired,
}

ContentLayout.Wrapper = styled.div`
  display: flex;
  flex-direction: ${({ theme }) => (theme.settings.position === 'right' ? 'row' : 'row-reverse')};
  height: 100%;
  min-height: 100%;

  ${media.tablet`
    min-height: 100vh;
  `}
`

ContentLayout.ButtonContainer = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: 20px;
  padding-top: 0;
  height: auto;

  ${media.desktop`
    display: block;
    position: relative;
    left: 0;
    height: 100vh;
    padding-top: 16px;
    ${({ theme }) => (theme.settings.position === 'right' ? 'padding-right: 16px' : 'padding-left: 16px')};
    background-color: transparent;
    box-sizing: content-box;
  `}
`

export default ContentLayout
