import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import { equals } from 'ramda'

import { isErrorStatusUnprocessableEntity } from 'utils/getErrorStatus'
import getBaseError from 'utils/getBaseError'
import { ERROR_REASONS } from 'constants'
import errorReason from 'utils/form/errorReason'
import { CREATE_BOOKING } from 'state/concepts/booking/types'
import { showModal } from 'state/modal/actions'
import { workspaceIdSelector } from 'state/concepts/widget/selectors'
import { serviceIdSelector } from 'state/concepts/services/selectors'
import { providerIdSelector } from 'state/concepts/providers/selectors'
import { timeSelector, timezoneSelector } from 'state/concepts/time/selectors'
import { dataApiRequest, dataApiSuccess, dataApiFailure } from 'state/data/actions'
import { locationIdSelector } from 'state/concepts/locations/selectors'
import { setBooking } from '../actions'
import { createBookingEndpoint } from '../endpoints'

const createBookingOperation = createLogic({
  type: CREATE_BOOKING,
  latest: true,

  async process({ action: { pushFunction }, httpClient, getState }, dispatch, done) {
    const state = getState()
    const workspaceId = workspaceIdSelector(state)
    const { endpoint, url } = createBookingEndpoint(workspaceId)

    const params = {
      user_profile_id: providerIdSelector(state),
      service_id: serviceIdSelector(state),
      service_provider_id: providerIdSelector(state),
      start_time: timeSelector(state),
      client_timezone: timezoneSelector(state).id,
      location_id: locationIdSelector(state),
      include: 'booking_setting,workspace',
    }

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.post(url, params)
      const response = normalize(data, { endpoint })

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(setBooking(data.data.id))

      if (pushFunction) {
        pushFunction()
      }
    } catch (error) {
      dispatch(dataApiFailure({ endpoint }))

      if (isErrorStatusUnprocessableEntity(error)) {
        const isStartTimeReason = equals(ERROR_REASONS.startTime, errorReason(error))

        dispatch(
          showModal({
            modalType: 'BOOKING_ERROR',
            modalProps: {
              time: isStartTimeReason ? timeSelector(state) : null,
              baseError: getBaseError(error),
            },
          }),
        )
      }
    }
    done()
  },
})

export default createBookingOperation
