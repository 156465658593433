import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { truncate } from 'lodash'

import Tooltip from 'views/shared/Tooltip'

const TruncatedText = ({ phrase, maxChars, className, placement, noWrap, textColor }) =>
  phrase.length > maxChars ? (
    <Tooltip
      placement={placement}
      title={
        <TruncatedText.Text className={className} noWrap={noWrap} textColor={textColor}>
          {truncate(phrase, { length: maxChars })}
        </TruncatedText.Text>
      }
      content={phrase}
    />
  ) : (
    phrase
  )

TruncatedText.Text = styled.div`
  color: ${({ theme, textColor }) => `${theme.colors[textColor]}`};
  text-overflow: ellipsis;
  overflow: hidden;
  ${({ noWrap }) => noWrap && 'white-space: nowrap;'};
`

TruncatedText.defaultProps = {
  placement: 'top',
  noWrap: false,
  phrase: '',
  className: '',
  textColor: 'black1000',
}

TruncatedText.propTypes = {
  className: PropTypes.string,
  placement: PropTypes.string,
  phrase: PropTypes.string,
  maxChars: PropTypes.number.isRequired,
  noWrap: PropTypes.bool,
  textColor: PropTypes.string,
}
export default TruncatedText
