import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

const StepName = styled.p`
  display: none;
  margin-top: 6px;
  margin-bottom: 6px;
  font-family: ${({ theme }) => theme.fonts.interTight};
  font-size: 16px;
  line-height: ${24 / 16};
  font-weight: 600;
  color: ${({ theme }) => theme.colors.black700};

  ${media.tablet`
    display: block;
  `}
`

const Title = styled.p`
  margin-bottom: 0;
  margin-right: 6px;
  line-height: ${20 / 14};
  color: ${({ theme }) => theme.colors.black1000};

  ${media.tablet`
    margin-right: 0;
    margin-bottom: 2px;
  `}
`

const Desc = styled.p`
  display: flex;
  align-items: flex-start;
  line-height: ${20 / 14};
  color: ${({ theme }) => theme.colors.black700};
  margin-bottom: 8px;
`

const TimeStepInfo = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${media.tablet`
    align-items: flex-start;
    flex-direction: column;
  `}
`

const ShowButton = styled.button`
  color: ${({ theme }) => theme.settings.color};
`

export { StepName, Title, Desc, TimeStepInfo, ShowButton }
