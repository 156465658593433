import React from 'react'
import { Switch, Route } from 'react-router-dom'

import BookingView from 'views/BookingView'
import ServicesListView from 'views/ServicesListView'
import ProvidersListView from 'views/ProvidersListView'
import TimeSlotsView from 'views/TimeSlotsView'
import ReviewBookingView from 'views/ReviewBookingView'
import ConfirmationView from 'views/ConfirmationView'
import LocationsListView from 'views/LocationsListView'
import Login from 'views/Login'
import AvailabilityPreferences from 'views/AvailabilityPreferences'
import ReviewWaitlistRequestView from 'views/ReviewWaitlistRequestView'
import WaitlistRequestConfirmationView from 'views/WaitlistRequestConfirmationView'
import * as S from './styled'

const WidgetContent = () => (
  <S.Wrapper>
    <Switch>
      <Route exact path="/">
        <BookingView />
      </Route>
      <Route exact path="/login">
        <Login />
      </Route>
      <Route exact path="/locations">
        <LocationsListView />
      </Route>
      <Route exact path="/services">
        <ServicesListView />
      </Route>
      <Route exact path="/providers">
        <ProvidersListView />
      </Route>
      <Route exact path="/time">
        <TimeSlotsView />
      </Route>
      <Route exact path="/availability_preferences">
        <AvailabilityPreferences />
      </Route>
      <Route exact path="/review">
        <ReviewBookingView />
      </Route>
      <Route exact path="/confirmation">
        <ConfirmationView />
      </Route>
      <Route exact path="/review_waitlist_request">
        <ReviewWaitlistRequestView />
      </Route>
      <Route exact path="/waitlist_request_confirmation">
        <WaitlistRequestConfirmationView />
      </Route>
    </Switch>
  </S.Wrapper>
)

export default React.memo(WidgetContent)
