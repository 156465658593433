import { connect } from 'react-redux'

import { bookingPriceSelector } from 'state/concepts/booking/selectors'
import PriceBarComponent from './component'

const mapStateToProps = state => ({
  price: bookingPriceSelector(state),
})

export default connect(mapStateToProps)(PriceBarComponent)
