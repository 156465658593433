import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import FormattedPrice from 'views/shared/FormattedPrice'
import * as S from './styled'

const PriceBar = ({ price }) =>
  price && (
    <S.PriceBar>
      <S.PriceBarTitle>
        <S.PriceBarIcon>
          <S.Icon iconName="price" />
        </S.PriceBarIcon>
        <S.PriceMessage data-cy="price-bar-title">
          <FormattedMessage id="shared.price" />
        </S.PriceMessage>
      </S.PriceBarTitle>
      <S.PriceBarPrice data-cy="price-bar-price">
        <FormattedPrice price={price} />
      </S.PriceBarPrice>
    </S.PriceBar>
  )

PriceBar.defaultProps = {
  price: null,
}

PriceBar.propTypes = {
  price: PropTypes.string,
}

export default PriceBar
