import * as types from './types'

export const fetchProviders = () => ({
  type: types.FETCH_PROVIDERS,
})

export const setProvider = providerId => ({
  type: types.SET_PROVIDER,
  providerId,
})

export const setProviders = providerIds => ({
  type: types.SET_PROVIDERS,
  providerIds,
})

export const setFilterParams = filterParams => ({
  type: types.SET_PROVIDERS_FILTER_PARAMS,
  filterParams,
})

export const filterProviders = filters => ({
  type: types.FILTER_PROVIDERS,
  filters,
})

export const setCurrentPage = pageNumber => ({
  type: types.SET_PROVIDERS_PAGE,
  pageNumber,
})

export const resetProviders = () => ({
  type: types.RESET_PROVIDERS,
})
