import styled from 'styled-components'

const Wrapper = styled.div`
  color: ${({ theme }) => theme.colors.black1000};
  font-size: 14px;
  line-height: ${20 / 14};
`

const Link = styled.a`
  display: inline-block;
  color: ${({ theme }) => theme.settings.color};

  &:focus {
    opacity: 0.9;
  }

  &:visited {
    color: ${({ theme }) => theme.settings.color};
  }

  &:hover:focus {
    color: ${({ theme }) => theme.settings.color};
  }
`

export { Wrapper, Link }
