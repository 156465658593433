import isPresent from 'utils/isPresent'

const disabledTimeOptions = startTime => {
  const disabledHours = () => Array.from({ length: startTime.hour }, (_, i) => i)

  const disabledMinutes = selectedHour => {
    if (!isPresent(selectedHour) || selectedHour !== startTime.hour) return []

    return Array.from({ length: Math.ceil(startTime.minute / 15) }, (_, i) => i * 15)
  }

  return { disabledHours, disabledMinutes }
}

export default disabledTimeOptions
