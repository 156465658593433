import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import responseIds from 'utils/responseIds'
import locationsFilter from 'utils/locationsFilter'
import { dataApiRequest, dataApiSuccess, dataApiFailure } from 'state/data/actions'
import { workspaceIdSelector, widgetIdSelector } from 'state/concepts/widget/selectors'
import { serviceIdSelector } from 'state/concepts/services/selectors'
import { providerIdSelector } from 'state/concepts/providers/selectors'
import { setLocations } from 'state/concepts/locations/actions'
import { fetchLocationsEndpoint } from 'state/concepts/locations/endpoints'
import { FETCH_LOCATIONS } from 'state/concepts/locations/types'

const fetchLocationsOperation = createLogic({
  type: FETCH_LOCATIONS,
  latest: true,

  async process({ httpClient, getState }, dispatch, done) {
    const state = getState()
    const workspaceId = workspaceIdSelector(state)
    const widgetId = widgetIdSelector(state)
    const serviceId = serviceIdSelector(state)
    const providerId = providerIdSelector(state)
    const { endpoint, url } = fetchLocationsEndpoint(workspaceId, widgetId)

    const params = {
      filter: locationsFilter(serviceId, providerId),
      page: { number: 1, size: 1000 },
    }

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.get(url, { params })
      const response = normalize(data, { endpoint })

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(setLocations(responseIds(data)))
    } catch {
      dispatch(dataApiFailure({ endpoint }))
    }
    done()
  },
})

export default fetchLocationsOperation
