import React from 'react'
import { useStripe, useElements } from '@stripe/react-stripe-js'

import BookingForm from './BookingForm'

const BookingFormInjected = props => {
  const stripe = useStripe()
  const elements = useElements()

  return <BookingForm stripe={stripe} elements={elements} {...props} />
}

export default BookingFormInjected
