import styled from 'styled-components'
import SvgIcon from 'views/shared/Icons'

export const Wrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-right: 1px solid ${({ theme }) => theme.colors.black400};
`

export const Selected = styled.button`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 13px 12px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`

export const Divider = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.black300};
  margin: 8px 0;
`

export const SelectedInfo = styled.div`
  margin-right: 10px;
`

export const Dropdown = styled.div`
  position: absolute;
  top: calc(100% + 1px);
  left: 0;
  min-width: 331px;
  background: white;
  border-radius: 4px;
  box-shadow: 0 8px 16px 0 ${({ theme }) => theme.colors.black400};
  z-index: 1;

  .main-search {
    outline: 1px solid ${({ theme }) => theme.colors.grey400};
    border-radius: 3px;
    margin: 8px 8px 0;
  }

  input {
    padding-top: 10px;
    padding-bottom: 10px;
  }
`

export const List = styled.div`
  padding: 8px 0;
  max-height: 190px;
  overflow: auto;
`

export const ListItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 40px;
  margin-bottom: 0;
  padding: 8px 16px;
  text-align: left;
  white-space: nowrap;
  cursor: pointer;
`

export const CodeGray = styled.div`
  margin-left: auto;
  color: ${({ theme }) => theme.colors.black500};
`

export const DownArrowIcon = styled(SvgIcon)`
  fill: ${({ theme }) => theme.colors.black500};
  margin-left: auto;
  transform: ${({ isOpen }) => isOpen && 'rotate(180deg)'};
`

export const ImageFlag = styled.img`
  width: 22px;
  vertical-align: middle;
  border-radius: 4px;
`

export const Code = styled.span`
  margin-left: 8px;
  vertical-align: middle;
  color: ${({ theme }) => theme.colors.black1000};
`

export const NoResults = styled.div`
  padding: 8px 16px;
  color: ${({ theme }) => theme.colors.black500};
`
