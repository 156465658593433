import React from 'react'
import PropTypes from 'prop-types'

import SvgIcon from 'views/shared/Icons'
import * as S from './styled'

const Header = ({ header, handleClose }) => (
  <S.Wrapper>
    <S.Container>{header}</S.Container>
    {handleClose && (
      <S.CloseButton onClick={handleClose}>
        <SvgIcon size={24} color="black500" iconName="iconCross" />
      </S.CloseButton>
    )}
  </S.Wrapper>
)

Header.defaultProps = {
  handleClose: undefined,
}

Header.propTypes = {
  handleClose: PropTypes.func,
  header: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
}

export default Header
