import { connect } from 'react-redux'

import { createBookingEndpoint } from 'state/concepts/booking/endpoints'
import { createBookingLoadingSelector } from 'state/concepts/booking/selectors'

import ProviderTimeSlotsCardComponent from './component'

const makeMapStateToProps = initialState => {
  const endpoint = createBookingEndpoint(initialState)

  return state => ({
    isLoading: createBookingLoadingSelector(state, endpoint),
  })
}

export default connect(makeMapStateToProps)(ProviderTimeSlotsCardComponent)
