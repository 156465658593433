import React from 'react'
import PropTypes from 'prop-types'

import FormattedOrRawMessage from 'views/shared/FormattedOrRawMessage'
import * as S from './styled'

const RadioButtonRound = ({ text, id, name, className, value, checked, onChange }) => (
  <S.Wrapper className={className}>
    <S.Input type="radio" id={id} name={name} value={value} checked={checked} onChange={onChange} />
    <S.Label htmlFor={id}>
      <S.Icon />
    </S.Label>
    <S.Text>
      <FormattedOrRawMessage message={text} />
    </S.Text>
  </S.Wrapper>
)

RadioButtonRound.defaultProps = {
  className: '',
  checked: false,
}

RadioButtonRound.propTypes = {
  text: PropTypes.shape().isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  value: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}

export default RadioButtonRound
