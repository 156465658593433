import * as types from './types'

export const createBooking = pushFunction => ({
  type: types.CREATE_BOOKING,
  pushFunction,
})

export const recreateBooking = replaceFunction => ({
  type: types.RECREATE_BOOKING,
  replaceFunction,
})

export const updateBooking = (values, form, pushFunction, confirmCardPayment, getElement, paymentMethod) => ({
  type: types.UPDATE_BOOKING,
  values,
  form,
  pushFunction,
  confirmCardPayment,
  getElement,
  paymentMethod,
})

export const setBooking = bookingId => ({
  type: types.SET_BOOKING,
  bookingId,
})

export const setStripeError = stripeError => ({
  type: types.SET_STRIPE_ERROR,
  stripeError,
})

export const deleteBooking = onSuccess => ({
  type: types.DELETE_BOOKING,
  onSuccess,
})

export const updatePendingClient = ({ firstName, lastName, email }) => ({
  type: types.UPDATE_PENDING_CLIENT,
  firstName,
  lastName,
  email,
})
