import React from 'react'
import PropTypes from 'prop-types'

import Theme from 'constants/Theme'
import Header from 'views/shared/Header'
import PageTitle from 'views/shared/PageTitle'
import MainContainer from 'views/shared/MainContainer'
import OnlineAppointment from './OnlineAppointment'
import OfflineLocations from './OfflineLocations'
import * as S from './styled'

const SelectLocation = ({ onSearch, locations, isLoading, handleSelect, showOnlineLocation }) => (
  <MainContainer bgColor={Theme.colors.black200}>
    <Header />
    <S.ScrollWrapper>
      <S.StyledContainer fluid>
        <PageTitle text={{ id: 'locationsListView.title' }} />
        {showOnlineLocation && (
          <OnlineAppointment
            title={{ id: 'locationsListView.onlineAppointment.title' }}
            description={{ id: 'locationsListView.onlineAppointment.description' }}
            onSelect={handleSelect()}
          />
        )}

        <OfflineLocations
          onSearch={onSearch}
          locations={locations}
          isLoading={isLoading}
          locationsCount={locations.length}
          onSelect={handleSelect}
        />
      </S.StyledContainer>
    </S.ScrollWrapper>
  </MainContainer>
)

SelectLocation.defaultProps = {
  locations: [],
  isLoading: undefined,
}

SelectLocation.propTypes = {
  showOnlineLocation: PropTypes.bool.isRequired,
  onSearch: PropTypes.func.isRequired,
  locations: PropTypes.arrayOf(PropTypes.shape()),
  isLoading: PropTypes.bool,
  handleSelect: PropTypes.func.isRequired,
}

export default SelectLocation
