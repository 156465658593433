import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

const Wrapper = styled.div`
  position: relative;
  border-bottom: 1px solid ${({ theme }) => theme.colors.black300};
  padding-right: 12px;
  padding-left: 12px;
  flex-shrink: 0;
  ${media.tablet`
    padding-right: 24px;
    padding-left: 24px;
  `}
`

const Container = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
`

const CloseButton = styled.button`
  position: absolute;
  right: 24px;
  top: 14px;
  background-color: transparent;
  border: none;
  padding: 0;
  font-size: 24px;
  color: ${({ theme }) => theme.colors.black500};
`

export { Wrapper, Container, CloseButton }
