import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import TruncatedText from 'views/shared/TruncatedText'

import { STEP_TYPE_PROPERTIES, MAX_NAME_LENGTH_IN_STEPPER } from 'constants'
import ModalContext from 'views/Modal/ModalContext'
import LocationDetailsModal from 'views/LocationsListView/LocationDetailsModal'
import * as S from './styled'
import Step from '../Step'

const LocationStep = ({ steps, offlineLocation }) => {
  const { showModal } = useContext(ModalContext)

  return (
    <Step iconName="location" type={STEP_TYPE_PROPERTIES.location} steps={steps} dataCy="widget-stepper-location">
      {offlineLocation ? (
        <S.Wrap>
          <S.StepName data-cy="widget-stepper-location-title">
            <FormattedMessage id="location.title" />
          </S.StepName>
          <S.Title
            onClick={showModal(LocationDetailsModal, { location: offlineLocation })}
            data-cy="widget-stepper-name"
          >
            <TruncatedText
              className="location-name"
              phrase={offlineLocation.name}
              maxChars={MAX_NAME_LENGTH_IN_STEPPER}
            />
          </S.Title>
          <S.Description data-cy="widget-stepper-address">
            <TruncatedText phrase={offlineLocation.addressLine} maxChars={MAX_NAME_LENGTH_IN_STEPPER} />
          </S.Description>
        </S.Wrap>
      ) : (
        <S.Wrap>
          <S.StepName data-cy="widget-stepper-location-name">
            <FormattedMessage id="location.title" />
          </S.StepName>
          <S.Description data-cy="widget-stepper-location-description">
            <FormattedMessage id="location.onlineAppointment" />
          </S.Description>
        </S.Wrap>
      )}
    </Step>
  )
}

LocationStep.defaultProps = {
  offlineLocation: null,
}

LocationStep.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
  offlineLocation: PropTypes.shape(),
}

export default LocationStep
