import { media } from 'styled-bootstrap-grid'
import styled from 'styled-components'

const SummaryWrapper = styled.section`
  padding: 16px;
  max-width: 648px;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  border: 1px solid ${({ theme }) => theme.colors.black300};

  ${media.desktop`
    padding: 32px;
  `}
`

const Col = styled.div`
  flex-grow: 1;
`

export { SummaryWrapper, Col }
