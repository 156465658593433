import styled, { css } from 'styled-components'
import { Col, Container, media, Row } from 'styled-bootstrap-grid'
import { rgba } from 'polished'

import SvgIcon from 'views/shared/Icons'
import SubmitFormButton from 'views/shared/SubmitFormButton'

const ScrollWrapper = styled.div`
  flex-grow: 1;
  overflow-y: auto;
`

const Wrapper = styled(Container)`
  flex-grow: 1;
  max-width: ${({ theme }) => theme.sizes.containerMaxWidth};
  height: ${({ theme }) => `calc(100vh - ${theme.sizes.headerHeight})`};
  padding-top: 16px;
  padding-bottom: 8px;

  ${media.tablet`
    padding-top: 32px;
    padding-right: 36px;
    padding-left: 36px;
  `}

  ${media.desktop`
    height: ${({ theme }) => `calc(100vh - ${theme.sizes.headerHeightDesktop})`};
  `}
`

const StyledRow = styled(Row)`
  ${media.desktop`
    height: calc(100% - 86px);
  `}
`

const StyledLeftCol = styled(Col)`
  position: relative;
`

/* stylelint-disable */
const StyledCol = styled(Col)`
  position: relative;

  ${({ isLoading }) =>
    isLoading &&
    css`
      display: flex;
      justify-content: center;
      align-items: start;
    `};

  ${media.tablet`
    margin-bottom: 90px;
  `}
`

const ScrolledBlock = styled.div`
  ${media.tablet`
    position: sticky;
    top: 4px;
  `}
`

const WrapperShadow = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 0 8px 1px rgba(${({ theme }) => theme.colors.boxShadowColor}, 0.1);
`

const FormContainer = styled.div`
  padding-top: 16px;
  padding-right: 16px;
  padding-left: 16px;
  ${media.tablet`
    padding: 16px 72px 16px 32px;
  `}
`

const FormHeader = styled.div`
  padding: 16px 16px 8px;
  display: flex;
  align-items: center;
  ${media.tablet`
    padding: 32px 32px 8px;
  `}
`

const FormFooter = styled.div`
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.colors.black300};
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  ${media.tablet`
    padding: 16px 32px 16px;
  `}
`

const BackButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 36px;
  height: 36px;
  background-color: ${({ theme }) => rgba(theme.settings.color, 0.1)};
  border-radius: 2px;
  margin-right: 24px;
`

const BackIcon = styled(SvgIcon)`
  transform: rotate(90deg);
  fill: ${({ theme }) => theme.settings.color} !important;
`

const FormTitle = styled.h3`
  font-family: ${({ theme }) => theme.fonts.interTight};
  font-size: 16px;
  line-height: ${28 / 16};
  font-weight: 600;
  color: ${({ theme }) => theme.colors.black1000};
`

const FormWrap = styled.div`
  background-color: white;
  margin-bottom: 16px;

  ${media.tablet`
    border: 1px solid ${({ theme }) => theme.colors.black300};
  `}
`

const Button = styled(SubmitFormButton)`
  background-color: ${({ theme }) => theme.settings.color};
`

export {
  ScrollWrapper,
  Wrapper,
  StyledRow,
  StyledLeftCol,
  StyledCol,
  ScrolledBlock,
  WrapperShadow,
  FormContainer,
  FormHeader,
  FormFooter,
  BackButton,
  BackIcon,
  FormTitle,
  FormWrap,
  Button,
}
