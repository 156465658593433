import React from 'react'
import PropTypes from 'prop-types'
import { prop } from 'ramda'
import { FormattedMessage } from 'react-intl'

import FormattedRange from 'views/shared/FormattedRange'
import * as S from './styled'

const AboutTab = ({ provider, handleSelect }) => (
  <>
    <S.ExpertLabelWrapper>
      <S.ServiceIcon iconName="bag" offsetRight={8} size={20} />
      <S.ExpertLabelText data-cy="available-service-count">{prop('activeServicesCount', provider)}</S.ExpertLabelText>
      <S.ExpertLabelDesc data-cy="available-services-count-text">
        <FormattedMessage
          id="providersListView.servicesAvailable"
          values={{ count: prop('activeServicesCount', provider) }}
        />
      </S.ExpertLabelDesc>
    </S.ExpertLabelWrapper>
    {!handleSelect && (
      <S.ExpertLabelWrapper>
        <S.ServiceIcon iconName="card" offsetRight={8} size={20} />
        <S.ExpertLabelText data-cy="providers-modal-service-price">
          <FormattedRange {...provider} />
        </S.ExpertLabelText>
      </S.ExpertLabelWrapper>
    )}
    <S.ExpertDescr>{prop('about', provider)}</S.ExpertDescr>
  </>
)

AboutTab.defaultProps = {
  handleSelect: undefined,
}

AboutTab.propTypes = {
  provider: PropTypes.shape().isRequired,
  handleSelect: PropTypes.func,
}

export default AboutTab
