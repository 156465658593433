import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'

import isPresent from 'utils/isPresent'
import { formattedTime, formatSlotDateTitle, groupNearestTimeSlots } from 'utils/dateTime'
import { isReviewsFeatureEnabledSelector } from 'state/concepts/widget/selectors'
import FormattedPriceOrRange from 'views/shared/FormattedPriceOrRange'
import SelectableButton from 'views/shared/SelectableButton'
import ConsultantInfo from 'views/shared/ConsultantInfo'
import ReviewsInformation from 'views/shared/ReviewsInformation'
import * as S from './styled'

const ProviderTimeSlotsCard = memo(
  ({ provider, provider: { id, about, nearestTimeSlots: allNearestTimeSlots }, handleSelect, dataCy, isLoading }) => {
    const isReviewsFeatureEnabled = useSelector(isReviewsFeatureEnabledSelector)
    const nearestTimeSlots = groupNearestTimeSlots(allNearestTimeSlots)

    return (
      <S.Card data-cy={dataCy}>
        <S.CardTop>
          <S.CardTopLeft>
            <ConsultantInfo avatarSize={52} {...provider} />
          </S.CardTopLeft>

          <S.Price>
            <FormattedPriceOrRange {...provider} />
          </S.Price>
        </S.CardTop>

        {isReviewsFeatureEnabled && <ReviewsInformation provider={provider} handleSelect={handleSelect} />}
        <S.CardDescription description={about} isWithoutBottomOffset />

        <S.Price isVisibleMobile>
          <FormattedPriceOrRange {...provider} />
        </S.Price>

        <div>
          <S.SlotsTitle data-cy="provider-card-slots-title">
            {isPresent(nearestTimeSlots) ? (
              <FormattedMessage {...formatSlotDateTitle(nearestTimeSlots[0])} />
            ) : (
              <FormattedMessage id="providersListView.noAvailableTimeSlots" />
            )}
          </S.SlotsTitle>
          <S.Slots>
            {isPresent(nearestTimeSlots) &&
              nearestTimeSlots.map((slot, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <S.SlotsCol key={`${slot}-${index}-${id}`} nearestTimeSlots={nearestTimeSlots.length}>
                  <SelectableButton
                    time={slot}
                    text={formattedTime(slot)}
                    onClick={handleSelect(provider, slot)}
                    disabled={isLoading}
                    isTimeslot
                  />
                </S.SlotsCol>
              ))}
            <S.SlotsCol isTimeSlotBtn>
              <SelectableButton
                text={
                  isPresent(nearestTimeSlots) ? { id: 'shared.otherTime' } : { id: 'providersListView.seeOtherOptions' }
                }
                onClick={handleSelect(provider)}
                disabled={isLoading}
                noMinWidth
              />
            </S.SlotsCol>
          </S.Slots>
        </div>
      </S.Card>
    )
  },
)

ProviderTimeSlotsCard.displayName = 'ProviderTimeSlotsCard'

ProviderTimeSlotsCard.defaultProps = {
  dataCy: undefined,
  isLoading: undefined,
  handleSelect: () => {},
}

ProviderTimeSlotsCard.propTypes = {
  isLoading: PropTypes.bool,
  dataCy: PropTypes.string,
  handleSelect: PropTypes.func,
  provider: PropTypes.shape({
    about: PropTypes.string,
    id: PropTypes.string.isRequired,
    nearestTimeSlots: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
}

export default ProviderTimeSlotsCard
