import React from 'react'
import PropTypes from 'prop-types'

import { STEP_TYPE_PROPERTIES } from 'constants'
import Step from '../Step'
import TimeInfo from './TimeInfo'

const TimeStep = ({ steps, time, availabilityPreferences }) => (
  <Step iconName="calendar" type={STEP_TYPE_PROPERTIES.time} steps={steps}>
    <TimeInfo time={time} availabilityPreferences={availabilityPreferences} />
  </Step>
)

TimeStep.defaultProps = {
  time: null,
  availabilityPreferences: null,
}

TimeStep.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
  time: PropTypes.string,
  availabilityPreferences: PropTypes.arrayOf(PropTypes.shape()),
}

export default TimeStep
