import React from 'react'

import TruncatedTextComponent from './component'

class TruncatedText extends React.Component {
  state = {
    isCollapsed: true,
  }

  handleToggleClick = () => {
    const { isCollapsed } = this.state

    this.setState({ isCollapsed: !isCollapsed })
  }

  render() {
    return <TruncatedTextComponent {...this.props} {...this.state} onToggleClick={this.handleToggleClick} />
  }
}

export default TruncatedText
