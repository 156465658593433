import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider, StyleSheetManager } from 'styled-components'
import { GridThemeProvider } from 'styled-bootstrap-grid'
// eslint-disable-next-line import/no-extraneous-dependencies
import { StyleProvider } from '@ant-design/cssinjs'

import isPresent from 'utils/isPresent'
import applyTheme from 'utils/applyTheme'
import ModalProvider from 'views/Modal/ModalProvider'
import ModalRoot from 'views/Modal/ModalRoot'
import Modal from 'views/ModalRoot'

import WidgetContent from 'views/WidgetContent'
import gridTheme from 'constants/Theme/gridTheme'
import GlobalStyles from 'constants/GlobalStyles'
import WidgetContext from './WidgetContext'

const ContentLayout = ({ contentDocument, settings, handleCloseWidget, contentWindow }) =>
  isPresent(settings) && (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <WidgetContext.Provider value={{ handleCloseWidget, contentWindow }}>
      <StyleProvider container={contentDocument.head}>
        <ThemeProvider theme={applyTheme(settings)}>
          <StyleSheetManager target={contentDocument.head}>
            <GridThemeProvider gridTheme={gridTheme}>
              <GlobalStyles />
              <ModalProvider>
                <ModalRoot />
                <Modal />
                <WidgetContent />
              </ModalProvider>
            </GridThemeProvider>
          </StyleSheetManager>
        </ThemeProvider>
      </StyleProvider>
    </WidgetContext.Provider>
  )

ContentLayout.propTypes = {
  settings: PropTypes.shape(),
  contentDocument: PropTypes.shape(),
  contentWindow: PropTypes.shape(),
  handleCloseWidget: PropTypes.func,
}

ContentLayout.defaultProps = {
  settings: null,
  contentDocument: null,
  contentWindow: undefined,
  handleCloseWidget: undefined,
}

export default ContentLayout
