import React from 'react'
import PropTypes from 'prop-types'
import { filter, indexOf, toLower, trim } from 'ramda'
import { injectIntl } from 'react-intl'

import TimezoneDropdownComponent from './component'

class TimezoneDropdown extends React.Component {
  state = {
    options: [],
    visible: false,
    filterValue: '',
  }

  targetEl = React.createRef()

  static propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    onTimezoneChange: PropTypes.func.isRequired,
  }

  componentDidMount = () => {
    document.addEventListener('click', this.handleClickOutside, true)
  }

  componentWillUnmount = () => {
    document.removeEventListener('click', this.handleClickOutside, true)
  }

  handleClickOutside = event => {
    const { visible } = this.state

    if (visible && !this.targetEl.current.contains(event.target)) {
      this.handleClose()
    }
  }

  handleClose = () => {
    this.setState({ visible: false })
  }

  handleOpen = () => {
    const { options } = this.props

    this.setState({ options, visible: true })
  }

  handleVisibleChange = () => (!this.state.visible ? this.handleOpen() : this.handleClose())

  makeHandleSelect = timezone => () => {
    this.props.onTimezoneChange(timezone)
    this.handleClose()
  }

  filterTimezones = ({ target: { value } }) => {
    const { options } = this.props

    this.setState({
      options: filter(({ label }) => indexOf(toLower(trim(value)), toLower(label)) !== -1, options),
      filterValue: value,
    })
  }

  clearFilter = () => {
    const { options } = this.props

    this.setState({
      options,
      filterValue: '',
    })
  }

  render = () => (
    <TimezoneDropdownComponent
      ref={this.targetEl}
      {...this.props}
      {...this.state}
      onVisibleChange={this.handleVisibleChange}
      makeHandleSelect={this.makeHandleSelect}
      filterTimezones={this.filterTimezones}
      clearFilter={this.clearFilter}
    />
  )
}

export default injectIntl(TimezoneDropdown)
