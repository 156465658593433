import styled from 'styled-components'

export const ReviewsInformation = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  cursor: pointer;
`

export const ReviewsCount = styled.div`
  color: ${({ theme }) => theme.settings.color};
`
