import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import Description from 'views/shared/Card/Description'

export { Card } from '../ProviderCard/styled'

const priceWidth = '176px'

export const CardTop = styled.div`
  margin-bottom: 16px;

  ${media.tablet`
    display: flex;
    flex-direction: row;
  `}
`

export const CardTopLeft = styled.div`
  margin-bottom: 16px;
  flex-shrink: 0;
  flex-grow: 1;

  ${media.tablet`
    flex-shrink: 1;
    margin-bottom: 0;
    margin-right: 16px;
    width: calc(100% - ${priceWidth});
  `}
`

export const CardDescription = styled(Description)`
  margin-bottom: 12px;

  ${media.tablet`
    margin-bottom: 24px;
  `}
`

export const SlotsTitle = styled.p`
  margin-bottom: 12px;
  margin-top: 24px;
  font-size: 16px;
  line-height: ${20 / 16};
  color: ${({ theme }) => theme.colors.black700};
`

export const Slots = styled.div`
  margin-left: -8px;
  margin-right: -8px;
  display: flex;
  flex-wrap: wrap;

  ${media.desktop`
    margin-left: -6px;
    margin-right: -6px;
  `}
`

export const SlotsCol = styled.div`
  display: flex;
  padding-left: 8px;
  padding-right: 8px;
  margin-bottom: 12px;
  width: ${100 / 3}%;

  ${media.desktop`
    ${({ nearestTimeSlots }) =>
      nearestTimeSlots === 5 &&
      `
      &:nth-child(5) {
        display: none;
      }
    `}
    padding-left: 6px;
    padding-right: 6px;
    width: ${({ isTimeSlotBtn }) => (isTimeSlotBtn ? '28%' : '18%')};
  `}
`

export const Price = styled.p`
  display: ${({ isVisibleMobile }) => (isVisibleMobile ? 'block' : 'none')};
  color: ${({ theme }) => theme.colors.black1000};
  font-family: ${({ theme }) => theme.fonts.interTight};
  font-size: 16px;
  line-height: ${24 / 16};
  font-weight: 600;
  margin-bottom: 16px;

  ${media.tablet`
    display: ${({ isVisibleMobile }) => (isVisibleMobile ? 'none' : 'block')};
    font-size: 20px;
    line-height: ${32 / 20};
    width: ${priceWidth};
    text-align: right;
  `}
`
