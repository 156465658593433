/* eslint-disable no-param-reassign */
import Axios from 'axios'
import cookies from 'component-cookie'
import { mergeRight } from 'ramda'

import { sessionRefreshRoute } from 'tools/apiRoutes'
import getCookiesDomain from 'utils/getCookiesDomain'
import removeClientCookies from 'utils/removeClientCookies'
import { API_HOST } from 'constants'

const refreshInterceptor = httpClient => {
  let requestsPool = []
  let isRefreshing = false
  const subscribeToRefresh = listener => requestsPool.push(listener)
  const finishRefresh = accessToken => {
    requestsPool.map(listener => listener(accessToken))
    isRefreshing = false
    requestsPool = []
  }

  return async error => {
    const retryConfig = error.response && error.response.config

    if (retryConfig && error.response.status === 401) {
      const defferedOriginalRequest = new Promise((resolve, reject) => {
        // eslint-disable-next-line consistent-return
        subscribeToRefresh(accessToken => {
          if (!accessToken) {
            return reject(error)
          }

          retryConfig.headers.Authorization = accessToken
          resolve(Axios.request(retryConfig))
        })
      })

      if (isRefreshing) {
        return defferedOriginalRequest
      }

      const { tokens, currentWorkspaceCode, path } = cookies()

      try {
        isRefreshing = true
        const refreshPath = sessionRefreshRoute(currentWorkspaceCode)

        const response = await Axios.post(
          `${API_HOST}/api/v1${refreshPath}`,
          {},
          { headers: { 'X-Refresh-Token': tokens.refresh } },
        )
        const newTokens = mergeRight(tokens, response.data.meta.jwt)

        cookies('tokens', JSON.stringify(newTokens), { path })
        cookies('tokens', JSON.stringify(newTokens), {
          path: `/client/${currentWorkspaceCode}`,
          domain: getCookiesDomain(),
        })

        const newAuthHeader = `Bearer ${response.data.meta.jwt.access}`
        httpClient.defaults.headers.Authorization = newAuthHeader
        finishRefresh(newAuthHeader)
      } catch {
        httpClient.defaults.headers.Authorization = null
        removeClientCookies()
        removeClientCookies({
          path: `/client/${currentWorkspaceCode}`,
          domain: getCookiesDomain(),
        })

        finishRefresh(null)
      }

      return defferedOriginalRequest
    }

    return Promise.reject(error)
  }
}

export default refreshInterceptor
