import { createSelector } from 'reselect'
import { path, prop, pathOr } from 'ramda'

export const dataSelector = prop('data')
const metaSelector = path(['data', 'meta'])

export const loadingSelector = createSelector(
  metaSelector,
  (_, endpoint) => endpoint,
  (meta, endpoint) => path([endpoint, 'loading'], meta),
)

export const linksSelector = createSelector(
  metaSelector,
  (_, endpoint) => endpoint,
  (meta, endpoint) => path([endpoint, 'links'], meta),
)

export const pageCountSelector = createSelector(linksSelector, prop('last'))

export const endpointMetaSelector = createSelector(
  metaSelector,
  (_, endpoint) => endpoint,
  (meta, endpoint) => pathOr({}, [endpoint, 'meta'], meta),
)
