import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'formik'
import { compose } from 'ramda'
import makeAsyncScriptLoader from 'react-async-script'

import { RECAPTCHA_SCRIPT_URL, RECAPTCHA_SITE_KEY } from 'constants'

class Recaptcha extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    action: PropTypes.string.isRequired,
    formik: PropTypes.shape({
      setFieldValue: PropTypes.func.isRequired,
      handleSubmit: PropTypes.func.isRequired,
    }).isRequired,
    isButton: PropTypes.bool,
  }

  static defaultProps = {
    isButton: false,
  }

  get childProps() {
    const { isButton } = this.props
    const key = isButton ? 'onClick' : 'onSubmit'

    return { [key]: this.handleSubmit }
  }

  componentWillUnmount() {
    document.querySelector('.grecaptcha-badge')?.remove?.()
  }

  handleSubmit = async e => {
    e.preventDefault()

    const { action, formik } = this.props
    const token = await window.grecaptcha.enterprise.execute(RECAPTCHA_SITE_KEY, { action })

    await formik.setFieldValue('captcha', { token, action })
    formik.handleSubmit()
  }

  render() {
    const { children } = this.props

    return React.cloneElement(children, this.childProps)
  }
}

export { Recaptcha as RecaptchaContainer }
export default compose(makeAsyncScriptLoader(RECAPTCHA_SCRIPT_URL, { removeOnUnmount: true }), connect)(Recaptcha)
